
import { EKpiStatus } from "../../../../interfaces";

const createScenarios = (data: any) => {
	return data.scenarios.map((scenario: any) => ({
		...scenario,
		active: false,
		notesConfirmed: false
	}));
};
const createKpis = (data: any) => {
	return data.kpis.map((kpi: any) => ({
		...kpi,
		active: false
	}));
};

function isValueCorrect(value: number, correctValue: number, unit: string): boolean {

	let tolerance = unit === '%' ? 0.01 : 1;
	let min = parseFloat((correctValue - tolerance).toFixed(2));
	let max = parseFloat((correctValue + tolerance).toFixed(2));

	return value >= min && value <= max;
}

const createMatrix = (baseMatrix: any, chapterData: any, parameters: any[]) => {

	const paramMap = new Map<string, number>(
		parameters.map(x => [
			`${x.kpiID}-${x.scenarioID}`, x.correctValue
	]));
	return chapterData.matrix.map((row: any, kpiIndex: number) => {
		return row.map((ele: any, scenarioIndex: number) => {

			const kpiID = chapterData.kpis[kpiIndex].id;
			const scenarioID = chapterData.scenarios[scenarioIndex].id;
			const correctValue = paramMap.get(`${kpiID}-${scenarioID}`)!;
			const matrixEntry = baseMatrix[kpiIndex] ? 
				baseMatrix[kpiIndex][scenarioIndex] : undefined;

			const status = !matrixEntry || !matrixEntry.value ? EKpiStatus.EMPTY : (
				isValueCorrect(matrixEntry.value, correctValue, 
					chapterData.kpis[kpiIndex].unit) ? 
				EKpiStatus.CORRECT : EKpiStatus.WRONG
			);
			return (ele && {
				retries: 0,
				...matrixEntry,
				scenarioID, kpiID, correctValue, status
			});
		});
	});
};

export { createScenarios, createKpis, createMatrix };
