import { CreateAssetInput } from "../api";

const parametersSeed = [
    {
        kpis: ["fatturato-anno-1", "utile-anno-1"],
        kpisName: ["Fatturato", "Utile"],
        units: ["€", "€"],
        scenarios: ["consolidato"],
        scenariosName: ["Il Consolidato"]
    },
    {
        kpis: [
            "fatturato-cumulato",
            "utile-cumulato",
            "quota-mercato-forni-anno-4",
            "quota-mercato-lavelli-anno-4",
            "livello-saturazione-reparto-piu-saturo-forni",
            "livello-saturazione-reparto-piu-saturo-lavelli"
        ],
        kpisName: [
            "Fatturato Cumulato",
            "Utile Cumulato",
            "Quota Mercato Anno 4 - Cottura",
            "Quota Mercato Anno 4 - Lavelli",
            "Saturazione Reparto Più Saturo - Cottura",
            "Saturazione Reparto Più Saturo - Lavelli",
        ],
        units: ["€", "€", "%", "%", "%", "%"],
        scenarios: [
            "estensione",
            "estensione-costi-produzione",
            "estensione-effetto-kit",
            "estensione-capacita-produttive",
            "estensione-costi-produzione-effetto-kit",
            "estensione-capacita-produttive-effetto-kit",
            "estensione-costi-produzione-capacita-produttive",
            "estensione-costi-produzione-capacita-produttive-effetto-kit"
        ],
        scenariosName: [
            "Estensione",
            "Estensione + Costi di Produzione",
            "Estensione + Effetto Kit",
            "Estensione + Capacità Produttive",
            "Estensione + Costi di Produzione + Effetto Kit",
            "Estensione + Capacità Produttive + Effetto Kit",
            "Estensione + Costi di Produzione + Capacità Produttive",
            "Estensione + Costi di Produzione + Capacità Produttive + Effetto Kit"
        ],
        matrix: [
            [true,  true,  true,  false, true,  true, true, true],
            [true,  true,  true,  false, true,  true, true, true],
            [true,  true,  true,  false, true,  true, true, true],
            [true,  true,  true,  false, true,  true, true, true],
            [false, false, false, true,  false, true, true, true],
            [false, false, false, true,  false, true, true, true]
        ]
    },
    {
        kpis: [
            "fatturato-cumulato",
            "utile-cumulato",
            "quota-mercato-forni-anno-4",
            "quota-mercato-lavelli-anno-4",
            "livello-saturazione-reparto-piu-saturo-forni",
            "livello-saturazione-reparto-piu-saturo-lavelli"
        ],
        kpisName: [
            "Fatturato Cumulato",
            "Utile Cumulato",
            "Quota Mercato Anno 4 - Cottura",
            "Quota Mercato Anno 4 - Lavelli",
            "Saturazione Reparto Più Saturo - Cottura",
            "Saturazione Reparto Più Saturo - Lavelli",
        ],
        units: ["€", "€", "%", "%", "%", "%"],
        scenarios: [
            "non-ristrutturare-progetto-immagine",
            "non-ristrutturare-progetto-revisione-prezzi",
            "non-ristrutturare",
            "ristrutturare-progetto-immagine",
            "ristrutturare-progetto-revisione-prezzi",
            "ristrutturare"
        ],
        scenariosName: [
            "Non Ristrutturare + Politica di Brand",
            "Non Ristrutturare + Politica di Prezzo",
            "Non Ristrutturare",
            "Ristrutturare + Politica di Brand",
            "Ristrutturare + Politica di Prezzo",
            "Ristrutturare"
        ]
    },
    {
        kpis: [
            "net-present-value",
            "net-present-value-con-disinvestimento"
        ],
        kpisName: [
            "Present Value",
            "Present Value con disinvestimento",
        ],
        units: ["€", "€"],
        scenarios: [
            "non-ristrutturare-progetto-immagine",
            "non-ristrutturare-progetto-revisione-prezzi",
            "non-ristrutturare",
            "ristrutturare-progetto-immagine",
            "ristrutturare-progetto-revisione-prezzi",
            "ristrutturare"
        ],
        scenariosName: [
            "Non Ristrutturare + Politica di Brand",
            "Non Ristrutturare + Politica di Prezzo",
            "Non Ristrutturare",
            "Ristrutturare + Politica di Brand",
            "Ristrutturare + Politica di Prezzo",
            "Ristrutturare",
        ]
    }
];

export type ParameterDataWithUnit = {
    kpiID         : string;
    kpiName       : string;
    scenarioID    : string;
    scenarioName  : string;
    chapterIndex  : number;
    correctValue  : (number | undefined) | (number | undefined)[];
    unit          : string;
}

export type ParameterData = Omit<ParameterDataWithUnit, 'kpiName' | 'scenarioName' | 'unit'>

export type ParameterInput = Omit<CreateAssetInput, 'groupId' | 'data'> & {
    data: ParameterDataWithUnit[]
}

export function generateParametersTemplate(): ParameterDataWithUnit[] {
    let values: ParameterDataWithUnit[] = [];

    for (let chapterIndex = 0; chapterIndex < 4; chapterIndex++) {

        const chapterParams = parametersSeed[chapterIndex];
        
        chapterParams.kpis.forEach((kpiID, kpiIndex) => {
            chapterParams.scenarios
            .filter((_, scenarioIndex) =>
                chapterParams.matrix === undefined
                || chapterParams.matrix[kpiIndex][scenarioIndex])
            .forEach((scenarioID, scenarioIndex) => {

                values.push({
                    kpiID: kpiID,
                    kpiName: chapterParams.kpisName[kpiIndex],
                    scenarioID: scenarioID,
                    scenarioName: chapterParams.scenariosName[scenarioIndex],
                    chapterIndex: chapterIndex,
                    correctValue: (kpiID !== "net-present-value") ? undefined : [undefined, undefined],
                    unit: chapterParams.units[kpiIndex]
                });

            });
        });
    }

    return values;
}

export function importParametersTemplate(
    json: string
): ParameterDataWithUnit[] {
    try {
        const parsed = JSON.parse(json);
        return parsed.flatMap((param: any) => {
            const temp = param as ParameterDataWithUnit;
            const chapter = parametersSeed[temp.chapterIndex]
            temp.unit = chapter.units[chapter.kpis.indexOf(temp.kpiID)];
            temp.kpiName = chapter.kpisName[chapter.kpis.indexOf(temp.kpiID)];
            temp.scenarioName = chapter.scenariosName[chapter.scenarios.indexOf(temp.scenarioID)];
            return temp;
        })
    } catch (err) {
        console.error("Error importing data", err, json);
        return generateParametersTemplate();
    }
}


