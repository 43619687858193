/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSession = /* GraphQL */ `
  mutation CreateSession($json: AWSJSON!) {
    createSession(json: $json)
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession($session: ID!, $json: AWSJSON!, $path: String!) {
    updateSession(session: $session, json: $json, path: $path) {
      data
      path
      session
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession($session: ID!) {
    deleteSession(session: $session)
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset($data: CreateAssetInput!) {
    createAsset(data: $data) {
      op
      types
      groups
      data
      __typename
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset($data: UpdateAssetInput!) {
    updateAsset(data: $data) {
      op
      types
      groups
      data
      __typename
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset($id: ID!) {
    deleteAsset(id: $id) {
      op
      types
      groups
      data
      __typename
    }
  }
`;
export const createAssets = /* GraphQL */ `
  mutation CreateAssets($list: [CreateAssetInput!]!) {
    createAssets(list: $list) {
      op
      types
      groups
      data
      __typename
    }
  }
`;
export const updateAssets = /* GraphQL */ `
  mutation UpdateAssets($list: [UpdateAssetInput!]!) {
    updateAssets(list: $list) {
      op
      types
      groups
      data
      __typename
    }
  }
`;
export const deleteAssets = /* GraphQL */ `
  mutation DeleteAssets($list: [UpdateAssetInput!]!) {
    deleteAssets(list: $list) {
      op
      types
      groups
      data
      __typename
    }
  }
`;
