import React, { Fragment, useContext, useEffect, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import ReactPlayer from 'react-player';
import { Checkbox } from 'semantic-ui-react';
import { AdminContext, AppContext } from '../../context';
import {
  EChapterKind,
  IAsset,
  IChapter,
  IGame,
  IKpi,
  IPlayer,
  IScenario
} from '../../interfaces';
import route, { NavLink, Redirect } from '../../route';
import { generatePhase4OfferMail, generateReportMail } from '../../utilities/mail';
import AdminMatrixProvider from '../AdminMatrixProvider';
import AdminModal from '../AdminModal';
import AdminNotes from '../AdminNotes';
import Card from '../Card';
// import CustomButton from '../CustomButton';
import Image from '../Image';
import Modal from '../Modal';
import WhiteButton from '../WhiteButton';
import './style.scss';

import { database } from '../../utilities/database';
import { storageS3 } from '../../utilities/s3storage';

interface Props {
  intl: any;
}
interface AssetEntryProps {
  game: IGame
  doc: IAsset
  assetIndex: number
  chapterIndex: number
  handleAssetActivation: (
    checked: boolean,
    token: string,
    chapterIndex: number,
    asset: IAsset,
    assetIndex: number
  ) => void
}


const AssetEntry: React.FC<AssetEntryProps> = ({ 
  game, doc, chapterIndex, 
  assetIndex, handleAssetActivation 
}) => {

  const { lang } = useContext(AppContext);
  const [fileUrl, setFileUrl] = useState<string>();

  let updatedUrl = (lang === 'it') ? doc.url : doc.urlEng

  useEffect(() => {
    (async () => {
      setFileUrl(doc.kind !== 'video' ? 
        (await storageS3.getFileUrl(updatedUrl)) : 
        updatedUrl
      );
    })()
  }, [doc, updatedUrl])

  function getImageByAssetKind(kind: string) {
    let fileName = 'doc';

    switch (kind) {
      case 'video':
        fileName = 'play';
        break;
    }

    return fileName;
  }
                        
  return (
    <div className="asset" key={doc.id}>
      <a
        href={fileUrl}
        className=" "
        target="_blank"
        rel="noopener noreferrer">
        <div>
          <Image
            name={getImageByAssetKind(doc.kind)}
            className={getImageByAssetKind(doc.kind)}
          />
          <span className="doc-title">
            {(lang === 'it') ? doc.name : doc.nameEng}
          </span>
        </div>
      </a>
      <Checkbox
        toggle
        checked={doc.active}
        onChange={(e, { checked }) =>
          handleAssetActivation(
            checked || false,
            game.token,
            chapterIndex,
            doc,
            assetIndex
          )
        }
      />
    </div>
  )
}



const AdminPanel: React.FC<Props> = ({ intl }) => {
  const [selectedAsset] = useState<IAsset>();
  const [showModalVideo, setShowModalVideo] = useState<boolean>(false);
  const [showModalKpis, setShowModalKpis] = useState<boolean>(false);
  const [showModalNotes, setShowModalNotes] = useState<boolean>(false);
  const [selectedScenario, setSelectedScenario] = useState<string>();
  const [selectedKpi, setSelectedKpi] = useState<string>();
  const [selectedChapter, setSelectedChapter] = useState<string>();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalOfferPhase4Visible, setModalOfferPhase4Visible] = useState(false);

  function showModal(value: any) {
    setModalVisible(value);
  }

  function showOfferPhase4Modal(value: any) {
    setModalOfferPhase4Visible(value);
  }

  const context = useContext(AdminContext);
  const { session } = useContext(AppContext);

  const { game } = context;
  if (!game) {
    /* eslint-disable no-unused-expressions */
    return <Redirect to={route('adminPanel')} />;
    // throw new Error('Not found games');
  }

  const setChapterActivation = (gameKey: string, chapterIndex?: number) => {
    const path = `games.${gameKey}.chapters#${chapterIndex}`;

    database.get(session!.data.sessionDataId!, path).then((data) => {
      const chapter: IChapter = data;
      if (!chapter.activeAt) {
        database.set(session!.data.sessionDataId!, path, {
          ...chapter,
          activeAt: new Date().toISOString()
        });
      }
    });
  };

  const handleGameSended = (token: string) => {
    const data = {
      ...game,
      sended: true
    };
    database.set(session!.data.sessionDataId!,
      `games.${token}`,
      JSON.parse(JSON.stringify(data))
    );
  };

  const handlePhase4OfferSended = (token: string) => {
    const data = {
      ...game,
      phase4ModalVisible: true,
      phase4OfferSended: true
    };
    database.set(session!.data.sessionDataId!,
      `games.${token}`,
      JSON.parse(JSON.stringify(data))
    );
  };

  const handleKpiActivation = (
    checked: boolean,
    token: string,
    chapterIndex: number,
    kpiIndex: number,
    kpi: IKpi
  ) => {
    database.set(session!.data.sessionDataId!,
      `games.${token}.chapters#${chapterIndex}.kpis#${kpiIndex}`, {
        ...kpi,
        active: checked
    });
  };

  const handleScenarioProperties = (
    name: string,
    checked: boolean,
    token: string,
    chapterIndex: number,
    scenarioIndex: number,
    scenario: IScenario
  ) => {
    database.set(session!.data.sessionDataId!,
      `games.${token}.chapters#${chapterIndex}.scenarios#${scenarioIndex}`, {
      ...scenario,
      [name]: checked
    });
  };

  const handleChapterProperty = (
    name: string,
    checked: boolean,
    token: string,
    chapter: IChapter,
    chapterIndex: number
  ) => {
    const newChapter = JSON.parse(
      JSON.stringify({
        ...chapter,
        [name]: checked
      })
    );
    database.set(session!.data.sessionDataId!,
      `games.${token}.chapters#${chapterIndex}`,
      newChapter
    );
  };

  const handleAssetActivation = (
    checked: boolean,
    token: string,
    chapterIndex: number,
    asset: IAsset,
    assetIndex: number
  ) => {
    database
      .set(session!.data.sessionDataId!,
        `games.${token}.chapters#${chapterIndex}.assets#${assetIndex}`, {
          ...asset,
          active: checked
      })
      .then(() => checked && setChapterActivation(token, chapterIndex));
  };

  const sendMail = (player: IPlayer, link: string, excelModelUrl: string, team: string) => {
    const data = generateReportMail(intl, player, link, excelModelUrl, team);
    fetch('https://learninglab.sdabocconi.it/mail-sender/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  };

  const sendPhase4OfferMail = (player: IPlayer) => {
    const data = generatePhase4OfferMail(intl, player);
    fetch('https://learninglab.sdabocconi.it/mail-sender/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log('Si è verificato un errore!'));
  };

  const sendMails = (link: string) => {
    const { excelModelUrl } = game;

    game.players &&
      game.players.length &&
      game.players.forEach(player => {
        sendMail(player, link, excelModelUrl, game.team);
      });
  };

  const sendPhase4OfferMails = () => {

    game.players &&
      game.players.length &&
      game.players.forEach(player => {
        sendPhase4OfferMail(player);
      });
  };

  const handleFileLink = async (fileKey: string | undefined) => {
    if (fileKey === undefined) return;
    const url = await storageS3.getFileUrl(fileKey);
    window.open(url);
  }


  return (
    <div className="AdminPanel">
      <AdminModal
        show={showModalVideo}
        onClose={() => setShowModalVideo(false)}>
        <ReactPlayer
          url={selectedAsset && selectedAsset.url}
          width={900}
          height={500}
        />
      </AdminModal>
      <AdminContext.Provider
        value={{
          ...context,
          selectedChapter
        }}>
        <AdminModal
          show={showModalKpis}
          onClose={() => setShowModalKpis(false)}>
          <AdminMatrixProvider
            selectedScenarioID={selectedScenario}
            selectedKpiID={selectedKpi}
          />
        </AdminModal>
        <AdminModal
          show={showModalNotes}
          onClose={() => setShowModalNotes(false)}>
          <AdminNotes
            selectedScenario={selectedScenario}
            selectedKpi={selectedKpi}
          />
        </AdminModal>
      </AdminContext.Provider>
      <ReactCSSTransitionGroup
        transitionName="fadeLateral"
        transitionAppear
        transitionAppearTimeout={500}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}>
        <Card>
          <div className="game" key={game.token}>
            {game.chapters.map(chapter => {
              const chapterIndex = game.chapters.indexOf(chapter);
              return (
                <div key={chapter.id} className="chapter-wrapper">
                  <div className="chapter">
                    <div className="label">
                      {intl.formatMessage({
                        id: `chapter-${chapter.id}:label`
                      })}
                    </div>
                    {chapter.kind !== EChapterKind.EPILOGUE && chapter.assets && (
                      <div className="assets">
                        {chapter.assets.map(
                          (doc: IAsset, assetIndex: number) => { 

                            return(<AssetEntry
                              {...{ game, doc, assetIndex, chapterIndex, handleAssetActivation }}
                              key={doc.id}
                            ></AssetEntry>)
                          }
                        )}
                      </div>
                    )}
                    {chapter.kind === EChapterKind.EPILOGUE && (
                      <div className="assets">
                        <div className="asset green">
                          <div style={{ flexBasis: '10px' }}>
                            <Image name="excelGreen" />
                            <a
                              href='#'
                              onClick={() => handleFileLink(chapter.excelUrl)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`${
                                (chapter.excelUrl) ? ' ' : 'noClickEvent'
                              }`}>
                              <span>
                                {intl.formatMessage({
                                  id: 'uploadExcel:label'
                                })}
                              </span>
                            </a>
                            {chapter.excelUrl && <Image name="checkGreen" />}
                          </div>
                          <Checkbox
                            toggle
                            checked={chapter.excelUpload}
                            onChange={(e, { checked }) => {
                              handleChapterProperty(
                                'excelUpload',
                                checked || false,
                                game.token,
                                chapter,
                                chapterIndex
                              );
                            }}
                          />
                        </div>
                        <div className="asset orange">
                          <div style={{ flexBasis: '10px' }}>
                            <Image name="ppt" />
                            <a
                              href='#'
                              onClick={() => handleFileLink(chapter.pptUrl)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`${
                                chapter.pptUrl ? ' ' : 'noClickEvent'
                              }`}>
                              <span>
                                {intl.formatMessage({
                                  id: 'uploadPresentation:label'
                                })}
                              </span>
                            </a>
                            {chapter.pptUrl && <Image name="checkOrange" />}
                          </div>
                          <Checkbox
                            toggle
                            checked={chapter.pptUpload}
                            onChange={(e, { checked }) => {
                              handleChapterProperty(
                                'pptUpload',
                                checked || false,
                                game.token,
                                chapter,
                                chapterIndex
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {chapter.kind === EChapterKind.KPIS_MATRIX &&
                    chapter.kpis &&
                    chapter.scenarios && (
                      <div className="scenarios">
                        {chapter.kpis.map((kpi, index) => {
                          return (
                            <div className="scenario" key={kpi.id}>
                              <span className="title">
                                {intl.formatMessage({
                                  id: `${kpi.id}-admin:label`
                                })}
                              </span>
                              <div className="elements-container">
                                <div
                                  className={`element-kpi ${
                                    kpi.active === true ? 'active' : ''
                                  }`}
                                  onClick={() => {
                                    if (kpi.active === true) {
                                      setShowModalKpis(true);
                                      setSelectedKpi(kpi.id);
                                      setSelectedChapter(chapter.id);
                                    }
                                  }}>
                                  <Image
                                    name="kpis"
                                    className="kpis modal-trigger"
                                  />
                                </div>
                                <div
                                  className={`element-kpi ${
                                    kpi.active === true ? 'active' : ''
                                  }`}
                                  onClick={() => {
                                    if (kpi.notesConfirmed) {
                                      setShowModalNotes(true);
                                      setSelectedKpi(kpi.id);
                                      setSelectedChapter(chapter.id);
                                    }
                                  }}>
                                  <Image
                                    name="comment"
                                    className="comment modal-trigger"
                                  />
                                  {kpi.notesConfirmed ? (
                                    <Image
                                      name="notesCheck"
                                      className="notesCheck"
                                    />
                                  ) : null}
                                </div>
                                <Checkbox
                                  toggle
                                  style={{ marginLeft: '15px' }}
                                  checked={kpi.active}
                                  onChange={(e, { checked }) =>
                                    handleKpiActivation(
                                      checked || false,
                                      game.token,
                                      chapterIndex,
                                      index,
                                      kpi
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  {chapter.kind === EChapterKind.SCENARIOS_MATRIX &&
                    chapter.scenarios &&
                    chapter.matrix && (
                      <div className="scenarios">
                        {chapter.scenarios.map((scenario, scenarioIndex) => {
                          const scenarioColumn = chapter.matrix.map(
                            row => row[scenarioIndex]
                          );
                          const completedKpis = scenarioColumn.filter(
                            kpi => kpi && kpi.status === 'CORRECT'
                          );
                          const totalKpis = scenarioColumn.filter(kpi => kpi);
                          return (
                            <div className="scenario" key={scenario.id}>
                              <span className="title">
                                {intl.formatMessage({
                                  id: `${scenario.id}:label`
                                })}
                              </span>
                              <div className="elements-container">
                                <div
                                  className={`element-kpi ${
                                    scenario.active === true ? 'active' : ''
                                  }`}
                                  onClick={() => {
                                    if (scenario.active) {
                                      setShowModalKpis(true);
                                      setSelectedScenario(scenario.id);
                                      setSelectedChapter(chapter.id);
                                    }
                                  }}>
                                  <Image
                                    name="kpis"
                                    className="kpis modal-trigger"
                                  />
                                  {totalKpis.length ===
                                    completedKpis.length && (
                                    <Image
                                      name="kpisCheck"
                                      className="kpisCheck"
                                    />
                                  )}
                                </div>
                                <div
                                  className={`element-kpi ${
                                    scenario.active === true ? 'active' : ''
                                  }`}
                                  onClick={() => {
                                    if (scenario.notesConfirmed) {
                                      setShowModalNotes(true);
                                      setSelectedScenario(scenario.id);
                                      setSelectedChapter(chapter.id);
                                    }
                                  }}>
                                  <Image
                                    name="comment"
                                    className="comment modal-trigger"
                                  />
                                  {scenario.notesConfirmed && (
                                    <Image
                                      name="notesCheck"
                                      className="notesCheck"
                                    />
                                  )}
                                </div>
                                <Checkbox
                                  style={{ marginLeft: '15px' }}
                                  toggle
                                  checked={scenario.active}
                                  onChange={(e, { checked }) =>
                                    handleScenarioProperties(
                                      'active',
                                      checked || false,
                                      game.token,
                                      chapterIndex,
                                      scenarioIndex,
                                      scenario
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {
                      (chapter.id === '4')?(
                        <Fragment>
                        <div className="chapter phase4Offer">
                          <div className="label">
                            {intl.formatMessage({ id: 'team-phase4Offer:label' })}
                          </div>
                          <div className="assets team-report">
                            <div
                              className={`asset blue ${game.phase4OfferSended ? 'sended' : ''}`}
                              onClick={() => {
                                showOfferPhase4Modal(true);
                              }}>
                              <span>
                                {intl.formatMessage({
                                  id: game.phase4OfferSended
                                    ? 'phase4Offer-sended:label'
                                    : 'phase4Offer-send:label'
                                })}
                              </span>
                              {game.phase4OfferSended && <Image name="blueCheck" />}
                              <Image name="arrowMail" />
                            </div>
                          </div>
                        </div>
                        <Modal show={modalOfferPhase4Visible} onClose={() => showOfferPhase4Modal(false)}>
                          <FormattedHTMLMessage id="admin-phase4Offer-submit-popup:label" />
                          <br />
                          <FormattedHTMLMessage id="admin-phase4Offer-submitA-popup:label" />{' '}
                          <WhiteButton
                            className="button-empty-white"
                            onClick={() => {
                              sendPhase4OfferMails();
                              handlePhase4OfferSended(game.token);
                              setModalOfferPhase4Visible(false);
                            }}>
                            {intl.formatMessage({
                              id: 'admin-phase4Offer-confirm-button:label'
                            })}
                          </WhiteButton>
                        </Modal>
                        </Fragment>
                      ):null
                    }
                    {

                    }
                </div>
              );
            })}
            {
              <div className="chapter-wrapper">
                <div className="chapter">
                  <div className="label">
                    {intl.formatMessage({ id: 'team-report:label' })}
                  </div>
                  <div className="assets team-report">
                    <NavLink
                      to={route('report', { gameSessionId: session!.id, token: game.token })}
                      target="_blank">
                      <div className="asset blue">
                        <span>
                          {intl.formatMessage({ id: 'report-view:label' })}
                        </span>
                        <Image name="eye" />
                      </div>
                    </NavLink>
                    <div
                      className={`asset blue ${game.sended ? 'sended' : ''}`}
                      onClick={() => {
                        showModal(true);
                      }}>
                      <span>
                        {intl.formatMessage({
                          id: game.sended
                            ? 'report-sended:label'
                            : 'report-send:label'
                        })}
                      </span>
                      {game.sended && <Image name="blueCheck" />}
                      <Image name="arrowMail" />
                    </div>
                  </div>
                </div>
                {
                  <Modal show={modalVisible} onClose={() => showModal(false)}>
                    <FormattedHTMLMessage id="admin-submit-popup:label" />
                    <br />
                    <FormattedHTMLMessage id="admin-submitA-popup:label" />{' '}
                    {game.team}
                    <FormattedHTMLMessage id="admin-submitB-popup:label" />
                    <WhiteButton
                      className="button-empty-white"
                      onClick={() => {
                        sendMails(
                          process.env.PUBLIC_URL + route('report', {
                            gameSessionId: session!.id,
                            token: game.token
                          })
                        );
                        handleGameSended(game.token);
                        showModal(false);
                      }}>
                      {intl.formatMessage({
                        id: 'admin-confirm-button:label'
                      })}
                    </WhiteButton>
                  </Modal>
                }
                
              </div>
            }
            {/*
              <CustomButton
                url={game.excelModelUrl}
                backgroundColor="#39CB85"
                imageName="excel"
              />
          */}
          </div>
        </Card>
      </ReactCSSTransitionGroup>
    </div>
  );
};

export default injectIntl(AdminPanel);
