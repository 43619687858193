import React, { useState } from 'react';
import './style.scss';
import Image from '../Image';
import History from './History/History';
import ModalParameters from './Modal/modal-parameters.tsx';
import { injectIntl, FormattedMessage } from 'react-intl';

interface Props {
  isInView: boolean;
  setView: (value: 'Parameters' | 'Media' | 'Template') => void;
  isAdmin?: boolean;
}

const Index: React.FC<Props> = ({ isInView, setView, isAdmin }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);


  const close = () => {
    setIsOpen(false)
  };

  return (
    <div className="parameter-container">
      <div className="header-container">
        <div className="header-parameter" style={{paddingLeft:'2rem'}}>
          {' '}
          {/*<button
            className="arrow-button"
            onClick={() => {
              setView('Parameters');
            }}>
            <Image
              name="arrowUp"
              className={`iconArrow ${isInView ? '' : 'rotate180'}`}
            />
          </button>*/}
          <p className="title">
            <FormattedMessage id="admin-sessions-parameters" /> 
          </p>
        </div>

        {isInView && (
          <button
            className="button-add-container"
            onClick={() => {
              setIsOpen(true);
            }}>
            <Image name="add" />
            <p className="text">
            <FormattedMessage id="admin-sessions-add-params" /> 
            </p>
          </button>
        )}

        {isOpen && <ModalParameters close={close} isNewSet={true} />}
        
      </div>
      {isInView && <History/>}
    </div>
  );
};

export default injectIntl(Index);
