import React, { useContext } from 'react';
import { AppContext, PlayerContext } from '../../context';
import { IAsset } from '../../interfaces';
import route, { Route } from '../../route';
import DocumentPreview from '../DocumentPreview';
import SandyIntroduction from '../SandyIntroduction';
import VideoPlayer from '../VideoPlayer';
import './style.scss';

interface Props {}

const Prologue: React.FC<Props> = () => {
  const context = useContext(PlayerContext);
  const { lang } = useContext(AppContext);

  const { activeAssets } = context;
  if (activeAssets) {
    const videos = activeAssets.filter(
      (asset: IAsset) => asset.kind === 'video'
    );
    const documents = activeAssets.filter(
      (asset: IAsset) => asset.kind === 'document'
    );

    return (
      <div className="Prologue card">
        {videos.length > 0 && (
          <div className="left">
            <VideoPlayer
              url={(lang === 'it')?videos[0].url:videos[0].urlEng}
              title={videos[0].id}
              width={'100%'}
              height={'500px'}
            />
          </div>
        )}
        <div className="right">
          {documents.length === 0 && (
            <Route
              path={route('chapter', { chapterID: 'p' })}
              component={SandyIntroduction}
            />
          )}
          {documents.length > 0 && (
            <DocumentPreview
              url={(lang === 'it')?documents[0].url:documents[0].urlEng}
              title={documents[0].id}
              image={documents[0].previewImage || ''}
            />
          )}
        </div>
      </div>
    );
  } else {
    throw new Error('chapter not found!');
  }
};

export default Prologue;
