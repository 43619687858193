import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import route, { Route } from '../../route';
import Image from '../Image';
import SectionTitle from '../SectionTitle';
import Tooltip from '../Tooltip';
import './style.scss';
import { storageS3 } from '../../utilities/s3storage';
import PdfPreview from '../PdfPreview';

interface Props {
  title: string;
  url: string;
  image: string;
  intl: any;
}

const DocumentPreview: React.FC<Props> = ({ title, url, image, intl }) => {
  const [fileUrl, setFileUrl] = useState<string>('/');

  useEffect(() => {
    let mounted = true;
    const getUrl = async () => {
      const fetchedUrl = await storageS3.getFileUrl(url);
      if (mounted && fetchedUrl) {
        setFileUrl(fetchedUrl);
      }
    };
    getUrl();

    return () => {
      mounted = false;
    };
  }, [url]);

  return (
    <div className="DocumentPreview">
      <Route
        path={route('chapter', { chapterID: 'p' })}
        render={() => (
          <Tooltip
            kind="left-center"
            style={{ top: '16rem', left: '-12rem', minWidth: '25rem' }}>
            <div
              className="ChaptersMenu-tooltip-avatar"
              style={{ width: '25rem' }}>
              <Image name="sparkAvatar" />
              <div className="text">
                <FormattedHTMLMessage id="eurofireTooltipDocuments:label" />
              </div>
            </div>
          </Tooltip>
        )}
      />
      <SectionTitle
        color="#cf0000"
        title={intl.formatMessage({ id: `${title}:label` })}
        image={'doc'}
      />
      <a
        href={fileUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="pdf-container">
        <PdfPreview fileUrl={fileUrl} />
      </a>
    </div>
  );
};

export default injectIntl(DocumentPreview);
