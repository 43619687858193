import React, { useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { AppContext, PlayerContext } from '../../context';
import Image from '../Image';
import OverHeader from '../OverHeader';
import ReportPreview from '../ReportPreview';
import WelcomeHeader from '../WelcomeHeader';
import './style.scss';
import route from '../../route';

const ThankYou: React.FC = () => {
  const { game } = useContext(PlayerContext);
  const { session, lang } = useContext(AppContext);

  const updatedImage = (lang === 'it')?`docPreview_Report`:`docPreview_Report_eng`



  return (
    <div>
      <OverHeader />
      <div className="ThankYou">
        <WelcomeHeader />
        <div className="ThankYou-message">
          <p>
            <FormattedHTMLMessage id="greetings:label" />
          </p>
          <p className="eurofire-knows-message">
            <FormattedHTMLMessage id="eurofire-knows:label" />
          </p>
        </div>
        <div className="report-container">
          <ReportPreview
            url={route('report', {
              gameSessionId: session!.id,
              token: game!.token
            })}
            image={updatedImage}
          />
        </div>
        <Image name="ThankYouImage" className="ThankYouImage" />
      </div>
    </div>
  );
};

export default ThankYou;
