/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type SessionData = {
  __typename: "SessionData",
  data: string,
  path: string,
  session: string,
};

export type CreateAssetInput = {
  groupId: string,
  type: AssetType,
  name?: string | null,
  date?: string | null,
  owner?: string | null,
  default?: boolean | null,
  global?: boolean | null,
  data?: string | null,
};

export enum AssetType {
  lang = "lang",
  session = "session",
  parameter = "parameter",
  video = "video",
  document = "document",
}


export type AssetMutationOutput = {
  __typename: "AssetMutationOutput",
  op: string,
  types: Array< AssetType >,
  groups: Array< string >,
  data: string,
};

export type UpdateAssetInput = {
  id: string,
  groupId: string,
  type: AssetType,
  name?: string | null,
  date?: string | null,
  owner?: string | null,
  default?: boolean | null,
  global?: boolean | null,
  data?: string | null,
};

export type AssetQueryOutput = {
  __typename: "AssetQueryOutput",
  id: string,
  groupId: string,
  type: AssetType,
  name?: string | null,
  date?: string | null,
  owner?: string | null,
  default?: boolean | null,
  global?: boolean | null,
  data?: string | null,
};

export type CreateSessionMutationVariables = {
  json: string,
};

export type CreateSessionMutation = {
  createSession: string,
};

export type UpdateSessionMutationVariables = {
  session: string,
  json: string,
  path: string,
};

export type UpdateSessionMutation = {
  updateSession:  {
    __typename: "SessionData",
    data: string,
    path: string,
    session: string,
  },
};

export type DeleteSessionMutationVariables = {
  session: string,
};

export type DeleteSessionMutation = {
  deleteSession: string,
};

export type CreateAssetMutationVariables = {
  data: CreateAssetInput,
};

export type CreateAssetMutation = {
  createAsset:  {
    __typename: "AssetMutationOutput",
    op: string,
    types: Array< AssetType >,
    groups: Array< string >,
    data: string,
  },
};

export type UpdateAssetMutationVariables = {
  data: UpdateAssetInput,
};

export type UpdateAssetMutation = {
  updateAsset:  {
    __typename: "AssetMutationOutput",
    op: string,
    types: Array< AssetType >,
    groups: Array< string >,
    data: string,
  },
};

export type DeleteAssetMutationVariables = {
  id: string,
};

export type DeleteAssetMutation = {
  deleteAsset:  {
    __typename: "AssetMutationOutput",
    op: string,
    types: Array< AssetType >,
    groups: Array< string >,
    data: string,
  },
};

export type CreateAssetsMutationVariables = {
  list: Array< CreateAssetInput >,
};

export type CreateAssetsMutation = {
  createAssets:  {
    __typename: "AssetMutationOutput",
    op: string,
    types: Array< AssetType >,
    groups: Array< string >,
    data: string,
  },
};

export type UpdateAssetsMutationVariables = {
  list: Array< UpdateAssetInput >,
};

export type UpdateAssetsMutation = {
  updateAssets:  {
    __typename: "AssetMutationOutput",
    op: string,
    types: Array< AssetType >,
    groups: Array< string >,
    data: string,
  },
};

export type DeleteAssetsMutationVariables = {
  list: Array< UpdateAssetInput >,
};

export type DeleteAssetsMutation = {
  deleteAssets:  {
    __typename: "AssetMutationOutput",
    op: string,
    types: Array< AssetType >,
    groups: Array< string >,
    data: string,
  },
};

export type GetSessionQueryVariables = {
  session: string,
  path: string,
};

export type GetSessionQuery = {
  getSession:  {
    __typename: "SessionData",
    data: string,
    path: string,
    session: string,
  },
};

export type GetAssetsQueryVariables = {
  includeData?: boolean | null,
  groupID?: string | null,
  type?: AssetType | null,
};

export type GetAssetsQuery = {
  getAssets:  Array< {
    __typename: "AssetQueryOutput",
    id: string,
    groupId: string,
    type: AssetType,
    name?: string | null,
    date?: string | null,
    owner?: string | null,
    default?: boolean | null,
    global?: boolean | null,
    data?: string | null,
  } >,
};

export type GetAssetQueryVariables = {
  id: string,
};

export type GetAssetQuery = {
  getAsset:  {
    __typename: "AssetQueryOutput",
    id: string,
    groupId: string,
    type: AssetType,
    name?: string | null,
    date?: string | null,
    owner?: string | null,
    default?: boolean | null,
    global?: boolean | null,
    data?: string | null,
  },
};

export type OnSessionUpdateSubscriptionVariables = {
  session: string,
  path: string,
};

export type OnSessionUpdateSubscription = {
  onSessionUpdate?:  {
    __typename: "SessionData",
    data: string,
    path: string,
    session: string,
  } | null,
};

export type OnAssetsUpdateSubscriptionVariables = {
  groupId?: string | null,
  type?: AssetType | null,
};

export type OnAssetsUpdateSubscription = {
  onAssetsUpdate?:  {
    __typename: "AssetMutationOutput",
    op: string,
    types: Array< AssetType >,
    groups: Array< string >,
    data: string,
  } | null,
};
