import React, { useContext } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AdminContext } from '../../context';
import AdminTimer from '../AdminTimer';
import AdminTimeline from '../AdminTimeline';
import './style.scss';

const AdminTimelineProvider: React.FC = () => {
  const context = useContext(AdminContext);
  const { games } = context;
  if (!games) {
    throw new Error('Not found games');
  }
  return (
    <div className="AdminTimelineProvider">
      <AdminContext.Provider
        value={{
          ...context,
          games: games.filter(game => game.initialized)
        }}>
        <AdminTimer />
        <ReactCSSTransitionGroup
          transitionName="fadeOpacity"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}>
          <AdminTimeline />
        </ReactCSSTransitionGroup>
      </AdminContext.Provider>
    </div>
  );
};

export default AdminTimelineProvider;
