import React from 'react';
import { injectIntl } from 'react-intl';
import Image from '../Image';
import SectionTitle from '../SectionTitle';
import './style.scss';

interface Props {
  url: string;
  image: string;
  intl: any;
}

const ReportPreview: React.FC<Props> = ({ url, image, intl }) => {
  return (
    <div className="ReportPreview">
      <a href={window.BASEPATH + url} target="_blank" rel="noopener noreferrer">
        <SectionTitle
          color="#cf0000"
          title={intl.formatMessage({ id: `team-report-title:label` })}
          image={'histogram'}
        />
      </a>
      <a
        href={window.BASEPATH + url}
        className="image-link"
        target="_blank"
        rel="noopener noreferrer">
        <Image name={image} />
      </a>
    </div>
  );
};

export default injectIntl(ReportPreview);
