
const aws_resources = {

    "aws_project_region"                : process.env.REACT_APP_AWS_PROJECT_REGION,

    "aws_cognito_identity_pool_id"      : process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region"                : process.env.REACT_APP_AWS_PROJECT_REGION,

    "aws_appsync_graphqlEndpoint"       : process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    "aws_appsync_region"                : process.env.REACT_APP_AWS_PROJECT_REGION,
    "aws_appsync_authenticationType"    : "AWS_IAM",

    "aws_user_files_s3_bucket"          : process.env.REACT_APP_AWS_STORAGE_BUCKET,
    "aws_user_files_s3_bucket_region"   : process.env.REACT_APP_AWS_PROJECT_REGION
}

export default aws_resources;
