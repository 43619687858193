import React, { useContext } from 'react';
import { AdminContext } from '../../context';
import './style.scss';
import { injectIntl } from 'react-intl';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import AdminChaptersMenu from '../AdminChaptersMenu';
import AdminOverviewTable from '../AdminOverviewTable';

interface Props {
  match: any;
  intl: any;
}

const AdminOverviewProvider: React.FC<Props> = ({ match, intl }) => {
  const context = useContext(AdminContext);
  const { chapters, games } = context;

  if (!games) {
    throw new Error('Not found games');
  }
  if (!chapters) {
    throw new Error('Not found chapters');
  }
  return (
    <AdminContext.Provider
      value={{
        ...context,
        match,
        games: games.filter(game => game.initialized)
      }}>
      {chapters && chapters.length ? (
        <div className="AdminOverviewProvider">
          <AdminChaptersMenu />
          <ReactCSSTransitionGroup
            transitionName="fadeOpacity"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}>
            <AdminOverviewTable />
          </ReactCSSTransitionGroup>
        </div>
      ) : (
        <h1>{intl.formatMessage({ id: 'notActiveChapters:label' })}</h1>
      )}
    </AdminContext.Provider>
  );
};

export default injectIntl(AdminOverviewProvider);
