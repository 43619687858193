import React, { useState } from 'react';
import './style.scss';
import Parameters from '../Parameters';
import SessionSection from '../AdminSessionSection';

type Section = 'Parameters' | 'Media' | 'Template';

interface Props {
  history: any;
}

const AdminSessions: React.FC<Props> = (props) => {
  const [inView, setInView] = useState<Section>('Parameters');

  const handleInViewElement = (element: Section) => {
    setInView(element);
  };

  return (
    <section className="AdminSessions">

      <div className="right-side">
        <SessionSection history={props.history}/>
      </div>

      <div className="left-side">
        <div
          className={`section ${
            inView === 'Parameters' ? 'isOpen' : 'isClose'
          }`}>
          <Parameters
            isInView={inView === 'Parameters'}
            setView={handleInViewElement}
            isAdmin={true}
          />
        </div>

       
       {/* <div
          className={`section ${inView === 'Template' ? 'isOpen' : 'isClose'}`}>
          <Template
            isInView={inView === 'Template' ? true : false}
            setView={handleInViewElement}
          />
        </div>*/}
      </div>
    </section>
  );
};

export default AdminSessions;
