import React from 'react';
import './style.scss';
import AdminMenu from '../AdminMenu';
import AdminSwitch from '../AdminSwitch';

const AdminMain: React.FC = () => (
  <main className="AdminMain">
    <AdminMenu />
    <AdminSwitch />
  </main>
);

export default AdminMain;
