import './style.scss'
import React from 'react';
import { Modal } from 'semantic-ui-react';

interface Props {
    open: boolean,
    title: string,
    progress: number,
}

const LoadingModal: React.FC<Props> = (props) => {
    return (
        <Modal
            open={props.open}
            centered={true}
            size="small"
        >
            <Modal.Header>
                {props.title}
            </Modal.Header>
            <Modal.Content>
              <div className="loading-spinner">
                  <div className="spinner-container">
                      <div className="spinner">
                          <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                              <circle cx="50" cy="50" r="45" />
                          </svg>
                      </div>
                      <span>{Math.floor(props.progress * 100 || 0)} %</span>
                  </div>
              </div>
            </Modal.Content>
        </Modal>
    )
}

export default LoadingModal;
