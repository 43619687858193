import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import Image from '../Image';
import './style.scss';

interface Props {
  intl: InjectedIntl;
  teamName?: string;
}

const Header: React.FC<Props> = ({ intl }) => {

  return (
    <header className="Header">
      <a
        href={
          'https://www.sdabocconi.it/en/faculty-research/learning-process/learning-lab'
        }
        target={'_blank'}>
        <Image name="bocconi" className="bocconi" />
      </a>
      <div className='header-eurofire-logo'>
        <Image name="flameWhite" className='flameWhite' />
        <div className="title">
          {intl.formatMessage({ id: 'eurofire:label' })}
        </div>
      </div>
      <Image name="logoArea" className="logoArea" />
    </header>
  );
};

export default injectIntl(Header);
