import React, { useContext } from 'react';
import route, { Switch, Route, Redirect, withRouter } from '../../route';
import { AdminContext, AppContext } from '../../context';
import AdminPanelProvider from '../AdminPanelProvider';
import AdminTimelineProvider from '../AdminTimelineProvider';
import AdminOverviewProvider from '../AdminOverviewProvider';
import AdminSessionsProvider from '../AdminSessionProvider';
import './style.scss';
interface Props {
  location: any;
}

const AdminSwitch: React.FC<Props> = ({ location }) => {

  const { session } = useContext(AppContext);
  const { games, chapters, ssoUser } = useContext(AdminContext);

  const chapterID = chapters?.length && chapters[0].id;
  const teamID = games?.length && games[0].token;

  return (
    <div className="AdminSwitch">
      <div>

        <Switch location={location}>

        <Route
            path={route('adminSessionsWithSelection')}
            component={AdminSessionsProvider}
          />
          <Route
            path={route('adminSessions')}
            render={(..._) => {
              return (
                <Redirect to={route('adminSessionsWithSelection', { 
                  gameSessionId: session ? session.id : 'list',
                  userId: ssoUser
                })} />
              );
            }}
          />
          <Route
            path={route('adminPanelTeam')}
            component={AdminPanelProvider}
          />
          <Route
            path={route('adminPanel')}
            render={(..._) => {
              return (
                <Redirect to={route('adminPanelTeam', { 
                  gameSessionId: session!.id,
                  userId: ssoUser,
                  teamID 
                })} />
              );
            }}
          />
          <Route
            path={route('adminOverviewChapter')}
            component={AdminOverviewProvider}
          />
          <Route
            path={route('adminOverview')}
            render={(..._) => {
              return (
                <Redirect to={route('adminOverviewChapter', { 
                  gameSessionId: session!.id,
                  userId: ssoUser,
                  chapterID 
                })} />
              );
            }}
          />
          <Route
            path={route('adminTimeline')}
            component={AdminTimelineProvider}
          />
          <Redirect to={route('adminSessions', { userId: ssoUser })} />
        </Switch>

      </div>
    </div>
  );
};

export default withRouter(AdminSwitch);
