import React, { useContext } from 'react';
import { AdminContext } from '../../context';
import './style.scss';
import AdminSessionsMenu from '../AdminSessionsMenu';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import AdminSessions from '../AdminSession';

interface Props {
  match: any;
  history: any
}

const AdminSessionsProvider: React.FC<Props> = ({ match, history }) => {
  
  const context = useContext(AdminContext);
  
  return (
    <AdminContext.Provider value={{ ...context, match }}>
      <div className="AdminProvider">
        <AdminSessionsMenu />
        <ReactCSSTransitionGroup
          transitionName="fadeOpacity"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}>
          <AdminSessions history={history}/>
        </ReactCSSTransitionGroup>
      </div>
    </AdminContext.Provider>
  );
};

export default AdminSessionsProvider;
