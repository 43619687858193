import React from 'react';
import { Viewer, SpecialZoomLevel, Worker } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { pageThumbnailPlugin } from './plugin';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface Props {
  fileUrl: string;
}

const PdfPreview: React.FC<Props> = ({ fileUrl }) => {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <Viewer
        fileUrl={fileUrl}
        defaultScale={SpecialZoomLevel.PageFit}
        plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
      />
    </Worker>
  );
};

export default PdfPreview;
