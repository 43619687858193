import React, { useContext, Fragment } from 'react';
import { AdminContext } from '../../context';
import { IScenarioKpi, IKpi, IScenario } from '../../interfaces';
import Image from '../Image';
import './style.scss';
import { injectIntl } from 'react-intl';
import AdminKpi from '../AdminKpi';

interface Props {
  intl: any;
  matrix: (IScenarioKpi | null)[][];
  kpis: IKpi[];
  scenarios: IScenario[];
  selectedKpiID?: string;
  selectedScenarioID?: string;
  totalsPerColumn?: number[];
}

const AdminMatrix: React.FC<Props> = ({
  intl,
  matrix,
  selectedKpiID = null,
  selectedScenarioID = null,
  kpis,
  scenarios,
  totalsPerColumn = []
}) => {
  const { selectedChapter, game } = useContext(AdminContext);
  if (!game) {
    throw new Error('Not found game');
  }
  const chapter = game.chapters.find(c => c.id === selectedChapter);
  let totalRetries = 0;
  const elementsCount = scenarios.length;
  const rowStyle = {
    gridTemplateColumns: `10rem repeat(${elementsCount}, minmax(100px, 1fr))`
  };
  return (
    <div className="AdminMatrix matrix card">
      <div className="matrix-row" style={rowStyle}>
        <div className="matrix-indexes">
          <div className="index-left">
            {intl.formatMessage({ id: 'table-kpi:label' })}
          </div>
          <div className="index-right">
            {intl.formatMessage({ id: 'table-scenario:label' })}
          </div>
        </div>
        {scenarios.map(scenario => {
          return (
            <div
              key={scenario.id}
              className={`matrix-title ${
                selectedScenarioID === scenario.id ? 'active' : ''
              }`}>
              {intl.formatMessage({ id: `${scenario.id}:label` })}
            </div>
          );
        })}
      </div>
      {matrix.map((matrixRow, matrixRowIndex) => {
        const kpi = kpis[matrixRowIndex];
        return (
          <Fragment key={kpi.id}>
            <div className="matrix-row" key={kpi.id} style={rowStyle}>
              <div
                className={`matrix-title ${
                  selectedKpiID === kpi.id ? 'active' : ''
                }`}>
                {selectedKpiID && selectedKpiID === kpi.id ? (
                  <div className="notes-active-reverse" />
                ) : kpi.notesConfirmed ? (
                  <Image name="notesCheck" className="notes-check" />
                ) : (
                  <div />
                )}
                {chapter &&
                chapter.id === '4' &&
                chapter.kpis.filter(k => k.active).length > 1 &&
                matrixRowIndex === 6
                  ? intl.formatMessage({ id: 'net-present-value-error:label' })
                  : intl.formatMessage({ id: `${kpi.id}:label` })}
              </div>
              {matrixRow.map((scenarioKpi, index) => {
                if (scenarioKpi) {
                  totalRetries += scenarioKpi.retries;
                  return (
                    <div key={index}>
                      <AdminKpi
                        selected={
                          selectedKpiID === scenarioKpi.kpiID ||
                          selectedScenarioID === scenarioKpi.scenarioID
                        }
                        scenarioKpi={scenarioKpi}
                        kpis={kpis}
                      />
                    </div>
                  );
                } else {
                  return <div key={index} />;
                }
              })}
            </div>
            <div className="matrix-row secondary-row" style={rowStyle}>
              <div />
              {matrixRow.map((scenarioKpi, index) => {
                return scenarioKpi ? (
                  <div className="scenario-retries" key={index}>
                    {intl.formatMessage({ id: 'kpiAttempts:label' })}{' '}
                    <b>{scenarioKpi.retries}</b>
                  </div>
                ) : (
                  <div key={index} />
                );
              })}
            </div>
          </Fragment>
        );
      })}

      <div className="matrix-row scenario-retries-totals-row" style={rowStyle}>
        <div />
        {totalsPerColumn.map((total, index) => (
          <div key={index}>
            {intl.formatMessage({ id: 'kpiTotalRetries:label' })} <b>{total}</b>
          </div>
        ))}
      </div>

      {
        <div className="matrix-row retries-totals-row" style={rowStyle}>
          <div>
            {intl.formatMessage({ id: 'totalRetries:label' })}{' '}
            <b>{totalRetries}</b>
          </div>
        </div>
      }
    </div>
  );
};

export default injectIntl(AdminMatrix);
