import React from 'react';
import images from './images';

interface Props {
	name?: string;
	url?: string;
	className?: string;
	style?: object;
	alt?: string;
    tooltip?: string;
	onClick?: Function;
}

const Image: React.FC<Props> = ({ name, url, className, style, alt, onClick }) => {
	return (
			<img
				src={url || (name ? images[name] : images['default'])}
				className={`image ${className}`}
				style={style ? style : {}}
				alt={alt || 'default'}
				onClick={onClick ? () => onClick() : () => null}
			/>
	);
};

export default Image;
