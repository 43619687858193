
import { EChapterKind, IGame } from '../../../../interfaces';
import chapter1 from './chapter1';
import chapter2 from './chapter2';
import chapter3 from './chapter3';
import chapter4 from './chapter4';
import { createKpis, createScenarios } from './utils';

export default (team: string): Partial<IGame> => ({
  team,
  sended: false,
  phase4ModalVisible: false,
  phase4OfferSended: false,
  players: [],
  chapters: [
    {
      id: 'p',
      kind: EChapterKind.PROLOGUE,
      assets: [],
      scenarios: [],
      kpis: [],
      matrix: [],
      viewed: true
    },
    {
      id: '0',
      kind: EChapterKind.PROLOGUE,
      assets: [],
      scenarios: [],
      kpis: [],
      matrix: [],
      viewed: false
    },
    {
      id: '1',
      kind: EChapterKind.SCENARIOS_MATRIX,
      assets: [],
      scenarios: createScenarios(chapter1),
      kpis: createKpis(chapter1),
      matrix: [],
      viewed: false
    },
    {
      id: '2',
      kind: EChapterKind.SCENARIOS_MATRIX,
      assets: [],
      scenarios: createScenarios(chapter2),
      kpis: createKpis(chapter2),
      matrix: [],
      viewed: false
    },
    {
      id: '3',
      kind: EChapterKind.SCENARIOS_MATRIX,
      assets: [],
      scenarios: createScenarios(chapter3),
      kpis: createKpis(chapter3),
      matrix: [],
      viewed: false
    },
    {
      id: '4',
      kind: EChapterKind.KPIS_MATRIX,
      assets: [],
      scenarios: createScenarios(chapter4),
      kpis: createKpis(chapter4),
      matrix: [],
      viewed: false
    },
    {
      id: 'e',
      kind: EChapterKind.EPILOGUE,
      excelUpload: false,
      excelUrl: undefined,
      pptUpload: false,
      pptUrl: undefined,
      assets: [],
      scenarios: [],
      kpis: [],
      matrix: [],
      viewed: false
    }
  ]
});
