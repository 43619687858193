import React from 'react';
import ChaptersMenu from '../ChaptersMenu';
import Content from '../Content';
import Phase4OfferModal from '../Phase4OfferModal';
import SectionsMenu from '../SectionsMenu';
import './style.scss';


const Main: React.FC = () => (
	<main className="Main">
		<ChaptersMenu />
		<Phase4OfferModal />
		<div className="content">
			<SectionsMenu />
			<Content />
		</div>
	</main>
);

export default Main;
