import React, { useState } from 'react';
import SectionDocuments from '../SectionDocuments';
import MatrixProvider from '../MatrixProvider';
import ChapterNotesProvider from '../ChapterNotesProvider';
import MatrixTitle from '../MatrixTitle';
import Card from '../Card';

import './style.scss';
import { useContext } from 'react';
import { PlayerContext } from '../../context';

interface Props { }

const ChapterMain: React.FC<Props> = () => {
  const { chapter, match } = useContext(PlayerContext);
  if (!match) {
    throw new Error('Not found match');
  }
  const [selectedScenarioID, setSelectedScenarioID] = useState<string>();
  const [selectedKpiID, setSelectedKpiID] = useState<string>();
  const [lastChapterID, setLastChapterID] = useState<string>();

  if (!chapter) return null;

  const selectedScenario = chapter.scenarios.filter(
    s => s.active && s.id === selectedScenarioID
  )[0];
  const selectedKPI = chapter.kpis.filter(
    k => k.active && k.id === selectedKpiID
  )[0];

  if (chapter.id !== lastChapterID || (!selectedScenario && !selectedKPI)) {
    const firstActiveKpi = chapter.kpis.filter(kpi => kpi.active)[0];
    const firstActiveScenario = chapter.scenarios.filter(
      scenario => scenario.active
    )[0];
    if (firstActiveKpi) setSelectedKpiID(firstActiveKpi.id);
    if (firstActiveScenario) setSelectedScenarioID(firstActiveScenario.id);
    setLastChapterID(chapter.id);
    return null;
  }

  return (
    <div className="ChapterMain" key={match.params.chapterID}>
      <SectionDocuments />
      <Card>
        <MatrixTitle />
        <MatrixProvider
          selectedScenarioID={selectedScenarioID}
          setSelectedScenarioID={setSelectedScenarioID}
          selectedKpiID={selectedKpiID}
          setSelectedKpiID={setSelectedKpiID}
        />
        <ChapterNotesProvider
          selectedScenarioID={selectedScenarioID}
          selectedKpiID={selectedKpiID}
        />
      </Card>
    </div>
  );
};

export default ChapterMain;
