import React, { useContext, useState, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { AdminContext } from '../../context';
import {
  EKpiStatus,
  IScenario,
  IGame,
  EChapterKind,
  IKpi,
  IScenarioKpi
} from '../../interfaces';
import './style.scss';
import Card from '../Card';
import AdminOverviewProgressBar from './AdminOverviewProgressBar';
import AdminOverviewModalButton from './AdminOverviewModalButton';

import AdminModal from '../AdminModal';
import AdminNotes from '../AdminNotes';
import AdminMatrixProvider from '../AdminMatrixProvider';
// import AdminKpis from '../AdminKpis';

interface Props {
  intl: any;
}

const AdminOverviewTable: React.FC<Props> = ({ intl }) => {
  const context = useContext(AdminContext);
  const { games, match, chapters } = context;
  const [showModalNotes, setShowModalNotes] = useState<boolean>(false);
  const [selectedScenario, setSelectedScenario] = useState<string>();
  const [selectedKpi, setSelectedKpi] = useState<string>();
  const [showModalKpis, setShowModalKpis] = useState<boolean>(false);
  const [selectedChapter, setSelectedChapter] = useState<string>();
  const [game, setGame] = useState<IGame>();

  if (!match) {
    throw new Error('Not found match');
  }

  if (!games) {
    throw new Error('Not found games');
  }

  if (!chapters || !chapters.length) {
    throw new Error('Not found chapters');
  }

  const { chapterID } = match.params;

  const chapter = chapters.find(chapter => chapter.id === chapterID);

  if (!chapter) {
    throw new Error('Not found chapters');
  }

  const availableScenarios = chapter.scenarios.filter(scenario =>
    games.some(game => {
      const gameChapter = game.chapters.find(gc => gc.id === chapter.id);
      const scenarioChapter =
        gameChapter && gameChapter.scenarios.find(s => s.id === scenario.id);
      return scenarioChapter ? scenarioChapter.active!! : false;
    })
  );

  const availableKpis = chapter.kpis.filter(kpi =>
    games.some(game => {
      const gameChapter = game.chapters.find(gc => gc.id === chapter.id);
      const kpiChapter =
        gameChapter && gameChapter.kpis.find(s => s.id === kpi.id);
      return kpiChapter ? kpiChapter.active!! : false;
    })
  );

  const buildMatrixCell = (
    maxValue: any,
    correctValue: any,
    wrongValue: any,
    gameEl: any,
    scenario: any,
    activeChapter: any,
    isKpi: boolean = false
  ) => {
    return (
      <Fragment>
        <AdminOverviewProgressBar
          maxValue={maxValue}
          correctValue={correctValue.length}
          wrongValue={wrongValue.length}
          onClick={() => {
            setShowModalKpis(true);
            setGame(gameEl);
            if (!isKpi) {
              setSelectedScenario(scenario.id);
            } else {
              setSelectedKpi(scenario.id);
            }
            setSelectedChapter(activeChapter.id);
          }}
        />
        <AdminOverviewModalButton
          disabled={!scenario.notesConfirmed}
          onClick={() => {
            setShowModalNotes(true);
            setGame(gameEl);
            if (!isKpi) {
              setSelectedScenario(scenario.id);
            } else {
              setSelectedKpi(scenario.id);
            }
            setSelectedChapter(activeChapter.id);
          }}
        />
      </Fragment>
    );
  };
  return (
    <Fragment>
      <AdminContext.Provider
        value={{
          ...context,
          game,
          selectedChapter
        }}>
        <AdminModal
          show={showModalKpis}
          onClose={() => setShowModalKpis(false)}>
          <AdminMatrixProvider
            selectedScenarioID={selectedScenario}
            selectedKpiID={selectedKpi}
          />
        </AdminModal>
        <AdminModal
          show={showModalNotes}
          onClose={() => setShowModalNotes(false)}>
          <AdminNotes
            selectedScenario={selectedScenario}
            selectedKpi={selectedKpi}
          />
        </AdminModal>
      </AdminContext.Provider>
      <div className="AdminOverviewTable">
        <Card>
          <div className="matrix">
            <div className="matrix-row" key={'table-header'}>
              <div className="matrix-indexes">
                <span className="index-left">
                  {intl.formatMessage({ id: 'table-team:label' })}
                </span>
                <span className="index-right">
                  {intl.formatMessage({ id: 'table-scenarios:label' })}
                </span>
              </div>
              {chapter.kind === EChapterKind.SCENARIOS_MATRIX
                ? availableScenarios.map(scenario => (
                    <div className="matrix-title" key={scenario.id}>
                      {intl.formatMessage({ id: `${scenario.id}:label` })}
                    </div>
                  ))
                : availableKpis.map((kpi, index) => (
                    <div className="matrix-title" key={kpi.id}>
                      {chapter.id === '4' &&
                      chapter.kpis.length > 1 &&
                      index < 1
                        ? intl.formatMessage({
                            id: 'net-present-value-error:label'
                          })
                        : intl.formatMessage({ id: `${kpi.id}:label` })}
                    </div>
                  ))}
            </div>
            {games.map(gameEl => {
              let gameChapter: any;
              if (gameEl.chapters.length && gameEl.chapters.find) {
                gameChapter = gameEl.chapters.find(c => c.id === chapter.id);
              } else {
                gameChapter = gameEl.chapters;
              }
              if (!gameChapter) return null;
              return (
                <div className="matrix-row" key={gameEl.token}>
                  <div className="matrix-title">
                    {gameEl.team && `${gameEl.team} `}
                    {gameEl.token && `(${gameEl.token})`}
                  </div>
                  {gameChapter.kind === EChapterKind.SCENARIOS_MATRIX
                    ? availableScenarios.map(
                        (fakeScenario: IScenario, scenarioIndex: number) => {
                          const scenario = gameChapter.scenarios.find(
                            (s: IScenario) => s.id === fakeScenario.id
                          );
                          if (
                            scenario &&
                            scenario.active &&
                            gameChapter.matrix
                          ) {
                            const scenarioArray = gameChapter.matrix.map(
                              (row: (IScenarioKpi | null)[]) =>
                                row[scenario.index]
                            );
                            const maxValue = scenarioArray.filter(
                              (scen: any) => scen
                            ).length;
                            const correctValue = scenarioArray.filter(
                              (kpi: IScenarioKpi | null) =>
                                kpi && kpi.status === EKpiStatus.CORRECT
                            );
                            const wrongValue = scenarioArray.filter(
                              (kpi: IScenarioKpi | null) =>
                                kpi &&
                                (kpi.status === EKpiStatus.CORRECT ||
                                  kpi.status === EKpiStatus.WRONG)
                            );
                            return (
                              <div
                                className={'matrix-cell'}
                                key={scenarioIndex}>
                                {buildMatrixCell(
                                  maxValue,
                                  correctValue,
                                  wrongValue,
                                  gameEl,
                                  scenario,
                                  gameChapter
                                )}
                              </div>
                            );
                          } else {
                            return <div key={scenarioIndex} />;
                          }
                        }
                      )
                    : availableKpis.map((kpiFake: IKpi, kpiIndex: number) => {
                        const kpi = gameChapter.kpis.find(
                          (s: IKpi) => s.id === kpiFake.id
                        );
                        if (
                          kpi &&
                          kpi.active &&
                          gameChapter.matrix &&
                          gameChapter.kpis[kpi.index]
                        ) {
                          const maxValue = gameChapter.matrix[kpi.index].filter(
                            (el: any) => el
                          ).length;
                          const correctValue = gameChapter.matrix[
                            kpi.index
                          ].filter(
                            (kpi: IScenarioKpi | null) =>
                              kpi && kpi.status === EKpiStatus.CORRECT
                          );
                          const wrongValue = gameChapter.matrix[
                            kpi.index
                          ].filter(
                            (kpi: IScenarioKpi | null) =>
                              kpi && kpi.status === EKpiStatus.WRONG
                          );
                          return (
                            <div className={'matrix-cell'} key={kpi.id}>
                              {buildMatrixCell(
                                maxValue,
                                correctValue,
                                wrongValue,
                                gameEl,
                                kpi,
                                gameChapter,
                                true
                              )}
                            </div>
                          );
                        } else {
                          return <div key={Math.random()} />;
                        }
                      })}
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default injectIntl(AdminOverviewTable);
