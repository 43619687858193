import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { IGame, ISession } from '../../../interfaces';

const blue = "#2764ff"
const grey = "#e8e8e8"

type Props = { session: ISession, games: IGame[] }

class SessionReportDocument extends React.Component<Props> {

    getStyles() {
        return StyleSheet.create({
            page: {
                padding: '10mm 15mm 15mm 15mm',
                fontFamily: "RubikRegular",
            },
            sessionName: {
                fontWeight: "bold",
                fontSize: 16,
                fontFamily: "RobotoBold"
            },
            session: {
                display: "flex",
                flexDirection: "column",
            },
            sessionInfo: {
                display: "flex",
                flexDirection: "row",
                fontSize: 11,
                paddingTop: "20px",
            },
            infoName: {
                marginRight: "8px",
            },
            infoValue: {
                color: 'grey',
            },
            divider: {
                marginVertical: 20,
                borderBottom: "1pt solid #94b2ff",
            },
            teamBox: {
                display: "flex",
                padding: "20px",
                backgroundColor: grey,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                marginVertical: 10
            },
            teamName: {
                fontSize: 14,
                fontFamily: "RobotoBold",
                color: blue
            },
            student: {
                fontSize: 11,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "grey",
                padding: 5,
            },
            studentName: {
                fontFamily: "RobotoBold",
            },
            studentEmail: {
            }
        })
    }
    
    render() {
        const styles = this.getStyles();
        
        const payload = {
            session: {
                name: this.props.session.name ?? "Nome sconosciuto",
                id: this.props.session.id,
                date: new Date(this.props.session.date!).toLocaleString(),
                owners: this.props.session.data.teachers.join(", ") ?? "",
            },
            teams: this.props.games.map((game) => {
                return {
                    name: game.team,
                    students: game.players
                }
            })
        }

        const totalStudents = payload.teams.reduce(
            (tot, team) => tot += team.students.length, 0
        );

        return (
            <Document onRender={() => {}}>
                <Page wrap style={styles.page}>
                    <View style={styles.session}>
                        <View>
                            <Text style={styles.sessionName}>
                              {payload.session.name}
                            </Text>
                        </View>

                        <View style={styles.sessionInfo}>
                            <Text style={styles.infoName}>
                              ID Sessione:
                            </Text>
                            <Text style={styles.infoValue}>
                              { payload.session.id }
                            </Text>
                        </View>

                        <View style={styles.sessionInfo}>
                            <Text style={styles.infoName}>
                              Data e ora:
                            </Text>
                            <Text style={styles.infoValue}>
                              { payload.session.date }
                            </Text>
                        </View>
                        
                        <View style={styles.sessionInfo}>
                            <Text style={styles.infoName}>
                              Docenti:
                            </Text>
                            <Text style={styles.infoValue}>
                              { payload.session.owners }
                            </Text>
                        </View>
                        
                        <View style={styles.sessionInfo}>
                            <Text style={styles.infoName}>
                              Studenti partecipanti:
                            </Text>
                            <Text style={styles.infoValue}>
                              { totalStudents }
                            </Text>
                        </View>
                    </View>

                    <View style={styles.divider}></View>

                    { payload.teams.filter(team => team.students.length != 0).map((team) =>
                        <View style={styles.teamBox} key={team.name}>
                            <Text style={styles.teamName}>
                                Team {team.name} ({team.students.length})
                            </Text>
                            { team.students.map((student, idx) =>
                                <View style={styles.student} wrap={false} key={idx}>
                                    <Text style={styles.studentName}>
                                        {student.firstName} {student.lastName}
                                    </Text>
                                    <Text style={styles.studentEmail}>
                                        {student.email}
                                    </Text>
                                </View>
                            )}
                        </View>
                    )}

                </Page>
            </Document>
        )
    }
    
}

export default SessionReportDocument
