/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Input, Label } from 'semantic-ui-react';
import './style.scss';


interface Props {
  onChange?: Function;
  defaultValue?: number | string;
  value?: number | string;
  type: string;
  unit: string;
  disabled?: boolean;
}

const InputWrapper: React.FC<Props> = ({
  onChange,
  type,
  unit,
  defaultValue,
  disabled = false,
  value
}) => {
  const [tempValue, setValue] = useState<number | string | undefined>(
    defaultValue
  );
  useEffect(() => setValue(defaultValue), [defaultValue])

  return (
    <Input
      className={`InputWrapper`}
      labelPosition="right"
      type="text"
      placeholder="">
      <input
        className="input-ele"
        value={value || tempValue}
        type={type}
        disabled={disabled}
        onBlur={e => onChange && onChange(e.target.value)}
        onChange={e => setValue(e.target.value)}
      />
      {<Label className="decimals">{unit === '%' ? '%' : 'k€'}</Label>}
    </Input>
  );
};

export default InputWrapper;
