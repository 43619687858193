import React, { useState, useEffect, useRef, useContext } from 'react';
import Player from '../Player';
import WithLoader from '../WithLoader';
import { AppContext, PlayerContext } from '../../context';
import { IGame, ISession } from '../../interfaces';
import route, { Switch, Route } from '../../route';
import GameReport from '../GameReport';
import ThankYou from '../ThankYou';
import { activeByKind } from '../../utilities/chapters';
import map from 'lodash/map';
import find from 'lodash/find';
import every from 'lodash/every';
import { database } from '../../utilities/database';
import { assetsService } from '../../utilities/assetsService';

interface Props {
  match: any;
  history: any;
}

const addIndex = (items: any) => {
  return items && items.map((ele: any, index: number) => ({ ...ele, index }));
};

const generateState = (game: IGame) => {
  const newGame = { ...game };
  const lastIndex = newGame.chapters.reduce((acc, chapter, index) => {
    if (activeByKind(chapter)) {
      acc = index;
    }
    return acc;
  }, 0);
  newGame.chapters = newGame.chapters.map((chapter, index) => {
    let matrix: any[][];
    if (chapter.kpis && chapter.scenarios && chapter.matrix) {
      matrix = Array(chapter.kpis.length)
        .fill(null)
        .map(() => Array(chapter.scenarios.length).fill(null));
      for (let i = 0; i < chapter.kpis.length; i++) {
        for (let y = 0; y < chapter.scenarios.length; y++) {
          matrix[i][y] = chapter.matrix[i][y]
            ? {
                ...chapter.matrix[i][y],
                rowIndex: i,
                columnIndex: y,
                chapterIndex: index,
                output: lastIndex === index ? false : true
              }
            : null;
        }
      }
    } else {
      matrix = [[]];
    }

    return {
      ...chapter,
      index,
      assets: addIndex(chapter.assets),
      kpis:
        chapter.kpis &&
        chapter.kpis.map((kpi, index) => ({ ...kpi, label: kpi.id, index })),
      scenarios:
        chapter.scenarios &&
        chapter.scenarios.map((scenario, index) => {
          const show = scenario.dependOn
            ? every(
                map(scenario.dependOn, id => find(chapter.assets, ['id', id])),
                ['active', true]
              )
            : true;
          return {
            ...scenario,
            label: scenario.id,
            index,
            show
          };
        }),
      matrix
    };
  });
  return newGame;
};

const PlayerProvider: React.FC<Props> = props => {
  const { match, history } = props;
  const { session, setSession } = useContext(AppContext);
  const [game, setGame] = useState<IGame>();
  const { gameSessionId, token } = match.params;

  const gameRef = useRef<IGame>();
  gameRef.current = game;

  useEffect(() => {
    if (session) return ; // noop

    assetsService.getAsset(gameSessionId).then(asset => {
			const session: ISession = {
				...asset,
				data: JSON.parse(asset.data!)
			};
			setSession!(session);
		})
  }, 
  [session, gameSessionId, setSession]);

  useEffect(() => {
    if (!session) return ; // noop 

    const subscription = database.subscribe(
      session!.data.sessionDataId!, `games.${token}`,
      (dbGame: any) => {
        if (!dbGame) {
          history.push('/enroll?err=1');
          return;
        }
        setGame(generateState(dbGame));
      },
      () => gameRef.current
    );
    return () => subscription.unsubscribe();
  },
  [session, history, token]);

  const activeChapters = game && game.chapters.filter(activeByKind);
  
  return (
    <WithLoader data={game}>
      <PlayerContext.Provider value={{ match, game, activeChapters }}>
        <Switch>
          <Route path={route('report')} component={GameReport} />
          <Route path={route('thankyou')} component={ThankYou} />
          <Route path={route('player')} component={Player} />
        </Switch>
      </PlayerContext.Provider>
    </WithLoader>
  );
};

export default PlayerProvider;
