import React from 'react';
import { injectIntl } from 'react-intl';
import Header from '../Header';
import Image from '../Image';
import './style.scss';

interface Props {
  intl: any;
}

const LowRes: React.FC<Props> = ({ intl }) => (
  <div className='lowres'>
    <Header />
    <div className="lowres-box">
      <div className="lowres-box-top">
        <div className="row">
          <div className="col">
            <h1>{intl.formatMessage({ id: 'ouch-LowRes:label' })}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>{intl.formatMessage({ id: 'notSupported:label' })}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="icon">
              <Image name="LowRes" className="LowRes" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h4>{intl.formatMessage({ id: 'suitableDevice:label' })}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default injectIntl(LowRes);