import React, { useContext } from 'react';
import AdminTeamsMenu from '../AdminTeamsMenu';
import AdminPanel from '../AdminPanel';
import route, { Switch, Route } from '../../route';
import { AdminContext } from '../../context';
import './style.scss';
import AdminGlobalProvider from '../AdminGlobalProvider';
import AdminOnboardingController from '../AdminOnboardingController';

interface Props {
  match: any;
}

const AdminPanelProvider: React.FC<Props> = ({ match }) => {
  const context = useContext(AdminContext);
  const { games } = context;
  if (!games) {
    throw new Error('Not found games');
  }
  const { teamID } = match.params;
  const game = games.find(game => game.token === teamID);
  return (
    <AdminContext.Provider value={{ ...context, match, game }}>
      <div className="AdminPanelProvider">
        <AdminTeamsMenu teamForPage={4} />
        <Switch>
          <Route
            path={route('adminPanelTeamEdit')}
            component={AdminOnboardingController}
          />
          <Route
            path={route('adminPanelTeam')}
            render={props =>
              teamID !== 'global' ? (
                <AdminPanel {...props} key={teamID} />
              ) : (
                <AdminGlobalProvider {...props} />
              )
            }
          />
        </Switch>
      </div>
    </AdminContext.Provider>
  );
};

export default AdminPanelProvider;
