import React, { useContext } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { AdminContext, AppContext } from '../../context';
import LanguageSelector from '../LanguageSelector';
import './style.scss';

import { assetsService } from '../../utilities/assetsService';

interface Props {
  intl: InjectedIntl;
}

const AdminHeader: React.FC<Props> = ({ intl }) => {
  const { user, userInfo } = useContext(AdminContext);
  const app = useContext(AppContext);

  const changeLang = (selectedLang: string) => {
    if (app.session) {
      assetsService.updateAsset({
        ...app.session,
        data: JSON.stringify({
          ...app.session.data,
          lang: selectedLang
        })
      })
    }
  }

  return (
    <header className="AdminHeader">
      <div className="info">
        <span className="welcome">
          {intl.formatMessage({ id: 'welcome:label' })}{" "}
          <b>{userInfo!.name}</b>
        </span>
        <span className="team">
          <b>{user ? `${user.firstName} ${user.lastName}` : ''}</b>
        </span>
      </div>
      {/* With LDAP or PBAC the logout from the admin 
          panel does not actually make any sense */
        user && (
        <a
          href={`${window.BASEPATH}/logout`}
          className="button-empty-blu logout">
          {intl.formatMessage({ id: 'logout:label' })}
        </a>
      )}
      <LanguageSelector lang={app.lang} changeLang={changeLang} />
    </header>
  );
};

export default injectIntl(AdminHeader);
