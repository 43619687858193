import { Storage } from 'aws-amplify';
import { 
    UploadTaskCompleteEvent, 
    UploadTaskProgressEvent 
} 
from '@aws-amplify/storage/lib-esm/providers/AWSS3UploadTask';

export type ProgressCallback = (progress: UploadTaskProgressEvent) => any

class StorageS3 {

    private static _instance ?: StorageS3;

    static get instance() {

        if (StorageS3._instance === undefined) {
            StorageS3._instance = new StorageS3();
        }
        return StorageS3._instance;
    }

    public async uploadFile(
            refPath       : string,
            fileName      : string,
            file          : File,
            contenteType ?: string,
            progressCb   ?: ProgressCallback
    )
    : Promise<string> {
        if (!refPath.endsWith('/')) refPath += '/';

        const result = await new Promise<UploadTaskCompleteEvent>((resolve, reject) => {

            Storage.put(`${refPath}${fileName}`, file, {
                contentType: contenteType,
                completeCallback: resolve,
                errorCallback: reject,
                progressCallback: progressCb,
                resumable: true
            });
        });
        return result.key!;
    }

    public async removeFile(
        fileKey: string
    ) : Promise<void> {

        await Storage.remove(fileKey);
    }

        
    public async getFileUrl(fileKey: string, download?: boolean): Promise<any> {
        return Storage.get(fileKey, {download: download});
    }

    public async deleteFile(fileKey: string): Promise<void> {
      try {
        await Storage.remove(fileKey);
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    public async getFileList(path: string): Promise<string[]> {
        const list = await Storage.list(path, {pageSize: 'ALL'})
            .catch(e => {
                console.error("Error while listing files", e);
                throw e;
            });
        return list.results
            .filter(res => res.key !== undefined && (res.size ?? 0 > 0))
            .map(res => res.key!);
    }

    public async deleteItemsFromFolder(
      idFolder: string,
      progressCallback?: (progress: number, total: number) => void
    ): Promise<void> {
      try {
        if (!idFolder.endsWith('/')) idFolder += '/';

        const list = await Storage.list(idFolder, {pageSize: 'ALL'});
        const total = list.results.length;
        let progress = 0;
        await Promise.all(
          list.results.map(async (file) => {
            if (file.key) {
              await Storage.remove(file.key);
              progress += 1;
              progressCallback && progressCallback(progress, total);
            }
          })
        );
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    public async copyFile(srcKey: string, destKey: string): Promise<string> {
      try {
        const res = await Storage.copy(
          { 
              key: srcKey,
          },
          { 
              key: destKey,
          }
        );
        return res.key;
      } catch (error) {
        console.error('Error while copying file', error)
        throw error;
      }
    }


    /// Copy the files in the map oldKey => newKey
    public async copyFileList(
        filesMap: Map<string, string>,
        onProgress?: (progress: number, total: number) => void
    ) {
      try {

        const operations: Promise<any>[] = [];

        const total = Array.from(filesMap.keys()).length;
        var done = 0;

        filesMap.forEach(async (dest, src) => {
            operations.push(
                this.copyFile(src, dest).then(() => {
                    done += 1;
                    if (onProgress) {
                        onProgress(done, total)
                    }
                })
            )
        })

        await Promise.all(operations);
      } catch (error) {
        console.error('Error while copying file', error)
        throw error;
      }
    }

    /// Copy all the files in a folder to another.
    /// Returns the map oldFileKey -> newFileKey
    public async copyFolder(srcFolder: string, destFolder: string): Promise<Map<string, string>> {
      try {
        if (!srcFolder.endsWith('/')) srcFolder += '/';
        if (!destFolder.endsWith('/')) destFolder += '/';
        const fileList = (await Storage.list(srcFolder, {pageSize: 'ALL'})).results.map(res => res.key);

        const fileMap = new Map<string, string>();

        const operations = fileList
        .filter(file => file !== undefined)
        .map(async (file) => {
          fileMap.set(
            file!,
            await this.copyFile(`${file}`, `${file!.replace(srcFolder, destFolder)}`)
          )
        })

        await Promise.all(operations);
        return fileMap;
      } catch (error) {
        console.error('Error while copying file', error)
        throw error;
      }
    }

}

export const storageS3 = StorageS3.instance;
