import React, {useEffect, useContext, useState} from 'react';
import Dropzone from 'react-dropzone';
import {FormattedHTMLMessage} from 'react-intl';
import moment from 'moment';
import Card from '../Card';
import Image from '../Image';
import SectionTitle from '../SectionTitle';
import './style.scss';
import { storageS3 } from '../../utilities/s3storage';
import { UploadTaskProgressEvent } from '@aws-amplify/storage/lib-esm/providers/AWSS3UploadTask';
import { AppContext } from '../../context';

interface Props {
  icon: string;
  title: string;
  color: string;
  accept?: string | string[];
  isXLS?: boolean;
  onUploadSuccess?: (downloadURL: string) => any;
  onReset?: Function;
  fileKey?: string;
  nameID: string;
  teamName?: string;
}

const Uploader: React.FC<Props> = ({
  icon,
  title,
  color,
  accept,
  isXLS,
  onUploadSuccess,
  onReset,
  fileKey,
  nameID,
  teamName,
}) => {
  const app = useContext(AppContext);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [uploadFails, setUploadFails] = useState<boolean>(false);

  // Async operation to get the file url from s3
  const [fileUrl, setFileUrl] = useState<string>("");
  useEffect(() => {
    const fetchUrl = async () => {
      if ((fileKey === undefined) || (fileKey === "") || (fileKey === null)) {
        setFileUrl("");
        return;
      }
      setFileUrl(await storageS3.getFileUrl(fileKey))
    }
    fetchUrl();
  });

  const onDrop = async (acceptedFiles: File[], rejectedFiles: File[]) => {
    if (acceptedFiles.length) {
      setError(false);
      setFile(acceptedFiles[0]);
      startUpload(acceptedFiles[0]);
    } else if (rejectedFiles.length) {
      if (rejectedFiles[0].type === '') {
        if (
          (rejectedFiles[0].name.includes('xlsx') && isXLS) ||
          (rejectedFiles[0].name.includes('pdf') ||
            rejectedFiles[0].name.includes('ppt'))
        ) {
          setError(false);
          setFile(rejectedFiles[0]);
          startUpload(rejectedFiles[0]);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };

  const startUpload = async (uploadFile: any) => {
    try {
      const path = `uploads/${app.session?.id}/${teamName}/`;
      
      const filename = `${moment(Date.now())
        .format('YYYY-MM-DD-hh-mm-ss')}_${uploadFile.name}`;

      const progressCb = (progress: UploadTaskProgressEvent) => {
          const percentage = 100 * progress.loaded / progress.total;
          setPercentage(percentage);
          setProgress(true);
      };
      const newFileKey: string = await storageS3
        .uploadFile(path, filename, uploadFile, uploadFile.type, progressCb);

      setProgress(false);
      setUploadSuccess(true);
      setUploadFails(false);

      if (onUploadSuccess) {
        onUploadSuccess(newFileKey!);
        setFileUrl(await storageS3.getFileUrl(newFileKey))
      }
    } 
    catch (error) {
      console.error('UPLOAD ERROR', error);

      setProgress(false);
      setUploadSuccess(false);
      setUploadFails(true);
    }
  };

  return (
    <div className="Uploader">
      <Card>
        <SectionTitle color={color} title={title} image={icon} />
        <div className="uploader-text-container">
          <FormattedHTMLMessage
            id={isXLS ? 'upload-excel-task:label' : 'upload-slides-task:label'}
          />
        </div>
        {!file && !fileUrl && (
          <Dropzone onDrop={onDrop} multiple={false} accept={accept}>
            {({getRootProps, getInputProps}) => {
              return (
                <div {...getRootProps()} className="drop-zone-container">
                  <input {...getInputProps()} />
                  <div className="drop-zone">
                    <div className="upload-title">
                      <FormattedHTMLMessage
                        id={
                          isXLS
                            ? 'upload-dropbox-excel:label'
                            : 'upload-dropbox-slides:label'
                        }
                      />
                    </div>
                    <div className="upload-subtitle">
                      <FormattedHTMLMessage id="upload-dropbox-or:label" />
                    </div>
                    <button className={isXLS ? '' : 'orange'}>
                      <FormattedHTMLMessage id="upload-dropbox-pick:label" />
                    </button>
                    {error && (
                      <div className="upload-error">
                        <FormattedHTMLMessage
                          id={
                            isXLS
                              ? 'upload-dropbox-excel-format:label'
                              : 'upload-dropbox-slides-format:label'
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {file && !fileUrl && (
          <div className="file-selected-wrapper">
            <div className="upload-filename">
              <span>{file.name}</span>
            </div>
            <div className="upload-filesize">
              <span>({(file.size / 1000000).toFixed(3)} MB)</span>
            </div>
            <div className="upload-fileicon">
              {!uploadSuccess && (
                <Image name={isXLS ? 'xlsFileIcon' : 'slidesFileIcon'} />
              )}
            </div>
            {!uploadSuccess && !uploadFails && progress && (
              <button className={isXLS ? '' : 'orange'}>
                <div className="upload-spinner-container">
                  <div className="upload-spinner">
                    <svg
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="50" cy="50" r="45" />
                    </svg>
                  </div>
                  <span>{Math.floor(percentage || 0)} %</span>
                </div>
              </button>
            )}
            {uploadFails && (
              <div
                className="upload-status fail"
                onClick={(e) => {
                  setFile(undefined);
                  setUploadFails(false);
                  setUploadSuccess(false);
                }}
              >
                <FormattedHTMLMessage id="upload-fail:label" />
              </div>
            )}
          </div>
        )}
        {fileUrl && (
          <div className="file-selected-wrapper">
            <a href={fileUrl} download rel="noopener noreferrer">
              <Image name={isXLS ? 'xlsFileIcon' : 'slidesFileIcon'} />
            </a>
            <div>
              <FormattedHTMLMessage id="upload-request:label" />
            </div>
            <div className={['upload-status', isXLS ? '' : 'orange'].join(' ')}>
              <FormattedHTMLMessage id="upload-success:label" />
              <div>
                <button
                  className={`reset ${isXLS ? '' : 'orange'}`}
                  onClick={() => {
                    if (fileKey !== undefined) storageS3.removeFile(fileKey);
                    setFile(undefined);
                    onReset && onReset();
                  }}
                >
                  RESET
                </button>
              </div>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Uploader;
