/* eslint-disable */

import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { PlayerContext } from '../../context';
import { IKpi, IScenario, IScenarioKpi } from '../../interfaces';
import Image from '../Image';
import Kpi from '../Kpi';
import './style.scss';

interface Props {
  intl: any;
  matrix: (IScenarioKpi | null)[][];
  kpis: IKpi[];
  scenarios: IScenario[];
  selectedKpiID?: string;
  selectedScenarioID?: string;
  setSelectedKpiID?: Function;
  setSelectedScenarioID?: Function;
}

const Matrix: React.FC<Props> = ({
  intl,
  matrix,
  selectedKpiID = null,
  selectedScenarioID = null,
  kpis,
  scenarios,
  setSelectedKpiID = () => {},
  setSelectedScenarioID = () => {}
}) => {
  const { chapter } = useContext(PlayerContext);

  if (!chapter) {
    throw new Error('Not found match');
  }

  return (
    <div className="Matrix matrix">
      <div className="matrix-row">
        <div className="matrix-indexes">
          <div className="index-left">{intl.formatMessage({ id: 'table-kpi:label' })}</div>
          <div className="index-right">{intl.formatMessage({ id: 'table-scenario:label'})}</div>
        </div>
        {scenarios.map((scenario,index) => (
          <div
            key={scenario.id}
            className={`matrix-title ${
              chapter.id === '4' ? 'noPointer' : ''
            }  ${selectedScenarioID === scenario.id ? 'active' : ''} matrix-header-${index}`}
            onClick={() => setSelectedScenarioID(scenario.id)}>
            {intl.formatMessage({ id: `${scenario.label}:label` })}
          </div>
        ))}
      </div>
      {matrix.map((matrixRow, matrixRowIndex) => {
        const kpi = kpis[matrixRowIndex];
        return (
          <div className={`matrix-row`} key={kpi.id}>
            <div
              className={`matrix-title ${
                chapter.id === '4' ? '' : 'noPointer'
              } ${selectedKpiID === kpi.id ? 'active' : ''} matrix-row-${matrixRowIndex}`}
              onClick={() => setSelectedKpiID(kpi.id)}>
              {selectedKpiID && selectedKpiID === kpi.id ? (
                <div className="notes-active-reverse" />
              ) : kpi.notesConfirmed ? (
                <Image name="notesCheck" className="notes-check" />
              ) : (
                <div />
              )}
              {intl.formatMessage({ id: `${kpi.label}:label` })}
            </div>
            {matrixRow.map((scenarioKpi, index) =>
              scenarioKpi ? (
                <div key={index}>
                  <Kpi
                    kpis={kpis}
                    selected={
                      selectedKpiID === scenarioKpi.kpiID ||
                      selectedScenarioID === scenarioKpi.scenarioID
                    }
                    scenarioKpi={scenarioKpi}
                    onClick={() => {
                      setSelectedKpiID(scenarioKpi.kpiID);
                      setSelectedScenarioID(scenarioKpi.scenarioID);
                    }}
                  />
                </div>
              ) : (
                <div key={index} />
              )
            )}
          </div>
        );
      })}
      {selectedScenarioID && (
        <div className="matrix-row">
          <div />
          {scenarios.map(scenario => {
            return (
              <div key={scenario.id}>
                {selectedScenarioID === scenario.id ? (
                  <div className="notes-active" />
                ) : scenario && scenario.notesConfirmed ? (
                  <Image name="notesCheck" className="notesCheck" />
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default injectIntl(Matrix);
