import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import './style.scss';
import route, { NavLink } from '../../route';
import { IChapter } from '../../interfaces';
import { AdminContext, AppContext } from '../../context';

interface Props {
  intl: any;
}

const AdminChaptersMenu: React.FC<Props> = ({ intl }) => {
  const { session } = useContext(AppContext);
  const { games, match, chapters } = useContext(AdminContext);
  if (!match) {
    throw new Error('Not found match');
  }
  if (!games) {
    throw new Error('Not found games');
  }
  if (!chapters) {
    throw new Error('Not found chapters');
  }
  const { chapterID } = match.params;
  return (
    <nav className="AdminChaptersMenu">
      <div className="admin-title">
        {intl.formatMessage({ id: 'overview-chapter:label' })}
      </div>
      <div className="menu">
        {chapters.map((chapter: IChapter, index: number) => {
          return (
            <NavLink
              key={index}
              to={route('adminOverviewChapter', { 
                gameSessionId: session!.id,
                chapterID: chapter.id 
              })}>
              <div
                className={`menu-item ${
                  chapter.id === chapterID ? 'active' : ''
                }`}>
                {intl.formatMessage({
                  id: `admin-chapter-${chapter.id}:label`
                })}
              </div>
            </NavLink>
          );
        })}
      </div>
    </nav>
  );
};

export default injectIntl(AdminChaptersMenu);
