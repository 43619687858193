import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';
import aws_resources from './aws-resources';
import { Font } from '@react-pdf/renderer';

import RobotoBold from './fonts/roboto/Roboto-Bold.ttf';
import RobotoLight from './fonts/roboto/Roboto-Light.ttf';
import RobotoMedium from './fonts/roboto/Roboto-Medium.ttf';
import RobotoRegular from './fonts/roboto/Roboto-Regular.ttf';
import RubikBold from './fonts/rubik/Rubik-Bold.ttf';
import RubikBoldItalic from './fonts/rubik/Rubik-BoldItalic.ttf';
import RubikLight from './fonts/rubik/Rubik-Light.ttf';
import RubikMedium from './fonts/rubik/Rubik-Medium.ttf';
import RubikRegular from './fonts/rubik/Rubik-Regular.ttf';
import RubikRegularItalic from './fonts/rubik/Rubik-RegularItalic.ttf';

Amplify.configure(aws_resources);

// Register fonts for pdf rendering
Font.registerHyphenationCallback(word => [word]);
Font.register({
  family: 'RubikLight',
  src: RubikLight,
  fontWeight: 'light'
});
Font.register({
  family: 'RubikRegular',
  src: RubikRegular,
  fontWeight: 'regular'
});
Font.register({
  family: 'RubikRegularItalic',
  src: RubikRegularItalic,
  fontWeight: 'regular',
  fontStyle: 'italic'
});
Font.register({
  family: 'RubikMedium',
  src: RubikMedium,
  fontWeight: 'bold'
});
Font.register({ family: 'RubikBold', src: RubikBold, fontWeight: 'bold' });
Font.register({
  family: 'RubikBoldItalic',
  src: RubikBoldItalic,
  fontWeight: 'bold',
  fontStyle: 'italic'
});

Font.register({
  family: 'RobotoLight',
  src: RobotoLight,
  fontWeight: 'light'
});
Font.register({
  family: 'RobotoRegular',
  src: RobotoRegular,
  fontWeight: 'regular'
});
Font.register({
  family: 'RobotoMedium',
  src: RobotoMedium,
  fontWeight: 'bold'
});
Font.register({
  family: 'RobotoBold',
  src: RobotoBold,
  fontWeight: 'bold'
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
