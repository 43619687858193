import findIndex from 'lodash/findIndex';
import React, { Component } from 'react';
import { PlayerContext } from '../../context';
import { IAsset, IChapter, ISession } from '../../interfaces';
import route, { Redirect } from '../../route';
import Main from '../Main';
import WelcomeMessageWithLogout from '../WelcomeMessageWithLogout';

interface Props {
  match: any;
  session: ISession
}
interface State { }

class ChapterSectionProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    const { game, activeChapters } = this.context;
    const { match } = this.props;
    const { token, chapterID } = match.params;
    const chapterIndex = findIndex(
      game.chapters,
      (c: IChapter) => c.id === chapterID
    );
    const chapter =
      activeChapters &&
      activeChapters.find((c: IChapter) => c.id === chapterID);
    const assets = chapter && chapter.assets;
    const activeAssets = assets && assets.filter((doc: IAsset) => doc.active);
    const scenarios = chapter && chapter.scenarios;
    return chapter ? (
      <PlayerContext.Provider
        value={{
          ...this.context,
          chapter,
          chapterIndex,
          assets,
          activeAssets,
          scenarios,
          match
        }}>
        <WelcomeMessageWithLogout />
        <Main />
      </PlayerContext.Provider>
    ) : (
        <Redirect to={route('player', { 
          gameSessionId: this.props.session,
          token 
        })} />
      );
  }
}

ChapterSectionProvider.contextType = PlayerContext;

export default ChapterSectionProvider;
