import React, { useContext } from 'react';
import route, { NavLink } from '../../route';
import Image from '../Image';
import './style.scss';
import { AdminContext, AppContext } from '../../context';

const AdminMenu: React.FC = () => {

  const { session } = useContext(AppContext);
  const { ssoUser } = useContext(AdminContext);

  const isSessionSelected = session?.data.paramId !== undefined;

  const routeParams = {
    userId: ssoUser,
    ...(!session ? { } : { gameSessionId: session.id })
  };

  const sections = [
    {
      id: 'adminSessions',
      image: 'admin',
      imageOff: 'adminOff'
    }
  ]
  if (isSessionSelected) sections.push(
    {
      id: 'adminPanel',
      image: 'panel',
      imageOff: 'panelOff'
    },
    {
      id: 'adminOverview',
      image: 'adminOverviewOn',
      imageOff: 'adminOverviewOff'
    },
    {
      id: 'adminTimeline',
      image: 'timeline',
      imageOff: 'timelineOff'
    }
  );

  return (
    <nav className="AdminMenu">
      {sections.map((section: any) => {
        return (
          <NavLink
            to={route(section.id, routeParams)}
            key={section.id}
            className={`menu-item ${
              section.id === 'adminSessions' ? 'first-element' : ''
            }`}
            activeClassName={'active'}>
            <Image name={section.image} className="on" />
            <Image name={section.imageOff} className="off" />
          </NavLink>
        );
      })}
    </nav>
  );
};

export default AdminMenu;
