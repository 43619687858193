import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import Image from '../Image';
import './style.scss';

interface Props {
	intl: InjectedIntl;
}

const Footer: React.FC<Props> = ({ intl }) => {
	return (
		<footer className="Footer">
			<div className="left">
			</div>
			<div className="right">
				<a
					href={"mailto:learning.lab@sdabocconi.it"}
					target={"_blank"}
					title={"SDA Bocconi"}
				>
					<Image name="mail" />
					{intl.formatMessage({ id: 'footer-email:label' })}
				</a>
			</div>
		</footer>
	);
};

export default injectIntl(Footer);
