import React from 'react';
import Image from '../Image';
import Tooltip from '../Tooltip';
import { FormattedHTMLMessage } from 'react-intl';
import './style.scss';

interface Props {}

const SandyIntroduction: React.FC<Props> = () => {
  return (
    <div className="SandyIntroduction">
      <Tooltip
        kind="right-center"
        style={{ left: '9rem', top: '23rem', minWidth: '25rem' }}>
        <div className="SandyIntroduction-tooltip">
          <div className="text">
            <FormattedHTMLMessage id="sandyPresentation:label" />
          </div>
        </div>
      </Tooltip>
      <Tooltip
        kind="right-center"
        style={{ left: '9rem', top: '35rem', minWidth: '25rem' }}>
        <div className="SandyIntroduction-tooltip">
          <div className="text">
            <FormattedHTMLMessage id="sandyIntroduction:label" />
          </div>
        </div>
      </Tooltip>
      {/*<Image name="sparkGif" />*/}
    </div>
  );
};

export default SandyIntroduction;
