import React, { useState } from 'react';
import faculty from '../../assets/faculty/faculty';
import blueRectangle from '../../assets/images/Fill37.png';
import Image from '../Image';
import OverHeader from '../OverHeader';
import TeacherInfoBox from '../TeacherInfoBox';
import TokenInput from '../TokenInput';
import WelcomeHeader from '../WelcomeHeader';
import './style.scss';
import SessionInput from '../SessionInput';


interface Props {
  history: any;
}
const Login: React.FC<Props> = ({ history }) => {

  const [ sessionId, setSessionId ] = useState<string>();

  window.localStorage.removeItem('local-notes')

  return (
    <div className="Login">
      <OverHeader />
      <div className='Login-main'>
        <WelcomeHeader />
        <SessionInput history={history} setSessionId={setSessionId}/>
        <TokenInput history={history} sessionId={sessionId}/>
        <div className="left-side-container">
          <div className="teacherInfoBoxes-container">
            {faculty.map((teacher, index) => {
              return <TeacherInfoBox key={index} teacherID={teacher.id} />;
            })}
          </div>
        </div>
        <Image name="welcomePageMainImage" className="welcomePageMainImage" />
        <div className='blueRectangle' style={{
          backgroundImage: `url(${blueRectangle})`
        }}>
          <a
            href="https://www.sdabocconi.it/en/faculty-research/learning-process/learning-lab"
            target="_blank"
            rel="noopener noreferrer">
            <Image name="logoAreaWelcome" className="logoAreaWelcome" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
