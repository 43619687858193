import React, { useContext } from 'react';
import './style.scss';
import { PlayerContext } from '../../context';
import { injectIntl } from 'react-intl';

interface Props {
  intl: any;
}

const SectionsMenu: React.FC<Props> = ({ intl }) => {
  const { chapter } = useContext(PlayerContext);
  if (!chapter) {
    throw new Error('Not found chapter');
  }
  return (
    <nav className="SectionsMenu">
      <div className="chapter">
        {intl.formatMessage({ id: `chapter-${chapter.id}:label` })}
      </div>
      {/*<div className="menu">
				{scenarioSections.map((section: ISection, index: number) => {
					const active = sectionID === section.id;
					return (
						<NavLink
							to={route('section', {
								token,
								chapterID: chapter.id,
								sectionID: section.id
							})}
							key={index}>
							<div className={`menu-item ${active ? 'active' : ''}`}>
								{section.name}
							</div>
						</NavLink>
					);
				})
			</div>*/}
    </nav>
  );
};

export default injectIntl(SectionsMenu);
