import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AdminContext } from '../../context';
import { IChapter } from '../../interfaces';
import { EChapterKind } from '../../interfaces';
import Card from '../Card';
import moment from 'moment';
import './style.scss';

interface Props {
  intl: any;
}

const formatDuration = (duration: any) => {
  var hours = Math.floor(duration.hours());
  var minutes = Math.floor(duration.minutes());
  var hoursText = hours.toString();
  var minutesText = minutes.toString();

  return `${('0' + hoursText).slice(-2)}:${('0' + minutesText).slice(-2)}`;
};

const AdminTimeline: React.FC<Props> = ({ intl }) => {
  const { games, chapters } = useContext(AdminContext);
  if (!games) {
    throw new Error('Not found games');
  }
  if (!chapters) {
    throw new Error('Not found chapters');
  }
  const chapterDuration = (chapter: IChapter) => {
    const difference = moment(chapter.completedAt).diff(
      moment(chapter.activeAt)
    );
    const duration = moment.duration(difference);
    return duration.asSeconds();
  };
  const maxChapterDuration = (chapterID: string) => {
    const durations = games.map(game => {
      const gameChapter = game.chapters.find(
        gameChapter => gameChapter.id === chapterID
      );
      if (gameChapter && gameChapter.completedAt) {
        return chapterDuration(gameChapter);
      } else {
        return 0;
      }
    });
    return Math.max(...durations);
  };

  const chaptersModel =
    games[0] && games[0].chapters
      ? games[0].chapters.filter(
          chapter =>
            chapter.kind === EChapterKind.SCENARIOS_MATRIX ||
            chapter.kind === EChapterKind.KPIS_MATRIX
        )
      : [];

  return chaptersModel && chaptersModel.length ? (
    <div className="AdminTimeline">
      <Card>
        <div className="matrix">
          <div className="matrix-row">
            <div className="matrix-indexes">
              <span className="index-left">
                {intl.formatMessage({ id: 'team:label' })}
              </span>
              <span className="index-right">
                {intl.formatMessage({ id: 'chapters:label' })}
              </span>
            </div>
            {chaptersModel.map(chapter => (
              <div className="matrix-title" key={chapter.id}>
                {intl.formatMessage({
                  id: `admin-chapter-${chapter.id}:label`
                })}
              </div>
            ))}
            <div className="matrix-total tot">
              {intl.formatMessage({ id: 'tot:label' })}
            </div>
          </div>
          {games.map(game => {
            const totalMinutes = game.chapters.reduce((acc, chapter) => {
              if (
                (chapter.kind === EChapterKind.SCENARIOS_MATRIX ||
                  chapter.kind === EChapterKind.KPIS_MATRIX) &&
                chapter.completedAt
              ) {
                const difference = moment(chapter.completedAt).diff(
                  moment(chapter.activeAt)
                );
                const duration = moment.duration(difference);
                acc += duration.minutes();
              }
              return acc;
            }, 0);
            const minutesDuration = moment.duration({ minutes: totalMinutes });
            return (
              <div key={game.token} className="matrix-row">
                <div className="matrix-title">
                  {game.team && `${game.team}`}
                  {!game.team
                    ? `${intl.formatMessage({ id: 'token:label' })}:`
                    : null}
                  &nbsp;
                  {game.team ? `(${game.token})` : game.token}
                </div>
                {chaptersModel.map(chapter => {
                  const gameChapter = game.chapters.find(
                    gameChapter => gameChapter.id === chapter.id
                  );
                  const activeScenarios =
                    gameChapter &&
                    gameChapter.scenarios.filter(scenario => scenario.active);
                  const activeKpis =
                    gameChapter && gameChapter.kpis.filter(kpi => kpi.active);
                  const completedScenarios =
                    activeScenarios &&
                    activeScenarios.filter(scenario => scenario.completed);
                  const completedKpis =
                    activeKpis && activeKpis.filter(kpi => kpi.completed);
                  const durationWidth =
                    gameChapter &&
                    gameChapter.completedAt &&
                    `${(
                      (chapterDuration(gameChapter) /
                        maxChapterDuration(gameChapter.id)) *
                      100
                    ).toFixed(2)}%`;
                  return gameChapter && gameChapter.activeAt ? (
                    gameChapter.completedAt ? (
                      <div key={chapter.id} className="chapter completed">
                        <div
                          className="timeline"
                          style={{ width: durationWidth }}
                        />
                        <div className="hours-label">
                          {moment(gameChapter.activeAt).format('HH:mm')}
                        </div>
                        <div className="scenarios-label">
                          {gameChapter.kind === EChapterKind.SCENARIOS_MATRIX
                            ? completedScenarios && completedScenarios.length
                            : completedKpis && completedKpis.length}
                          /
                          {gameChapter.kind === EChapterKind.SCENARIOS_MATRIX
                            ? activeScenarios && activeScenarios.length
                            : activeKpis && activeKpis.length}
                        </div>
                        <div className="hours-label">
                          {moment(gameChapter.completedAt).format('HH:mm')}
                        </div>
                      </div>
                    ) : (
                      <div key={chapter.id} className="chapter active">
                        <div className="hours-label">
                          {moment(gameChapter.activeAt).format('HH:mm')}
                        </div>
                        <div className="scenarios-label">
                          {gameChapter.kind === EChapterKind.SCENARIOS_MATRIX
                            ? completedScenarios && completedScenarios.length
                            : completedKpis && completedKpis.length}
                          /
                          {gameChapter.kind === EChapterKind.SCENARIOS_MATRIX
                            ? activeScenarios && activeScenarios.length
                            : activeKpis && activeKpis.length}
                        </div>
                        {/* I'm filling the following div with 00:00 and setting it as transparent so that the scenarios label is always centered */}
                        <div className="hours-label default">00:00</div>
                      </div>
                    )
                  ) : (
                    <div key={chapter.id} />
                  );
                })}
                <div className="matrix-total">
                  {minutesDuration.asHours() || minutesDuration.asMinutes()
                    ? `h${formatDuration(minutesDuration)}`
                    : 'h00:00'}
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  ) : (
    <h1>{intl.formatMessage({ id: 'notActiveChapters:label' })}</h1>
  );
};

export default injectIntl(AdminTimeline);
