/* eslint-disable */

const docBilanciURL = '/assets/il-bilancio-di-ItalInox.pdf'


const assets = [
	{
		active: false,
		id: 'doc-bilanci-italinox',
		url: '',
		urlEng: '',
		kind: 'document',
		previewImage: ''
	}
]

const scenarios = [
	{
		id: 'consolidato',
		dependOn: ['doc-bilanci-italinox']
	}
];

const kpis = [
	{
		id: 'fatturato-anno-1',
		unit: '€'
	},
	{
		id: 'utile-anno-1',
		unit: '€'
	}
];

const matrix = [
	[
		{ refId:'fatturato-il-consolidato', value: 117218, unit: '€' }
	], 
	[
		{ refId:'utile-il-consolidato', value: 4670.25, unit: '€' }
	]
];

export default {
	assets,
	scenarios,
	kpis,
	matrix
};
