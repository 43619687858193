import React, { useContext, useEffect, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Checkbox } from 'semantic-ui-react';
import { AppContext } from '../../context';
import { EChapterKind, IAsset, IGame } from '../../interfaces';
import { InputUpdateAllGames } from '../AdminGlobalProvider';
import Image from '../Image';
import Modal from '../Modal';
import WhiteButton from '../WhiteButton';
import './style.scss';
import { storageS3 } from '../../utilities/s3storage';


interface Props {
  game: IGame;
  intl: any;
  updateData: (arg: InputUpdateAllGames) => void;
  sendMails: () => void;
  sendPhase4OfferMails: () => void
}
interface AssetEntryProps extends Props { 
  doc: IAsset
  assetIndex: number
  chapterIndex: number
}

const AssetEntry: React.FC<AssetEntryProps> = ({ 
      doc, chapterIndex, 
      assetIndex, updateData 
}) => {

  const { lang } = useContext(AppContext);
  const [fileUrl, setFileUrl] = useState<string>();

  let updatedUrl = (lang === 'it') ? doc.url : doc.urlEng

  useEffect(() => {
    (async () => {
      setFileUrl(doc.kind !== 'video' ? 
        (await storageS3.getFileUrl(updatedUrl)) : 
        updatedUrl
      );
    })()
  }, [doc, updatedUrl])

  function getImageByAssetKind(kind: string) {
    var fileName = 'doc';

    switch (kind) {
      case 'video':
        fileName = 'play';
        break;
    }

    return fileName;
  }

  return (
    <div className="asset">
      <a
        href={fileUrl}
        className={` `}
        target="_blank"
        rel="noopener noreferrer">
        <div>
          <Image
            name={getImageByAssetKind(doc.kind)}
            className={getImageByAssetKind(doc.kind)}
          />
          <span className="doc-title">
            {(lang === 'it')? doc.name : doc.nameEng}
          </span>
        </div>
      </a>
      <Checkbox
        toggle
        checked={doc.active}
        onChange={(e, { checked }) =>
          updateData({
            active: checked ? checked : false,
            chapterIndex,
            assetIndex: assetIndex
          })
        }
      />
    </div>
  )
}

const AdminGlobal: React.FC<Props> = (props) => {
  
  const {
    intl, game,
    updateData,
    sendMails,
    sendPhase4OfferMails
  } = props;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalOfferPhase4Visible, setModalOfferPhase4Visible] = useState(false);

  function onSendMailsClick() {
    sendMails();
    setModalVisible(false);
  }

  function onSendPhase4OfferMailsClick() {
    sendPhase4OfferMails();
    setModalOfferPhase4Visible(false);
  }

  return (
    <div className="AdminGlobal">
      <ReactCSSTransitionGroup
        transitionName="fadeLateral"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}>
        <div className="game card" key={game.token}>
          {game.chapters.map(chapter => {
            const chapterIndex = game.chapters.indexOf(chapter);

            return (
              <div key={chapter.id} className={'chapter-wrapper'}>
                <div className="chapter">
                  <div className="label">
                    {intl.formatMessage({ id: `chapter-${chapter.id}:label` })}
                  </div>
                  {chapter.assets && (
                    <div className="assets">
                      {chapter.assets.map((doc: IAsset, assetIndex: number) => {

                        return(<AssetEntry {...props} 
                          {...{ doc, assetIndex, chapterIndex }}
                          key={doc.id}
                        ></AssetEntry>)
                      }
                      )}
                    </div>
                  )}
                  {chapter.kind === EChapterKind.EPILOGUE && (
                    <div className="assets">
                      <div className="asset green">
                        <div>
                          <Image name={'excelGreen'} />
                          <span>
                            {intl.formatMessage({ id: 'uploadExcel:label' })}
                          </span>
                        </div>
                        <Checkbox
                          toggle
                          checked={chapter.excelUpload}
                          onChange={(e, { checked }) =>
                            updateData({
                              active: checked ? checked : false,
                              chapterIndex,
                              isEpilogue: true,
                              excel: true
                            })
                          }
                        />
                      </div>
                      <div className="asset orange">
                        <div>
                          <Image name={'ppt'} />
                          <a
                            href={chapter.pptUrl ? chapter.pptUrl : '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`${
                              chapter.pptUrl ? ' ' : 'noClickEvent'
                            }`}>
                            <span>
                              {intl.formatMessage({
                                id: 'uploadPresentation:label'
                              })}
                            </span>
                          </a>
                          {chapter.pptUrl && <Image name={'checkOrange'} />}
                        </div>
                        <Checkbox
                          toggle
                          checked={chapter.pptUpload}
                          onChange={(e, { checked }) => {
                            updateData({
                              active: checked ? checked : false,
                              chapterIndex,
                              isEpilogue: true,
                              ppt: true
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {chapter.kind === EChapterKind.KPIS_MATRIX &&
                  chapter.kpis &&
                  chapter.scenarios && (
                    <div className="scenarios">
                      {chapter.kpis.map((kpi, index) => {
                        return (
                          <div className="scenario" key={kpi.id}>
                            <span className="title">
                              {intl.formatMessage({
                                id: `${kpi.id}-admin:label`
                              })}
                            </span>
                            <div className={`elements-container`}>
                              <div
                                className={`element-kpi ${
                                  kpi.active === true ? 'active' : ''
                                }`}
                                onClick={() => false}>
                                <Image
                                  name="kpis"
                                  className="kpis modal-trigger"
                                />
                              </div>
                              <div
                                className={`element-kpi ${
                                  kpi.active === true ? 'active' : ''
                                }`}
                                onClick={() => false}>
                                <Image
                                  name="comment"
                                  className="comment modal-trigger"
                                />
                                {kpi.notesConfirmed ? (
                                  <Image
                                    name="notesCheck"
                                    className="notesCheck"
                                  />
                                ) : null}
                              </div>
                              <Checkbox
                                toggle
                                style={{ marginLeft: '15px' }}
                                checked={kpi.active}
                                onChange={(e, { checked }) =>
                                  updateData({
                                    active: checked ? checked : false,
                                    chapterIndex,
                                    kpiIndex: kpi.index
                                  })
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                {chapter.kind === EChapterKind.SCENARIOS_MATRIX &&
                  chapter.scenarios &&
                  chapter.matrix && (
                    <div className="scenarios">
                      {chapter.scenarios.map((scenario, scenarioIndex) => {
                        const scenarioColumn = chapter.matrix.map(
                          row => row[scenarioIndex]
                        );
                        const completedKpis = scenarioColumn.filter(
                          kpi => kpi && kpi.status === 'CORRECT'
                        );
                        const totalKpis = scenarioColumn.filter(kpi => kpi);
                        return (
                          <div className="scenario" key={scenario.id}>
                            <span className="title">
                              {intl.formatMessage({
                                id: `${scenario.id}:label`
                              })}
                            </span>
                            <div className={`elements-container`}>
                              <div
                                className={`element-kpi ${
                                  scenario.active === true ? 'active' : ''
                                }`}
                                onClick={() => false}>
                                <Image
                                  name="kpis"
                                  className="kpis modal-trigger"
                                />
                                {totalKpis.length === completedKpis.length && (
                                  <Image
                                    name="kpisCheck"
                                    className="kpisCheck"
                                  />
                                )}
                              </div>
                              <div
                                className={`element-kpi ${
                                  scenario.active === true ? 'active' : ''
                                }`}
                                onClick={() => false}>
                                <Image
                                  name="comment"
                                  className="comment modal-trigger"
                                />
                                {scenario.notesConfirmed && (
                                  <Image
                                    name="notesCheck"
                                    className="notesCheck"
                                  />
                                )}
                              </div>
                              <Checkbox
                                toggle
                                style={{ marginLeft: '15px' }}
                                checked={scenario.active}
                                onChange={(e, { checked }) =>
                                  updateData({
                                    active: checked ? checked : false,
                                    chapterIndex,
                                    scenarioIndex: scenario.index
                                  })
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            );
          })}
          {
            <div className={'chapter-wrapper'}>
              <div className="chapter">
                <div className="label">
                  {intl.formatMessage({ id: 'team-report:label' })}
                </div>
                <div className="assets team-report">
                  <div
                    className={`asset blue ${game.sended ? 'sended' : ''}`}
                    onClick={() => setModalVisible(true)}>
                    <span>
                      {intl.formatMessage({
                        id: game.sended
                          ? 'report-sended:label'
                          : 'report-send:label'
                      })}
                    </span>
                    {game.sended && <Image name={'blueCheck'} />}
                    <Image name={'arrowMail'} />
                  </div>
                </div>
              </div>
            </div>
          }
          {
            <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
              <FormattedHTMLMessage id="admin-submit-popup:label" />
              <br />
              <FormattedHTMLMessage id="admin-submitA-popup:label" />{' '}
              {game.team}{' '}
              <FormattedHTMLMessage id="admin-submitB-popup:label" />
              <WhiteButton
                className="button-empty-white"
                onClick={() => onSendMailsClick()}>
                {intl.formatMessage({
                  id: 'admin-confirm-button:label'
                })}
              </WhiteButton>
            </Modal>
          }
          {
            <div className={'chapter-wrapper'}>
              <div className="chapter phase4Offer">
                <div className="label">
                  {intl.formatMessage({ id: 'team-phase4Offer:label' })}
                </div>
                <div className="assets team-report">
                  <div
                    className={`asset blue ${game.phase4OfferSended ? 'sended' : ''}`}
                    onClick={() => setModalOfferPhase4Visible(true)}>
                    <span>
                      {intl.formatMessage({
                        id: game.phase4OfferSended
                          ? 'phase4Offer-sended:label'
                          : 'phase4Offer-send:label'
                      })}
                    </span>
                    {game.phase4OfferSended && <Image name={'blueCheck'} />}
                    <Image name={'arrowMail'} />
                  </div>
                </div>
              </div>
            </div>
          }
          {
            <Modal show={modalOfferPhase4Visible} onClose={() => setModalOfferPhase4Visible(false)}>
              <FormattedHTMLMessage id="admin-phase4Offer-submit-popup:label" />
              <br />
              <FormattedHTMLMessage id="admin-phase4Offer-submitA-popup:label" />{' '}
              {game.team}{' '}
              <FormattedHTMLMessage id="admin-phase4Offer-submitB-popup:label" />
              <WhiteButton
                className="button-empty-white"
                onClick={() => onSendPhase4OfferMailsClick()}>
                {intl.formatMessage({
                  id: 'admin-phase4Offer-confirm-button:label'
                })}
              </WhiteButton>
            </Modal>
          }
        </div>
      </ReactCSSTransitionGroup>
    </div>
  );
};
export default injectIntl(AdminGlobal);
