import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Image from '../Image';
import './style.scss';

interface Props {
  bright?: boolean;
}

const WelcomeHeader: React.FC<Props> = ({ bright }) => {
  return (
    <div className={`WelcomeHeader ${bright && 'bright'}`}>
      <Image name="flameWhite" className="flameWhite" style={{}} />
      <span className="title">
        <FormattedHTMLMessage id="eurofire:label" />
      </span>
      <br />
      <span className="subtitle">
        <FormattedHTMLMessage id="interactive-business-case:label" />
      </span>
    </div>
  );
};

export default WelcomeHeader;
