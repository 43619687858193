import React, { useEffect, useState } from 'react';
import Image from '../Image';
import './style.scss';
import { VideoData, MediaInput, Lang, Section } from './data-templates';
import { AssetType } from '../../api';
import ImageTooltip from '../Tooltip/hover-tooltip';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';

interface Props {
  lang          : Lang;
  label         : string;
  labelEng      : string;
  assetKey      : string;
  assets        : Map<string, MediaInput | undefined>
  onViewAsset   : (asset: MediaInput) => void
  setSection    : (section: Section ) => void
  index         : number;
  chapterIndex  : number
  isEditable    : boolean
  intl          : InjectedIntl
}

const VideoTab: React.FC<Props> = ({
    lang, assetKey, label, labelEng, assets, index, chapterIndex, onViewAsset, setSection, isEditable, intl
}) => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [video, setVideo] = useState <MediaInput | undefined> (undefined)

  //Set Video
  useEffect(()=>{
    const asst = assets.get(assetKey)
    if(asst){
      const videoInfo = asst.data as VideoData;
      const newTitle = lang === 'Eng' ? videoInfo.nameEng : videoInfo.name;
      const newUrl = lang === 'Eng' ? videoInfo.urlEng : videoInfo.url;
      setTitle(newTitle);
      setUrl(newUrl);
      setVideo(asst)
    } else {
      setVideo(undefined);
      setTitle(lang === 'Ita' ? label : labelEng)
    }
  },[lang, assets, label, labelEng, assetKey])

  const editVideo = () => {
    if(video){
    onViewAsset(video)
    setSection('UploadVideo')
    }
  }

  const createNewVideo = () =>{
    const newVideo: VideoData = {
      id: assetKey,
      chapterIndex: chapterIndex,
      index: index,
      name: label,
      nameEng: labelEng,
      url: '',
      urlEng: ''
    };
    onViewAsset({data: newVideo, type: AssetType.video});
    setSection('UploadVideo');
  }

  const formatUrl = (link: string) =>{
    if (link.startsWith('https://')) {
      link = link.substring(8);
    } else if (link.startsWith('http://')) {
      link = link.substring(7);
    }
  
    if (link.endsWith('/')) {
      link = link.slice(0, -1);
    }
  
    return link;
  }

  return (
    <div className="media">
      <div className="header-documents">
        <div className="chapter-title-box">
          <Image name="playGray" />
          <h2 className="chapter-title">{title}</h2>
        </div>
        <div className='icon-container'>
          {(video && isEditable) && (
            <Image name="penGray" className="edit-button" onClick={editVideo} />
          )}
         {!video && 
          <ImageTooltip text={intl.formatMessage({id: 'admin-sessions-feedback-all-video'})} position={'left'}>
            <Image name="warning" className="warning-button"/>
          </ImageTooltip>}
            
        </div>
      </div>

      {video ? (
        <div className="element-container">
          <div className="video-box">
            <div className="video-text-container">
              <a href={url} target="_blank" rel="noopener noreferrer"  className="video-url">{formatUrl(url)}</a>
            </div>
          </div>
        </div>
      ) : (
        <div className="element-container">
          <div className="media-box" onClick={createNewVideo}>
            <div className="upload-text">
              <FormattedMessage id="admin-sessions-set-video" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(VideoTab);
