import React from 'react';
import './style.scss';

interface AdminOverviewProgressBarProps {
  maxValue: number;
  correctValue: number;
  wrongValue: number;
  onClick?: () => void;
}

const AdminOverviewProgressBar: React.FC<AdminOverviewProgressBarProps> = ({
  maxValue,
  wrongValue,
  correctValue,
  onClick
}) => {
  const percentSection = 100 / maxValue;
  return (
    <div className="AdminOverviewProgressBar" onClick={onClick}>
      <div
        className="WrongValueBar"
        style={{ width: `${percentSection * wrongValue}%` }}>
        {wrongValue > 0 && <span>{wrongValue}</span>}
      </div>
      <div
        className="CorrectValueBar"
        style={{ width: `${percentSection * correctValue}%` }}>
        {correctValue > 0 && <span>{correctValue}</span>}
      </div>
      <label>{maxValue}</label>
    </div>
  );
};

export default AdminOverviewProgressBar;
