import React, { useContext } from 'react';
import { IScenarioKpi, IKpi } from '../../interfaces';
import { AdminContext } from '../../context';

import './style.scss';

interface Props {
  scenarioKpi: IScenarioKpi;
  kpis: IKpi[];
  selected?: boolean;
  onClick?: Function;
}

const AdminKpi: React.FC<Props> = ({
  scenarioKpi,
  kpis,
  selected = false,
  onClick = () => {}
}) => {
  const { selectedChapter } = useContext(AdminContext);
  if (!selectedChapter) {
    throw new Error('Not found chapter');
  }
  const kpi = kpis.find(kpi => kpi.id === scenarioKpi.kpiID);
  return (
    <div
      className={`AdminKpi ${scenarioKpi.status.toLowerCase()} ${
        selected ? 'active' : ''
      }`}
      onClick={() => onClick()}>
      <div className="output">
        {kpi && kpi.unit === '€'
          ? scenarioKpi.value || 0
          : (scenarioKpi.value || 0).toFixed(2)}
        {kpi && kpi.unit === '€' ? 'k' : ''}
        {kpi && kpi.unit === '€' ? '' : ' '}
        {(kpi && kpi.unit) || '€'}
      </div>
    </div>
  );
};

export default AdminKpi;
