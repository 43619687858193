import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import WhiteButton from '../WhiteButton';
import './style.scss';


interface Props {
	history: any,
	intl: any,
	setSessionId: (id: string) => void;
}


const TokenInput: React.FC<Props> = (props) => {
	
	const inputEl = useRef<HTMLInputElement>(null);

	const { intl, setSessionId } = props;

	return (
		<div className="TokenInput">
			<WhiteButton
				onClick={() => null}
				className={'login'}
			>
				<input placeholder={intl.formatMessage({ id: 'login:session-label' })} 
					   ref={inputEl} 
					   onChange={e => setSessionId(inputEl.current!.value)}
				></input>
			</WhiteButton>
		</div>
	);
};

export default injectIntl(TokenInput);
