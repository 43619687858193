import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AdminContext } from '../../context';
import SectionTitle from '../SectionTitle';
import { EChapterKind, IScenario, IKpi } from '../../interfaces';
import './style.scss';

interface Props {
  intl: any;
  selectedScenario?: string;
  selectedKpi?: string;
}

const AdminNotes: React.FC<Props> = ({
  intl,
  selectedScenario,
  selectedKpi
}) => {
  const { game, selectedChapter } = useContext(AdminContext);
  if (!game) {
    throw new Error('Not found game');
  }
  const chapter = game.chapters.find(c => c.id === selectedChapter);
  if (!chapter) {
    throw new Error('Not found chapter');
  }
  const scenario = chapter.scenarios.find(
    scenario => scenario.id === selectedScenario
  );
  const kpi = chapter.kpis.find(kpi => kpi.id === selectedKpi);
  const targetObject: IScenario | IKpi | undefined =
    chapter.kind === EChapterKind.KPIS_MATRIX ? kpi : scenario;

  if (!targetObject) {
    throw new Error('Selected target not found');
  }

  var sectionTitleLabel = `${intl.formatMessage({
    id: 'contributionTeam:label'
  })}${game.team || ''}${intl.formatMessage({
    id: 'contributionTeamOn:label'
  })}${intl.formatMessage({ id: `${targetObject.id}:label` })}:`;

  return (
    <div className="AdminNotes">
      <SectionTitle
        color="#FF8744"
        title={sectionTitleLabel}
        image={'comment'}
      />
      <div className="content">
        {targetObject.notes ||
          intl.formatMessage({ id: 'notYetInsertedMessage:label' })}
      </div>
    </div>
  );
};

export default injectIntl(AdminNotes);
