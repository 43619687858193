/* eslint-disable */

const assets = [
  {
    active: false,
    id: 'doc-ristrutturare',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  },
  {
    active: false,
    id: 'doc-immagine',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  },
  {
    active: false,
    id: 'doc-prezzi',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  }
];

const scenarios = [
  {
    id: 'non-ristrutturare-progetto-immagine', dependOn: ['doc-immagine']
  },
  {
      id: 'non-ristrutturare-progetto-revisione-prezzi',
      dependOn: ['doc-prezzi']
  },
  {
    id: 'non-ristrutturare',
    dependOn: ['doc-ristrutturare']
  },
  {
    id: 'ristrutturare-progetto-immagine',
    dependOn: ['doc-ristrutturare', 'doc-immagine']
  },
  {
    id: 'ristrutturare-progetto-revisione-prezzi',
    dependOn: ['doc-ristrutturare', 'doc-prezzi']
  },
  {
    id: 'ristrutturare',
    dependOn: ['doc-ristrutturare']
  },
];

const kpis = [
  {
    id: 'fatturato-cumulato',
    unit: '€'
  },
  {
    id: 'utile-cumulato',
    unit: '€'
  },
  {
    id: 'quota-mercato-forni-anno-4',
    unit: '%'
  },
  {
    id: 'quota-mercato-lavelli-anno-4',
    unit: '%'
  },
  {
    id: 'livello-saturazione-reparto-piu-saturo-forni',
    unit: '%'
  },
  {
    id: 'livello-saturazione-reparto-piu-saturo-lavelli',
    unit: '%'
  }
];

const matrix = [
  [
    { refId:'fatturato-cumulato-non-ristrutturare-politica-di-brand',value: 573143 },
    { refId:'fatturato-cumulato-non-ristrutturare-politica-di-prezzo',value: 537701 },
    { refId:'fatturato-cumulato-non-ristrutturare',value: 547083 },
    { refId:'fatturato-cumulato-ristrutturare-politica-di-brand',value: 573143 },
    { refId:'fatturato-cumulato-ristrutturare-politica-di-prezzo',value: 537701 },
    { refId:'fatturato-cumulato-ristrutturare',value: 547083 },
  ],
  [
    { refId:'utile-cumulato-non-ristrutturare-politica-di-brand',value: 46918},
    { refId:'utile-cumulato-non-ristrutturare-politica-di-prezzo',value: 23594 },
    { refId:'utile-cumulato-non-ristrutturare',value: 33620 },
    { refId:'utile-cumulato-ristrutturare-politica-di-brand',value: 51151 },
    { refId:'utile-cumulato-ristrutturare-politica-di-prezzo',value: 27850 },
    { refId:'utile-cumulato-ristrutturare',value: 37861 },
  ],
  [
    { refId:'quota-mercato-anno-4-cottura-non-ristrutturare-politica-di-brand',value: 4 },
    { refId:'quota-mercato-anno-4-cottura-non-ristrutturare-politica-di-prezzo',value: 4.1 },
    { refId:'quota-mercato-anno-4-cottura-non-ristrutturare',value: 4.1 },
    { refId:'quota-mercato-anno-4-cottura-ristrutturare-politica-di-brand', value: 4 },
    { refId:'quota-mercato-anno-4-cottura-ristrutturare-politica-di-prezzo', value: 4.1 },
    { refId:'quota-mercato-anno-4-cottura-ristrutturare', value: 4.1 },
  ],
  [
    { refId:'quota-mercato-anno-4-lavelli-non-ristrutturare-politica-di-brand',value: 14.1 },
    { refId:'quota-mercato-anno-4-lavelli-non-ristrutturare-politica-di-prezzo',value: 13.6 },
    { refId:'quota-mercato-anno-4-lavelli-non-ristrutturare',value: 13.4 },
    { refId:'quota-mercato-anno-4-lavelli-ristrutturare-politica-di-brand',value: 14.1 },
    { refId:'quota-mercato-anno-4-lavelli-ristrutturare-politica-di-prezzo',value: 13.6 },
    { refId:'quota-mercato-anno-4-lavelli-ristrutturare',value: 13.4 },
  ],
  [
    { refId:'saturazione-reparto-piu-saturo-cottura-non-ristrutturare-politica-di-brand',value: 88.5 },
    { refId:'saturazione-reparto-piu-saturo-cottura-non-ristrutturare-politica-di-prezzo',value: 90.8 },
    { refId:'saturazione-reparto-piu-saturo-cottura-non-ristrutturare',value: 90.8 },
    { refId:'saturazione-reparto-piu-saturo-cottura-ristrutturare-politica-di-brand',value: 100.7 },
    { refId:'saturazione-reparto-piu-saturo-cottura-ristrutturare-politica-di-prezzo',value: 102.5 },
    { refId:'saturazione-reparto-piu-saturo-cottura-ristrutturare',value: 102.3 },
  ],
  [
    { refId:'saturazione-reparto-piu-saturo-lavelli-non-ristrutturare-politica-di-brand',value: 84.9 },
    { refId:'saturazione-reparto-piu-saturo-lavelli-non-ristrutturare-politica-di-prezzo',value: 81.9 },
    { refId:'saturazione-reparto-piu-saturo-lavelli-non-ristrutturare',value: 80.7 },
    { refId:'saturazione-reparto-piu-saturo-lavelli-ristrutturare-politica-di-brand',value: 84.9 },
    { refId:'saturazione-reparto-piu-saturo-lavelli-ristrutturare-politica-di-prezzo',value: 81.9 },
    { refId:'saturazione-reparto-piu-saturo-lavelli-ristrutturare',value: 80.7 },
  ]
];

export default {
  assets,
  scenarios,
  kpis,
  matrix
};
