import React, { useEffect, useState } from 'react';
import './style.scss';

interface Props {
    children?: any,
    onClick: () => void
}

const ResetButton: React.FC<Props> = ({children, onClick}) => {
    return <p className="discard-text" onClick={onClick}> {children} </p>
}

export default ResetButton