import adminOverviewModalIconOff from '../../assets/images/adminOverviewModalIconOff.svg';
import adminOverviewModalIconOn from '../../assets/images/adminOverviewModalIconOn.svg';
import adminOverviewOff from '../../assets/images/adminOverviewOff.svg';
import adminOverviewOn from '../../assets/images/adminOverviewOn.svg';
import albertoGrando from '../../assets/images/albertoGrando.png';
import arrowMail from '../../assets/images/arrow-mail.png';
import arrowWhite from '../../assets/images/arrow-right-white.svg';
import arrow from '../../assets/images/arrow-point-to-right.svg';
import blueCheck from '../../assets/images/blueCheck.svg';
import {
  default as bocconi,
  default as llabLogoWhite
} from '../../assets/images/bocconi.png';
import checkGreen from '../../assets/images/check-green.svg';
import checkOrange from '../../assets/images/check-orange.svg';
import close from '../../assets/images/close.png';
import closeLowResMessage from '../../assets/images/closeLowResMessage.png';
import comment from '../../assets/images/comment.svg';
import docSample1 from '../../assets/images/doc-1.png';
import docPreview_ValutazFinanziaria_eng from '../../assets/images/docPreview_10_ValutazioneFinanziaria-eng.png';
import docPreview_ValutazFinanziaria from '../../assets/images/docPreview_10_ValutazioneFinanziaria.png';
import docPreview_CapacitàProduttive_eng from '../../assets/images/docPreview_11_CapacitaProduttive-eng.png';
import docPreview_CapacitàProduttive from '../../assets/images/docPreview_11_CapacitaProduttive.png';
import docPreview_Intro_eng from '../../assets/images/docPreview_1_Intro-eng.png';
import docPreview_Intro from '../../assets/images/docPreview_1_Intro.png';
import docPreview_AnalisiEuroFire_eng from '../../assets/images/docPreview_2_AnalisidiEuroFire-eng.png';
import docPreview_AnalisiEuroFire from '../../assets/images/docPreview_2_AnalisidiEuroFire.png';
import docPreview_BilancioItalInox_eng from '../../assets/images/docPreview_3_BilancidiItalInox-eng.png';
import docPreview_BilancioItalInox from '../../assets/images/docPreview_3_BilancidiItalInox.png';
import docPreview_Estensione_eng from '../../assets/images/docPreview_4_Estensione2-eng.png';
import docPreview_Estensione from '../../assets/images/docPreview_4_Estensione2.png';
import docPreview_Produzione_eng from '../../assets/images/docPreview_5_CostiProduzione-eng.png';
import docPreview_Produzione from '../../assets/images/docPreview_5_CostiProduzione.png';
import docPreview_IlSistemaProduttivo_eng from '../../assets/images/docPreview_5_IlSistemaProduttivo-eng.png';
import docPreview_IlSistemaProduttivo from '../../assets/images/docPreview_5_IlSistemaProduttivo.png';
import docPreview_Kit_eng from '../../assets/images/docPreview_6_EffettoKit-eng.png';
import docPreview_Kit from '../../assets/images/docPreview_6_EffettoKit.png';
import docPreview_Ristrutturazione_eng from '../../assets/images/docPreview_7_Ristrutturazione-eng.png';
import docPreview_Ristrutturazione from '../../assets/images/docPreview_7_Ristrutturazione.png';
import docPreview_ProgImmagine_eng from '../../assets/images/docPreview_8_ProgettoImmagine-eng.png';
import docPreview_ProgImmagine from '../../assets/images/docPreview_8_ProgettoImmagine.png';
import docPreview_ProgPrezzi_eng from '../../assets/images/docPreview_9_ProgettoRevisionePrezzi-eng.png';
import docPreview_ProgPrezzi from '../../assets/images/docPreview_9_ProgettoRevisionePrezzi.png';
import docPreview_Report from '../../assets/images/docPreview_Report.png';
import docPreview_Report_eng from '../../assets/images/docPreview_Report_eng.png';
import preview from '../../assets/images/preview.png';
import doc from '../../assets/images/document.svg';
import docGray from '../../assets/images/docGray.svg'
import downArrow from '../../assets/images/downArrow.png';
import downArrowWhite from '../../assets/images/downArrowWhite.png';
import downloadIcon from '../../assets/images/downloadIcon.svg';
import downloadIconGrey from '../../assets/images/downloadIconGrey.svg';
import eye from '../../assets/images/EFeye.png';
import excelGreen from '../../assets/images/excel-green.svg';
import excel from '../../assets/images/excel.svg';
import firmaCallegari from '../../assets/images/firmaCallegari.png';
import flame from '../../assets/images/flame.svg';
import save from '../../assets/images/save.svg';
import flameWhite from '../../assets/images/flameWhite.svg';
import giorgioLazzaro from '../../assets/images/giorgioLazzaro.png';
import histogram from '../../assets/images/histogram.svg';
import kpisCheck from '../../assets/images/kpis-check.svg';
import kpis from '../../assets/images/kpis.svg';
import leftArrow from '../../assets/images/left-arrow.svg';
import arrowUp from '../../assets/images/arrow-up.svg';
import location from '../../assets/images/location.svg';
import logoArea from '../../assets/images/logoArea.png';
import logoAreaWelcome from '../../assets/images/logoAreaWelcome.png';
import LowRes from '../../assets/images/LowRes.png';
import mail from '../../assets/images/mail.svg';
import noteSend from '../../assets/images/note-send.svg';
import notesCheck from '../../assets/images/notes-check.svg';
import panelOff from '../../assets/images/panel-off.svg';
import panel from '../../assets/images/panel.svg';
import pencil from '../../assets/images/pencil.svg';
import phase4OfferFooterImage from '../../assets/images/phase4OfferFooterImage.png';
import play from '../../assets/images/play.svg';
import playGray from '../../assets/images/playGray.svg';
import ppt from '../../assets/images/ppt.svg';
import problem from '../../assets/images/problem.png';
import robotSandyLarge from '../../assets/images/robot_sandy_large.svg';
import womanAvatar from '../../assets/images/sandy.svg';
import severinoMeregalli from '../../assets/images/severinoMeregalli.png';
import slidesFileIcon from '../../assets/images/slidesFileIcon.svg';
import sparkGif from '../../assets/images/Sparkv2.gif';
import sparkAvatar from '../../assets/images/spark_mini2.png';
import ThankYouImage from '../../assets/images/ThankYouImage.png';
import ThankYouReportImage from '../../assets/images/ThankYouReportImage.png';
import timelineOff from '../../assets/images/timeline-off.svg';
import timeline from '../../assets/images/timeline.svg';
import closeBlack from '../../assets/images/unnamed-close.png';
import upload from '../../assets/images/upload.svg';
import valterConca from '../../assets/images/valterConca.png';
import videoIcon from '../../assets/images/video-icon.png';
import welcomeLearninglabLogo from '../../assets/images/welcome-learninglab-logo.png';
import welcomePageMainImage from '../../assets/images/welcomePageMainImage.png';
import xlsFileIcon from '../../assets/images/xlsFileIcon.svg';
import download from '../../assets/images/download.svg';
import pen from '../../assets/images/pen.svg';
import penGray from '../../assets/images/penGray.svg';
import whitePen from '../../assets/images/pen-white.svg';
import bin from '../../assets/images/bin.svg';
import uploadFile from '../../assets/images/uploadFile.svg';
import confirm from '../../assets/images/confirm.svg';
import confirmGrey from '../../assets/images/confirm-grey.svg';
import admin from '../../assets/images/admin.svg';
import adminOff from '../../assets/images/adminOff.svg';
import search from '../../assets/images/search.svg';
import add from '../../assets/images/add.svg';
import calendar1 from '../../assets/images/calendar1.svg';
import calendar2 from '../../assets/images/calendar2.svg';
import selectedItem from '../../assets/images/selected-item.svg';
import warning from '../../assets/images/warning.svg'

const images: { [s: string]: string } = {
  default: doc,
  flame,
  admin,
  add,
  arrowUp,
  arrowWhite,
  download,
  pen,
  penGray,
  whitePen,
  bin,
  uploadFile,
  confirm,
  calendar1,
  calendar2,
  selectedItem,
  confirmGrey,
  adminOff,
  search,
  flameWhite,
  bocconi,
  llabLogoWhite,
  location,
  mail,
  upload,
  excel,
  kpis,
  doc,
  docGray,
  playGray, 
  save,
  histogram,
  notesCheck,
  comment,
  docSample1,
  welcomePageMainImage,
  ThankYouImage,
  ThankYouReportImage,
  downArrow,
  downArrowWhite,
  valterConca,
  albertoGrando,
  giorgioLazzaro,
  severinoMeregalli,
  kpisCheck,
  noteSend,
  play,
  panel,
  panelOff,
  LowRes,
  problem,
  videoIcon,
  close,
  timeline,
  timelineOff,
  adminOverviewOn,
  adminOverviewOff,
  adminOverviewModalIconOff,
  adminOverviewModalIconOn,
  ppt,
  excelGreen,
  checkGreen,
  checkOrange,
  xlsFileIcon,
  slidesFileIcon,
  docPreview_Intro,
  docPreview_AnalisiEuroFire,
  docPreview_BilancioItalInox,
  docPreview_Estensione,
  docPreview_Produzione,
  docPreview_IlSistemaProduttivo,
  docPreview_Kit,
  docPreview_Ristrutturazione,
  docPreview_ProgImmagine,
  docPreview_ProgPrezzi,
  docPreview_ValutazFinanziaria,
  docPreview_Report,
  docPreview_CapacitàProduttive,
  preview,
  eye,
  arrowMail,
  firmaCallegari,
  womanAvatar,
  leftArrow,
  arrow,
  blueCheck,
  downloadIcon,
  downloadIconGrey,
  robotSandyLarge,
  sparkGif,
  sparkAvatar,
  pencil,
  logoArea,
  logoAreaWelcome,
  welcomeLearninglabLogo,
  closeLowResMessage,
  docPreview_Intro_eng,
  docPreview_ValutazFinanziaria_eng,
  docPreview_CapacitàProduttive_eng,
  docPreview_AnalisiEuroFire_eng,
  docPreview_BilancioItalInox_eng,
  docPreview_Estensione_eng,
  docPreview_Produzione_eng,
  docPreview_IlSistemaProduttivo_eng,
  docPreview_Kit_eng,
  docPreview_Ristrutturazione_eng,
  docPreview_ProgImmagine_eng,
  docPreview_ProgPrezzi_eng,
  docPreview_Report_eng,
  phase4OfferFooterImage,
  warning,
  closeBlack
};

export default images;
