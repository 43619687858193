import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';
import {  injectIntl } from 'react-intl';


interface Props {
	data: any;
	children: any;
	intl: any;
}

const WithLoader: React.FC<Props> = ({ data, children, intl }) => {
	return data !== undefined ? (
		children
	) : (
		<Dimmer active inverted>
			<Loader inverted>{intl.formatMessage({id : 'loading:label'})}</Loader>
		</Dimmer>
	);
};

export default injectIntl(WithLoader);
