
const assets = [
    {
        active: true,
        id: 'video-prologue',
        url: 'https://player.vimeo.com/video/565553418',
        urlEng: 'https://player.vimeo.com/video/565554599',
        kind: 'video'
    },
    {
        active: false,
        id: 'doc-prologue',
        url: '',
        urlEng: '',
        kind: 'document',
        previewImage: ''
    }
]

export default {
	assets
};