import React from 'react';
import Image from '../Image';
import './style.scss';
interface Props {
	onClick?: Function;
	text: string;
	image: string;
	color: string;
}

const ActionButton: React.FC<Props> = ({ onClick, text, image, color }) => {
	return (
		<div
			className={`ActionButton ${onClick ? 'clickable' : ''}`}
			onClick={() => onClick && onClick()}
			style={{
				color
			}}>
			<span className="text">{text}</span>
			<Image className="image" name={image} />
		</div>
	);
};

export default ActionButton;
