/* eslint-disable */

const assets = [
	{
		active: false,
		id: 'doc-valutazione-finanziaria',
		url: '',
		urlEng: '',
		kind: 'document',
		previewImage: ''
	}
]

const scenarios = [
	{
		id: 'non-ristrutturare-progetto-immagine'
	},
	{
		id: 'non-ristrutturare-progetto-revisione-prezzi'
	},
	{
		id: 'non-ristrutturare'
	},
	{
		id: 'ristrutturare-progetto-immagine'
	},
	{
		id: 'ristrutturare-progetto-revisione-prezzi'
	},
	{
		id: 'ristrutturare'
	},
];

const kpis = [
	{
		id: 'net-present-value',
		unit: '€',
		dependOn: ['doc-valutazione-finanziaria']
	},
	{
		id: 'net-present-value-con-disinvestimento',
		unit: '€',
		dependOn: ['doc-valutazione-finanziaria']
	}
];

const matrix = [
	[
		{ refId:'present-value-non-ristrutturare-progetto-immagine',value: [59351, 90041] },
        { refId:'present-value-non-ristrutturare-progetto-revisione-prezzi',value: [31547, 68777] },
        { refId:'present-value-non-ristrutturare',value: [50237, 79615] },
		{ refId:'present-value-ristrutturare-progetto-immagine',value: [73779, 94317] },
		{ refId:'present-value-ristrutturare-progetto-revisione-prezzi',value: [46068, 73086] },
        { refId:'present-value-ristrutturare',value: [64730, 83908] },
	],
	[
		{ refId:'present-value-con-disinvestimento-non-ristrutturare-progetto-immagine',value: 90041 },
        { refId:'present-value-con-disinvestimento-non-ristrutturare-progetto-revisione-prezzi',value: 68777 },
        { refId:'present-value-con-disinvestimento-non-ristrutturare',value: 79615 },
		{ refId:'present-value-con-disinvestimento-ristrutturare-progetto-immagine',value: 94317 },
		{ refId:'present-value-con-disinvestimento-ristrutturare-progetto-revisione-prezzi',value: 73085 },
        { refId:'present-value-con-disinvestimento-ristrutturare',value: 83908 },
	]
];

export default {
	assets,
	scenarios,
	kpis,
	matrix
};
