import './style.scss';
import React, { Fragment, useContext, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import Image from '../../Image';
import { AppContext } from '../../../context';
import { IGame, ISession, ParamWithOwner } from '../../../interfaces';
import { AssetMutationOutput, AssetQueryOutput } from '../../../api';
import { createSessionData, updateMedia, updateParameters } from '../sessions/populate';
import { database } from '../../../utilities/database';
import { assetsService } from '../../../utilities/assetsService';
import SessionModals from '../sessions/EditModal';
import SessionReportDocument from '../../SessionReport/SessionReportDocument';
import { pdf } from '@react-pdf/renderer';
import GameReportDocument from '../../GameReport/GameReportDocument';
import ArchiveDownloader from '../../../utilities/archiveDowloader';
import { injectIntl, FormattedMessage, FormattedHTMLMessage, FormattedDate } from 'react-intl';
import LoadingModal from '../../LoadingModal';
import _ from 'lodash';
import ConfirmModal from '../../ConfirmModal';

interface Props {
  history: any;
  intl: any;
}

const SelectedSession: React.FC<Props> = (props) => {

  const { session, setSession } = useContext(AppContext);

  const [ modal, setModal ] = useState<'edit'|'info'|'close'>('close')

  const [ confirmModalOpen, setConfirmModalOpen ] = useState<boolean>(false);

  const [archiveLoading, setArchiveLoading] = React.useState(false);
  const [archiveProgress, setArchiveProgress] = React.useState(0);

  const closeModal = () => setModal('close');

  const areParameterSelected = (session: ISession) => !!session?.data.paramId

  const selectedParameterCallback = async (param: ParamWithOwner): Promise<void> => {
      
    const clonedSession = _.cloneDeep(session);
    let sessionDataId = clonedSession!.data.sessionDataId;
    const isUpdate = sessionDataId !== undefined;

    const sessionData: any = !isUpdate ?
      createSessionData(clonedSession!.data.teamNo) : 
      await database.get(sessionDataId!, 'games')
        .then(res => { return { 'games': res }});

    const assets = (await assetsService
      .getAssets({ includeData: true, groupID: param.groupId }))
      .filter((asset: AssetQueryOutput) => 
          ['parameter', 'video', 'document'].includes(asset.type));

    updateParameters(sessionData, assets);
    updateMedia(sessionData, assets);

    sessionDataId = await (isUpdate ? 
      database.set(sessionDataId!, 'games', sessionData.games) : 
      database.create(sessionData)
    );
    const res: AssetMutationOutput = await assetsService.updateAsset({
        ...clonedSession!,
        groupId: param.groupId,
        data: JSON.stringify({
          ...clonedSession!.data,
          paramId: param.id, paramLabel: param.name!,
          sessionDataId
      })
    });
    const newSession: ISession = JSON.parse(res.data);
    delete newSession.__typename;
    setSession!(newSession);
  }

  const stopSessionCallback = async (): Promise<void> => {

    // TODO ask for confirmation

    const clonedSession = _.cloneDeep(session);

    const res: AssetMutationOutput = await assetsService.updateAsset({
        ...clonedSession!,
        data: JSON.stringify({
          ...clonedSession!.data,
          terminated: true
      })
    });
    const newSession: ISession = JSON.parse(res.data);
    delete newSession.__typename;
    setSession!(newSession);
  }

  const archiveSessionCallback = async (): Promise<void> => {

    setArchiveLoading(true); setArchiveProgress(0);

    const games: IGame[] = Array.from(Object.values(
        await database.get(session!.data.sessionDataId!, 'games')
    ));
    const sessionReport = await pdf(
        <SessionReportDocument session={session!} games={games} />
    ).toBlob();

    const gamesReports = await Promise.all(games
        .filter(game => game.players.length !== 0)
        .map(async (game) => {
            return {
                name: game.team,
                blob: await pdf(
                    <GameReportDocument intl={props.intl} game={game} />
                ).toBlob(),
            }
    }));
    const archive = new ArchiveDownloader(
        session!, sessionReport, gamesReports,
        (progress, total) => setArchiveProgress(progress / total)
    );
    await archive.generate();
    await archive.download();

    setArchiveLoading(false);
  }

  return (
    <div className="section selected-session">
    
        <ConfirmModal
            open={confirmModalOpen}
            title={props.intl.formatMessage({id: "admin-sessions-confirm-terminate-title"})}
            content={props.intl.formatMessage({id: "admin-sessions-confirm-terminate-content"})}
            onConfirm={() => {
                stopSessionCallback();
                setConfirmModalOpen(false);
            }}
            onCancel={() => setConfirmModalOpen(false)}
            danger={true}
        />

        <section className='session-section'>
            <div className="header-container">
                <p className="title">
                    <FormattedMessage id="admin-sessions-selected" /> 
                    { session && session.data.terminated && 
                      <span className="old-session">
                        <FormattedMessage id="admin-sessions-closed" />
                      </span>
                    }
                </p>
                { session && (
                  <div className='selected-session-btns'>
                    { !session.data.terminated ? (
                      <Fragment>
                        { areParameterSelected(session) &&
                        <button onClick={() => setConfirmModalOpen(true)}
                            className="stop-session">
                          <Icon name="stop" className='stop-session-icon'/>
                          <p className="text">
                            <FormattedMessage id="admin-sessions-terminate" />
                          </p>
                        </button>
                        }
                        <button onClick={()=> setModal('edit')}
                            className="edit-session">
                            <Image name="whitePen" />
                            <p className="text">
                                { !areParameterSelected(session)
                                    ? <FormattedMessage id="admin-sessions-select-params" />
                                    : <FormattedMessage id="admin-sessions-edit-params" /> 
                                }
                            </p>
                        </button>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <button onClick={archiveSessionCallback}
                            className="archive-session-button">
                          <Icon name="archive" className='archive-session-icon'/>
                          <p className="text">
                            <FormattedMessage id="admin-sessions-archive" />
                          </p>
                        </button>
                        <button onClick={()=> setModal('edit')}
                          className="view-session-parameter">
                          <Icon name="eye" className='eye-icon'/>
                          <p className="text">
                            <FormattedMessage id="admin-sessions-see-params" />
                          </p>
                        </button> 
                      </Fragment>  
                    )}
                  </div>
                )}
            </div>
            <p className="explanation-text">
              <FormattedHTMLMessage id="admin-sessions-info-session" />
            </p>
            <div className='content-container'>
                { session && (
                <div className='current-session'>

                    { !areParameterSelected(session) && (
                    <div className='warning parameter-na'>
                        <Image name="warning" className="warning-button" />
                        <p className='text'>
                            <FormattedMessage id="admin-sessions-feedback-no-param-set" />
                        </p>
                    </div>
                    )}
                
                    <div className='session-info'>
                    <div className='info-box session-id'>
                        <p className='text-info'>{props.intl.formatMessage({id: "admin-sessions-session-id"})}:</p>
                        <p className='text-data'>{session.id}</p>
                    </div>
                    <div className='info-box date'>
                        <p className='text-info'>{props.intl.formatMessage({id: "admin-sessions-date-creation"})}:</p>
                        <p className='text-data'>
                            <FormattedDate value={session.date!} />
                        </p>
                    </div>
                    <div className='info-box parameter'>
                        <p className='text-info'>{props.intl.formatMessage({id: "admin-sessions-parameters"})}:</p>
                        <p className={"text-data " + 
                            (areParameterSelected(session) ? 'selected' : 'pending') }
                        >{
                            areParameterSelected(session) ? 
                            session?.data.paramLabel : 'NA'
                        }</p>
                    </div>
                    <div className='info-box teachers'>
                        <p className='text-info'>{props.intl.formatMessage({id: "admin-sessions-teachers"})}:</p>
                        <p className='text-data'>{
                        session?.data.teachers.join(', ')
                        }</p>
                    </div>
                    </div>
                </div>
                )}
                <div className='history-sessions'>
                    { modal !== 'close' && 
                    <SessionModals close={closeModal} 
                        confirmSelectionCb={selectedParameterCallback}
                        sessionParamId={session?.data.paramId}
                        selectedGroup={session?.groupId}
                        showEditModal={session?.data.terminated}
                    />
                    }
                </div>
            </div>

            <LoadingModal title={props.intl.formatMessage({id: "admin-sessions-archive-generation"})} open={archiveLoading} progress={archiveProgress} />
        </section>
    </div>
  );
};

export default injectIntl(SelectedSession);
