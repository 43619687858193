import JSZip from 'jszip';
import { ISession } from '../interfaces';
import { storageS3 } from './s3storage';
import saveAs from 'file-saver';

class ArchiveDownloader {
    
    private sessionName: string;
    private date: Date;
    private sessionDate: string;

    private archive: JSZip = JSZip();

    private progress: number = 0;
    private total: number = 0;

    constructor(
        private session: ISession,
        private sessionReport: Blob, 
        private teamReports: {name: string, blob: Blob}[],
        private onProgress: (progress: number, total: number) => void,
    ) {
        this.archive = JSZip();

        this.sessionName = session.name ?? '';
        this.date = new Date(session.date!);
        this.sessionDate = `${this.date.getFullYear()}-`
            + `${this.date.getMonth()}-` 
            + `${this.date.getDay()}`;
    }

    public async generate(): Promise<JSZip>{
        
        this.progress = 0;
        const [paramFiles, uploadFiles] = await Promise.all([
            storageS3.getFileList(`media/${this.session.groupId}`),
            storageS3.getFileList(`uploads/${this.session.id}`)
        ]);
        this.total = paramFiles.length + uploadFiles.length + 1;
        
        await Promise.all([
            this.archiveReports(),
            this.archiveMedia(paramFiles),
            this.archiveUploads(uploadFiles)
        ]);
        return this.archive;
    }
    
    public async download(): Promise<void> {
        saveAs(
            await this.archive.generateAsync({type: "blob"}),
            `${this.sessionName}_${this.sessionDate}.zip`
        );
    }

    /**
     * Archive generated reports for the session
     * */
    private async archiveReports(): Promise<void> {
        this.archive.file(
            `report_${this.sessionName}.pdf`, 
            this.sessionReport
        );
        this.teamReports.forEach(teamReport => this.archive.file(
            `${this.sessionName}_${teamReport.name}_${this.sessionDate}_report.pdf`,
            teamReport.blob
        ));
    }

    /**
     * Archive media files of parameters
     * */
    private async archiveMedia(paramFiles: string[]): Promise<void> {
        await Promise.all(paramFiles.map(async fileKey => {
            const result = await storageS3.getFileUrl(fileKey, true);
            const name = fileKey.split('/').pop();
            const mime = result.Body.type;
            const ext = mime === 'application/pdf' ? 'pdf' : 
                (mime === 'application/msword' ? 'doc' : 'docx');

            this.archive.file(
                `${this.sessionName}_medias/${name}.${ext}`, 
                result.Body
            );
            this.advanceProgress();
        }))
    }

    /**
     * Archive students uploads
     * */
    private async archiveUploads(uploadFiles: string[]): Promise<void> {
        await Promise.all(uploadFiles.map(async fileKey => {
            const result = await storageS3.getFileUrl(fileKey, true);
            const name = fileKey.split('/').slice(-2)[0];
            const mime = result.Body.type;
            const ext = mime === 'application/pdf' ? 'pdf' : 
                (mime === 'application/vnd.ms-excel' ? 'xls' : 'xlsx');

            this.archive.file(
                `${this.sessionName}_${name}_${this.sessionDate}_upload.${ext}`, 
                result.Body
            );
            this.advanceProgress();
        }));
    }

    private advanceProgress() {
        this.progress += 1;
        this.onProgress(this.progress, this.total)
    }
}

export default ArchiveDownloader
