
export const teamName = [
    'Tokyo',
    'Moscow',
    'Berlin',
    'Nairobi',
    'Rio',
    'Denver',
    'Helsinki',
    'Oslo',
    'Madrid',
    'Rome',
    'Beijing',
    'Amsterdam',
    'London',
    'Bangkok',
    'Baku',
    'Astana',
    'Beirut',
    'Bruxelles',
    'Copenhagen',
    'Dublin',
    'Paris',
    'Havana',
    'Edinburgh',
    'Lima',
    'Lisbon',
    'Washington',
    'Prague',
    'Stockholm',
    'Tunisi',
    'Wien',
    'Warsaw',
    'Montevideo'
];