import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Image from '../Image';
import './style.scss';

interface Props {
	teacherID: string;
}

const TeacherInfoBox: React.FC<Props> = ({ teacherID }) => {
	return (
		<div className="TeacherInfoBox">
			<p className="text">
				<FormattedHTMLMessage id={`${teacherID}-title:label`} />
				<FormattedHTMLMessage id={`${teacherID}-name:label`} />
			</p>
			<Image name={teacherID} className='' />
		</div>
	);
};

export default TeacherInfoBox;
