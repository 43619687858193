/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSession = /* GraphQL */ `
  query GetSession($session: ID!, $path: String!) {
    getSession(session: $session, path: $path) {
      data
      path
      session
      __typename
    }
  }
`;
export const getAssets = /* GraphQL */ `
  query GetAssets($includeData: Boolean, $groupID: ID, $type: AssetType) {
    getAssets(includeData: $includeData, groupID: $groupID, type: $type) {
      id
      groupId
      type
      name
      date
      owner
      default
      global
      data
      __typename
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      groupId
      type
      name
      date
      owner
      default
      global
      data
      __typename
    }
  }
`;
