import map from 'lodash/map';
import range from 'lodash/range';
import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { AppContext, PlayerContext } from '../../context';
import { IAsset } from '../../interfaces';
import SectionTitle from '../SectionTitle';
import './style.scss';
import { storageS3 } from '../../utilities/s3storage';

interface Props {
  intl: any;
}
interface LinkProps extends Props {
  asset: IAsset;
}

const DocumentLink: React.FC<LinkProps> = ({ intl, asset }) => {

  const { lang } = useContext(AppContext);
  const [fileUrl, setFileUrl] = useState<string>();
  const {url, urlEng} = asset
  let updatedUrl = (lang === 'it')? url : urlEng

  useEffect(() => {
    (async () => {
      setFileUrl(asset.kind !== 'video' ? 
        (await storageS3.getFileUrl(updatedUrl)) : 
        updatedUrl
      );
    })()
  }, [asset, updatedUrl])

  return(
    <a  rel="noopener noreferrer"
        href={fileUrl ?? 'javscript:void(0)'}
        target="_blank">
        <SectionTitle
          color="#cf0000"
          title={intl.formatMessage({ id: `${asset.id}:label` })}
          image={asset.kind === 'video' ? 'play' : 'doc'}
      />
    </a>
  )
};

const SectionDocuments: React.FC<Props> = (props) => {
  const { activeAssets } = useContext(PlayerContext);

  if (!activeAssets) {
    throw new Error('Not found activeAssets');
  }
  const elementsNumber = Math.max(activeAssets.length, 3);

  return (
    <div
      className="SectionDocuments"
      style={{
        gridTemplateColumns: `${map(range(elementsNumber), el => `1fr`).join(
          ' '
        )}`
      }}>
      {activeAssets.map((asset: IAsset) => {
          return (<DocumentLink 
            key={asset.id}
            {...props} asset={asset}
          ></DocumentLink>)
        }
      )}
    </div>
  );
};

export default injectIntl(SectionDocuments);
