import React, { Fragment, useState } from 'react';
import './style.scss';
import Image from '../../Image';
import Modal from '../../SessionsModal';
import History from '../../Parameters/History/History';
import { ParamWithOwner } from '../../../interfaces';
import ModalParameters from '../../Parameters/Modal/modal-parameters.tsx';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';

interface Props {
    close: ()=>void
    confirmSelectionCb: (param: ParamWithOwner) => Promise<void>
    sessionParamId?: string
    showEditModal?: boolean
    selectedGroup?: string
    intl: InjectedIntl
}

const SessionModals: React.FC<Props> = ({ 
    close, confirmSelectionCb, sessionParamId, showEditModal, selectedGroup, intl
}) => {

    const [selectedParam, setSelectedParam] = useState<ParamWithOwner>();

    return (
        <Fragment>
            { showEditModal ? (
              <ModalParameters close={close} groupId={selectedGroup} isNewSet={false} /> 
            ) : (
            <Modal             
                onClose={close}
                title={intl.formatMessage({id: "admin-sessions-ask-param-selection"})}
                fullSize={true}
            >
                <div className='modal-content'>

                    <History 
                        selectParamCb={setSelectedParam} 
                        selectable={true}
                        sessionParamId={sessionParamId}
                    />

                    <div className="buttons-save-mode">
                    <p
                        className="discard-text"
                        onClick={()=>close()}>
                        <FormattedMessage id="admin-sessions-cancel" />
                    </p>
                    <button
                        disabled={!selectedParam}
                        onClick={async () => {
                            await confirmSelectionCb(selectedParam!);
                            close();
                        }}
                        className="confirm-button"
                    >
                        <Image name="confirm" />
                        <p className="confirm-text">
                            <FormattedMessage id="admin-sessions-confirm-selection" />
                        </p>
                    </button>
                    </div>
                </div>
            </Modal>
        )}
        </Fragment>
  )
}

export default injectIntl(SessionModals)
