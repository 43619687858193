import { API } from 'aws-amplify';
import { 
    GraphQLQuery, GraphQLSubscription, GraphQLResult, GRAPHQL_AUTH_MODE 
} from '@aws-amplify/api';
import * as api from "../api";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';

import Observable, { ZenObservable } from 'zen-observable-ts';

export type AssetsSubscription = ZenObservable.Subscription;

/**
 * A sigleton wrapper to incapsulate methods which act onto the assets api.
 * */
class AssetsService {

    private static _instance ?: AssetsService;

    public static get instance() {

        if (AssetsService._instance === undefined) {
            AssetsService._instance = new AssetsService();
        }
        return AssetsService._instance;
    }

    /**
     * Execute an api call which returns a promise
     * */
    private async apiCall<T>(query: string, variables?: object): Promise<T> {

        const queryData = {
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM, 
            ...(variables ? { variables } : {}),
            query
        };
        const res = await (
            API.graphql<GraphQLQuery<T>>(queryData) as Promise<GraphQLResult<T>>
        );
        if (res.errors) {
            return Promise.reject("Generic API error");
        }
        return res.data!;
    }

    /**
     * Execute an api call which returns an observable
     * */
    private apiObserve<T>(query: string, variables?: object): Observable<T> {

        const queryData = {
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM, 
            ...(variables ? { variables } : {}),
            query
        };
        const res = (API.graphql<GraphQLSubscription<T>>(queryData) as Observable<{
            provider: any ; // AWSAppSyncRealTimeProvider
            value: GraphQLResult<T>;
        }>);
        return res.map(x => x.value.data!);
    }

    public async createAsset(asset: api.CreateAssetInput): Promise<api.AssetMutationOutput | undefined> {
        const variables: api.CreateAssetMutationVariables = {data: asset};

        const mutation = await this.apiCall<api.CreateAssetMutation>(
            mutations.createAsset,
            variables
        )
        .catch((err) => {
            console.error("Error while creating asset ", asset, err);
        });

        if (mutation) return mutation.createAsset;
    }

    public async createAssets(assets: api.CreateAssetInput[]): Promise<api.AssetMutationOutput | undefined> {
        const variables: api.CreateAssetsMutationVariables = {list: assets};

        const mutation = await this.apiCall<api.CreateAssetsMutation>(
            mutations.createAssets,
            variables
        )
        .catch((err) => {
            console.error("Error while creating assets ", assets, err);
        });

        if (mutation) return mutation.createAssets;
    }

    public async updateAsset(asset: api.UpdateAssetInput): Promise<api.AssetMutationOutput> {

        try {
            const variables: api.UpdateAssetMutationVariables = {data: asset};

            const mutation = await this.apiCall<api.UpdateAssetMutation>(
                mutations.updateAsset,
                variables
            );
            return mutation.updateAsset;
        }
        catch(err) {
            console.error(`Error while updating asset with id {id}`, asset, err);
            throw err;   
        }
    }

    public async updateAssets(assets: api.UpdateAssetInput[]): Promise<api.AssetMutationOutput | undefined> {
        const variables: api.UpdateAssetsMutationVariables = {list: assets};

        const mutation = await this.apiCall<api.UpdateAssetsMutation>(
            mutations.updateAssets,
            variables
        )
        .catch((err) => {
            console.error(`Error while updating assets`, assets, err);
        });

        if (mutation) return mutation.updateAssets;
    }

    public async deleteAsset(id: string): Promise<api.AssetMutationOutput | undefined> {
        const variables: api.DeleteAssetMutationVariables = {id: id};

        const mutation = await this.apiCall<api.DeleteAssetMutation>(
            mutations.deleteAsset,
            variables
        )
        .catch((err) => {
            console.error(`Error while deleting asset with id {id}`, err);
        });

        if (mutation) return mutation.deleteAsset;
    }

    public async deleteAssets(assets: api.UpdateAssetInput[]): Promise<api.AssetMutationOutput | undefined> {
        const variables: api.DeleteAssetsMutationVariables = {list: assets};

        const mutation = await this.apiCall<api.DeleteAssetsMutation>(
            mutations.deleteAssets,
            variables
        )
        .catch((err) => {
            console.error(`Error while deleting assets`, assets, err);
        });

        if (mutation) return mutation.deleteAssets;
    }

    public async getAsset(id: string): Promise<api.AssetQueryOutput> {

        try {
            const variables: api.GetAssetQueryVariables = {id: id};

            const mutation = await this.apiCall<api.GetAssetQuery>(
                queries.getAsset,
                variables
            );
            return mutation.getAsset;
        } 
        catch(err) {
            console.error(`Error while getting asset with id {id}`, err);
            throw err;
        };
    }

    public async getAssets(
        options: api.GetAssetsQueryVariables): Promise<api.AssetQueryOutput[]> {
        
        try {
            const query = await this.apiCall<api.GetAssetsQuery>(
                queries.getAssets,
                options
            );
            return query.getAssets;
        }
        catch (error) {
            console.error("Error while getting assets", error);
            return [];
        }
    }

    public onAssetsUpdate(
        options: api.OnAssetsUpdateSubscriptionVariables,
        callback: (mutation: api.AssetMutationOutput) => void
    ): AssetsSubscription | undefined {

        if (!options.groupId && !options.type) {
            console.error("The group ID or the asset type must be specified");
            return undefined;
        }
        
        const obs = this.apiObserve<api.OnAssetsUpdateSubscription>(
            subscriptions.onAssetsUpdate,
            options
        );
        const sub: AssetsSubscription = obs.subscribe({
            next: event => {
                const mutation: api.AssetMutationOutput = event.onAssetsUpdate!;
                callback(mutation);
            },
            error: (e) => {
                console.error("Subscription to Asset Update error", e);
            }
        });

        return sub;
    }
}

export const assetsService = AssetsService.instance;
