import React, { useContext } from 'react';
import { PlayerContext } from '../../context';
import { EChapterKind } from '../../interfaces';
import ChapterNotes from '../ChapterNotes';

interface Props {
  selectedKpiID?: string;
  selectedScenarioID?: string;
}

const ChapterNotesProvider: React.FC<Props> = ({
  selectedKpiID,
  selectedScenarioID
}) => {
  const { chapter } = useContext(PlayerContext);
  if (!chapter) {
    throw new Error('Not found match');
  }

  const selectedKpi = chapter.kpis.find(kpi => kpi.id === selectedKpiID);
  const selectedScenario = chapter.scenarios.find(
    scenario => scenario.id === selectedScenarioID
  );
  const activeKpis = chapter.kpis.filter(kpi => kpi.active);
  if (
    chapter.id === '4' &&
    selectedKpi &&
    selectedKpi.id === 'net-present-value' &&
    activeKpis.length > 1
  ) {
    selectedKpi.label = 'net-present-value-error';
  }

  const item =
    chapter.kind === EChapterKind.KPIS_MATRIX ? selectedKpi : selectedScenario;
  const model =
    chapter.kind === EChapterKind.KPIS_MATRIX ? 'kpis' : 'scenarios';
  return (
    <div className="ChapterNotesProvider">
      {item && <ChapterNotes item={item} model={model} />}
    </div>
  );
};

export default ChapterNotesProvider;
