/* eslint-disable */

import React, { useContext } from 'react';
import { AppContext, PlayerContext } from '../../context';
import {
  EKpiStatus,
  // IChapter,
  // IScenario,
  IKpi, IScenarioKpi
} from '../../interfaces';
import InputWrapper from '../InputWrapper';
import './style.scss';

import { database } from '../../utilities/database';

interface Props {
  scenarioKpi: IScenarioKpi;
  kpis: IKpi[];
  selected?: boolean;
  onClick?: Function;
}

const Kpi: React.FC<Props> = ({
  scenarioKpi,
  kpis,
  selected = false,
  onClick = () => {}
}) => {
  const { session } = useContext(AppContext);
  const { match, chapter } = useContext(PlayerContext);
  if (!chapter) {
    throw new Error('Not found chapter');
  }
  const { token } = match && match.params;

  const kpi = kpis.find(kpi => kpi.id === scenarioKpi.kpiID);


  let tolerance = 1;

  if (kpi) {
    if(kpi.unit === '%') {
      tolerance = 0.01
    }
  }

  function between(value: number, tolerance: number, correctValue: number) {
    let min = parseFloat((correctValue - tolerance).toFixed(2));
    let max = parseFloat((correctValue + tolerance).toFixed(2));
    return value >= min && value <= max;
  }

  function IncludesWithTolerance(value: number, tolerance: number, correctValues: number[]){

    let isIncluded = false

    correctValues.forEach((correctValue)=>{

      if(between(value,tolerance,correctValue) === true) {
        isIncluded = true
      }

    })

    return isIncluded
  }


  const handleChange = (scenarioKpi: IScenarioKpi, value: any) => {
    const dbPath = `games.${token}.chapters#${scenarioKpi.chapterIndex}`;

    if (scenarioKpi.value !== Number(value)) {


      const correct =
        typeof scenarioKpi.correctValue === 'number'
          ? between(Number(value),tolerance,scenarioKpi.correctValue)
          : scenarioKpi.correctValue &&
            IncludesWithTolerance(Number(value),tolerance,scenarioKpi.correctValue);

     
      const newMatrix = [...chapter.matrix];
      newMatrix[scenarioKpi.rowIndex][scenarioKpi.columnIndex] = {
        ...scenarioKpi,
        value: Number(value),
        status:
          Number(value) === 0
            ? EKpiStatus.EMPTY
            : correct
            ? EKpiStatus.CORRECT
            : EKpiStatus.WRONG,

        retries: scenarioKpi.retries + 1,
        updatedAt: new Date().toISOString()
      };
      database.set(session!.data.sessionDataId!, dbPath, {
        ...chapter,
        matrix: newMatrix
      });
    }
    //.then(() => checkIfScenarioCompleted());
  };


  return (
    <div
      className={`Kpi ${scenarioKpi.status.toLowerCase()} ${
        selected ? 'active' : ''
      }`}
      onClick={() => onClick()}>
      {scenarioKpi.output ? (
        <div className="output">
          {kpi && kpi.unit === '€'
            ? scenarioKpi.value || 0
            : (scenarioKpi.value || 0).toFixed(2)}
          {kpi && kpi.unit === '€' ? 'k' : ''}
          {kpi && kpi.unit === '€' ? '' : ' '}
          {(kpi && kpi.unit) || '€'}
        </div>
      ) : (
        <InputWrapper
          unit={(kpi && kpi.unit) || '€'}
          disabled={!selected}
          defaultValue={scenarioKpi.value || ""}
          type="number"
          onChange={(value: number) => handleChange(scenarioKpi, value)}
        />
      )}
    </div>
  );
};

export default Kpi;
