import React from 'react';
import SquareButton from '../SquareButton';
import './style.scss';

interface Props {
	color: string;
	image: string;
	title: string;
	actions?: any;
}

const SectionTitle: React.FC<Props> = ({ color, image, title, actions }) => {
	return (
		<div className="SectionTitle">
			<SquareButton color={color} image={image} />
			<div
				className="title"
				style={{
					borderBottom: `1px solid ${color}`
				}}>
				{title}
			</div>
			{actions}
		</div>
	);
};

export default SectionTitle;
