import React, { useEffect, useState } from 'react';
import './style.scss';
import Image from '../Image';
import { DocumentData, FilesData, MediaInput, Lang } from './data-templates';
import { AssetType } from '../../api';
import { Urls } from './document-tab';
import { storageS3 } from '../../utilities/s3storage';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';


interface Props {
  asset          : MediaInput | undefined
  filesMap       : Map<string, FilesData | undefined>
  close          : () => void
  updateDocument : (key: string, document: MediaInput) => void
  updateFile     : (key: string, file: FilesData) => void
  intl           : InjectedIntl
}
type TypeMedia = 'word' | 'pdf';

interface FileInfo {
  name    : string,
  size    : number,
  file?   : File,
  fileKey?: string
}

const UploadDocuments: React.FC<Props> = ({
    asset , close , filesMap, updateDocument, updateFile, intl
}) => {
  const [wordFile, setWordFile] = useState<FileInfo | null>(null);
  const [wordEngFile, setWordEngFile] = useState<FileInfo | null>(null);
  const [pdfFile, setPdfFile] = useState<FileInfo | null>(null);
  const [pdfEngFile, setPdfEngFile] = useState<FileInfo | null>(null);
  const [isFormFulfilled, setIsFormFulfilled] = useState<boolean>(false)
  const [italianLabel, setItalianLabel] = useState<string>('')
  const [englishLabel, setEnglishLabel] = useState<string>('')

  const fetchUrls = async (data: DocumentData) : Promise<Urls> => {
    // TODO: this could be improved, maybe using a list get
    return {
      pdfUrl: await storageS3.getFileUrl(data.url),
      pdfEngUrl: await storageS3.getFileUrl(data.urlEng),
      wordUrl: await storageS3.getFileUrl(data.urlWord),
      wordEngUrl: await storageS3.getFileUrl(data.urlEngWord),
    }
  }

  //Check form
  useEffect(()=>{
    (wordFile && wordEngFile && pdfFile && pdfEngFile !==null) && (italianLabel.length > 0 && englishLabel.length > 0) ?
    setIsFormFulfilled(true) : setIsFormFulfilled(false)
  },[
    wordFile, wordEngFile,pdfFile,pdfEngFile,italianLabel, englishLabel
  ])

  //Set file info
  useEffect(() => {
    if (asset) {
      const data = asset.data as DocumentData;
      const localFiles = filesMap.get(data.id);

      fetchUrls(data).then((urls) => { 
  
        const wordInfo = data.urlWord.length > 1 || localFiles?.wordIta ?{ 
          name: localFiles?.wordIta?.name || urls?.wordUrl || data.url,
          size: localFiles?.wordIta?.size || data.itaSizeWord,
          file: localFiles?.wordIta,
          fileKey: localFiles?.wordIta ? undefined : data.urlWord
        } : null

          
        const pdfInfo = data.url.length > 1 ||localFiles?.pdfIta ? { 
          name: localFiles?.pdfIta?.name || urls?.pdfUrl || data.url,
          size: localFiles?.pdfIta?.size || data.itaSizePdf,
          file: localFiles?.pdfIta,
          fileKey: localFiles?.pdfIta ? undefined : data.url
        } : null

          
        const wordEngInfo = data.urlEngWord.length > 1 || localFiles?.wordEng ? { 
          name: localFiles?.wordEng?.name || urls?.wordEngUrl || data.url,
          size: localFiles?.wordEng?.size || data.engSizeWord,
          file: localFiles?.wordEng,
          fileKey: localFiles?.wordEng ? undefined : data.urlEngWord
        } : null

          
        const pdfEngInfo= data.urlEng.length > 1 || localFiles?.pdfEng ? { 
          name: localFiles?.pdfEng?.name || urls?.pdfEngUrl || data.url,
          size: localFiles?.pdfEng?.size || data.engSizePdf,
          file: localFiles?.pdfEng,
          fileKey: localFiles?.pdfEng ? undefined : data.urlEng
        } : null

        setItalianLabel(data.name)
        setEnglishLabel(data.nameEng)
        setPdfFile(pdfInfo);
        setPdfEngFile(pdfEngInfo);
        setWordFile(wordInfo);
        setWordEngFile(wordEngInfo);
      });
    }
  },[asset,filesMap]);
  
  
  const handleItalianLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItalianLabel(e.target.value);
  };

  const handleEnglishLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnglishLabel(e.target.value);
  };

  const setFile = (input: File | null, formatFile: TypeMedia, lang: Lang ) => {
    
    const file = input?{
      name: input.name,
      date: input.lastModified.toString(),
      size: input.size,
      file: input
    } : null;

    const mediaInfo = `${formatFile}-${lang}`

    switch (mediaInfo) {
      case 'word-Ita':
        setWordFile(file);
        break;
      case 'word-Eng':
        setWordEngFile(file);
        break;
      case 'pdf-Ita':
        setPdfFile(file);
        break;
      case 'pdf-Eng':
        setPdfEngFile(file);
        break;
      default:
        break;
    }
  }

  const confirmChanges = async() => {
    if(isFormFulfilled){
     //Update Asset Map 
    const data = asset?.data as DocumentData
    const documentData: DocumentData = 
      {
        id: data.id,
        chapterIndex: data.chapterIndex,
        index: data.index,
        name: italianLabel,
        nameEng: englishLabel,
        url: data.url,
        urlEng: data.urlEng,
        urlWord: data.urlWord,
        urlEngWord: data.urlEngWord,
        itaSizePdf: pdfFile!.size,
        itaSizeWord	 : wordFile!.size,
        engSizeWord	 : wordEngFile!.size,
        engSizePdf   : pdfEngFile!.size,
        previewImage : 'preview'
      }
    updateDocument(data.id, {data: documentData, type: AssetType.document})

    //Update Asset Files
    const updatedFilesMap: FilesData = {
      wordIta : wordFile!.file,
      wordEng : wordEngFile!.file,
      pdfIta  : pdfFile!.file,
      pdfEng  : pdfEngFile!.file   
    }
    updateFile(data.id,updatedFilesMap)
    close();
      }
    }

  return (
    <div className="upload-documents">
      <div className='upload-header'>
        <div className="input-container">
          <input
            className="input"
            placeholder={intl.formatMessage({id: "admin-sessions-chapter-title-it"})}
            value={italianLabel}
            onChange={handleItalianLabel}
          />
          <p className="input-label">
            <FormattedMessage id="admin-sessions-chapter-title-it" />
          </p>
        </div>
        <div className="input-container">
          <input
            className="input"
            placeholder={intl.formatMessage({id: "admin-sessions-chapter-title-en"})}
            value={englishLabel}
            onChange={handleEnglishLabel}
          />
          <p className="input-label">
            <FormattedMessage id="admin-sessions-chapter-title-en" />
          </p>
        </div>
      </div>
  
      <div className='upload-content'>
        <div className='new-set-documents'>
          <FileContainer asset={asset} fileInfo={wordFile}
          label={intl.formatMessage({id: 'admin-sessions-upload-it'}, {type: 'word'})}
          formatFile='word' lang='Ita' setFile={setFile}/>
          <FileContainer asset={asset} fileInfo={wordEngFile}
          label={intl.formatMessage({id: 'admin-sessions-upload-en'}, {type: 'word'})}
          formatFile='word' lang='Eng' setFile={setFile}/>
          <FileContainer asset={asset} fileInfo={pdfFile}
          label={intl.formatMessage({id: 'admin-sessions-upload-it'}, {type: 'pdf'})}
          formatFile='pdf' lang='Ita' setFile={setFile}/>
          <FileContainer asset={asset} fileInfo={pdfEngFile}
          label={intl.formatMessage({id: 'admin-sessions-upload-en'}, {type: 'pdf'})}
          formatFile='pdf' lang='Eng' setFile={setFile}/>
        </div>

      </div>
        
      <div className='footer-upload'>
          <div className='warning' style={{opacity: isFormFulfilled ? 0 : 1}}>
          <Image name="warning" className="warning-button" />
            <p className='text'>
              <FormattedMessage id="admin-sessions-feedback-all-file" />
            </p>
          </div>
          <div className="button-confirm-container">
            <p className="discard-text" onClick={close}>
              <FormattedMessage id="admin-sessions-cancel" />
            </p>
            <button
              disabled={!isFormFulfilled}
              className={`confirm-button ${isFormFulfilled ? '' : 'disabled'}`}
              onClick={confirmChanges}
            >
              <p className="confirm-text">
                <FormattedMessage id="confirm:label" />
              </p>
            </button>
          </div>
        </div>
    </div>
  );  
};

export default injectIntl(UploadDocuments);


interface FileContainerProps{
  asset : MediaInput | undefined
  fileInfo : FileInfo | null, 
  label   : string, 
  formatFile: TypeMedia,
  lang    : Lang 
  setFile : (input: File | null, format: TypeMedia, lang: Lang) => void
}

const FileContainer : React.FC <FileContainerProps> = ({
    asset, fileInfo, label, formatFile, lang, setFile 
}) => {
  const [sizeWarning, setSizeWarning] = useState<boolean>(false)
  const [isLocalFile, setIsLocalFile] = useState<boolean>(false)

  //Check if it's a local file
  useEffect(()=>{
    setIsLocalFile(fileInfo?.fileKey? false : true)
  },[fileInfo])

  const MAX_FILE_SIZE = 3000000; // 2MB   TODO: maybe make this configurable -> if so the label must change

  const formatUrl = (link: string) => {
    if (link.startsWith('https://')) {
      link = link.substring(8);
    } else if (link.startsWith('http://')) {
      link = link.substring(7);
    }  
    return link;
  }

  const formatString = (inputString: string) => {
    const formatInputString = formatUrl(inputString)
    if (formatInputString.length <= 27) {
      return formatInputString;
    } else {
      const firstPart = formatInputString.substring(0, 16);
      const lastPart = formatInputString.substring(formatInputString.length - 8);
      return `${firstPart} ... ${lastPart}`;
    }
  };


  const downloadFile = async (s3url: string) => {
    try {
      const result = await storageS3.getFileUrl(s3url, true)
      const url = URL.createObjectURL(result.Body);
      const a = document.createElement('a');
      a.href = url;
      a.download = lang === 'Ita' ? asset!.data.name : asset!.data.nameEng;

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
      return a;

    } catch (error) {
      console.error('Errore durante il download del file:', error);
    }
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formatFile: TypeMedia,
    language: Lang
  ) => {
    if (!e.target.files) return;
    
    const file = e.target.files[0];

    if(file.size > MAX_FILE_SIZE){
      setSizeWarning(true)
     } else {
      setSizeWarning(false)
      setFile(file, formatFile,language)
    }
  }

  const removeFile = (formatFile: TypeMedia, language: Lang)=>{
    setFile(null, formatFile,language)
  }

  const acceptFileType = formatFile === 'word' ? '.doc, .docx' : '.pdf';

  if (fileInfo) {
    return (
      <div className='file-box'>
        <div className='file'>
          <div className="file-header">
            <p className="file-title">{formatString(fileInfo.name)}</p>
            <p className="file-text">({(fileInfo.size / 1000000).toFixed(3)} MB)</p>
            <p className="file-text">{`File ${formatFile} ${lang==='Ita'? 'ita' : 'eng'}`}</p>
          </div>
          <div className='file-footer'>
            {!isLocalFile ? <Image name="downloadIconGrey" className='icon' onClick={() => downloadFile(fileInfo.fileKey!)} /> : null}
            <Image name="bin" className='icon' onClick={() => removeFile(formatFile, lang)} />
          </div>
        </div>
        <div className='error-container'>
        {/* Error */}
        </div>
      </div>
    );
  } else {
    return (
      <div className='file-box'>
        <label htmlFor={`${formatFile}-${lang}File`} className='file empty'>
          <input id={`${formatFile}-${lang}File`} type="file" style={{ display: 'none' }} accept={acceptFileType} onChange={(event) => handleFileChange(event ,formatFile, lang)} />
          <p className='text-empty'>{label}</p>
          <p className='text-empty'>{`Max ${3} MB`}</p>
        </label>
        <div className='error-container'>
        {sizeWarning && <Image name="warning" className='icon' />}
        {sizeWarning && <p className='error-text'>
          <FormattedMessage id="admin-sessions-feedback-file-size" />
        </p>}      
        </div>       
      </div>
    );
  }
}
