
export interface IUserInfo {
    email:      string;
    id:         string;
    name:       string;
}

export class UserAuth {

    public static getUserData(): IUserInfo {

        return {
            "id"    : (window as any).PBAC_USERNAME     ?? 'na',
            "email" : (window as any).PBAC_EMAIL        ?? 'na',
            "name"  : (window as any).PBAC_DISPLAY_NAME ?? 'NA',
        };
    }
}
