import React, { useEffect, useState } from 'react';
import Image from '../Image';
import './style.scss';
import { MediaInput , DocumentData, Section, Lang, FilesData } from './data-templates';
import { AssetType } from '../../api';
import { storageS3 } from '../../utilities/s3storage';
import ImageTooltip from '../Tooltip/hover-tooltip';
import { FormattedMessage } from 'react-intl';

type Document = { titleIta: string; titleEng: string, sizeItaFile: number; sizeEngFile: number, format: 'pdf' | 'word',  isLocalFileIta:boolean , isLocalFileEng: boolean };
type DocumentTab = { word: Document; pdf: Document };

interface Props {
  lang          : Lang;
  label         : string;
  labelEng      : string;
  assetKey      : string;
  assets        : Map<string, MediaInput | undefined>
  localFilesMap : Map<string, FilesData>
  onViewAsset   : (asset: MediaInput) => void
  setSection    : (section: Section ) => void
  index         : number;
  chapterIndex  : number;
  isEditable    : boolean;
}

export interface Urls {
  pdfUrl: string | undefined,
  pdfEngUrl: string | undefined,
  wordUrl: string | undefined,
  wordEngUrl: string | undefined,
}

const DocumentsTab: React.FC<Props> = ({ lang, assetKey, label, labelEng, assets, index, chapterIndex, localFilesMap, onViewAsset, setSection, isEditable}) => {
  const [title, setTitle] = useState<string>('');
  const [documents, setDocuments] = useState<DocumentTab | null>(null);

  //Set Docus
  useEffect(() => {
    const asst = assets.get(assetKey);
    const localFiles = localFilesMap.get(assetKey);
  
    if (asst) {
      const data = asst.data as DocumentData;

      const word: Document = {
        titleIta: localFiles?.wordIta?.name || data.name,
        titleEng: localFiles?.wordEng?.name || data.nameEng,
        sizeItaFile: localFiles?.wordIta?.size || data.itaSizeWord,
        sizeEngFile: localFiles?.wordEng?.size || data.engSizeWord,
        isLocalFileIta: localFiles?.wordIta? true : false,
        isLocalFileEng: localFiles?.wordEng? true : false,
        format: 'word',
      };
      const pdf: Document = {
        titleIta: localFiles?.pdfIta?.name || data.name,
        titleEng: localFiles?.pdfEng?.name || data.nameEng,
        sizeItaFile: localFiles?.pdfIta?.size || data.itaSizePdf,
        sizeEngFile: localFiles?.pdfEng?.size || data.engSizePdf,
        isLocalFileIta: localFiles?.pdfIta? true : false,
        isLocalFileEng: localFiles?.pdfEng? true : false,
        format: 'pdf',
      };
  
      const files = {
        word: word,
        pdf: pdf,
      };
  
      const newTitle = lang === 'Eng' ? data.nameEng : data.name;
      setTitle(newTitle);
      setDocuments(files);
    } else {
      setDocuments(null);
      setTitle(lang === 'Ita' ? label : labelEng);
    }
  }, [lang, label, labelEng, assetKey, assets, localFilesMap]);
  
  const editDocument = () => {
    if(documents){
    const asst = assets.get(assetKey)
    onViewAsset(asst!)
    setSection('UploadDocuments')
    }
  }

  const createNewDocumentsSet = () =>{
    const newDocument: DocumentData = {
      id: assetKey,
      chapterIndex: chapterIndex,
      index: index,
      name: label,
      nameEng: labelEng,
      url: '',
      urlWord: '',
      urlEng: '',
      urlEngWord: '',
      itaSizePdf: 0,
      engSizePdf: 0,
      itaSizeWord: 0,
      engSizeWord: 0,
    };
    onViewAsset({data: newDocument, type: AssetType.document})
    setSection('UploadDocuments')
    }

    const downloadFile = async (format: string, lang: Lang) => {
      
      const data = assets.get(assetKey)?.data as DocumentData;
      const getUrl = (): string => {
        if (format === "word") {
          return lang === 'Eng' ? data.urlEngWord : data.urlWord;
        } else {
          return lang === 'Eng' ? data.urlEng : data.url;
        }
      }
      const correctUrl = getUrl()
      try {
        const result = await storageS3.getFileUrl(correctUrl, true)
        const url = URL.createObjectURL(result.Body);
        const a = document.createElement('a');
        a.href = url;
        a.download =  lang === 'Eng' ? 
            documents!.word.titleEng : documents!.word.titleIta;

        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
  
      } catch (error) {
        console.error('Errore durante il download del file:', error);
      }
    };


    const checkPdfIsLocalFile = lang === 'Ita' ?  documents?.pdf.isLocalFileIta : documents?.pdf.isLocalFileEng;
    const checkWordIsLocalFile = lang === 'Ita' ?  documents?.word.isLocalFileIta : documents?.word.isLocalFileEng;

  

  return (
    <div className="media">
      <div className='header-documents'>
        <div className='chapter-title-box'>
          <Image name='docGray' />
          <h2 className='chapter-title'>{title}</h2>
        </div>
        <div className='icon-container'>
          {(documents && isEditable) && (
            <Image name="penGray" className="edit-button" onClick={editDocument} />
          )}
           {!documents && 
           <ImageTooltip text={'Documenti mancanti'} position={'left'}>
            <Image name="warning" className="warning-button"/>
           </ImageTooltip>}
        </div>
      </div>
      {documents ? (
        <div className="element-container">
          <div className='documents-container'>
            {/* word */}
            <div className='document-box'>
              <div className='text-container'>
                <div className='title-docu'> 
                 <p className={checkWordIsLocalFile ? '': 'download-link' } onClick={() => checkWordIsLocalFile ? null : downloadFile('word', lang)}>
                    {lang === 'Eng' ? documents!.word.titleEng : documents!.word.titleIta}
                  </p>
                 </div>
                <div className='info-docu'> 
                  WORD - 
                  ({((lang === 'Eng' ? documents.word.sizeEngFile : documents.word.sizeItaFile) / 1000000).toFixed(3)} MB)
                </div>
              </div>
            </div>

            {/* pdf */}
            <div className='document-box'>
              <div className='text-container'>
                <div className='title-docu'>
                  <p className={checkPdfIsLocalFile ? '': 'download-link' } onClick={() => checkPdfIsLocalFile ? null : downloadFile('pdf', lang)}>
                      {lang === 'Eng' ? documents!.pdf.titleEng : documents!.pdf.titleIta}
                    </p>
                </div>
                <div className='info-docu'> 
                  PDF - 
                  ({((lang === 'Eng' ? documents.pdf.sizeEngFile : documents.pdf.sizeItaFile) / 1000000).toFixed(3)} MB)
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='element-container' onClick={createNewDocumentsSet}>
          <div className='media-box'>
            <div className='upload-text'>
              <FormattedMessage id="admin-sessions-upload" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsTab;
