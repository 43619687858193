import React from 'react';
import './style.scss';

interface Props {
  children?: any;
}

const Card: React.FC<Props> = ({ children }) => (
  <div className='Card'>
    {children}
  </div>
)

export default Card;