import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import route from '../../route';
import Image from '../Image';
import WhiteButton from '../WhiteButton';
import './style.scss';


interface Props {
	history: any,
	intl: any,
	sessionId?: string
}


const TokenInput: React.FC<Props> = (props) => {
	// const [insertTokenInputActive, setInputActive] = useState<boolean>(false);
	const inputEl = useRef<HTMLInputElement>(null);

	const { intl, sessionId } = props;

	const [ teamName, setTeamName ] = useState<string>();

	const goToGame = () => {
		if (sessionId !== undefined && inputEl.current?.value) {
			const token = inputEl.current.value;
			const routeFormed = route('onboarding', { 
				gameSessionId: sessionId, 
				token 
			})
			props.history.push(routeFormed)
		}
	}

	return (
		<div className="TokenInput">
			<WhiteButton
				onClick={() => null}
				className={'login'}
			>
				<input placeholder={intl.formatMessage({ id: 'login:label' })} 
					   ref={inputEl} 
					   onChange={e => setTeamName(inputEl.current?.value)}
					   onKeyDown={e => {
					if (inputEl.current && e.keyCode === 13 && inputEl.current.value) {
						goToGame();
					}
				}}></input>
				<div className={"downArrowWhite " + 
						((!sessionId || !teamName) ? 'disabled' : '')
					 } 
					 onClick={() => goToGame()}>
					<Image name='leftArrow' className="iconArrow" />
				</div>
			</WhiteButton>
			{
				(window.location.search==='?err=1')?(
					<p className='error-message'>
					  <b>{intl.formatMessage({id : 'login-invalid-token:label'})}</b><br/>
					     {intl.formatMessage({id : 'login-invalid-token-message:label'})}
				  </p>
				):null
			}
		</div>
	);
};

export default injectIntl(TokenInput);
