import React from 'react';
import { injectIntl } from 'react-intl';
import './style.scss';

interface Props {
	intl: any;
	onClick: any;
	className?: string;
	children: any;
}

const WhiteButton: React.FC<Props> = ({ intl, onClick, className, children }) => {



	return (
		<div className='WhiteButton'>
			<div
				className={`button-empty-white ${className}`}
				onClick={() => onClick()}>
					{children}
			</div>
		</div>
	);
};

export default injectIntl(WhiteButton);
