import './style.scss'
import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

interface Props {
    open: boolean,
    title: string,
    content?: string,
    onConfirm: () => void,
    onCancel: () => void,
    danger?: boolean
}

const ConfirmModal: React.FC<Props> = (props) => {
    return (
        <Modal
            open={props.open}
            centered={true}
            size="small"
        >
            <Modal.Header>
                {props.title}
            </Modal.Header>
            <Modal.Content>
                <p>{props.content}</p>
                <div className="confirm-modal-actions">
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button className="cancel-action" onClick={props.onCancel} color={ props.danger ? 'blue' : 'red'} >
                    <FormattedMessage id="cancel:label" />
                </Button>
                <Button className="confirm-action" onClick={props.onConfirm} color={ props.danger ? 'red' : 'blue'}>
                    <FormattedMessage id="confirm:label" />
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ConfirmModal;
