import React from 'react';
import { injectIntl } from 'react-intl';
import './style.scss';
import ReactPlayer from 'react-player';
import SectionTitle from '../SectionTitle';

interface Props {
  url: string;
  title: string;
  width?: string | number;
  height?: string | number;
  intl: any;
}

const VideoPlayer: React.FC<Props> = ({ url, title, width, height, intl }) => {
  return (
    <div className="VideoPlayer">
      <SectionTitle
        color={'#CF0000'}
        image={'play'}
        title={intl.formatMessage({ id: `${title}:label` })}
      />
      <ReactPlayer
        className="content"
        url={url}
        width={width}
        height={height}
      />
    </div>
  );
};

export default injectIntl(VideoPlayer);
