import React, { useEffect, useState } from 'react';
import './style.scss';
import Image from '../../Image';
import Modal from '../../SessionsModal';
import ModalParameters from '../Modal/modal-parameters.tsx';
import { AssetType } from '../../../api';
import { AssetsSubscription, assetsService } from '../../../utilities/assetsService';
import { IUserInfo } from '../../../utilities/auth-services';
import ResetButton from '../../ResetButton';
import { ParamWithOwner } from '../../../interfaces';
import {injectIntl, InjectedIntl, FormattedMessage, FormattedHTMLMessage, FormattedDate} from 'react-intl';

interface Props {
  sessionParamId?: string
  selectParamCb?: (param: ParamWithOwner) => void
  selectable?: boolean
  intl: InjectedIntl
}

const History: React.FC<Props> = ({ 
      selectable, sessionParamId, selectParamCb, intl
}) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [showGlobal, setShowGlobal] = useState<boolean>(true);
  const [selectedGroup, setSelectedGroup] = useState<string>("");

  const [selectedParam, setSelectedParam] = 
      useState<string | undefined>(sessionParamId);

  const [searchLabel, setSearchLabel] = useState<string>("");
  const [searchMinDate, setSearchMinDate] = useState<Date>(new Date('1970'));
  const [searchMaxDate, setSearchMaxDate] = useState<Date>(new Date('5000'));

  const [parameters, setParameters] = useState <ParamWithOwner[]> ([]);

  useEffect(() => {
    let subscription: AssetsSubscription | undefined ;
    
    const fetchParameters = async () => {
      const fetchedParameters = await assetsService.getAssets({
        type: AssetType.parameter
      });
      setParameters(fetchedParameters.map((param) => {
        return {
            ...param,
            owner: !param.owner ? undefined : JSON.parse(param.owner) as IUserInfo
        }
      }));
    }

    fetchParameters().then(() => {
      subscription = assetsService.onAssetsUpdate(
        { type: AssetType.parameter },
        () => fetchParameters()
      )
    });
    return () => subscription?.unsubscribe()
  }, [])

  const handleLabelSearch = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setSearchLabel(event.currentTarget.value);
  }

  const handleMinDateSearch = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setSearchMinDate(new Date(event.currentTarget.value || '1970'));
  }

  const handleMaxDateSearch = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setSearchMaxDate(new Date(event.currentTarget.value || '5000'));
  }

  const resetFilters = () => {
    setSearchLabel('');
    setSearchMinDate(new Date('1970'));
    setSearchMaxDate(new Date('5000'));
    const fields = Array.from(document.getElementsByClassName('input date'));
    fields.forEach((field) => (field as HTMLInputElement).value = ''); 
  }

  const filteredParameters = (): ParamWithOwner[] => {
    return parameters
      .filter(asset => {
        if (asset.global !== showGlobal
            || !(asset.name?.toLowerCase().includes(searchLabel.toLowerCase())
                || (`${asset.owner?.name}`)
                    .toLowerCase().includes(searchLabel.toLowerCase()))
            ) return false; 
        if (!asset.date) return false;
        const date = new Date(asset.date).setHours(0,0,0,0);
        return date >= searchMinDate.setHours(0,0,0,0) && date <= searchMaxDate.setHours(0,0,0,0);
      })
      .sort((asset1, asset2) => {
          return new Date(asset1.date!) > new Date(asset2.date!) ? -1 : 1
      })
  }

  const close = () => {
    setShowEditModal(false);
    setShowSaveModal(false);
  };

  return (
    <div className="history-container">
      <div className="param-selector">
        <p
          className={`selector ${
            showGlobal ? 'isClicked' : ''
          }`}
          onClick={() => {
            setShowGlobal(true);
          }}>
            <FormattedMessage id="admin-sessions-global" />
        </p>
        <p
          className={`selector ${
            !showGlobal ? 'isClicked' : ''
          }`}
          onClick={() => {
            setShowGlobal(false);
          }}>
            <FormattedMessage id="admin-sessions-personal" />
        </p>
      </div>
      <p className="explanation-text">
        { showGlobal
            ? <FormattedHTMLMessage id="admin-sessions-info-global" />
            : <FormattedHTMLMessage id="admin-sessions-info-personal" />
        }
      </p>
      <div className="filters">
        <div className="input-box item-one">
          <input className="input" placeholder={intl.formatMessage({id: "admin-sessions-label-search"})} onChange={handleLabelSearch} value={searchLabel} />
          <Image name="search" className="search-icon" />
        </div>
        <p className="input-label item-two">
          <FormattedMessage id="admin-sessions-from" />
        </p>
        <div className="input-box item-three">
          <Image name="search" className="calendar-icon" />
          <input id="min-date" className="input date" type="date" defaultValue="" onChange={handleMinDateSearch} />
        </div>
        <p className="input-label item-four">
          <FormattedMessage id="admin-sessions-to" />
        </p>
        <div className="input-box item-five">
          <Image name="search" className="calendar-icon" />
          <input id="max-date" className="input date" type="date" defaultValue="" onChange={handleMaxDateSearch} />
        </div>
        <ResetButton onClick={resetFilters}>Reset</ResetButton>
      </div>
      {(filteredParameters().length !== 0)
        ? <div className="history-list">
          {filteredParameters().map((params, index) => (
            <div key={index}
              className={"box " + (selectedParam === params.id ? 'selected' : '') }
              onClick={() => {
                if (!selectable) {
                  setSelectedGroup(params.groupId);
                  setShowEditModal(true);
                }
                else {
                  setSelectedParam(params.id)
                  selectParamCb!(params);
                } 
              }}>
              { params.default ? 
                  <Image className='default-icon' name='flame' /> : 
                  <div></div> 
              }
              <div className="text-box">
                <p className="text">
                  <FormattedMessage id="admin-sessions-name" />
                </p>
                <p className="data-text">{params.name}</p>
              </div>
              { params.owner ?
                  <div className="text-box">
                    <p className="text">
                      <FormattedMessage id="admin-sessions-owner" />
                    </p>
                    <p className="data-text">{`${params.owner?.name}`}</p>
                  </div>
              : <div></div>}
              <div className="text-box">
                <p className="text">
                  <FormattedMessage id="admin-sessions-date" />
                </p>
                <div className="date-box">
                  <p className="data-text">
                    <FormattedDate value={params.date ?? ""} />
                  </p>
                </div>
              </div>
              { selectable ? 
                  <button onClick={(evt)=>{
                        evt.stopPropagation();
                        setSelectedGroup(params.groupId);
                        setShowEditModal(true);
                      }}
                      className="show-param-detail">
                    <Image className='default-icon' name='eye' />               
                  </button> : 
                  <div></div> 
              }
            </div>
          ))}
        </div>
        : <div><p className='info-text'>
            { searchMinDate < searchMaxDate
            ? <FormattedMessage id="admin-sessions-feedback-no-params" />
            : <FormattedMessage id="admin-sessions-feedback-impossible-range" />
            }
        </p></div>
      }

      {showEditModal && <ModalParameters close={close} groupId={selectedGroup} isNewSet={false} />}

      {showSaveModal && (
        <Modal
          onClose={close}
          title={intl.formatMessage({id: "admin-sesssions-save-params"})}
          subtitle={intl.formatMessage({id: "admin-sessions-save-subtitle"})}>
          <div className="save-modal">
            <input className="input" placeholder={intl.formatMessage({id: "admin-sessions-name"})} />

            <div className="buttons-save-mode">
              <p
                className="discard-text"
                onClick={() => {
                  close();
                }}>
                <FormattedMessage id="admin-sessions-cancel" />
              </p>
              <div className="confirm-button">
                <Image name="confirm" />
                <p className="confirm-text">
                <FormattedMessage id="admin-sessions-confirm-edit" />
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default injectIntl(History);
