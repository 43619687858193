/* eslint-disable */

import React, { useContext } from 'react';
import { PlayerContext } from '../../context';
import { EChapterKind } from '../../interfaces';
import Matrix from '../Matrix';
import './style.scss';

interface Props {
  selectedKpiID?: string;
  selectedScenarioID?: string;
  setSelectedKpiID: Function;
  setSelectedScenarioID: Function;
}

const MatrixProvider: React.FC<Props> = ({
  selectedKpiID,
  selectedScenarioID,
  setSelectedKpiID,
  setSelectedScenarioID
}) => {
  const { game, chapter } = useContext(PlayerContext);
  if (!game) {
    throw new Error('Not found match');
  }
  if (!chapter) {
    throw new Error('Not found match');
  }
  const renderScenarioMatrix = () => {
    const activeScenarios = chapter.scenarios.filter(
      scenario => scenario.active
    );
    const activeScenariosIndexes = activeScenarios.map(
      scenario => scenario.index
    );
    const activeMatrix = chapter.matrix.map(row =>
      row.filter((column, index) => activeScenariosIndexes.includes(index))
    );
    const activeKpis = chapter.kpis.filter((kpi, index) =>
      activeMatrix[index].some(scenarioKpi => scenarioKpi != null)
    )
    const activeKpisIndexes = activeKpis.map(
      kpi => kpi.index
    );
    const newActiveMatrix = activeMatrix.filter((row, index) =>
      activeKpisIndexes.includes(index)
    );
    return (
      <Matrix
        matrix={newActiveMatrix}
        selectedScenarioID={selectedScenarioID}
        setSelectedScenarioID={setSelectedScenarioID}
        kpis={activeKpis}
        scenarios={activeScenarios}
      />
    );
  };
  const renderKpiMatrix = () => {
    let activeKpis;
    let activeMatrix;
    switch (chapter.id) {
      case '4':
        const chapter3 = game.chapters.find(chapter => chapter.id === '3');
        const chapter3Matrix =
          chapter3 &&
          chapter3.matrix.map(row =>
            row.map(
              scenarioKpi =>
                scenarioKpi && {
                  ...scenarioKpi,
                  output: true
                }
            )
          );
        let chapter4Kpis = chapter.kpis.filter(kpi => kpi.active);
        if (chapter4Kpis.length === 2) {
          chapter4Kpis = chapter4Kpis.map((kpi, index) => {
            return index === 0
              ? { ...kpi, label: 'net-present-value-error' }
              : kpi;
          });
        }
        const chapter4KpisIndexes = chapter4Kpis.map(kpi => kpi.index);
        const chapter4Matrix = chapter.matrix.filter((row, index) =>
          chapter4KpisIndexes.includes(index)
        );
        activeKpis = chapter3
          ? [...chapter3.kpis, ...chapter4Kpis]
          : chapter4Kpis;
        activeMatrix = chapter3Matrix
          ? [...chapter3Matrix, ...chapter4Matrix]
          : chapter4Matrix;
        break;
      default:
        activeKpis = chapter.kpis.filter(kpi => kpi.active);
        const activeKpisIndexes = activeKpis.map(kpi => kpi.index);
        activeMatrix = chapter.matrix.filter((row, index) =>
          activeKpisIndexes.includes(index)
        );
    }
    return (
      <Matrix
        matrix={activeMatrix}
        selectedKpiID={selectedKpiID}
        setSelectedKpiID={setSelectedKpiID}
        kpis={activeKpis}
        scenarios={chapter.scenarios}
      />
    );
  };
  return (
    <>
      {chapter.kind === EChapterKind.SCENARIOS_MATRIX
        ? renderScenarioMatrix()
        : renderKpiMatrix()}
    </>
  );
};

export default MatrixProvider;
