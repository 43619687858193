
const assets = [
    {
      active: false,
      id: 'doc-contesto',
      url: '',
      urlEng: '',
      kind: 'document',
      previewImage: ''
    }
  ]

export default {
	assets
};