import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AppContext, PlayerContext } from '../../context';
import { IAsset } from '../../interfaces';
import DocumentPreview from '../DocumentPreview';
import VideoPlayer from '../VideoPlayer';
import './style.scss';

interface Props {
  intl: any;
}

const ChapterDocuments: React.FC<Props> = ({ intl }) => {
  const { activeAssets } = useContext(PlayerContext);
  const { lang } = useContext(AppContext);
  

  if (!activeAssets) {
    throw new Error('Not found activeAssets');
  }
  if (activeAssets) {
    return (
      <div
        className="ChapterDocuments"
        // style={{
        //   gridTemplateColumns: `${activeAssets
        //     .map(el => (el.kind === 'video' ? '66%' : '33%'))
        //     .join(' ')}`
        // }}
        >
        {activeAssets.map((asset: IAsset) => {


          let updatedUrl = (lang === 'it')?asset.url:asset.urlEng


          return (
            asset.kind === 'video' ? (
              <VideoPlayer
                key={asset.id}
                url={updatedUrl}
                title={asset.id}
                width={'100%'}
              />
            ) : (
              <DocumentPreview
                key={asset.id}
                title={asset.id}
                url={updatedUrl}
                image={asset.previewImage || ''}
              />
            )
          )
        }
        )}
      </div>
    );
  } else {
    return (
      <h2>{intl.formatMessage({ id: 'waitingAssetsActivation:label' })}</h2>
    );
  }
};

export default injectIntl(ChapterDocuments);
