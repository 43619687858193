import React, { useContext } from 'react';
import './style.scss';
import Header from '../Header';
import Footer from '../Footer';
import ChapterSectionProvider from '../ChapterSectionProvider';
import { AppContext, PlayerContext } from '../../context';
import route, { Switch, Route, Redirect } from '../../route';
import { injectIntl } from 'react-intl';

interface Props {
  intl : any
}

const Player: React.FC<Props> = ({ intl }) => {
  const { session } = useContext(AppContext);
  const { game, match, activeChapters } = useContext(PlayerContext);
  if (!match) {
    throw new Error('Not found match');
  }
  if (!game) {
    throw new Error('Not found game');
  }
  if (!activeChapters) {
    throw new Error('Not found activeChapters');
  }
  const { token } = match.params;
  
  return (
    <div className="Player">
      <Header />
      {activeChapters && activeChapters.length > 0 ? (
        <Switch>
          <Route path={route('chapter')} 
            render={(props) => <ChapterSectionProvider {...props} session={session!} />}
          />
          <Redirect
            to={route('chapter', {
              gameSessionId: session!.id,
              token,
              chapterID: activeChapters[0].id
            })}
          />
        </Switch>
      ) : (
        <h1>{intl.formatMessage({ id : 'notActiveChapters:label' })}</h1>
      )}
      <Footer />
    </div>
  );
};

export default injectIntl(Player);
