import flatten from 'lodash/flatten';
import React, { useContext } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { PlayerContext } from '../../context';
import { EChapterKind, EKpiStatus } from '../../interfaces';
import route, { Route } from '../../route';
import ActionButton from '../ActionButton';
import Image from '../Image';
import SectionTitle from '../SectionTitle';
import Tooltip from '../Tooltip';

interface Props {
  intl: any;
}

const MatrixTitle: React.FC<Props> = ({ intl }) => {
  const { match, chapter } = useContext(PlayerContext);
  if (!match) {
    throw new Error('Not found match');
  }
  if (!chapter) {
    throw new Error('Not found chapter');
  }
  const { token } = match.params;
  let activeMatrix;
  if (chapter.kind === EChapterKind.KPIS_MATRIX) {
    const activeKpis = chapter.kpis.filter(kpi => kpi.active);
    const activeKpisIndexes = activeKpis.map(kpi => kpi.index);
    activeMatrix = chapter.matrix.filter((row, index) =>
      activeKpisIndexes.includes(index)
    );
  } else {
    const activeScenarios = chapter.scenarios.filter(
      scenario => scenario.active
    );
    const activeScenariosIndexes = activeScenarios.map(
      scenario => scenario.index
    );
    activeMatrix = chapter.matrix.map(row =>
      row.filter((column, index) => activeScenariosIndexes.includes(index))
    );
  }
  const flatMatrix = flatten(activeMatrix);
  const total = flatMatrix.filter(el => el!!).length;
  const correct = flatMatrix.filter(
    kpi => kpi && kpi.status === EKpiStatus.CORRECT
  ).length;

  return (
    <div>
      <SectionTitle
        image={'kpis'}
        color={'#6C39D8'}
        title={intl.formatMessage({ id: 'kpi-extended-title:label' })}
        actions={
          <ActionButton
            text={`${correct}/${total}`}
            image={'kpisCheck'}
            color={'#6C39D8'}
          />
        }
      />
      <Route
        path={route('chapter', { token, chapterID: 1 })}
        render={() => (
          <Tooltip
            kind="top-right"
            style={{ top: '-5rem', left: '17rem', minWidth: '48rem' }}>
            <div
              className="ChaptersMenu-tooltip-avatar"
              style={{ width: '48rem' }}>
              <Image name="sparkAvatar" />
              <div className="text">
                <FormattedHTMLMessage id="eurofireTooltipFirstTask:label" />
              </div>
            </div>
          </Tooltip>
        )}
      />
    </div>
  );
};

export default injectIntl(MatrixTitle);
