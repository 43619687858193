import React, { useContext } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { PlayerContext } from '../../context';
import './style.scss';

interface Props {
  intl: InjectedIntl;
  teamName?: string;
}

const WelcomeMessageWithLogout: React.FC<Props> = ({ intl, teamName }) => {
  const { game } = useContext(PlayerContext);

  return (
    <div className='WelcomeMessageWithLogout'>
      <div className="info">
        <span className="welcome">
          {intl.formatMessage({ id: 'welcome-team:label' })}
        </span>
        {` `}
        <span className="team">
          <b>
            {game && game.team ? game.team : teamName ? teamName : ''}
          </b>
        </span>
      </div>
      <a
        href={`${window.BASEPATH}/logout`}
        className="button-empty-blu logout">
        {intl.formatMessage({ id: 'logout:label' })}
      </a>
    </div>
  );
};

export default injectIntl(WelcomeMessageWithLogout);
