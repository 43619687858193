import React, { useEffect, useState } from 'react';
import Modal from '../../SessionsModal';
import List from '../ListParam/list';
import './style.scss';
import Image from '../../Image';
import MediaSection from '../../Media';
import { ParameterInput, generateParametersTemplate, importParametersTemplate } from '../../../utilities/parametersTemplate';
import { AssetType } from '../../../api';
import { AssetsSubscription, assetsService } from '../../../utilities/assetsService';
import ConfirmationModal from '../ConfirmationModal/confirmation-modal';
import { DocumentData, DocumentsMap, MediaInput, keysMedia } from '../../Media/data-templates';
import { storageS3 } from '../../../utilities/s3storage';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Selector from '../../Selector';
import { Accordion, Icon } from 'semantic-ui-react';
import LoadingModal from '../../LoadingModal';
import { injectIntl, InjectedIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

interface Props {
  close: () => void;
  isNewSet?: boolean;
  groupId?: string;
  intl: InjectedIntl;
}
interface ParametersProps extends Props {
  saveOrClone: () => void;
  remove: () => void;
  reset: () => void;
  clone: () => void;
  update: (data: {global?: boolean, default?: boolean}) => void;

  isUndeletable: boolean;
  originalAssetWasDeleted: boolean;

  parametersInput: ParameterInput;
  mediaInputList: MediaInput[];
  documentsMap: DocumentsMap;

  setParametersInput: (parametersInput: ParameterInput) => void;
  setMediaInputList: (mediaInputλList: MediaInput[]) => void;
  setDocumentsMap: (documentMap: DocumentsMap) => void;
}

interface SaveRemoveModalProps {
  close: () => void;
  goBack: () => void;
  groupId: string;
}

type ModalType = 'ParametersModal' | 'ConfirmCreateModal' | 'ConfirmUpdateModal' | 'ConfirmRemoveModal';
type Section = 'Parameters' | 'Media';

const ModalParameters: React.FC<Props> = ({ close, isNewSet, groupId, intl }) => {
  const [modal, setModal] = useState<ModalType>('ParametersModal');

  const [isNew, setIsNew] = useState<boolean>(isNewSet ?? true);
  const [isUndeletable, setIsUndeletable] = useState(true);
  const [originalAssetWasDeleted, setOriginalAssetWasDeleted] = useState(false);

  // Used when cloning, we need to generate the groupId here to
  // copy files on s3
  const [newGroupId, setNewGroupId] = useState<string>();

  const [parametersInput, setParametersInput] = useState<ParameterInput>({
    type: AssetType.parameter,
    data: generateParametersTemplate()
  });

  const [mediaInputList, setMediaInputList] = useState<MediaInput[]>([]);
  const [documentsMap, setDocumentsMap] = useState<DocumentsMap>(new Map());

  // Loading popup
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0.0);

  // Used when updating global/default
  const paramsId = React.useRef<string>();

  // Caching
  const originalParametersData = React.useRef(_.cloneDeep(parametersInput.data));
  const originalMediaInputList = React.useRef(_.cloneDeep(mediaInputList));
  const originalDocumentsMap = React.useRef(_.cloneDeep(documentsMap));

  const subscription: React.MutableRefObject<AssetsSubscription | undefined> = React.useRef();

  const goBack = () => setModal('ParametersModal');

  const closeModal = (keepData?: boolean) => {
    if (!keepData) {
      if (subscription.current) subscription.current.unsubscribe();
      if (newGroupId !== undefined) storageS3.deleteItemsFromFolder(`media/${newGroupId}`)
    }
    close();
  }

  const saveOrClone = async (): Promise<void> => {
    if (isNew) { subscription.current?.unsubscribe() }
    if (!isNew) {
        setIsLoading(true);
        setLoadingProgress(0.0);
        await clone(progress => { setLoadingProgress(progress) });
        setIsLoading(false)
    }
    else setModal('ConfirmCreateModal');
  };

  const remove = (): void => {
    if (isUndeletable) {
      console.error('Cannot delete a set used in a session');
      return;
    }
    setModal('ConfirmRemoveModal');
  }

  // This is only used to change default/global fields
  const update = async (data: {global?: boolean, default?: boolean}) => {
    
    if (isNew || groupId === undefined || paramsId.current === undefined) return;
    
    if (data.global !== undefined) parametersInput.global = data.global;
    if (data.default !== undefined) parametersInput.default = data.default;

    
    // if we save as default we need to check for uniqueness
    // Note: this section is exactly the same in the confirmation modal
    // except for the fact that here we also filter out this set
    if (parametersInput.default === true) {
      const others = await assetsService.getAssets({type: AssetType.parameter})

      const otherDefaults = others.filter(asset =>
          asset.default
          && asset.global === parametersInput.global
          && asset.id !== paramsId.current
      );
      
      if (otherDefaults.length !== 0) {
        // Tecnically this array should have lenght of 1
        if (otherDefaults.length !== 1) {
          console.warn("Mutiple defaults found, will update them all, but this shouldn't happen");
        }
  
        const promises: Promise<any>[] = otherDefaults.map(async defaultAsset => {
          const defAsset = await assetsService.getAsset(defaultAsset.id);
          if (!defAsset) {
            console.error("Update failed, could not find default asset")
            return;
          }
          assetsService.updateAsset(
            {
              id: defAsset.id,
              groupId: defAsset.groupId,
              type: defAsset.type,
              name: defAsset.name,
              date: defAsset.date,
              data: defAsset.data!,
              default: false,
              global: defAsset.global
            }
          );
        });
        await Promise.all(promises);
      }
    }

    await assetsService.updateAsset({
      id: paramsId.current!,
      groupId: groupId,
      type: AssetType.parameter,
      default: parametersInput.default,
      global: parametersInput.global,
    });
  }

  const clone = async (onProgress?: (progress: number) => void): Promise<void> => {
    const cloneGroupId = uuidv4();
    setNewGroupId(cloneGroupId);
    if (parametersInput.name) parametersInput.name = `Clone di ${parametersInput.name}`;

    // Copy the folder
    if (cloneGroupId === undefined || groupId === undefined) {
        return;
    } else {
      const cloneFileMap: Map<string, string> = new Map();
      mediaInputList.forEach((media) => {
        if (media.type !== AssetType.document) {
            return;
        }
        const data = media.data as DocumentData;
        cloneFileMap.set(data.url, data.url.replace(groupId, cloneGroupId));
        cloneFileMap.set(data.urlEng, data.urlEng.replace(groupId, cloneGroupId));
        cloneFileMap.set(data.urlWord, data.urlWord.replace(groupId, cloneGroupId));
        cloneFileMap.set(data.urlEngWord, data.urlEngWord.replace(groupId, cloneGroupId));
      });
      try {
        await storageS3.copyFileList(
          cloneFileMap,
          (progress, total) => { onProgress && onProgress(progress / total) }
        );
      } catch (err) {
        console.error('Clone operation failed.');
        return;
      }
      // Apply the map if the operation is successful
      mediaInputList
      .filter(media => media.type === AssetType.document)
      .forEach(media => {
        const data = media.data as DocumentData;
        data.url = cloneFileMap.get(data.url) ?? "";
        data.urlEng = cloneFileMap.get(data.urlEng) ?? "";
        data.urlWord = cloneFileMap.get(data.urlWord) ?? "";
        data.urlEngWord = cloneFileMap.get(data.urlEngWord) ?? "";
        media.data = data;
      })
    }

    setIsNew(true);

    // Cache original for resets 
    originalParametersData.current = _.cloneDeep(parametersInput.data)
    originalMediaInputList.current = _.cloneDeep(mediaInputList);
    originalDocumentsMap.current = _.cloneDeep(documentsMap);
  }

  const reset = () => {
    setParametersInput({...parametersInput, data: originalParametersData.current});
    setMediaInputList(_.cloneDeep(originalMediaInputList.current));
    setDocumentsMap(_.cloneDeep(originalDocumentsMap.current));
  }

  // Effect to fetch assets data from db
  useEffect(() => {
    // Fetch assets from db and set intermediate structures
    const fetchAssets = async () => {
        const assets = await assetsService.getAssets({groupID: groupId, includeData: true});
        const params = assets?.find(asset => asset.type === AssetType.parameter);
        const mediaList  = assets?.filter(asset => asset.type === AssetType.document || asset.type === AssetType.video);

        if (assets?.find(asset => asset.type === AssetType.session) === undefined) {
          setIsUndeletable(false);
        }

        if (!params) {
          throw new Error(`Could not find assets for group ${groupId}`);
        }

        // Convert fetched data into intermediate structures
        setParametersInput({
          ...params,
          data: importParametersTemplate(params!.data!)
        });
        setMediaInputList(mediaList?.flatMap(media => {
          if (!media.data) return [];
          return {
            type: media.type,
            data: JSON.parse(media.data as string)
          }
        }));
        setDocumentsMap(new Map());
        paramsId.current = params.id;
    }

    if (!isNew) {
      fetchAssets();
    }

    // Now subscribe to changes
    if (groupId === undefined) return;
    subscription.current = assetsService.onAssetsUpdate(
      {type: AssetType.parameter, groupId: groupId },
      (mutation) => {
        if (mutation.op === 'deleteAssets') {
          setIsUndeletable(true);
          setOriginalAssetWasDeleted(true);
        } else if (!isNew) {
          fetchAssets();
        }
      }
    );
    return () => subscription.current && subscription.current.unsubscribe();

  }, [isNew, groupId, isUndeletable]);

  return (<div>
    {(modal === 'ParametersModal') ?
    <Parameters
      close={closeModal}

      isNewSet={isNew}
      isUndeletable={isUndeletable}
      originalAssetWasDeleted={originalAssetWasDeleted}

      saveOrClone={saveOrClone}
      remove={remove}
      reset={reset}
      clone={clone}
      update={update}

      parametersInput={parametersInput!}
      mediaInputList={mediaInputList!}
      documentsMap={documentsMap!}

      setParametersInput={setParametersInput}
      setMediaInputList={setMediaInputList}
      setDocumentsMap={setDocumentsMap}
      
      intl={intl}
    /> : (
        (modal === 'ConfirmCreateModal') ?
        <ConfirmationModal
          close={closeModal}
          goBack={goBack}
          inputs={{
            groupId: newGroupId,
            parameters: parametersInput!,
            mediaList: mediaInputList!,
            documentsMap: documentsMap!,
          }}
        /> : 
        <SaveRemoveModal
          close={closeModal}
          goBack={goBack}
          groupId={groupId!}
        />
    )
  }
  <LoadingModal title={intl.formatMessage({id:"loading:label"})} open={isLoading} progress={loadingProgress} />
  </div>)
};

export default injectIntl(ModalParameters);

const Parameters: React.FC<ParametersProps> = ({
  saveOrClone,
  remove,
  reset,
  close,
  update,

  isNewSet,
  isUndeletable,
  originalAssetWasDeleted,
  parametersInput,
  mediaInputList,
  documentsMap,
  
  setParametersInput,
  setMediaInputList,
  setDocumentsMap,

  intl,
}) => {
  const [visibleSection, setVisibleSection] = useState<Section>('Parameters');
  const [missingParameters, setMissingParameters] = useState<boolean>(false);
  const [missingMedia, setMissingMedia] = useState<boolean>(false);
  const [isFulfilled,setIsFulfilled] = useState<boolean>(false);
  const [showFooter,setShowFooter] = useState<boolean>(true);
  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(true);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);

  // When the parameters change check if some are missing
  useEffect(() => {
    if (parametersInput.data.some(x => x.correctValue === undefined)) {
        setMissingParameters(true);
    } else {
        setMissingParameters(false);
    } 
  }, [parametersInput.data]);

  //Check if form is fulfilled
  useEffect(()=>{
    if( missingMedia || missingParameters) {
      setIsFulfilled(false)
    } else {
      setIsFulfilled(true)
    }
  }, [missingMedia,missingParameters])

  //Check media input list
  useEffect(() => {
    if (mediaInputList.length < keysMedia.length) {
        setMissingMedia(true);
    } else {
        setMissingMedia(false);
    } 
  }, [mediaInputList]);

  const changeParameterValue = (idx: number, value: (number | undefined) | (number | undefined)[]) => {
    const newValues = [...parametersInput.data];
    newValues[idx].correctValue = value;
    setParametersInput({...parametersInput, data: [...newValues]});
  }

  return (
    <Modal 
      onClose={close}
      title={(isNewSet && parametersInput.name === undefined)
      ? intl.formatMessage({id: 'admin-sessions-add-params'}) : parametersInput.name! }
      subtitle={isNewSet || parametersInput?.date === undefined
      ? '' : intl.formatDate(parametersInput?.date!)}
      fullSize={true}
    >
      <div className="modal-param">
         <div className="modal-param-body">
          {showExtraInfo && <Accordion className='info-accordion'>
            <Accordion.Title
              active={isOpenDetail}
              index={0}
              onClick={() => setIsOpenDetail(!isOpenDetail)}
              className='info-accordion-title'
            >
            <Icon name='info circle' className='info-icon-accordion' />
                <FormattedMessage id="admin-sessions-show-info" />
            </Accordion.Title>
            <Accordion.Content 
              active={isOpenDetail}
              className='info-accordion-content'
            >
                <p className="explanation-text">
                  <FormattedHTMLMessage id="admin-sessions-info-params" />
                </p>
            </Accordion.Content>
          </Accordion> }
        {/* Selector */}
        <div className="options-row">
          <div className="section-selector">
            <p className={`selector ${visibleSection === 'Parameters' ? 'isClicked' : ''}`}
            onClick={() => { setVisibleSection('Parameters'); }}>
              <FormattedHTMLMessage id="admin-sessions-parameters" />
            </p>
            <p className={`selector ${visibleSection === 'Media' ? 'isClicked' : ''}`}
            onClick={() => { setVisibleSection('Media'); }}>
              <FormattedHTMLMessage id="admin-sessions-media" />
            </p>
          </div>
          {!isNewSet && <div className="options">
            <div className="option">
              <label>
                <FormattedHTMLMessage id="admin-sessions-visibility" />
              </label>
                <Selector options={[intl.formatMessage({id: "admin-sessions-global"}), intl.formatMessage({id: "admin-sessions-personal"})]}
                onChange={(_, index) => update({global: index === 0})}
                selected={parametersInput.global ? 0 : 1}
                />
            </div>
            <div className="option">
              <label>
                <FormattedHTMLMessage id="admin-sessions-default" />
              </label>
                <Selector options={[intl.formatMessage({id: "admin-sessions-yes"}), intl.formatMessage({id: "admin-sessions-no"})]}
                onChange={(_, index) => update({default: index === 0})}
                selected={parametersInput.default ? 0 : 1}
                />
            </div>
          </div> }
        </div>
        {/* Content */}
        {visibleSection === 'Parameters' ? (
          <div className="modal-param-content">
            <List
              parameters={parametersInput.data}
              setParameter={changeParameterValue}
              isEditable={isNewSet ?? false}
            />
          </div>
        ) : (
          <div className="modal-param-content">
            <MediaSection
              setMissingData = {setMissingMedia}
              mediaList = {mediaInputList}
              setMediaList = {setMediaInputList}
              documentsMap = {documentsMap}
              setDocumentsMap = {setDocumentsMap}
              isEditable={isNewSet ?? false}
              setShowExtraInfo={(val: boolean) => {
                  setShowExtraInfo(val);
                  setShowFooter(val);
              }}
            />
          </div>
        )}
        </div>
        {showFooter && 
          <div className="footer-section"> 
              <div className="warning" style={{opacity: isFulfilled ? 0 : 1}}>
                <Image name="warning"/>
                {missingMedia && missingParameters && <p className='text'>
                  <FormattedMessage id="admin-sessions-feedback-all-media-params" />
                </p>}
                {!missingMedia && missingParameters && <p className='text'>
                  <FormattedMessage id="admin-sessions-feedback-all-params" />
                </p>}
                {missingMedia && !missingParameters && <p className='text'>
                  <FormattedMessage id="admin-sessions-feedback-all-media" />
                </p>}
              </div>
              {originalAssetWasDeleted &&
                <div className="warning">
                  <Image name="warning"/>
                  <p className='text'>
                    <FormattedMessage id="admin-sessions-original-deleted" />
                  </p>
                </div>
              }
              <div className="buttons-save-mode">
                {!isNewSet && !isUndeletable &&
                  <button className="delete-button-modal" onClick={remove}>
                    <p className="delete-text">
                      <FormattedMessage id="admin-sessions-delete" />
                    </p>
                  </button>
                }
                {isNewSet && <p className="discard-text" onClick={() => { reset(); }}> Reset </p> }
                <button className={`confirm-button-modal ${ !isFulfilled && isNewSet ? 'disabled' :''}`} disabled={isNewSet? !isFulfilled:false} onClick={ () => saveOrClone() }>
                  <p className="confirm-text">{ isNewSet
                      ? <FormattedMessage id="admin-sessions-create" />
                      : <FormattedMessage id="admin-sessions-clone" />
                  }</p>
                </button>
              </div>
            </div>}
        </div>
    </Modal>
  );
};

const SaveRemoveModal: React.FC<SaveRemoveModalProps> = (
  { close, goBack, groupId }
) => {

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const removeData = async () => {
    setIsLoading(true);
    const assets = await assetsService.getAssets({ groupID: groupId});
    await storageS3.deleteItemsFromFolder(`media/${groupId}`, (progress, total) => {
      setProgress(progress / total);
    });
    await assetsService.deleteAssets(
      assets.map(asset => {return {
        id: asset.id,
        groupId: asset.groupId,
        type: asset.type,
        name: asset.name,
        date: asset.date,
        default: asset.default,
        global: asset.global,
        data: asset.data
      }})
    );
    close()
  }

  return (
    <Modal onClose={ () => !isLoading && close() } title="Cancella parametri" subtitle="Questa operazione è irreversibile ed eliminerà tutti i parametri ed i media caricati. Sei sicuro di voler continuare?">
      <div className="save-modal">
        { !isLoading
        ? <div className="buttons-save-mode">
            <p className="discard-text" onClick={ goBack }>
              Annulla
            </p>
            <div className="delete-button" onClick={ removeData }>
              <p className="delete-text">Elimina</p>
            </div>
          </div>
        : <div className='load'>
            <div className="upload-spinner-container">
              <div className="upload-spinner">
                <svg
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="50" cy="50" r="45" />
                </svg>
              </div>
              <span>{Math.floor(progress * 100 || 0)} %</span>
            </div>
          </div>
        }
      </div>
    </Modal>
  );
};
