import React, { useCallback, useEffect } from 'react';
import Image from '../Image';
import './styles.scss';

interface Props {
  children?: any;
  show: boolean;
  onClose: Function;
  className?: string;
}

const Modal: React.FC<Props> = ({ children, show, onClose, className }) => {
  const handleKeyDown = useCallback(
    (ev) => {
      const { keyCode, wich } = ev;
      if (keyCode === 27 || wich === 27) {
        onClose();
      }
    },
    [onClose]
  );

  //ComponentDidMount
  useEffect(
    () => document.addEventListener('keydown', handleKeyDown, false),
    [handleKeyDown]
  );

  //ComponentWillUnmount
  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const modal = (
    <div
      className={`modal ${className ? className : ''}`}
      onKeyDown={(ev) => handleKeyDown(ev)}>
      <div
        className="close-container"
        onClick={() => {
          onClose();
        }}>
        <Image name="close" className="close" />
      </div>
      {children}
    </div>
  );

  return show ? modal : null;
};

export default Modal;
