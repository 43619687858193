import React, { useContext } from 'react';
import { AdminContext } from '../../context';
import { EChapterKind } from '../../interfaces';
import './style.scss';
import AdminMatrix from '../AdminMatrix';

interface Props {
  selectedKpiID?: string;
  selectedScenarioID?: string;
}

const AdminMatrixProvider: React.FC<Props> = ({
  selectedKpiID,
  selectedScenarioID
}) => {
  const { game, selectedChapter } = useContext(AdminContext);

  if (!game) {
    throw new Error('Not found game');
  }

  const chapter = game.chapters.find(c => c.id === selectedChapter);

  if (!chapter) {
    throw new Error('Not found chapter');
  }

  const renderScenarioMatrix = () => {
    const activeScenarios = chapter.scenarios.filter(
      scenario => scenario.active
    );
    const activeScenariosIndexes = activeScenarios.map(
      scenario => scenario.index
    );
    const activeMatrix = chapter.matrix.map(row =>
      row.filter((column, index) => activeScenariosIndexes.includes(index))
    );
    const activeKpis = chapter.kpis.filter((kpi, index) =>
      activeMatrix[index].some(scenarioKpi => scenarioKpi != null)
    );
    const activeKpisIndexes = activeKpis.map(kpi => kpi.index);
    const newActiveMatrix = activeMatrix.filter((row, index) =>
      activeKpisIndexes.includes(index)
    );

    const totals: any[] = [];

    activeScenarios.forEach((scenario, index) => {
      const column = newActiveMatrix.map(row => {
        return row[index];
      });
      totals[index] = column.reduce(
        (acc, ele) => acc + (+(ele || { retries: 0 })['retries'] || 0),
        0
      );
    });

    return (
      <AdminMatrix
        matrix={newActiveMatrix}
        selectedScenarioID={selectedScenarioID}
        kpis={chapter.kpis}
        scenarios={activeScenarios}
        totalsPerColumn={totals}
      />
    );
  };
  const renderKpiMatrix = () => {
    let activeKpis;
    let activeMatrix: any[][];
    const totals: number[] = [];
    let kpiForTotals: any[] = [];
    switch (chapter.id) {
      case '4':
        const chapter3 = game.chapters.find(chapter => chapter.id === '3');
        const chapter3Matrix =
          chapter3 &&
          chapter3.matrix.map(row =>
            row.map(scenarioKpi =>
              scenarioKpi
                ? {
                    ...scenarioKpi,
                    output: true
                  }
                : null
            )
          );
        const chapter4Kpis = chapter.kpis.filter(kpi => kpi.active);
        const chapter4KpisIndexes = chapter4Kpis.map(kpi => kpi.index);
        const chapter4Matrix = chapter.matrix.filter((row, index) =>
          chapter4KpisIndexes.includes(index)
        );
        activeKpis = chapter3
          ? [...chapter3.kpis, ...chapter4Kpis]
          : chapter4Kpis;
        activeMatrix = chapter3Matrix
          ? [...chapter3Matrix, ...chapter4Matrix]
          : chapter4Matrix;

        if (chapter3) {
          kpiForTotals = chapter3.kpis;
        } else {
          kpiForTotals = chapter4Kpis;
        }
        break;
      default:
        activeKpis = chapter.kpis.filter(kpi => kpi.active);
        kpiForTotals = activeKpis;
        const activeKpisIndexes = activeKpis.map((_, index) => index);
        activeMatrix = chapter.matrix.filter((row, index) =>
          activeKpisIndexes.includes(index)
        );
    }

    kpiForTotals.forEach((kpi, index) => {
      const column = activeMatrix.map(row => {
        return row[index];
      });
      totals[index] = column.reduce(
        (acc, ele) => acc + (+(ele || { retries: 0 })['retries'] || 0),
        0
      );
    });

    return (
      <AdminMatrix
        matrix={activeMatrix}
        kpis={activeKpis}
        scenarios={chapter.scenarios}
        selectedKpiID={selectedKpiID}
        totalsPerColumn={totals}
      />
    );
  };
  return (
    <div className="AdminMatrixProvider">
      {chapter.kind === EChapterKind.SCENARIOS_MATRIX
        ? renderScenarioMatrix()
        : renderKpiMatrix()}
    </div>
  );
};

export default AdminMatrixProvider;
