import React, { useContext } from 'react';
import { AdminContext, AppContext } from '../../context';
import AdminOnboarding from '../AdminOnboarding';
import route from '../../route';

import { database } from '../../utilities/database';

interface Props {
  match: any;
  history: any;
}

const AdminOnboardingController: React.FC<Props> = ({ match, history }) => {
  const { session } = useContext(AppContext);
  const context = useContext(AdminContext);
  const { games } = context;
  if (!games) {
    throw new Error('Not found games');
  }
  const { teamID } = match.params;
  const game = games.find(game => game.token === teamID);
  if (!game) {
    throw new Error('Not found game');
  }
  const token = teamID;

  function updateData(val: any, name: string) {
    switch (name) {
      case 'players': {
        database.set(
          session!.data.sessionDataId!, `games.${token}.players`, val);
        break;
      }
      case 'team': {
        database.set(
          session!.data.sessionDataId!, `games.${token}`, { ...game, team: val });
        break;
      }
    }
  }

  function creationTeamCallback() {
    history.push(route('adminPanelTeam', { 
      gameSessionId: session!.id,
      teamID: token 
    }));
  }

  return (
    <AdminOnboarding
      game={game}
      updateData={(val: any, name: string) => updateData(val, name)}
      creationTeamCB={() => creationTeamCallback()}
    />
  );
};

export default AdminOnboardingController;
