import React from 'react';
import { FormattedMessage } from 'react-intl';
import './style.scss';

interface Props {
}

const AdminSessionsMenu: React.FC<Props> = () => {
  return (
    <nav className="AdminChaptersMenu">
      <div className="admin-title">
        <FormattedMessage id="admin-sessions-section-title" />
      </div>
    </nav>
  );
};

export default AdminSessionsMenu;
