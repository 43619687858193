import './style.scss';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Icon, Divider } from 'semantic-ui-react';
import Sessions from './sessions';
import SessionHistory from './history';
import SelectedSession from './selected';
import { AdminContext, AppContext } from '../../context';
import { ISession } from '../../interfaces';
import { AssetsSubscription, assetsService } from '../../utilities/assetsService';
import { AssetType } from '../../api';
import route from '../../route';
import ResetButton from '../ResetButton';
import Image from '../Image';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';

interface Props {
  history: any;
  intl: InjectedIntl;
}

const SessionSection: React.FC<Props> = (props) => {

  const { session, setSession } = useContext(AppContext);
  const { match, ssoUser } = useContext(AdminContext);

  const [ allSessions, setAllSessions ] = useState<ISession[]>([]);
  const [ sessionList, setSessionList ] = useState<ISession[]>([]);
  const [ activeIndex, setActiveIndex ] = useState<number>(0);

  const gameSessionId = match?.params.gameSessionId;

  const sessionRef = useRef<ISession>();
  sessionRef.current = session;

  const [ searchLabel, setSearchLabel ] = useState<string>('');
  const [ searchMinDate, setSearchMinDate ] = useState<Date>(new Date('1970'));
  const [ searchMaxDate, setSearchMaxDate ] = useState<Date>(new Date('5000'));

  // load the list of active sessions and subscribe to any changes
  // 
  useEffect(() => {
    let subscription: AssetsSubscription | undefined ;
    
    const loadSessions = async () => {
      const sessions = await getSessionLst();      
      if (!sessionRef.current || sessionRef.current.data.terminated) {
        
        const selectedSession = selectDefaultSession(sessions);
        if (selectedSession !== undefined) {
          if (selectedSession?.data.terminated)
            setActiveIndex(1);

          setSession!(selectedSession);
          props.history.push(route('adminSessionsWithSelection', { 
            gameSessionId: selectedSession.id,
            userId: ssoUser
          }));
        }
      }
      setAllSessions(sessions);
    }
    loadSessions().then(() => {
      subscription = assetsService.onAssetsUpdate(
        { type: AssetType.session },
        () => loadSessions()
      );
    });
    return () => subscription?.unsubscribe()
  }, 
  [gameSessionId, props.history, setSession])

  useEffect(() => {
      setSessionList(allSessions.filter(session => {
          const date = new Date(session.date!).setHours(0, 0, 0, 0);
          return (searchLabel === ""
          || session.name?.toLowerCase().includes(searchLabel)
          || session.data.paramLabel?.toLowerCase().includes(searchLabel)
          ) && (
            date >= searchMinDate.setHours(0, 0, 0, 0)
            && date <= searchMaxDate.setHours(0, 0, 0, 0)
          )
      }));
  }, [allSessions, searchLabel, searchMinDate, searchMaxDate])

  //////////////////////////////////////////////////////////////// UTILS
  
  const handleLabelSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchLabel(event.currentTarget.value);
  }

  const handleMinDateSearch = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setSearchMinDate(new Date(event.currentTarget.value || '1970'));
  }

  const handleMaxDateSearch = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setSearchMaxDate(new Date(event.currentTarget.value || '5000'));
  }
  
  const resetFilters = () => {
    setSearchLabel('');
    setSearchMinDate(new Date('1970'));
    setSearchMaxDate(new Date('5000'));
    const fields = Array.from(document.getElementsByClassName('input date'));
    fields.forEach((field) => (field as HTMLInputElement).value = ''); 
  }

  const handleAccordionClick = (e: any, titleProps: any) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  const selectDefaultSession = (sessions: ISession[]): ISession | undefined => {

    const selectedSession = (
        gameSessionId && 
        sessions.find(s => s.id === gameSessionId)
    ) ?? 
    sessions.filter(s => !s.data.terminated)[0];

    return selectedSession;
  }

  const getSessionLst = async () => {
    const sessions = (await assetsService.getAssets({
        type: AssetType.session,
        includeData: true
    })).map(x => {
        x.data = JSON.parse(x.data!);
        return x as unknown as ISession;
    })
    .map(x => { delete x.__typename; return x; })
    .sort((a, b) => 
        new Date(b.date!).getTime() -    
        new Date(a.date!).getTime()
    );
    return sessions;
  };

  //////////////////////////////////////////////////////////////// MARKUP

  return (
    <Fragment>
        <SelectedSession history={props.history}></SelectedSession>

        <div className="section isOpen">
              <div className="filters session">
                <div className="input-box item-one">
                  <input className="input" placeholder={props.intl.formatMessage({id: "admin-sessions-label-search"})}
                  onChange={handleLabelSearch} value={searchLabel} />
                  <Image name="search" className="search-icon" />
                </div>
                <p className="input-label item-two">
                  <FormattedMessage id="admin-sessions-from" />
                </p>
                <div className="input-box item-three">
                  <Image name="search" className="calendar-icon" />
                  <input id="min-date" className="input date" type="date" defaultValue="" onChange={handleMinDateSearch} />
                </div>
                <p className="input-label item-four">
                  <FormattedMessage id="admin-sessions-to" />
                </p>
                <div className="input-box item-five">
                  <Image name="search" className="calendar-icon" />
                  <input id="max-date" className="input date" type="date" defaultValue="" onChange={handleMaxDateSearch} />
                </div>
                <ResetButton onClick={resetFilters}>Reset</ResetButton>
              </div>

              {(searchMinDate > searchMaxDate) &&
              <p className="explanation-text">
                <FormattedMessage id="admin-sessions-feedback-impossible-range" />
              </p>}

            <Divider style={{margin: 0}} />

            <Accordion fluid={true} className='custom-accordion'>
              <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleAccordionClick}
                  className='custom-accordion-title first'
              >
                  <Icon name='dropdown'/> <FormattedMessage id="admin-sessions-active-list" /> 
              </Accordion.Title>
              <Accordion.Content 
                  active={activeIndex === 0}
                  className='custom-accordion-content'
              >
                  <Sessions 
                    history={props.history} 
                    sessionList={sessionList}
                  />
              </Accordion.Content>

              <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleAccordionClick}
                  className='custom-accordion-title'
              >
                  <Icon name='dropdown'/> <FormattedMessage id="admin-sessions-history-list" /> 
              </Accordion.Title>
              <Accordion.Content 
                  active={activeIndex === 1}
                  className='custom-accordion-content'
              >
                  <SessionHistory 
                    history={props.history} 
                    sessionList={sessionList}
                  />
              </Accordion.Content>
            </Accordion>
        </div>
    </Fragment>
  );
};

export default injectIntl(SessionSection);
