import React, { useContext, useState } from 'react';
import { AdminContext, AppContext } from '../../context';
import { IGame } from '../../interfaces';
import route, { NavLink } from '../../route';
import './style.scss';
import { injectIntl } from 'react-intl';
import chunk from 'lodash/chunk';
import Image from '../Image';

interface Props {
  intl: any;
  teamForPage: number;
}

const AdminTeamsMenu: React.FC<Props> = ({ intl, teamForPage }) => {
  const { session } = useContext(AppContext);
  const { match, games, ssoUser } = useContext(AdminContext);
  const [page, setPage] = useState(0);
  if (!match) {
    throw new Error('Not found match');
  }
  if (!games) {
    throw new Error('Not found games');
  }
  const { teamID } = match.params;

  function onBackClick() {
    if (page > 0) {
      setPage(page - 1);
    }
  }

  function onForwardClick() {
    if (games) {
      if (chunk(games, teamForPage).length + 1 > page + 1) {
        setPage(page + 1);
      }
    }
  }

  function getSliceArguments(): [number, number] {
    if (page === 0) {
      return [0, teamForPage];
    } else {
      return [page * teamForPage, (page + 1) * teamForPage];
    }
  }

  function getTabLabel(game: IGame | Partial<IGame>) {
    if (game.team === 'global')
      return `${intl.formatMessage({ id: 'admin-global:label' })}`;
    return game.team
      ? `Team: ${game.team} (${game.token})`
      : `${intl.formatMessage({ id: 'token:label' })}: ${game.token}`;
  }

  const listGames = [{ token: 'global', team: 'global' }, ...games];

  return (
    <nav className="TeamsMenu">
      <div className="admin-title">
        {intl.formatMessage({ id: 'dashboard:label' })}
      </div>
      <div className="menu">
        <Image name={'arrow'} onClick={onBackClick} />
        {listGames
          .slice(...getSliceArguments())
          .map((game: IGame | Partial<IGame>, index: number) => (
            <div
              key={index}
              className={'menu-item-container'}
              style={{ width: `${100 / teamForPage}%` }}>
              <NavLink to={route('adminPanelTeam', { 
                  userId: ssoUser,
                  gameSessionId: session!.id,
                  teamID: game.token 
              })}>
                <div
                  className={`menu-item ${
                    game.token === teamID ? 'active' : ''
                  } ${game.team === 'global' ? 'global' : ''} ${
                    game.initialized === true ? 'initialized' : ''
                  }`}>
                  {getTabLabel(game)}
                </div>
              </NavLink>
              {game.team !== 'global' && game.token === teamID && (
                <NavLink
                  className={'onboard-link'}
                  to={route('adminPanelTeamEdit', { 
                    userId: ssoUser,
                    gameSessionId: session!.id,
                    teamID: game.token 
                  })}>
                  <Image name={'pencil'} />
                </NavLink>
              )}

            </div>
          ))}
        <Image name={'arrow'} onClick={onForwardClick} className={'inverted'} />
      </div>
    </nav>
  );
};

export default injectIntl(AdminTeamsMenu);
