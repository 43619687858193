import React from 'react';
import {
  IAsset, IChapter, IGame,
  IRouteMatch, IScenario, IUser, ISession
} from './interfaces';
import { IUserInfo } from './utilities/auth-services';

interface IPlayerContext {
  match?: IRouteMatch;
  game?: IGame;
  chapter?: IChapter;
  activeAssets?: [IAsset];
  assets?: string;
  scenarios?: [IScenario];
  activeChapters?: IChapter[];
}
const defaultPlayerContext = {};

const PlayerContext = React.createContext<IPlayerContext>(defaultPlayerContext);

interface IAdminContext {
  match?: IRouteMatch;
  user?: IUser;
  games?: IGame[];
  game?: IGame;
  chapters?: IChapter[];
  selectedChapter?: string;
  userInfo?:IUserInfo;
  ssoUser?: string;
}
const defaultAdminContext = {};

const AdminContext = React.createContext<IAdminContext>(defaultAdminContext);

interface IAppContext {
  lang: string;
  setLang?: (l: string) => void,
  session?: ISession;
  setSession?: (s: ISession) => void;
}
const defaultAppContext = {
  lang: 'it'
};

const AppContext = React.createContext<IAppContext>(defaultAppContext);

export { PlayerContext, AdminContext, AppContext };
