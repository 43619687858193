import _ from 'lodash';
import { IGame } from '../interfaces';
import { API } from 'aws-amplify';
import { 
    GraphQLQuery, GraphQLSubscription, GraphQLResult, GRAPHQL_AUTH_MODE 
} from '@aws-amplify/api';
import {
    GetSessionQuery,
    UpdateSessionMutation,
    OnSessionUpdateSubscription,
    DeleteSessionMutation,
    CreateSessionMutation,
} from '../api';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';

import Observable, {ZenObservable} from 'zen-observable-ts';

export type DatabaseSubscription = ZenObservable.Subscription;

export type SubscribeCallback = (
    data: any, subscription: DatabaseSubscription
) => void;

export type ParentCallback = () => object | undefined

/**
 * A sigleton wrapper to incapsulate methods which act onto the database.
 * */
class Database {

    private static _instance ?: Database;

    public static get instance() {

        if (Database._instance === undefined) {
            Database._instance = new Database();
        }
        return Database._instance;
    }

    /**
     * Execute an api call which returns a promise
     * */
    private async apiCall<T>(query: string, variables?: object): Promise<T> {

        const queryData = {
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM, 
            ...(variables ? { variables } : {}),
            query
        };
        const res = await (
            API.graphql<GraphQLQuery<T>>(queryData) as Promise<GraphQLResult<T>>
        );
        if (res.errors) {
            return Promise.reject("Generic API error");
        }
        return res.data!;
    }

    /**
     * Execute an api call which returns an observable
     * */
    private apiObserve<T>(query: string, variables?: object): Observable<T> {

        const queryData = {
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM, 
            ...(variables ? { variables } : {}),
            query
        };
        const res = (API.graphql<GraphQLSubscription<T>>(queryData) as Observable<{
            provider: any ; // AWSAppSyncRealTimeProvider
            value: GraphQLResult<T>;
        }>);
        return res.map(x => x.value.data!);
    }

    /**
     * Get data from the specified path
     * */ 
    public async get(session: string, path: string): Promise<any> {

        const data = await this.apiCall<GetSessionQuery> (
            queries.getSession,
            { session, path }
        )
        .catch(err => {
            console.error("Error while retrieving information from", path);
            return err;
        });
        return JSON.parse(data.getSession.data);
    }

    /** 
     * Set data at specified path, overwriting existing data
     * */
    public async create(data: any): Promise<string> {
        try {
            const res = await this.apiCall<CreateSessionMutation>(
                mutations.createSession,
                { json: JSON.stringify(data) }
            );
            return res.createSession;
        } 
        catch(err) {
            console.error("Error while creating the session data", data, err);
            throw err;
        };
    }

    /** 
     * Set data at specified path, overwriting existing data
     * */
    public async set(session: string, path: string, data: any): Promise<string> {
        
        try {
            const res = await this.apiCall<UpdateSessionMutation>(
                mutations.updateSession,
                { session, path, json: JSON.stringify(data) }
            );
            return res.updateSession.session;
        }
        catch(err) {
            console.error("Error while setting data in path", path, data, err);
            throw err;
        };
    }

    /**
     * Delete data from specified path
     * */
    public async remove(session: string): Promise<void> {

        await this.apiCall<DeleteSessionMutation>(
            mutations.deleteSession,
            { session }
        )
        .catch(err => {
            console.error("Error while deleting session");
        });
    }

    /**
     * Subscribe to changes in the database, this method will
     * immediately call the callback once, acting like a sort of get
     * */
    public subscribe(
        session: string, path: string, callback: SubscribeCallback, parent: ParentCallback
    )
    : DatabaseSubscription {

        const obs = this.apiObserve<OnSessionUpdateSubscription>(
                subscriptions.onSessionUpdate,
                { session, path }
        );
        const sub: DatabaseSubscription = obs.subscribe({
            next: event => {
                const update = event.onSessionUpdate!;
                const relativePath = update.path.replace(path, "");
                const data = JSON.parse(update.data);

                const result = applyMutation(relativePath, data, parent());

                callback(result, sub);
            },
            error: (e) => {
                console.error("Subscription error", path, e);
            }
        });
        // We do a call immediately on subscribe
        // with no relative path so we will get the full object
        this.get(session, path)
            .then(data => callback(applyMutation("", data, parent()), sub));

        return sub;
    }
}

/**
 * Given the relative path of the child and the parent object,
 * this method will extract the child object at the given path
 * */
function applyMutation(
        relativePath: string, childData: object, parentData ?: object): any {

    if (relativePath === "" || parentData === undefined) return childData;
    
    let   data    = _.cloneDeep(parentData);
    const matches = relativePath.matchAll(/([^.#]+)/gm);
    
    const isGameList = (data: any): data is IGame[] => {
        return data instanceof Array && data[0].token !== undefined
    };
    const deepSearch = (parent: any): any => {
        const needle = matches.next();
        if (needle.done) return undefined;
        
        const [, key] = needle.value;
        const idx = isGameList(parent) ?
            parent.findIndex(needle => needle.token === key) : key;
            
        if (deepSearch(parent[idx]) === undefined) {
            parent[idx] = childData;
        }
        return data;
    }
    return deepSearch(data);
}

export const database = Database.instance;