import React, { useState } from 'react';
import './style.scss';
import { ParameterDataWithUnit } from '../../../utilities/parametersTemplate';
import Image from '../../Image';
import _ from 'lodash';
import ResetButton from '../../ResetButton';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';

interface Props {
  parameters: ParameterDataWithUnit[];
  setParameter: (index: number, value: (number | undefined) | (number | undefined)[]) => void;
  isEditable: boolean;
  intl: InjectedIntl
}

type Phase = 1 | 2 | 3 | 4;

const phases: Phase[] = [1, 2, 3, 4];

const List: React.FC<Props> = ({ parameters, setParameter, isEditable, intl }) => {
  const [phase, setPhase] = useState<Phase>(1);
  const [searchLabel, setSearchLabel] = useState<string>("");

  const phaseStarts: number[] = [0, 0, 0, 0];
 
  const handleLabelSearch = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setSearchLabel(event.currentTarget.value);
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    subindex?: number,
  ) => {
    if (!isEditable) return;

    if (phaseStarts[3] === 0) {
      phaseStarts.forEach((_, phaseIndex) => {
        phaseStarts[phaseIndex] = parameters.filter(p => p.chapterIndex < phaseIndex).length
      });
    }

    const val = event.currentTarget.value;
    const realIndex = phaseStarts[phase - 1] + index;

    const copy = _.cloneDeep(parameters[realIndex].correctValue);

    if (val === '' || val === undefined || val == null) {
        if (subindex !== undefined) {
          (copy as (number | undefined)[])[subindex] = undefined
        }
        setParameter(realIndex, (subindex !== undefined) ? copy : undefined);
        return;
    }

    const newValue = +val;
    if (subindex !== undefined) {
      (copy as (number | undefined)[])[subindex] = +val
    }

    setParameter(realIndex, (subindex !== undefined) ? copy : newValue)
  };

  const filteredParameters = (): ParameterDataWithUnit[] => {
    return parameters.filter((param) => {
        return (
          param.kpiName.toLowerCase().includes(searchLabel.toLowerCase())
          || param.scenarioName.toLowerCase().includes(searchLabel.toLowerCase())
        ) && param.chapterIndex === phase - 1
    });
  }

  return (
    <div className="container">
      <div className="header-container">
        <div className="phases-container">
          {phases.map((phs) => (
            <div
              key={phs}
              onClick={() => setPhase(phs)}
              className={`phase ${phs === phase ? 'isClicked' : ''}`}>
              <FormattedMessage id="admin-sessions-phase" values={{"phase": phs}} />
            </div>
          ))}
        </div>
      </div>
      
      <div className="filters">
        <div className="input-box">
          <input className="input" placeholder={intl.formatMessage({id: "admin-sessions-label-search"})} onChange={handleLabelSearch} value={searchLabel} />
          <Image name="search" className="search-icon" />
          <ResetButton onClick={() => setSearchLabel('')}>Reset</ResetButton>
        </div>
      </div>

        
      {filteredParameters().length !== 0 ? <div className="parameters-container">
        {filteredParameters()
          .map((parameter, index) => (
            <div className={`parameter-box light `} key={index}>
              <p className="text">
                <FormattedMessage id={`${parameter.kpiID}:label`} />
              </p>
              <p className="text">
                <FormattedMessage id={`${parameter.scenarioID}:label`} />
              </p>

              <div className="value-box">
                { parameter.kpiID !== "net-present-value"
                ? <>
                    <input
                      className="input"
                      type="number"
                      placeholder=""
                      disabled={!isEditable}
                      value={parameter.correctValue?.toString() ?? ""}
                      onChange={(event) => handleChange(event, index)}
                    />
                    <p className="unit">{parameter.unit}</p>
                    <div className='warning-box'>
                    { parameter.correctValue ? null : 
                        <Image name="warning" className="warning-button" />
                    }
                    </div>
                  </>
                : <>
                    <input
                      className="input-multi"
                      type="number"
                      placeholder=""
                      disabled={!isEditable}
                      value={(parameter.correctValue as (number | undefined)[])[0]?.toString() ?? ""}
                      onChange={(event) => handleChange(event, index, 0)}
                    />
                    <p className="unit">{parameter.unit}</p>
                    <input
                      className="input-multi"
                      type="number"
                      placeholder=""
                      disabled={!isEditable}
                      value={(parameter.correctValue as (number | undefined)[])[1]?.toString() ?? ""}
                      onChange={(event) => handleChange(event, index, 1)}
                    />
                    <p className="unit">{parameter.unit}</p>
                    <div className='warning-box'>
                    { (parameter.correctValue as (number | undefined)[]).every(x => x !== undefined) ? null : 
                        <Image name="warning" className="warning-button" />
                    }
                    </div>
                  </>
                }
              </div>
            </div>
        ))}
      </div>
      : <p className='info-text'>
      <FormattedMessage id="admin-sessions-feedback-no-param" />
      </p>}
    </div>
  );
};

export default injectIntl(List);
