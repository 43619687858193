import React, { useState, useEffect } from 'react';
import { Popup } from 'semantic-ui-react';
import Image from '../Image';
import './style.scss';

interface Props {
  kind: string;
  children: any;
  onClose?: Function;
  closeButton?: boolean;
  style?: any;
  controlled?: any;
  open?: boolean;
}

const getPosition = (kind: string) => {
  switch (kind) {
    case 'bottom-right':
      return 'bottom left';
    case 'bottom-left':
      return 'bottom right';
    case 'top-right':
      return 'top left';
    case 'top-left':
      return 'top right';
    case 'top-center':
      return 'top center';
    case 'bottom-center':
      return 'bottom center';
    case 'left-center':
      return 'right center';
    case 'right-center':
      return 'left center';
    default:
      throw new Error(`Can't find kind: ${kind} in Tooltip`);
  }
};

const Tooltip: React.FC<Props> = ({
  kind = 'left-top',
  children,
  onClose = () => {},
  closeButton = true,
  style = {},
  controlled = false,
  open = true
}) => {
  const [openState, setOpenState] = useState<boolean>(open);

  const isTablet = window.matchMedia("(min-width:601px) and (max-width: 1199px)").matches;

  useEffect(() => {
    if (isTablet) {
      setOpenState(false)
    }
  },
  [])



  return (
    <Popup
      style={style}
      content={
        <div className="Tooltip">
          <div className="content">
            {closeButton && (
              <div
                className="close-button"
                onClick={() =>
                  controlled ? onClose && onClose() : setOpenState(false)
                }>
                <Image name="close" />
              </div>
            )}
            {children}
          </div>
        </div>
      }
      open={controlled ? open : openState}
      position={getPosition(kind)}
      trigger={<div />}
    />
  );
};

export default Tooltip;
