import React, { useContext } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import Image from '../Image';
import './style.scss';
import LanguageSelector from '../LanguageSelector';
import { AppContext } from '../../context';

interface Props {
  intl: InjectedIntl;
  teamName?: string;
}

const OverHeader: React.FC<Props> = ({ intl }) => {

  const { lang, setLang, session } = useContext(AppContext);

  const changeLang = (lang: string) => setLang!(lang);

  return (
    <div className="OverHeader">
      <a
        href={
          'https://www.sdabocconi.it/en/faculty-research/learning-process/learning-lab'
        }
        target={'_blank'}>
        <Image name="bocconi" className="bocconi" />
      </a>
      <div className='welcomeLearninglabLogo-container'>
        <Image name="welcomeLearninglabLogo" className="welcomeLearninglabLogo" />
      </div>
      { !session && 
        <LanguageSelector lang={lang} changeLang={changeLang} />
      }
    </div>
  );
};

export default injectIntl(OverHeader);
