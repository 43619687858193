import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, InjectedIntl, injectIntl } from 'react-intl';
import { AppContext, PlayerContext } from '../../context';
import { EChapterKind, IChapter, IKpi, IScenario } from '../../interfaces';
import route, { Route } from '../../route';
import ActionButton from '../ActionButton';
import Image from '../Image';
import Modal from '../Modal';
import SquareButton from '../SquareButton';
import Tooltip from '../Tooltip';
import WhiteButton from '../WhiteButton';
import './style.scss';

import { database } from '../../utilities/database';

interface Props {
  intl: InjectedIntl;
  item: IScenario | IKpi;
  model: string;
}

const ChapterNotes: React.FC<Props> = ({ intl, item, model }) => {
  function showModal(value: any) {
    setModalVisible(value);
  }

  const [modalVisible, setModalVisible] = useState(false);

  const { session } = useContext(AppContext);
  const { match, chapter } = useContext(PlayerContext);

  if (!match) {
    throw new Error('Not found match');
  }
  if (!chapter) {
    throw new Error('Not found match');
  }

  const [notes, setNotes] = useState<string>('');
  const [notesFromDatabase, setNotesFromDatabase] = useState<string>(item.notes || '');
/*
  const localNotes = window.localStorage.getItem('local-notes')
  const localNotesMap = new Map<string, string>(
    localNotes ? JSON.parse(localNotes) : []
  );

  useEffect(() => {
    setNotes(localNotesMap.get(`${chapter.id}-${item.id}`) || '')
  }, 
  [localNotesMap, chapter, item])
*/
  const { token } = match.params;

  const checkIfChapterCompleted = () => {
    const db_path = `games.${token}.chapters#${chapter.index}`;
    database.get(session!.data.sessionDataId!, db_path).then(
      (data) => {
        const chapter: IChapter = data;
        const completed =
          chapter.kind === EChapterKind.SCENARIOS_MATRIX
            ? chapter.scenarios
                .filter(scenario => scenario.active)
                .every(scenario => scenario.completed!!)
            : chapter.kpis
                .filter(kpi => kpi.active)
                .every(kpi => kpi.completed!!);

        database.set(session!.data.sessionDataId!, db_path, {
          ...chapter,
          completed,
          completedAt: completed ? new Date().toISOString() : null
        });
    });
  };

  const handleChange = () => {
    database.set(session!.data.sessionDataId!,
      `games.${token}.chapters#${chapter.index}.${model}#${item.index}`, {
        ...item,
        notes,
        notesConfirmed: true,
        completed: true
      })
      .then(() => checkIfChapterCompleted());

    showModal(false);
  };

  useEffect(() => {
    const sub = database.subscribe(
      session!.data.sessionDataId!,
      `games.${token}.chapters#${chapter.index}.${model}#${item.index}`,
      (value) => {
        setNotesFromDatabase(value.notes);
      },
      () => item
    );
    return () => sub.unsubscribe();
  }, 
  [session, item, chapter, token, model]);

/*
  const securitySave = () => {
    localNotesMap.set(`${chapter.id}-${item.id}`, notes);
    window.localStorage
      .setItem('local-notes', JSON.stringify([...localNotesMap.entries()]))
  };

  useEffect(() => {
    const interval = setInterval(() => {
      securitySave();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
*/
  return (
    <div className="ChapterNotes">
      <Route
        path={route('chapter', { gameSessionId: session!.id, token, chapterID: 1 })}
        render={() => (
          <Tooltip
            kind="top-right"
            style={{ top: '-0.5rem', left: '20rem', minWidth: '30rem' }}>
            <div
              className="ChaptersMenu-tooltip-avatar"
              style={{ width: '30rem' }}>
              <Image name="sparkAvatar" />
              <div className="text">
                <FormattedHTMLMessage id="eurofireTooltipNotes:label" />
              </div>
            </div>
          </Tooltip>
        )}
      />
      <Route
        path={route('chapter', { gameSessionId: session!.id, token, chapterID: 2 })}
        render={() => (
          <Tooltip
            kind="top-right"
            style={{ top: '-1rem', left: '43rem', minWidth: '40rem' }}>
            <div
              className="ChaptersMenu-tooltip-avatar"
              style={{ width: '40rem' }}>
              <Image name="sparkAvatar" />
              <div className="text">
                <FormattedHTMLMessage id="eurofireTooltipNotesComplex:label" />
              </div>
            </div>
          </Tooltip>
        )}
      />
      <SquareButton image={'comment'} color={''} />
      <div className="textarea-container">
        <textarea
          disabled={item.notesConfirmed}
          value={(item.notesConfirmed === true)? notesFromDatabase:notes}
          onChange={e => setNotes(e.target.value)}
        />
        {!item.notesConfirmed && !notes && (
          <div className="Placeholder">
            <b>
              {intl.formatMessage({ id: `${item.label || item.id}:label` })}
            </b>
            : {intl.formatMessage({ id: 'chapter-notes:label' })}
          </div>
        )}
      </div>
      {!item.notesConfirmed && notes ? (
        <ActionButton
          onClick={() => showModal(true)}
          text={intl.formatMessage({ id: 'send-button:label' })}
          image={'noteSend'}
          color={'#FF8744'}
        />
      ) : (
        undefined
      )}
      {
        <Modal show={modalVisible} onClose={() => showModal(false)}>
          <FormattedHTMLMessage id="scenario-notes-confirm-popup:label" />
          <WhiteButton onClick={handleChange}>
            {intl.formatMessage({ id: 'scenario-notes-confirm-button:label' })}
          </WhiteButton>
        </Modal>
      }
    </div>
  );
};

export default injectIntl(ChapterNotes);
