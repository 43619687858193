export default {
  'eurofire:label': 'EuroFire',
  'interactive-business-case:label': 'Interactive Business Case',
  'token:label': 'Token',
  'confirm:label': 'Confirm',
  'cancel:label': 'Cancel',
  'proceed:label': 'Continue',
  'correct:label': 'Correct',
  'login:label': 'Input your token here',
  'login:session-label': 'Input the session id here',
  'login-invalid-token:label': 'Wrong data access.',
  'login-invalid-token-message:label':
    'Please check your credentials with your professor and try again!',
  'logout:label': 'Logout',
  'welcome:label': 'Welcome',
  'welcome-team:label': 'Welcome Team',
  'title-faculty-author:label': 'Faculty Author',
  'valterConca-name:label': 'Valter Conca',
  'albertoGrando-name:label': 'Alberto Grando',
  'giorgioLazzaro-name:label': 'Giorgio Lazzaro',
  'severinoMeregalli-name:label': 'Severino Meregalli',
  'admin-username:label': 'Username',
  'admin-password:label': 'Password',
  'valterConca-title:label': 'Faculty Author',
  'giorgioLazzaro-title:label': 'Faculty Author',
  'severinoMeregalli-title:label': 'Faculty Author',
  'albertoGrando-title:label': 'Faculty Author',
  'loading:label': 'Loading',
  'dashboard:label': 'Control panel',
  'uploader:label': 'uploader',
  'chapters:label': 'Steps',
  'team:label': 'Team',
  'tot:label': 'Total',
  'overview-chapter:label': 'Step Overview',
  'kpiAttempts:label': 'Attempts:',
  'kpiTotalRetries:label': 'Total Attempts:',
  'totalRetries:label': 'Total Step Attempts',
  'notActiveChapters:label': 'No active step',
  'adminTimeline:label': 'Admin Timeline',
  'footer-email:label': 'learninglab@sdabocconi.it',
  'onboarding-title:label': 'Welcome to EuroFire Team <b>{teamName}!</b>',
  'onboarding-team-name:label': 'Team name',
  'onboarding-team-number:label': 'Before you begin, enter your team members:',
  'onboarding-team-members:label': 'Members',
  'onboarding-member-name:label': 'First Name',
  'onboarding-member-surname:label': 'Last Name',
  'onboarding-member-email:label': 'E-mail',
  'onfirm-button:label': 'Confirm',
  'onboarding-incomplete:label': 'Complete all fields in red to continue.',
  'onboarding-wrong-email:label': 'Enter a valid email address to continue.',
  'onboarding-confirm-text:label':
    "Attention! You will not be able to change the team's composition after confirmation",
  'onboarding-confirm-popup:label':
    '<b> Attention! </b> <br> Changes will not be possible after confirmation. Please check that the data you entered are correct.',
  'scenario-notes:label':
    'Briefly describe the key points and main considerations after analyzing this scenario.',
  'send-button:label': 'Submit',
  'scenario-notes-confirm-popup:label':
    '<b> Attention! </b> <br> Changes will not be possible after confirmation.',
  'kpi-extended-title:label': 'Key Performance Indicators',
  'chapter-p:label': 'Prologue: a cold December night',
  'chapter-prologue:label': 'Prologue: a cold December night',
  'chapter-0:label': 'Context: EuroFire analysis',
  'chapter-1:label': 'Step 1: ItalInox financial statement',
  'chapter-2:label': 'Step 2: extending the consolidated accounts',
  'chapter-3:label': 'Step 3: potential synergies',
  'chapter-4:label': 'Step 4: financial assessment',
  'chapter-epilogue:label': 'Epilogue: upload the job',
  'chapter-e:label': 'Epilogue: upload the job',
  'team-report:label': 'Team Report',
  'chapter-p-menu:label': 'P',
  'chapter-0-menu:label': 'C',
  'chapter-1-menu:label': '1',
  'chapter-2-menu:label': '2',
  'chapter-3-menu:label': '3',
  'chapter-4-menu:label': '4',
  'chapter-e-menu:label': 'E',
  'video-prologue:label': 'A cold December night',
  'doc-prologue:label': 'A cold December night',
  'video-contesto:label': 'EuroFire: the manufacturing process',
  'doc-contesto:label': 'EuroFire: the competitive environment',
  'doc-bilanci-italinox:label': 'ItalInox financial statement',
  'doc-prospettiva:label': 'Medium-term outlook',
  'doc-produzione:label': 'Production costs',
  'doc-sistema-produttivo:label': 'EuroFire: the manufacturing process',
  'doc-capacita:label': 'Production capacity',
  'doc-effetto-kit:label': 'Business integration: the kit effect',
  'doc-ristrutturare:label': 'Restructuring production',
  'doc-immagine:label': 'Branding',
  'doc-prezzi:label': 'Pricing',
  'doc-valutazione-finanziaria:label': 'Financial assessment',
  'uploadExcel:label': 'Upload the Excel spreadsheet',
  'uploadPresentation:label': 'Upload the presentation',
  'table-team:label': 'Team',
  'table-kpi:label': 'Kpi',
  'table-scenario:label': 'Scenario',
  'table-scenarios:label': 'Scenarios',
  'consolidato:label': 'Consolidated accounts',
  'estensione:label': 'Extension',
  'costi-produzione:label': 'Production costs',
  'capacita-produttive:label': 'Production capacity',
  'estensione-costi-produzione:label': 'Extension + Production costs',
  'estensione-effetto-kit:label': 'Extension + Kit effect',
  'estensione-capacita-produttive:label': 'Extension + Production capacity',
  'estensione-costi-produzione-effetto-kit:label':
    'Extension + Production costs + Kit effect',
  'estensione-capacita-produttive-effetto-kit:label':
    'Extension + Production capacity + Kit effect',
  'estensione-costi-produzione-capacita-produttive:label':
    'Extension + Production costs + Production capacity',
  'estensione-costi-produzione-capacita-produttive-effetto-kit:label':
    'Extension + Production costs + Production capacity + Kit effect',
  'non-ristrutturare:label': 'No restructuring',
  'non-ristrutturare-progetto-revisione-prezzi:label':
    'No restructuring + Pricing',
  'non-ristrutturare-progetto-immagine:label': 'No restructuring + Branding',
  'ristrutturare:label': 'Restructuring',
  'ristrutturare-progetto-immagine:label': 'Restructuring + Branding',
  'scenario-3.6:label': 'Restructuring + Pricing',
  'net-present-value:label': 'Present Value',
  'net-present-value-admin:label': 'Present Value',
  'net-present-value-error:label': 'Present Value (wrong method)',
  'net-present-value-con-disinvestimento:label': 'Present Value',
  'net-present-value-con-disinvestimento-admin:label':
    'Present Value with divestment',
  'ristrutturare-progetto-revisione-prezzi:label': 'Restructuring + Pricing',
  'kpi-sales-year1:label': 'Turnover',
  'kpi-revenues-year1:label': 'Profit',
  'kpi-sales-sum:label': 'Aggregate turnover',
  'kpi-revenues-sum:label': 'Aggregate profit',
  'kpi-market-share-ovens:label': 'Market Share Year 4 - Cooktops',
  'kpi-market-share-sinks:label': 'Market Share Year 4 - Sinks',
  'kpi-max-saturation-ovnes:label':
    'Saturation of the most saturated division - Cooktops',
  'kpi-max-saturation-sinks:label':
    'Saturation of the most saturated division - Sinks',
  'kpi-pv:label': 'Present Value',
  'kpi-pv-disinvestment:label': 'Present Value Review',
  'fatturato-anno-1:label': 'Turnover',
  'utile-anno-1:label': 'Profit',
  'fatturato-cumulato:label': 'Consolidated Turnover',
  'utile-cumulato:label': 'Consolidated Profits',
  'quota-mercato-forni-anno-4:label': 'Market Share Year 4 - Cooktops',
  'quota-mercato-lavelli-anno-4:label': 'Market Share Year 4 - Sinks',
  'livello-saturazione-reparto-piu-saturo-forni:label':
    '% Saturation Most Saturated Dept. - Cooktops',
  'livello-saturazione-reparto-piu-saturo-lavelli:label':
    '% Saturation Most Saturated Dept. - Sinks',
  'upload-excel:label': 'Upload the Excel spreadsheet',
  'upload-slides:label': 'Upload the presentation',
  'upload-excel-task:label':
    'Upload the final version of the Excel file containing your analysis in the field below.',
  'upload-excel-howto:label':
    'To download the Excel file: <b>File > Save as > Download a copy<b/>',
  'upload-slides-task:label':
    'Upload the final version of the Presentation containing your analysis in the field below.',
  'upload-button:label': 'Upload',
  'upload-request:label': 'Click on the icon to download the file',
  'upload-success:label': 'UPLOAD COMPLETED SUCCESSFULLY',
  'upload-fail:label': 'UPLOAD FAILED',
  'upload-dropbox-excel:label': 'Drag and drop your Excel file here',
  'upload-dropbox-slides:label': 'Drag and drop your file here',
  'upload-dropbox-or:label': 'or',
  'upload-dropbox-pick:label': 'Select file',
  'upload-dropbox-invalid:label': 'The uploaded file is not valid.',
  'upload-dropbox-excel-format:label':
    'check that your file is in .xlsx format',
  'upload-dropbox-slides-format:label':
    'check that your file is in .ppt, .pptx or .pdf format',
  'report-view:label': 'View',
  'report-send:label': 'Submit',
  'report-sended:label': 'Sent',
  'thankyou-title:label': 'Thanks for your effort!',
  'thankyou-message:label':
    'Your task is completed. EuroFire S.p.A. is now aware of the strategy options ahead and your input will allow it to make suitable decisions!',
  'thankyou-report:label':
    'Below is a letter confirming the work you have completed.',
  'admin-global:label': 'Global',
  'admin-chapter-prologue:label': 'Prologue',
  'admin-chapter-0:label': 'Context',
  'admin-chapter-1:label': 'Step 1',
  'admin-chapter-2:label': 'Step 2',
  'admin-chapter-3:label': 'Step 3',
  'admin-chapter-4:label': 'Step 4',
  'admin-chapter-epilogue:label': 'Epilogue',
  'admin-tot-time:label': 'Total',
  'admin-num-tries:label': 'number of attempts',
  'admin-tot-tries:label': 'Total Attempts',
  'admin-chapter-tot-tries:label': 'Total Step Attempts',
  'ouch-LowRes:label': 'Ouch!',
  'lowres:label': '<b><span class="lowres-error-message">Attention! EuroFire does not support the screen resolution of your device.</span></b><br/><small>Please access the app from a desktop or tablet device with screen resolution no lower than 600x600px</small>',
  'notSupported:label':
    'EuroFire does not support the screen resolution of your device.',
  'suitableDevice:label':
    'Please access the app from a desktop or tablet device with width resolution no lower than 600px',
  'onboarding-confirm-button:label': 'Confirm',
  'scenario-notes-confirm-button:label': 'Confirm',
  'onboarding-submit:label':
    '<b> Attention! </b> <br> Changes will not be possible after the content has been confirmed. Please check that the data you entered are correct.',
  'admin-confirm-button:label': 'Confirm',
  'admin-submit-popup:label': '<b>Attention!</b>',
  'admin-submitA-popup:label':
    'The following action will send the report and the excel file by email to all team members in Team',
  'admin-submitA-global-popup:label':
    'The following action will send the report and the excel file by email to all participants',
  'admin-submitB-popup:label': '.<br/>Confirm to send.',
  'members:label': 'Members',
  'member-name:label': 'First Name',
  'member-lastname:label': 'Last Name',
  'member-email:label': 'E-mail',
  'chapter-notes:label': 'Add your comments here.',
  'greetings:label':
    "<b class='greetings-label'>Congratulations!</b><br>You have completed your tasks.",
  'eurofire-knows:label':
    '<small>EuroFire S.p.A. is now aware of the strategy options ahead and your efforts now allow them to take the best decisions.</small>',
  'admin-login:label': 'Admin login',
  'report-location:label': 'Castelfranco Veneto',
  'report-doctor:label': 'Dott. E. Callegari',
  'report-eurofireAddress:label':
    'EuroFire S.p.A. \n Viale Piave, 12 \n Castelfranco Veneto 31033 - Treviso',
  'report-firstPartMessageA:label': 'Spett.le ',
  'report-firstPartMessageB:label': 'Dear Team',
  'report-firstPartMessageC:label': 'Via Sarfatti, 10 - Milano',
  'report-secondPartMessage:label':
    'We truly thank you for your valuable work, which contributed to improve our method and increased our awareness for the future decisions ahead. \n \n \n In view of a final assessment, we outline the main outcomes from the analysis for each individual step.',
  'reportSign:label':
    'We thank you again for your methodological support to this project. Looking forward to future collaboration, we send you our best regards.',
  'reportSign2:label': 'Castelfranco Veneto',
  'reportSign3:label': 'Dott. E. Callegari',
  'reportNotes:label':
    'Key points and main considerations after analyzing this scenario:',
  'contributionTeam:label': 'Team input ',
  'contributionTeamOn:label': ' on ',
  'notYetInsertedMessage:label': 'Message not entered yet!',
  'waitingAssetsActivation:label': 'Waiting for asset activation',
  'sectionKindNotFound:label': 'Section Kind not found! Call tech support!',
  'eurofireTooltipContext:label':
    "<p>EuroFire has just sent additional documents on its <b style='color:#2764FF'>processes, product and corporate information.</b></p><p>Click here to view!</p>",
  'eurofireTooltipChapter1:label':
    "<p>We have just received <b style='color:#2764FF'>ItalInox's financial statement </b>from Studio Cravero, you will find it on your desk.</p><p>Click here to view!</p>",
  'eurofireTooltipChapter2:label':
    "<p>Now it's time to assess what will happen in the next period: click here to view information that can be useful for the <b style='color:#2764FF'>extended consolidated financial statement.</b></p>",
  'eurofireTooltipChapter3:label':
    "<p>It's time to analyze some <b style='color:#2764FF'>potential business strategies.</b><br /><br />Click here to go to the relevant step.</p>",
  'eurofireTooltipChapter4:label':
    "<p>Now it is also available <b style='color:#2764FF'>the analysis on the financial dimensions.</b><br /><br />Click here to go to the relevant step.</p>",
  'eurofireTooltipChapter5:label':
    "<p>EuroFire is looking forward to the <b style='color:#2764FF'>outcomes of your analysis.</b><br /><br />Click here to access the area where to submit your documents</p>",
  'eurofireTooltipExcelButton:label':
    "<p>Clicking on this button you can view the <b style='color:#39CB85'>shared Excel spreadsheet</b> for your analysis.</p><p>This is the reference document to be used for your work and to be delivered at the end of your analysis.</p>",
  'eurofireTooltipFirstTask:label':
    "<p><b>Here's your first task:</b><br />Input the <b style='color:#6C39D8'>KPIs</b> from your analysis in the card below. Separators for thousands and decimals are not required when inputing them. <br/>If the inserted value is correct, you will see a green mark. Invalid values will be marked in red.</p>",
  'eurofireTooltipNotes:label':
    "<p>To complete the analysis scenario, add a short comment to KPIs with your <b style='color:#FF8744'>key points</b>. When the text is complete, press the “SUBMIT” button that will appear below on the right.</p>",
  'eurofireTooltipNotesComplex:label':
    "<p><b>Please enter some <b style='color:#FF8744'>key points</b> for each analyzed scenario in the field below:</b><br /> the orange bar will show the relevant comment field at any moment. After sending your comment, a flag will appear to confirm that your text has been submitted.</p>",
  'eurofireTooltipEpilogueExcel:label':
    "<p>Use the upload field to upload the <b style='color:#39CB85'>Excel file</b> containing your analysis after downloading it from the Excel interface <b>(File > Save as > Download a copy)</b>.</p>",
  'eurofireTooltipEpilogueBoth:label':
    "<p>Use the upload field on the left to upload the <b style='color:#39CB85'>Excel file</b> containing your analysis, after downloading it from the Excel interface <b>(File > Save as > Download a copy)</b>. Upload the <b style='color:#FF8744'>presentation</b> using the right field.</p>",
  'eurofireTooltipDocuments:label':
    "<p>White cards like this one are <b style='color:#CF0000'>documents</b> of the business case.<br /><br />Click on the card to view the full content, zoom or download it.</p>",
  'sandyPresentation:label':
    '<p><b>Nice to meet you, my name is Spark!</b><br />I will assist you during all your tasks. It will be my pleasure to present you the working tools and notify any new piece of available information.</p>',
  'sandyIntroduction:label':
    "<p>Click on the play button on the card on my left to view the <b style='color:#CF0000'>introductory video</b> by EuroFire.<br /><br />Mr. Callegari is called upon to make a delicate decision for his company. It will be your duty to help him in the choice.</p>",
  'team-report-title:label': 'Team Report',
  'email-subject': 'EuroFire - Team ',
  'email-subject-b': ', your report',
  'email-body':
    "<html><body><p>Dear {name},</p><p>Thanks for participating in our EuroFire session.</p><p>You have completed all your tasks and you can now view and download to your <a href='{report}'>Team's Report</a>.</p><p>We would like to also grant you access to the <a href='{excel}'>MS Excel file</a> that enabled you and your Team to achieve the desired goal.</p><p>We wish you all the best for your future career.</p><p>Valter Conca, Alberto Grando, Giorgio Lazzaro, Severino Meregalli,<br/>together with the Learning Lab Team</p><div class='footer' style='margin-top:15px;'><a href='https://www.sdabocconi.it/en/faculty-research/learning-process/learning-lab' target='_blank'><img src='https://learninglab.sdabocconi.it/files/simulations/logo-llab-positive.png' width='220' /></a></div></body></html>",
  'email-phase4Offer-subject': 'A message from Sergio Callegari - EuroFire',
  'email-phase4Offer-popup-title': 'A message from Sergio Callegari',
  'email-phase4Offer-body':
    "<html><body><p>My dear friends,</p><p>I’ve just received a proposal from Financial Partners Advisors, which I would like to share with you,<br/>which on behalf of China Kitchen Furniture Group proposing a non-negotiable offer for €110m. (enterprise value) to buy my company.</p><p>This is the moment in which I need the most your professional support in order to come to a fully informed critical decision.</p><p>I’d like to ask you to think about this opportunity, before organizing a meeting to evaluate the various options we have before us.</p><p>Sincerely yours,</p><p>Sergio Callegari<br/>CEO, EuroFire</p></body></html>",
  'email-phase4Offer-popup-signature': "<div class='footer' style='margin-top:15px;'><img src='https://learninglab.sdabocconi.it/files/simulations/logo-eurofire.png' width='200' /></div>",
  "old-correct-label": "Old correct value",
  'phase4Offer-popup-other-message': '<p>During the strategic business planning, strictly related to Italinox’s integration, an important  letter appeared which could change the whole scenario: the China Kitchen Furniture Group, interested in entering in the Kitchen market, has made an offer for €110m. (enterprise value) to buy  EuroFire.</p>',
  'team-phase4Offer:label': 'China K.F. Gr. offer',
  'phase4Offer-send:label': 'Send',
  'phase4Offer-sended:label': 'Sent',
  'admin-phase4Offer-submit-popup:label': '<b>Attention.</b>',
  'admin-phase4Offer-submitA-popup:label':'Please note that you will send an e-mail containing the proposal of the China Kitchen Furniture Group to the participants.<br/>Are you sure?',
  'admin-phase4Offer-submitB-popup:label': ' team members. Are you sure?',
  'admin-phase4Offer-confirm-button:label': 'Confirm',
  'language-en': 'ENG',
  'language-it': 'ITA',
  'without-investment':'Without',
  'with-investment':'With',
  'admin-sessions-section-title': 'Sessions manager',
  'admin-sessions-selected': 'Selected session',
  'admin-sessions-archive': 'Archive',
  'admin-sessions-see-params': 'See parameters',
  'admin-sessions-edit-params': 'Edit parameters',
  'admin-sessions-select-params': 'Select parameters',
  'admin-sessions-create-params': 'Create parameters',
  'admin-sessions-parameters': 'Parameters',
  'admin-sessions-media': 'Media',
  'admin-sessions-add-params': 'Add set',
  'admin-sessions-global': 'Global',
  'admin-sessions-personal': 'Personal',
  'admin-sessions-label-search': 'Search by label',
  'admin-sessions-from': 'from:',
  'admin-sessions-to': 'to:',
  'admin-sessions-active-list': 'Active Sessions',
  'admin-sessions-history-list': 'History',
  'admin-sessions-date': 'Date',
  'admin-sessions-date-creation': 'Date of creation',
  'admin-sessions-name': 'Name',
  'admin-sessions-session': 'Session',
  'admin-sessions-session-id': 'Session ID',
  'admin-sessions-closed': 'closed',
  'admin-sessions-terminate': 'End',
  'admin-sessions-owner': 'Owner',
  'admin-sessions-teachers': 'Teachers',
  'admin-sessions-cancel': 'Cancel',
  'admin-sessions-visibility': 'Visibility',
  'admin-sessions-default': 'Default',
  'admin-sessions-create': 'Create',
  'admin-sessions-delete': 'Delete',
  'admin-sessions-clone': 'Clone',
  'admin-sessions-phase': 'Phase {phase}',
  'admin-sessions-ask-param-selection': "Select a parameter set",
  'admin-sessions-confirm-selection': 'Confirm selection',
  'admin-sessions-confirm-edit': 'Confirm edits',
  'admin-sessions-archive-generation': 'Generating archive',
  'admin-sessions-feedback-no-params': "No parameter set found. Create a new set or change the chosen filters.",
  'admin-sessions-feedback-no-session-past': "No past session found.",
  'admin-sessions-feedback-no-session-active': "No active session found.",
  'admin-sessions-feedback-no-param': "No parameter found.",
  'admin-sessions-feedback-no-media': "No video or document found.",
  'admin-sessions-feedback-impossible-range': "You have chosen a starting date later then the ending date. Change the chosen filters.",
  'admin-sessions-feedback-all-media-params': "Set all paramters and media",
  'admin-sessions-feedback-all-params': "Set all parameters",
  'admin-sessions-feedback-all-media': "Set all media",
  'admin-sessions-feedback-all-file': "Set all files",
  'admin-sessions-feedback-file-size': "File size too large",
  'admin-sessions-feedback-original-deleted': "Original parameter set deleted",
  'admin-sessions-feedback-no-param-set': "Warning! The selected session isn't has no associated parameter set.",
  'admin-sessions-info-session': "In this box you will find information related to the currently selected session. It's possible to choose a session from the two boxes below, <b>Active Sessions</b> and <b>History</b>. Once a session is selected, you can view and/or modify the associated parameter set or terminate the session. Terminated sessions are located in the history.",
  'admin-sessions-info-global': "In the <b>Global</b> section, you will find parameter sets formally defined by the simulation owners. By clicking on an individual entry in the list below, you can view the details of the parameter set and clone it. Clicking the button in the top right allows you to create a new parameter set. You can also filter parameter sets by name, creator, and the creation date associated with the parameter set.",
  'admin-sessions-info-personal': "In the <b>Personal</b> section, you will find your newly created and/or cloned parameter sets. By clicking on an individual entry in the list below, you can view the details of the parameter set and clone it. Clicking the button in the top right allows you to create a new parameter set. You can also filter parameter sets by name, creator, and the creation date associated with the parameter set.",
  'admin-sessions-info-params': `
    In this section you can see the selected paramter set.
    A parameter set consists of KPIs and medias organized by phase.
    Paramter sets are immutable and is necessary to clone them to make edits.
    The deletion of a parameter set is possible only if they aren't associated to a game session.

    It's possible to change the visibility of a paramter set, from global to personal.
    It's possible to define a default parameter set; which will be used automatically
    when creating a new session. If a personal default exists it will be prioritized
    over the global default.
    <br />
    <br />
    In the <b>Parameters</b> section is the list of the couples KPI-scenario organized
    by phase and the related values.
    <br/>
    <br/>
    In the <b>Media</b> section is the list of the videos and documents
    organized by phase and language. Every document is composed by two couples
    of files word-pdf in the respective languages.
    During cloning or creation is possible to edit individual media clicking on the pencil icon.
  `,
  'admin-sessions-create-params-subtitle': "Choose a name for this parameter set and decide whether they are to be set as global and/or default.",
  'admin-sessions-params-name': 'Parameters name',
  'admin-sessions-show-info': 'Click here for more information',
  'admin-sessions-save-params': 'Save parameters',
  'admin-sessions-save-subtitle': 'Choose a name for the set',
  'admin-sessions-yes': 'Yes',
  'admin-sessions-no': 'No',
  'admin-sessions-upload': 'Uplaod file',
  'admin-sessions-chapter-title-it': 'Chapter title in italian',
  'admin-sessions-chapter-title-en': 'Chapter title in english',
  'admin-sessions-upload-it': 'Upload {type} file in italian',
  'admin-sessions-upload-en': 'Upload {type} file in english',
  'admin-sessions-video-title': 'Video title',
  'admin-sessions-video-link': 'Video link',
  'admin-sessions-video-title-it': 'Video title in italian',
  'admin-sessions-video-title-en': 'Video title in english',
  'admin-sessions-video-link-it': 'Video link in italian',
  'admin-sessions-video-link-en': 'Video link in english',
  'admin-sessions-feedback-protocol': 'The link must contain a valid protocol (http or https).',
  'admin-sessions-set-video': "Set video",
  'admin-sessions-feedback-all-video': "Missing video",
  'admin-sessions-phase:Prologo': "Prologue",
  'admin-sessions-phase:Contesto': "Context",
  'admin-sessions-phase:Fase 1': "Phase 1",
  'admin-sessions-phase:Fase 2': "Phase 2",
  'admin-sessions-phase:Fase 3': "Phase 3",
  'admin-sessions-phase:Fase 4': "Phase 4",
  'admin-sessions-phase:Epilogo': "Epilogue",
  'admin-sessions-confirm-terminate-title': "Confirm termination",
  'admin-sessions-confirm-terminate-content': "Are you sure you want to end the session?",
};
