import React from 'react';
import './style.scss';
import Image from '../../Image';

const AdminOverviewModalButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({disabled, onClick}) => {
  return (
    <button className="AdminOverviewModalButton" disabled={disabled} onClick={onClick}>
      <Image name={disabled ? "adminOverviewModalIconOff" : "adminOverviewModalIconOn"} ></Image>
    </button>
  );
};

export default AdminOverviewModalButton;