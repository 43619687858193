import React, { useContext } from 'react';
import { PlayerContext } from '../../context';
import Prologue from '../Prologue';
import Epilogue from '../Epilogue';
import ChapterMain from '../ChapterMain';
import ChapterDocuments from '../ChapterDocuments';
import './style.scss';
import { injectIntl } from 'react-intl';
import { EChapterKind } from '../../interfaces';

interface Props {
  intl: any;
}

const Content: React.FC<Props> = ({ intl }) => {
  const { chapter } = useContext(PlayerContext);
  if (chapter) {
    switch (chapter.kind) {
      case EChapterKind.PROLOGUE:
        return <Prologue />;
      case EChapterKind.EPILOGUE:
        return <Epilogue />;
      case EChapterKind.SCENARIOS_MATRIX:
        return chapter.scenarios.filter(scenario => scenario.active).length >
          0 ? (
          <ChapterMain />
        ) : (
          <ChapterDocuments />
        );
      case EChapterKind.KPIS_MATRIX:
        return chapter.kpis &&
          chapter.kpis.filter(kpi => kpi.active).length > 0 ? (
          <ChapterMain />
        ) : (
          <ChapterDocuments />
        );
      default:
        return (
          <div className="Content">
            {intl.formatMessage({ id: 'sectionKindNotFound:label' })}
          </div>
        );
    }
  } else {
    throw new Error('chapter not found!');
  }
};

export default injectIntl(Content);
