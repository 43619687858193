import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import './style.scss';


interface Props {
  intl: InjectedIntl;
  lang: string;
  changeLang: Function
}



const LowResMessage: React.FC<Props> = ({ intl, changeLang, lang }) => {


  
  return (
      <div className="langs">
        <div
          className={`lang ${lang === 'it' ? 'active' : ''}`}
          onClick={() => changeLang('it')}>
          {intl.formatMessage({ id: 'language-it' })}
          </div>
          |
          <div
          className={`lang ${lang === 'en' ? 'active' : ''}`}
          onClick={() => changeLang('en')}>
          {intl.formatMessage({ id: 'language-en' })}
          </div>
      </div>
  );
};

export default injectIntl(LowResMessage);
