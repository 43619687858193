import React, { useState } from 'react';
import Modal from '../../SessionsModal';
import './style.scss';
import Image from '../../Image';
import { CreateAssetInput, AssetType } from '../../../api';
import { assetsService } from '../../../utilities/assetsService';
import { v4 as uuidv4 } from 'uuid';
import { FilesData, MediaInput, DocumentData } from '../../Media/data-templates';
import { ParameterInput } from '../../../utilities/parametersTemplate';
import { storageS3 } from '../../../utilities/s3storage';
import { AdminContext } from '../../../context';
import { IUserInfo } from '../../../utilities/auth-services';
import Selector from '../../Selector';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';

interface Props {
    close: (keepData?: boolean) => void,
    goBack: () => void,
    inputs: SaveParameterSetInput,
    intl: InjectedIntl,
}

export interface SaveParameterSetInput {
    parametersId?: string,
    parameters: ParameterInput,
    mediaIdList?: string[],
    mediaList: MediaInput[],
    documentsMap: Map<string, FilesData>,
    groupId?: string,
}

export enum Operation {
    clone,
    create,
}

const saveParameterSet = async (
    inputs: SaveParameterSetInput,
    owner?: IUserInfo,
    progressCallback?: (progress: number, total: number) => void,
) => {

    // Create a group id if it doesn't exist i.e. this is not a clone
    if (inputs.groupId === undefined) inputs.groupId = uuidv4()

    let totalFiles = 0;
    inputs.documentsMap.forEach((docs) => {
        Object.entries(docs).forEach((doc) => {
            if (doc !== undefined) totalFiles += 1;
        })
    })

    let uploadsDone = 0;

    const fileUploads: Promise<void>[] = inputs.mediaList
    .flatMap((media) => {
        if (media.type !== AssetType.document) return [];
        const documents = inputs.documentsMap.get(media.data.id);

        return (documents !== undefined) 
        ? Object.entries(documents)
          .filter((doc) => {
            const [, file] = doc;
            return (file !== undefined) && (file !== null)
          })
          .map(async (doc) => {
            const [name, file] = doc as [string, File];

            try {
                const fileKey = await storageS3.uploadFile(
                  `media/${inputs.groupId!}`, `${media.data.id}-${name}`, file, file.type
                )

                const data = media.data as DocumentData;
                if (name === 'pdfIta') data.url = fileKey;
                else if (name === 'pdfEng') data.urlEng = fileKey;
                else if (name === 'wordIta') data.urlWord = fileKey;
                else if (name === 'wordEng') data.urlEngWord = fileKey;
                media.data = data;

            } catch {
                console.error(`Error while uploading file ${media.data.id}-${name}, skipping`);
            }

            uploadsDone += 1;
            if (progressCallback) progressCallback(uploadsDone, totalFiles);
        })
        : []
    })

    try {
      await Promise.all(fileUploads)
    } catch (error) {
      // TODO delete folder
      console.error('Error uploading files', error);
      return Promise.reject(error);
    };

    // Now we create the asset inputs for a create operation
    const now = new Date();

    console.log(JSON.stringify(owner))

    const parameters: CreateAssetInput = {
        name: inputs.parameters.name,
        default: inputs.parameters.default,
        global: inputs.parameters.global,
        type: AssetType.parameter,
        groupId: inputs.groupId!,
        data: JSON.stringify(inputs.parameters.data),
        date: now.toISOString(),
        owner: JSON.stringify(owner), 
    }

    const mediaList: CreateAssetInput[] = inputs.mediaList.map((media) => {
        return {
            ...media,
            groupId: inputs.groupId!,
            data: JSON.stringify(media.data)
        }
    });

    // We are done
    assetsService.createAssets([parameters, ...mediaList])
}

const ConfirmationModal: React.FC<Props> = (
    { close, goBack, inputs, intl }
  ) => {

    const owner = React.useContext(AdminContext);
    
    const [name, setName] = useState<string>(inputs.parameters.name ?? "");
    const [isGlobal, setIsGlobal] = useState<boolean>(inputs.parameters.global ?? false);
    const [isDefault, setIsDefault] = useState<boolean>(inputs.parameters.default ?? false);

    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
  
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement> ) => {
      setName(event.currentTarget.value);
    }
  
    const saveData = async () => {
      setLoading(true);
      inputs.parameters.name = name;
      inputs.parameters.global = isGlobal;
      inputs.parameters.default = isDefault;
  
      // if we save as default we need to check for uniqueness
      if (isDefault) {
        const others = await assetsService.getAssets({type: AssetType.parameter})

        const otherDefaults = others.filter(asset =>
            asset.default
            && asset.global === isGlobal
        );
        
        if (otherDefaults.length !== 0) {
          // Tecnically this array should have lenght of 1
          if (otherDefaults.length !== 1) {
            console.warn("Mutiple defaults found, will update them all, but this shouldn't happen");
          }
  
          const promises: Promise<any>[] = otherDefaults.map(async defaultAsset => {
            const defAsset = await assetsService.getAsset(defaultAsset.id);
            if (!defAsset) {
              console.error("Update failed, could not find default asset")
              return;
            }
            assetsService.updateAsset(
              {
                id: defAsset.id,
                groupId: defAsset.groupId,
                type: defAsset.type,
                name: defAsset.name,
                date: defAsset.date,
                data: defAsset.data!,
                default: false,
                global: defAsset.global
              }
            );
          });
          await Promise.all(promises);
        }
      }
  
      await saveParameterSet(inputs, owner.userInfo, (progress, total) => {
        setProgress(progress / total)
      })
  
      close(true)
    }

    return (
      <Modal onClose={loading ? () => {} : close}
      title={intl.formatMessage({id: "admin-sessions-create-params"})}
      subtitle={intl.formatMessage({id: "admin-sessions-create-params-subtitle"})}
      >
        <div className="save-modal">
          <div className="save-modal-content">
            <input className="input" placeholder={intl.formatMessage({id: 'admin-sessions-params-name'})}
            value={name} onChange={handleNameChange} />
          </div>

          { !loading && <div className='save-options'>
            <div className="save-option">
                <label>
                    <FormattedMessage id="admin-sessions-visibility" />
                </label>
                <Selector options={[intl.formatMessage({id: "admin-sessions-global"}), intl.formatMessage({id: "admin-sessions-personal"})]}
                onChange={(_, index) => setIsGlobal(index === 0)}
                selected={isGlobal ? 0 : 1}
                />
            </div>
            <div className="save-option">
                <label>
                    <FormattedMessage id="admin-sessions-default" />
                </label>
                <Selector options={[intl.formatMessage({id: "admin-sessions-yes"}), intl.formatMessage({id: "admin-sessions-no"})]}
                onChange={(_, index) => setIsDefault(index === 0)}
                selected={isDefault ? 0 : 1}
                />
            </div>
          </div>}

          { !loading ? <div>
            
            <div className="buttons-save-mode">
              <p className="discard-text" onClick={ goBack }>
                <FormattedMessage id="admin-sessions-cancel" />
              </p>
              <div className="confirm-button" onClick={ saveData }>
                <Image name="confirm" />
                <p className="confirm-text">
                  <FormattedMessage id="admin-sessions-cancel" />
                </p>
              </div>
            </div>
          </div> : <div className='load'>
              <div className="upload-spinner-container">
                <div className="upload-spinner">
                  <svg
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="50" cy="50" r="45" />
                  </svg>
                </div>
                <span>{Math.floor(progress * 100 || 0)} %</span>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  };

  export default injectIntl(ConfirmationModal);
