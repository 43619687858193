/* eslint-disable */

const assets = [
  {
    active: false,
    id: 'doc-prospettiva',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  },
  {
    active: false,
    id: 'video-contesto',
    url: 'https://player.vimeo.com/video/343424720',
    urlEng: 'https://player.vimeo.com/video/343424720',
    kind: 'video'
  },
  {
    active: false,
    id: 'doc-sistema-produttivo',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  },
  {
    active: false,
    id: 'doc-produzione',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  },
  {
    active: false,
    id: 'doc-capacita',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  },
  {
    active: false,
    id: 'doc-effetto-kit',
    url: '',
    urlEng: '',
    kind: 'document',
    previewImage: ''
  }
];
const scenarios = [
  {
    id: 'estensione',
    dependOn: ['doc-prospettiva']
  },
  {
    id: 'estensione-costi-produzione',
    dependOn: ['doc-prospettiva', 'doc-produzione']
  },
  {
    id: 'estensione-effetto-kit',
    dependOn: ['doc-prospettiva', 'doc-effetto-kit']
  },
  {
    id: 'estensione-capacita-produttive',
    dependOn: ['doc-prospettiva', 'doc-capacita']
  },
  {
    id: 'estensione-costi-produzione-effetto-kit',
    dependOn: ['doc-prospettiva', 'doc-produzione', 'doc-effetto-kit']
  },
  {
    id: 'estensione-capacita-produttive-effetto-kit',
    dependOn: ['doc-prospettiva', 'doc-capacita', 'doc-effetto-kit']
  },
  {
    id: 'estensione-costi-produzione-capacita-produttive',
    dependOn: ['doc-prospettiva', 'doc-produzione', 'doc-capacita']
  },
  {
    id: 'estensione-costi-produzione-capacita-produttive-effetto-kit',
    dependOn: [
      'doc-prospettiva',
      'doc-produzione',
      'doc-capacita',
      'doc-effetto-kit'
    ]
  }
];

const kpis = [
  {
    id: 'fatturato-cumulato',
    unit: '€'
  },
  {
    id: 'utile-cumulato',
    unit: '€'
  },
  {
    id: 'quota-mercato-forni-anno-4',
    unit: '%'
  },
  {
    id: 'quota-mercato-lavelli-anno-4',
    unit: '%'
  },
  {
    id: 'livello-saturazione-reparto-piu-saturo-forni',
    unit: '%'
  },
  {
    id: 'livello-saturazione-reparto-piu-saturo-lavelli',
    unit: '%'
  }
];

const matrix = [
  [
    { refId:'fatturato-cumulato-estensione', value: 499281 },
    { refId:'fatturato-cumulato-estensione-costi-di-produzione', value: 499281 },
    { refId: 'fatturato-cumulato-estensione-effetto-kit', value: 547083 },
    null,
    { refId:'fatturato-cumulato-estensione-costi-di-produzione-effetto-kit', value: 547083 },
    { refId:'fatturato-cumulato-estensione-capacita-produttive-effetto-kit',value: 547083 },
    { refId:'fatturato-cumulato-estensione-costi-di-produzione-capacita-produttive',value: 499281 },
    { refId:'fatturato-cumulato-estensione-costi-di-produzione-capacita-produttive-effetto-kit',value: 547083 }
  ],
  [
    { refId:'utile-cumulato-estensione', value: 19455 },
    { refId:'utile-cumulato-estensione-costi-di-produzione',value: 22992 },
    { refId: 'utile-cumulato-estensione-effetto-kit',value: 31364 },
    null,
    { refId:'utile-cumulato-estensione-costi-di-produzione-effetto-kit',value: 33620 },
    { refId:'utile-cumulato-estensione-capacita-produttive-effetto-kit',value: 31364 },
    { refId:'utile-cumulato-estensione-costi-di-produzione-capacita-produttive',value: 22992 },
    { refId:'utile-cumulato-estensione-costi-di-produzione-capacita-produttive-effetto-kit',value: 33620 }
  ],
  [
    { refId:'quota-mercato-anno-4-cottura-estensione',value: 3.6 },
    { refId:'quota-mercato-anno-4-cottura-estensione-costi-di-produzione',value: 3.6 },
    { refId:'quota-mercato-anno-4-cottura-estensione-effetto-kit',value: 4.1 },
    null,
    { refId:'quota-mercato-anno-4-cottura-estensione-costi-di-produzione-effetto-kit',value: 4.1 },
    { refId:'quota-mercato-anno-4-cottura-estensione-capacita-produttive-effetto-kit',value: 4.1 },
    { refId:'quota-mercato-anno-4-cottura-estensione-costi-di-produzione-capacita-produttive',value: 3.6 },
    { refId:'quota-mercato-anno-4-cottura-estensione-costi-di-produzione-capacita-produttive-effetto-kit',value: 4.1 }
  ],
  [
    { refId:'quota-mercato-anno-4-lavelli-estensione',value: 12.5 },
    { refId:'quota-mercato-anno-4-lavelli-estensione-costi-di-produzione',value: 12.5 },
    { refId:'quota-mercato-anno-4-lavelli-estensione-effetto-kit',value: 13.4 },
    null,
    { refId:'quota-mercato-anno-4-lavelli-estensione-costi-di-produzione-effetto-kit',value: 13.4 },
    { refId:'quota-mercato-anno-4-lavelli-estensione-capacita-produttive-effetto-kit', value: 13.4 },
    { refId:'quota-mercato-anno-4-lavelli-estensione-costi-di-produzione-capacita-produttive',value: 12.5 },
    { refId:'quota-mercato-anno-4-lavelli-estensione-costi-di-produzione-capacita-produttive-effetto-kit',value: 13.4 }
  ],
  [
    null,
    null,
    null,
    { refId:'saturazione-reparto-piu-saturo-cottura-estensione-capacita-produttive',value: 79.7 },
    null,
    { refId:'saturazione-reparto-piu-saturo-cottura-estensione-capacita-produttive-effetto-kit',value: 90.8 },
    { refId:'saturazione-reparto-piu-saturo-cottura-estensione-costi-di-produzione-capacita-produttive',value: 79.7 },
    { refId:'saturazione-reparto-piu-saturo-cottura-estensione-costi-di-produzione-capacita-produttive-effetto-kit',value: 90.8 }
  ],
  [
    null,
    null,
    null,
    { refId:'saturazione-reparto-piu-saturo-lavelli-estensione-capacita-produttive',value: 75.3 },
    null,
    { refId:'saturazione-reparto-piu-saturo-lavelli-estensione-capacita-produttive-effetto-kit',value: 80.7 },
    { refId:'saturazione-reparto-piu-saturo-lavelli-estensione-costi-di-produzione-capacita-produttive',value: 75.3 },
    { refId:'saturazione-reparto-piu-saturo-lavelli-estensione-costi-di-produzione-capacita-produttive-effetto-kit',value: 80.7 }
  ]
];

export default {
  assets,
  scenarios,
  kpis,
  matrix
};
