/**
 * @todo
 */

import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { string } from 'prop-types';
import React from 'react';
import HtmlText from './HtmlText';
import IMAGE_CUSTOMER_LOGO_CENTER from './images/customer-logo-center.png';
import IMAGE_CUSTOMER_LOGO_LEFT from './images/customer-logo-left.png';
import IMAGE_CUSTOMER_LOGO_RIGHT from './images/customer-logo-right.png';
import IMAGE_CONTACT from './images/image-contact.png';
import IMAGE_PLACE from './images/image-place.png';
import IMAGE_PLACEHOLDER from './images/image-placeholder.jpg';
import IMAGE_SIGNATURE from './images/image-signature.png';
import { IGame, EChapterKind, IScenario } from '../../../interfaces';
import { InjectedIntl } from 'react-intl';
import moment from 'moment';

const images = {
  productLogo: string.isRequired,
  customerLogo: string.isRequired,
  placeIcon: string.isRequired,
  contactIcon: string.isRequired,
  signature: string.isRequired
};

type Props = {
    onRender?: () => void,
    intl: InjectedIntl,
    game: IGame,
}

class GameReportDocument extends React.Component<Props> {
  static defaultProps = {
    images: Object.keys(images).reduce(
      (acc, key) => ({ ...acc, [key]: IMAGE_PLACEHOLDER }),
      {}
    )
  };

  getStyles() {
    return StyleSheet.create({
      page: {
        padding: '10mm 20mm 20mm 20mm',
        fontFamily: 'RubikRegular'
      },
      header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: '5mm',
        borderBottom: '1pt solid #94B2FF'
      },
      footerContainer: {
        borderTop: '1pt solid #94B2FF',
        bottom: '0mm',
        marginLeft: '20mm',
        position: 'absolute',
        width: '100%',
        height: '20mm'
      },
      footer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      location: {
        marginTop: '10mm'
      },
      locationText: {
        fontFamily: 'RubikLight',
        fontSize: 11
      },
      sender: {
        marginTop: '5mm'
      },
      senderNameText: {
        fontFamily: 'RubikRegular',
        fontSize: 12
      },
      senderAddressText: {
        marginTop: '1mm',
        // lineHeight: 'normal',
        fontFamily: 'RubikLight',
        fontSize: 11
      },
      recipient: {
        display: 'flex',
        alignSelf: 'stretch',
        textAlign: 'right'
      },
      recipientNameText: {
        fontFamily: 'RubikRegular',
        fontSize: 12
      },
      recipientAddressText: {
        marginTop: '1mm',
        // lineHeight: 'normal',
        fontFamily: 'RubikLight',
        fontSize: 11
      },
      prologue: {
        alignSelf: 'stretch',
        textIndent: '2mm',
        marginTop: '10mm',
        fontFamily: 'RubikLight',
        fontSize: 11,
        paddingBottom: '10mm',
        borderBottom: '1pt solid #94B2FF'
      },
      prologueText: {
        textIndent: '8mm',
        fontSize: 11
      },
      chapter: {
        paddingBottom: '10mm',
        borderTop: '1pt solid #94B2FF'
      },
      chapterTitleText: {
        marginTop: '10mm',
        fontFamily: 'RubikMedium',
        fontSize: 15
      },
      section: {
        marginTop: '5mm',
        marginLeft: '10mm'
      },
      sectionTitleText: {
        fontFamily: 'RubikMedium',
        fontSize: 11
      },
      item: {
        marginTop: '2mm',
        paddingRight: '50mm',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      itemTitle: {
        width: '70mm',
        paddingRight: '5mm'
      },
      itemValue: {
        width: '50mm'
      },
      itemTitleText: {
        fontFamily: 'RubikLight',
        fontSize: 11
      },
      itemValueText: {
        fontFamily: 'RubikLight',
        fontSize: 11
      },
      epilogue: {
        marginTop: '5mm',
        fontFamily: 'RubikLight',
        fontSize: 11
      },
      note: {
        marginTop: '10mm'
      },
      notesCaption: {
        fontFamily: 'RubikLight',
        fontSize: 11,
        textDecoration: 'underline'
      },
      notes: {
        marginTop: '5mm',
        fontFamily: 'RubikLight',
        fontSize: 11
      },
      signatureContainer: {
        marginTop: '10mm',
        paddingBottom: '5mm'
      },
      signature: {
        marginTop: '5mm',
        width: '60mm',
        height: '20mm'
      },
      placeContainer: {
        display: 'flex',
        flexDirection: 'row'
      },
      placeText: {
        fontFamily: 'RubikLight',
        fontSize: 11,
        marginLeft: '2mm',
        color: '#003A69'
      },
      contactContainer: {
        display: 'flex',
        flexDirection: 'row'
      },
      contactText: {
        fontFamily: 'RubikLight',
        fontSize: 11,
        marginLeft: '2mm',
        color: '#003A69'
      },
      logo: {
        height: '10mm'
      },
      icon: {
        height: '5mm'
      }
    });
  }

  getPayload() {
      return {
          location: `${this.props.intl.formatMessage({id: 'report-location:label'})}, ${moment().format('DD.MM.YYYY')}`,
          sender: {
            name: this.props.intl.formatMessage({ id: 'report-doctor:label' }),
            address: this.props.intl.formatMessage({ id: 'report-eurofireAddress:label' })
          },
          recipient: {
            name: `${this.props.intl.formatMessage({ id: 'report-firstPartMessageB:label' })} ${this.props.game.team}`,
            address: this.props.intl.formatMessage({ id: 'report-firstPartMessageC:label' })
          },
          prologue: this.props.intl.formatMessage({ id: 'report-secondPartMessage:label' }),
          epilogue: this.props.intl.formatMessage({ id: 'reportSign:label' }),
          correct: this.props.intl.formatMessage({ id: 'correct:label' }).toLowerCase(),
          notesCaption: this.props.intl.formatMessage({ id: 'reportNotes:label' }),
          
          chapters: this.props.game.chapters.flatMap((chapter) => {
              if (chapter.kind !== EChapterKind.SCENARIOS_MATRIX
              && chapter.kind !== EChapterKind.KPIS_MATRIX) {
                  return [];
              }

              const isScenario = (chapter.kind === EChapterKind.SCENARIOS_MATRIX);

              const sections: IScenario[] = isScenario
                  ? chapter.scenarios : chapter.kpis;

              const newSections = sections.flatMap((section, sectionIndex) => {
                  if (!section.active) return [];
                  const items = isScenario
                      ? chapter.matrix.map(e => e[section.index || 0])
                      : chapter.matrix[section.index || 0];
                  
                  const twoTempts = chapter.kpis.filter(kpi => kpi.active).length > 1;
                  const newItems = items
                      .flatMap(item => {
                          if (item === null) return [];
                          const titleID = isScenario
                              ? item.kpiID : item?.scenarioID;
                          const kpi = chapter.kpis.find(kpi => kpi.id === item.kpiID);
                          if (!kpi) throw new Error('KPI not found');

                          const formatUnitBased = (
                              value: number = 0,
                              unit: string = '€'
                          ) => `${value}${unit === '€' ? 'k €' : ' %'}`;

                          const correctValueIndex = twoTempts ? 0 : 1;

                          return {
                            title: this.props.intl.formatMessage({ id: `${titleID}:label` }),
                            playerValue: formatUnitBased(item.value, kpi.unit),
                            correctValue: item.correctValue
                              ? item.value === item.correctValue
                                ? ''
                                : typeof item.correctValue === 'number'
                                ? item.correctValue !== item.value
                                  ? formatUnitBased(item.correctValue, kpi.unit)
                                  : ''
                                : item.correctValue[correctValueIndex] !== item.value
                                ? formatUnitBased(
                                    item.correctValue[correctValueIndex],
                                    kpi.unit
                                  )
                                : ''
                              : ''
                          };
                      });
                  return {
                    title: this.props.intl.formatMessage({
                      id: `${section.id}${
                        twoTempts && sectionIndex === 0 ? '-error' : ''
                      }:label`
                    }),
                    items: newItems,
                    notes: section.notes || 'N.A.'
                  };
              });

              return {
                  title: this.props.intl.formatMessage({ id: `chapter-${chapter.id}:label` }),
                  sections: newSections
              }
          })
      }
  }

  render() {
    const styles = this.getStyles();

    const payload = this.getPayload();

    return (
      <Document onRender={this.props.onRender}>
        <Page wrap style={styles.page}>
          <View fixed>
            <View style={styles.header}>
              <Image src={IMAGE_CUSTOMER_LOGO_LEFT} style={styles.logo} />
              <Image src={IMAGE_CUSTOMER_LOGO_CENTER} style={styles.logo} />
              <Image src={IMAGE_CUSTOMER_LOGO_RIGHT} style={styles.logo} />
            </View>
          </View>

          <View wrap={false} style={styles.location}>
            <View>
              <Text style={styles.locationText}>
                {payload.location}
              </Text>
            </View>
          </View>

          <View wrap={false} style={styles.sender}>
            <View>
              <Text style={styles.senderNameText}>
                {payload.sender.name}
              </Text>
            </View>
            <View>
              <Text style={styles.senderAddressText}>
                {payload.sender.address}
              </Text>
            </View>
          </View>

          <View wrap={false} style={styles.recipient}>
            <View>
              <Text style={styles.recipientNameText}>
                {payload.recipient.name}
              </Text>
            </View>
            <View>
              <Text style={styles.recipientAddressText}>
                {payload.recipient.address}
              </Text>
            </View>
          </View>

          <View style={styles.prologue}>
            <HtmlText
              styles={styles.prologueText}
              text={payload.prologue}
            />
          </View>
          {payload.chapters.map((chapter, index) => (
            <View key={index} style={styles.chapter}>
              {chapter.sections.map((section, index) => (
                <View wrap={false} key={index}>
                  {index === 0 && (
                    <View>
                      <Text style={styles.chapterTitleText}>
                        {chapter.title}
                      </Text>
                    </View>
                  )}
                  <View style={styles.section}>
                    <View>
                      <Text style={styles.sectionTitleText}>
                        {section.title}
                      </Text>
                    </View>
                    {section.items.map((item, index) => (
                      <View style={styles.item} key={index}>
                        <View style={styles.itemTitle}>
                          <Text style={styles.itemTitleText}>{item.title}</Text>
                        </View>
                        <View style={styles.itemValue}>
                          <Text
                            style={{
                              ...styles.itemValueText,
                              ...(item.correctValue === ''
                                ? { color: '#39CB85' }
                                : {})
                            }}>
                            {item.playerValue}
                          </Text>
                        </View>
                        {item.correctValue !== '' ? (
                          <View style={styles.itemValue}>
                            <Text style={styles.itemValueText}>
                              {payload.correct}:{' '}
                              <Text style={{ color: '#39CB85' }}>
                                {item.correctValue}
                              </Text>
                            </Text>
                          </View>
                        ) : (
                            <View style={styles.itemValue}>
                              <Text style={styles.itemValueText} />
                            </View>
                          )}
                      </View>
                    ))}
                    {section.notes && (
                      <View style={styles.note}>
                        <View>
                          <Text style={styles.notesCaption}>
                            {payload.notesCaption}
                          </Text>
                        </View>
                        <View>
                          <Text style={styles.notes}>{section.notes}</Text>
                        </View>
                      </View>
                    )}
                  </View>
                </View>
              ))}
            </View>
          ))}
          <View wrap={false}>
            <View style={styles.epilogue}>
              <HtmlText
                styles={styles.prologueText}
                text={payload.epilogue}
              />
            </View>

            <View style={[styles.location, { marginTop: '5mm' }]}>
              <View>
                <Text style={styles.locationText}>
                  {payload.location}
                </Text>
              </View>
            </View>

            <View style={styles.signatureContainer}>
              <View>
                <Text style={styles.senderNameText}>
                  {payload.sender.name}
                </Text>
              </View>
              <Image src={IMAGE_SIGNATURE} style={styles.signature} />
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}

export default GameReportDocument;
