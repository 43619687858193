import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { PlayerContext } from '../../context';
import GameReportDocument from './GameReportDocument';

interface Props {
  intl: any;
}

const GameReport: React.FC<Props> = ({ intl }) => {
  const { game } = useContext(PlayerContext);
  if (!game) {
    throw new Error('Not found game');
  }

  return (
    <div>
      {false && (
        <PDFDownloadLink document={
            <GameReportDocument game={game!} intl={intl} />
        }>
          Download
        </PDFDownloadLink>
      )}
      <PDFViewer style={{ width: '50vw', height: '100vh' }}>
            <GameReportDocument game={game!} intl={intl} />
      </PDFViewer>
    </div>
  );
};

export default injectIntl(GameReport);
