import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import WithLoader from '../WithLoader';
import { IGame, ISession } from '../../interfaces';
import Onboarding from '../Onboarding';
import { Redirect } from '../../route';
import { database } from '../../utilities/database';
import { AppContext } from '../../context';
import { assetsService } from '../../utilities/assetsService';
import { ZenObservable } from 'zen-observable-ts';

interface Props {
	match: any;
	history: any;
}

const OnboardingController: React.FC<Props> = props => {
	const { match, history } = props;
	const { session, setSession } = useContext(AppContext);
	const [game, setGame] = useState<IGame>();
	const gameRef = useRef<IGame>();
	gameRef.current = game;
	const [loading, setLoading] = useState(true);
	const { token, gameSessionId } = match.params;

	useEffect(() => {
		let sub: ZenObservable.Subscription | undefined

		(async() => {
			try {
				const asset = await assetsService.getAsset(gameSessionId);

				const session: ISession = {
					...asset,
					data: JSON.parse(asset.data!)
				};
				sub = database.subscribe(
					session!.data.sessionDataId!, `games.${token}`,
					(dbGame) => {
						if (dbGame.team) {
							delete session.__typename;
							setSession!(session);
							setGame(dbGame);
						}
						setLoading(false);
					},
					() => gameRef.current
				);
			}
			catch(err) { setLoading(false); }
		})();

		return () => sub?.unsubscribe();
	},
	[gameSessionId, token, setSession]);

	function updateData(val: any, name: string) {
		switch (name) {
			case 'players': {
				database.set(
					session!.data.sessionDataId!,
					`games.${token}.players`,
					val);
				break;
			}
			case 'team': {
				database.set(
					session!.data.sessionDataId!,
					`games.${token}`,
					{ ...game, team: val });
				break;
			}
		}
	}

	function creationTeamCallback() {
		database.set(session!.data.sessionDataId!,
			`games.${token}`,
			{ ...game, initialized: true }
		);
		history.push(`/player/${gameSessionId}/${token}`);
	}

	return (
		<WithLoader data={loading ? undefined : {}}>

			{(!loading && (!session || !game)) ? (
				<Redirect to={`/enroll?err=1`} />

			) : (
				<WithLoader data={game}>
					{
						game ? (game.initialized ?
							<Redirect to={`/player/${gameSessionId}/${token}`} /> :
							<Onboarding
								game={game}
								updateData={(val: any, name: string) => updateData(val, name)}
								creationTeamCB={() => creationTeamCallback()}
							/>
						) : 
						<Fragment/>
					}
				</WithLoader>
			)}
		</WithLoader>
	);
};

export default OnboardingController;
