import React, { useEffect, useCallback } from 'react';
import './style.scss';
// import Image from '../Image';
interface Props {
  children?: any;
  show: boolean;
  onClose: Function;
}

const Modal: React.FC<Props> = ({ children, show, onClose }) => {

  const handleKeyDown = useCallback(
    (ev) => {
      const { keyCode, wich } = ev;
      if (keyCode === 27 || wich === 27) {
        onClose();
      }
    },
    [onClose]
  );

  //ComponentDidMount
  useEffect(() => document.addEventListener("keydown", handleKeyDown, false), [handleKeyDown]);

  //ComponentWillUnmount
  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false)
    }
  }, [handleKeyDown]);

  return (
    show ? (
      <div className="AdminModal" onKeyPress={(ev) => handleKeyDown(ev)} onClick={() => onClose()}>
        <div className="content" onClick={(ev) => ev.stopPropagation()}>
          {children}
        </div>
      </div >
    ) : null
  )
}

export default Modal;
