import React, { useState, useEffect, useContext } from 'react';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';
import { EChapterKind, ISession, IGame } from '../../interfaces';
import Admin from '../Admin';
import WithLoader from '../WithLoader';
import { AdminContext, AppContext } from '../../context';
import { generateState } from '../../utilities/chapters';
import { UserAuth } from '../../utilities/auth-services';
import { database } from '../../utilities/database';
import { assetsService } from '../../utilities/assetsService';

interface Props { 
  location: any;
  match: any; 
}

const AdminProvider: React.FC<Props> =props => {
  const { match } = props;
  const { userId, gameSessionId } = props.match.params;

  const userInfo = UserAuth.getUserData();
  const ssoUser = userId === undefined ? userInfo.id : userId;
  const location: string = props.location.pathname;
  const isSessionsPage = 
    location.includes('sessions') ? true : undefined;

  const { session, setSession } = useContext(AppContext);
  const [games, setGames] = useState<IGame[]>();

  const gamesRef = React.useRef<IGame[]>();
  gamesRef.current = games;

  useEffect(() => {
    if (session || !gameSessionId) return ; // noop
    
    assetsService.getAsset(gameSessionId).then(asset => {
			const session: ISession = {
				...asset,
				data: JSON.parse(asset.data!)
			};
			setSession!(session);
		})
  }, 
  [session, gameSessionId, setSession]);
  
  useEffect(() => {
    if (session?.data.paramId === undefined) return ; // noop

    const subscription = database.subscribe(
      session!.data.sessionDataId!, 'games',
      (dbGames: any) => {
        const newGames: IGame[] = reduce(
          dbGames,
          (acc: IGame[], game: IGame) => {
            acc.push(game);
            return acc;
          }, []
        );
        setGames(newGames.map(game => generateState(game)));
      },
      () => gamesRef.current
    );
    return () => subscription.unsubscribe();  
  }, 
  [session]);

  const chapters =
    games &&
    uniqBy(
      flatten(games.map(game => game.chapters)).filter(
        chapter =>
          (chapter.kind === EChapterKind.SCENARIOS_MATRIX &&
            chapter.scenarios.some(scenario => scenario.active!!)) ||
          (chapter.kind === EChapterKind.KPIS_MATRIX &&
            chapter.kpis &&
            chapter.kpis.some(kpi => kpi.active!!))
      ),
      'id'
    );
  
  return (
    <WithLoader data={games || isSessionsPage}>
      <AdminContext.Provider 
        value={{ match, games, chapters, userInfo, ssoUser }}
      >
        <Admin/>
      </AdminContext.Provider>
    </WithLoader>
  );
};

export default AdminProvider;
