import React, { useEffect, useState } from 'react';
import './style.scss';
import { MediaInput, VideoData } from './data-templates';
import { AssetType } from '../../api';
import Image from '../Image';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';

interface Props {
  asset   :  MediaInput | undefined
  updateVideo: (key: string, video: MediaInput) => void
  close   : () => void;
  intl: InjectedIntl;
}

const UploadDocuments: React.FC<Props> = ({ close, asset, updateVideo, intl }) => {
  const [italianVideoTitle, setItalianVideoTitle] = useState<string>('');
  const [italianVideoLink, setItalianVideoLink] = useState<string>('');
  const [englishVideoTitle, setEnglishVideoTitle] = useState<string>('');
  const [englishVideoLink, setEnglishVideoLink] = useState<string>('');
  const [isFormFulfilled, setIsFormFulfilled] = useState<boolean>(false)
  const [italianInputError, setItalianInputError] = useState<boolean>(false);
  const [englishInputError, setEnglishInputError] = useState<boolean>(false);


  useEffect(()=>{
    if(asset)
    { 
      const data = asset.data as  VideoData
      setItalianVideoTitle(data.name)
      setEnglishVideoTitle(data.nameEng)
      setItalianVideoLink(data.url)
      setEnglishVideoLink(data.urlEng)
    }
  },[asset])

  useEffect(() => {
    const isFulfilled =
      italianVideoTitle.length > 0 &&
      italianVideoLink.length > 0 &&
      englishVideoTitle.length > 0 &&
      englishVideoLink.length > 0;

    setIsFormFulfilled(isFulfilled);

  }, [italianVideoLink, italianVideoTitle, englishVideoTitle, englishVideoLink]);


  const handleItalianVideoTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItalianVideoTitle(e.target.value);
  };

  const handleItalianVideoLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItalianVideoLink(e.target.value);
  };

  const handleEnglishVideoTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnglishVideoTitle(e.target.value);
  };

  const handleEnglishVideoLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnglishVideoLink(e.target.value);
  };

 const isValidURL = (url: string) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return pattern.test(url);
  }

  const confirmChanges = () => {

    if (!isFormFulfilled) {
      return;
    }
  
    setEnglishInputError(false);
    setItalianInputError(false);
    
    const isItalianValid = isValidURL(italianVideoLink);
    const isEnglishValid = isValidURL(englishVideoLink);
  
    if (isItalianValid && isEnglishValid) {

  
      const data = asset?.data as VideoData;
      const videoData: VideoData = {
        id: data.id,
        chapterIndex: data.chapterIndex,
        index: data.index,
        name: italianVideoTitle,
        nameEng: englishVideoTitle,
        url: italianVideoLink,
        urlEng: englishVideoLink,
      };
  
      updateVideo(data.id, { type: AssetType.video, data: videoData });
      close();
    } else {
      if (!isItalianValid) {
        setItalianInputError(true);
      }
  
      if (!isEnglishValid) {
        setEnglishInputError(true);
      }
    }
  };

 

  return (
    <div className="upload-documents">
      <div className="upload-content">

        {/* title video ita*/}
        <div className="input-container">
          <input
            className="input video"
            placeholder={intl.formatMessage({id: "admin-sessions-video-title"})}
            value={italianVideoTitle}
            onChange={handleItalianVideoTitleChange}
          />
          <p className="input-label">
            <FormattedMessage id="admin-sessions-video-title-it" />
          </p>
          <div className='error-container'></div>
        </div>

         {/* link video ita*/}
        <div className="input-container ">
          <input
            className="input video"
            placeholder={intl.formatMessage({id: "admin-sessions-video-link"})}
            value={italianVideoLink}
            onChange={handleItalianVideoLinkChange}
          />
          <p className="input-label">
            <FormattedMessage id="admin-sessions-video-link-it" />
          </p>
          <div className='error-container'>
          {italianInputError&&  <Image name="warning" className='icon' />}
          {italianInputError&&   <p className='error-text'>
            <FormattedMessage id="admin-sessions-feedback-protocol" />
          </p>}
          </div>
        </div>

         {/* title video eng*/}
        <div className="input-container ">
          <input
            className="input video"
            placeholder={intl.formatMessage({id: "admin-sessions-video-title"})}
            value={englishVideoTitle}
            onChange={handleEnglishVideoTitleChange}
          />
          <p className="input-label">
            <FormattedMessage id="admin-sessions-video-title-en" />
          </p>
          <div className='error-container'></div>
        </div>

         {/*link video eng*/}
        <div className="input-container ">
          <input
            className="input video"
            placeholder={intl.formatMessage({id: "admin-sessions-video-link"})}
            value={englishVideoLink}
            onChange={handleEnglishVideoLinkChange}
          />
          <p className="input-label">
            <FormattedMessage id="admin-sessions-video-link-en" />
          </p>
          <div className='error-container'>
          {englishInputError&&  <Image name="warning" className='icon' />}
          {englishInputError&&   <p className='error-text'>
            <FormattedMessage id="admin-sessions-video-title-it" />
          </p>}
          </div>
        </div>
      </div>

      <div className="button-confirm-container">
        <p className="discard-text" onClick={close}>
           <FormattedMessage id="admin-sessions-cancel" />
        </p>
        <div className={`confirm-button ${isFormFulfilled? '' : 'disabled'}`} onClick={confirmChanges}>
         <p className="confirm-text">
           <FormattedMessage id="confirm:label" />
         </p>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(UploadDocuments);
