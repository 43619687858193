import React, { Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Sound from 'react-sound';
import { AppContext, PlayerContext } from '../../context';
import Image from '../Image';
import './styles.scss';

import { database } from '../../utilities/database';
import { IGame } from '../../interfaces';


interface Props {
}

const Phase4OfferModal: React.FC<Props> = () => {
  const { session } = useContext(AppContext);
  const { game } = useContext(PlayerContext);
  const gameRef = useRef<IGame>();
  gameRef.current = game;
  let modalVisible = false;
  let gameToken = ''

  if (game) {
    gameToken=game.token
    if (game.phase4ModalVisible === true) {
      modalVisible = true;
    }
  }


  const onClose = useCallback(() => {
    database.set(session!.data.sessionDataId!,
      `games.${gameToken}`, 
      { ...gameRef.current, phase4ModalVisible: false });
  }, [session, gameToken]);

  const handleKeyDown = useCallback(
    ev => {
      const { keyCode, wich } = ev;
      if (keyCode === 27 || wich === 27) {
        onClose();
      }
    },
    [onClose]
  );

  //ComponentDidMount
  useEffect(() => document.addEventListener('keydown', handleKeyDown, false), [
    handleKeyDown
  ]);

  //ComponentWillUnmount
  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);


  const modal = (
    <Fragment>
      <Sound
        url="https://learninglab.sdabocconi.it/files/eurofire_material/Incoming_Message.mp3"
        playStatus={"PLAYING"}
        playFromPosition={300}
      />
      <div className="modal Phase4OfferModal" onKeyDown={ev => handleKeyDown(ev)}>
        <div className='content'>
          <div
            className="close-container"
            onClick={() => {
              onClose();
            }}>
            <Image name="closeBlack" className="close" />
          </div>
          <div className='phase4Offer-popup-other-message'><FormattedHTMLMessage id={`phase4Offer-popup-other-message`} /></div>
          <h1><FormattedHTMLMessage id={`email-phase4Offer-popup-title`} /></h1>
          <div className='email-phase4Offer-body'><FormattedHTMLMessage id={`email-phase4Offer-body`} /></div>
        </div>
    </div>
    </Fragment>
  );

  return modalVisible ? modal : null;
};

export default Phase4OfferModal;
