import React, { useState, ReactNode, useEffect } from 'react';
import './style.scss';

type ImageTooltipProps = {
  children: ReactNode;
  text?: string;
  position?: 'top' | 'bottom' | 'left' | 'right'| 'bottom-left';
};

const ImageTooltip: React.FC<ImageTooltipProps> = ({ children, text, position }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [toolTipText, setToolTipText] = useState(false);

  useEffect(()=>{
    if(text!== undefined&&text.length>0){
        setToolTipText(true)
    } else {
        setToolTipText(false)
    }
  },[text])

  const showTooltip = () => {
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  return (
    <div className="image-tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {isVisible && toolTipText && (
       <div className={`tooltip tooltip-${position}`}>
          {text}
        </div>
      )}
    </div>
  );
};

export default ImageTooltip;
