export default {
  'eurofire:label': 'EuroFire',
  'interactive-business-case:label': 'Interactive Business Case',
  'token:label': 'Token',
  'confirm:label': 'Conferma',
  'cancel:label': 'Annulla',
  'proceed:label': 'Procedi',
  'correct:label': 'Corretto',
  'login:label': 'Inserisci qui il tuo token',
  'login:session-label': 'Inserisci qui l\'id della sessione',
  'login-invalid-token:label': 'Dati di accesso errati.',
  'login-invalid-token-message:label':
    'Per favore verifica le tue credenziali con il docente e prova di nuovo!',
  'logout:label': 'Logout',
  'welcome:label': 'Benvenuto',
  'welcome-team:label': 'Benvenuto Team',
  'title-faculty-author:label': 'Faculty Author',
  'valterConca-name:label': 'Valter Conca',
  'albertoGrando-name:label': 'Alberto Grando',
  'giorgioLazzaro-name:label': 'Giorgio Lazzaro',
  'severinoMeregalli-name:label': 'Severino Meregalli',
  'admin-login:label': 'Admin Login',
  'admin-username:label': 'Username',
  'admin-password:label': 'Password',
  'valterConca-title:label': 'Faculty Author',
  'giorgioLazzaro-title:label': 'Faculty Author',
  'severinoMeregalli-title:label': 'Faculty Author',
  'albertoGrando-title:label': 'Faculty Author',
  'loading:label': 'Loading',
  'dashboard:label': 'Pannello di controllo',
  'uploader:label': 'uploader',
  'chapters:label': 'Fasi',
  'team:label': 'Team',
  'tot:label': 'Tot',
  'overview-chapter:label': 'Overview Fase',
  'kpiAttempts:label': 'n° tentativi:',
  'kpiTotalRetries:label': 'Tot. Tentativi:',
  'totalRetries:label': 'Tot. Tentativi Fase',
  'notActiveChapters:label': 'Nessuna fase attiva',
  'adminTimeline:label': 'Admin Timeline',
  'footer-email:label': 'learninglab@sdabocconi.it',
  'onboarding-title:label': 'Benvenuti in EuroFire <b>{teamName}!</b>',
  'onboarding-team-name:label': 'Il team si chiama',
  'onboarding-team-number:label': 'Prima di cominciare inserisci i membri del team:',
  'onboarding-team-members:label': 'membri',
  'onboarding-member-name:label': 'Nome',
  'onboarding-member-surname:label': 'Cognome',
  'onboarding-member-email:label': 'E-mail',
  'onfirm-button:label': 'Conferma',
  'onboarding-incomplete:label':
    'Per procedere completare tutti i campi contrassegnati in rosso.',
  'onboarding-wrong-email:label':
    'Per procedere inserire un indirizzo email nel formato corretto.',
  'onboarding-confirm-popup:label':
    '<b>Attenzione!</b><br>Dopo aver confermato non sarà più possibile apportare modifiche. Per favore controllate l’esattezza dei dati inseriti.',
  'onboarding-confirm-text:label':
    'Attenzione! Non potrai modificare la composizione del team una volta confermato.',
  'scenario-notes:label':
    "Descrivete sinteticamente i punti chiave e le principali riflessioni a valle dell'analisi di questo scenario",
  'send-button:label': 'Invio',
  'scenario-notes-confirm-popup:label':
    '<b>Attenzione!</b><br>Dopo aver confermato non sarà più possibile apportare modifiche.',
  'kpi-extended-title:label': 'Key Performance Indicators',
  'chapter-p:label': 'Prologo: una fredda sera di dicembre',
  'chapter-prologue:label': 'Prologo: una fredda sera di dicembre',
  'chapter-0:label': "Contesto: l'analisi di EuroFire",
  'chapter-1:label': 'Fase 1: il bilancio di ItalInox',
  'chapter-2:label': "Fase 2: l'estensione del consolidato",
  'chapter-3:label': 'Fase 3: le ipotesi di sinergie',
  'chapter-4:label': 'Fase 4: la prospettiva finanziaria',
  'chapter-epilogue:label': 'Epilogo: upload del lavoro',
  'chapter-e:label': 'Epilogo: upload del lavoro',
  'team-report:label': 'Team Report',
  'chapter-p-menu:label': 'P',
  'chapter-0-menu:label': 'C',
  'chapter-1-menu:label': '1',
  'chapter-2-menu:label': '2',
  'chapter-3-menu:label': '3',
  'chapter-4-menu:label': '4',
  'chapter-e-menu:label': 'E',
  'video-prologue:label': 'Una fredda sera di Dicembre',
  'doc-prologue:label': 'Una fredda sera di Dicembre',
  'video-contesto:label': 'EuroFire: il sistema produttivo',
  'doc-contesto:label': "EuroFire: l'azienda e il contesto competitivo",
  'doc-bilanci-italinox:label': 'Il bilancio di ItalInox',
  'doc-prospettiva:label': 'La prospettiva di medio termine',
  'doc-produzione:label': 'I costi di produzione',
  'doc-sistema-produttivo:label': 'EuroFire: il sistema produttivo',
  'doc-capacita:label': 'Le capacità produttive',
  'doc-effetto-kit:label': "Le sinergie commerciali: l'effetto kit",
  'doc-ristrutturare:label': 'La ristrutturazione produttiva',
  'doc-immagine:label': 'La politica di brand',
  'doc-prezzi:label': 'La politica di prezzo',
  'doc-valutazione-finanziaria:label': 'La prospettiva finanziaria',
  'uploadExcel:label': 'Upload Foglio Excel',
  'uploadPresentation:label': 'Upload Presentazione',
  'table-team:label': 'Team',
  'table-kpi:label': 'Kpi',
  'table-scenario:label': 'Scenario',
  'table-scenarios:label': 'Scenari',
  'consolidato:label': 'Il Consolidato',
  'estensione:label': 'Estensione',
  'costi-produzione:label': 'Costi di Produzione',
  'capacita-produttive:label': 'Capacità Produttive',
  'estensione-costi-produzione:label': 'Estensione + Costi di Produzione',
  'estensione-effetto-kit:label': 'Estensione + Effetto Kit',
  'estensione-capacita-produttive:label': 'Estensione + Capacità Produttive',
  'estensione-costi-produzione-effetto-kit:label':
    'Estensione + Costi di Produzione + Effetto Kit',
  'estensione-capacita-produttive-effetto-kit:label':
    'Estensione + Capacità Produttive + Effetto Kit',
  'estensione-costi-produzione-capacita-produttive:label':
    'Estensione + Costi di Produzione + Capacità Produttive',
  'estensione-costi-produzione-capacita-produttive-effetto-kit:label':
    'Estensione + Costi di Produzione + Capacità Produttive + Effetto Kit',
  'non-ristrutturare:label': 'Non Ristrutturare',
  'non-ristrutturare-progetto-revisione-prezzi:label':
    'Non Ristrutturare + Politica di Prezzo',
  'non-ristrutturare-progetto-immagine:label':
    'Non Ristrutturare + Politica di Brand',
  'ristrutturare:label': 'Ristrutturare',
  'ristrutturare-progetto-immagine:label': 'Ristrutturare + Politica di Brand',
  'scenario-3.6:label': 'Ristrutturare + Politica di Prezzo',
  'net-present-value:label': 'Present Value',
  'net-present-value-admin:label': 'Present Value',
  'net-present-value-error:label': 'Present Value (metodo errato)',
  'net-present-value-con-disinvestimento:label': 'Present Value',
  'net-present-value-con-disinvestimento-admin:label':
    'Present Value con disinvestimento',
  'ristrutturare-progetto-revisione-prezzi:label':
    'Ristrutturare + Politica di Prezzo',
  'kpi-sales-year1:label': 'Fatturato',
  'kpi-revenues-year1:label': 'Utile',
  'kpi-sales-sum:label': 'Fatturato Cumulato',
  'kpi-revenues-sum:label': 'Utile Cumulato',
  'kpi-market-share-ovens:label': 'Quota Mercato Anno 4 - Cottura',
  'kpi-market-share-sinks:label': 'Quota Mercato Anno 4 - Lavelli',
  'kpi-max-saturation-ovnes:label': 'Saturazione Reparto Più Saturo - Cottura',
  'kpi-max-saturation-sinks:label': 'Saturazione Reparto Più Saturo - Lavelli',
  'kpi-pv:label': 'Present Value',
  'kpi-pv-disinvestment:label': 'Present Value Review',
  'fatturato-anno-1:label': 'Fatturato',
  'utile-anno-1:label': 'Utile',
  'fatturato-cumulato:label': 'Fatturato Cumulato',
  'utile-cumulato:label': 'Utile Cumulato',
  'quota-mercato-forni-anno-4:label': 'Quota Mercato Anno 4 - Cottura',
  'quota-mercato-lavelli-anno-4:label': 'Quota Mercato Anno 4 - Lavelli',
  'livello-saturazione-reparto-piu-saturo-forni:label':
    '% Saturazione Reparto Più Saturo - Cottura',
  'livello-saturazione-reparto-piu-saturo-lavelli:label':
    '% Saturazione Reparto Più Saturo - Lavelli',
  'upload-excel:label': 'Upload Foglio Excel',
  'upload-slides:label': 'Upload Presentazione',
  'upload-excel-task:label':
    'Caricate nello spazio sottostante la versione finale del file excel prodotto per l’analisi svolta.',
  'upload-excel-howto:label':
    'Per effettuare il download del file excel: <b>File > Salva con nome > Scarica una copia<b/>',
  'upload-slides-task:label':
    'Caricate nello spazio sottostante la versione finale della presentazione prodotta per l’analisi svolta.',
  'upload-button:label': 'Upload',
  'upload-request:label':
    "Clicca sull'icona del file per effettuarne il download",
  'upload-success:label': 'UPLOAD AVVENUTO CORRETTAMENTE',
  'upload-fail:label': 'UPLOAD FALLITO',
  'upload-dropbox-excel:label': 'Rilascia il file excel qui',
  'upload-dropbox-slides:label': 'Rilascia il file qui',
  'upload-dropbox-or:label': 'oppure',
  'upload-dropbox-pick:label': 'Scegli file',
  'upload-dropbox-invalid:label': 'Il file caricato non risulta valido.',
  'upload-dropbox-excel-format:label':
    'accertati che il file sia in formato .xlsx',
  'upload-dropbox-slides-format:label':
    'accertati che il file sia in formato .ppt, .pptx o .pdf',
  'report-view:label': 'Visualizza',
  'report-send:label': 'Invia',
  'report-sended:label': 'Inviata',
  'thankyou-title:label': 'Grazie per il vostro contributo!',
  'thankyou-message:label':
    'Il vostro mandato è concluso. EuroFire S.p.A. è ora consapevole delle opzioni strategiche che la attendono e, grazie al vostro lavoro, saprà assumere le opportune decisioni!',
  'thankyou-report:label':
    'Di seguito una lettera che attesta il lavoro da voi svolto',
  'admin-global:label': 'Globale',
  'admin-chapter-prologue:label': 'Prologo',
  'admin-chapter-0:label': 'Contesto',
  'admin-chapter-1:label': 'Fase 1',
  'admin-chapter-2:label': 'Fase 2',
  'admin-chapter-3:label': 'Fase 3',
  'admin-chapter-4:label': 'Fase 4',
  'admin-chapter-epilogue:label': 'Epilogo',
  'admin-tot-time:label': 'Tot',
  'admin-num-tries:label': 'n° tentativi',
  'admin-tot-tries:label': 'Tot. Tentativi',
  'admin-chapter-tot-tries:label': 'Totale Tentativi Fase',
  'ouch-LowRes:label': 'Ouch!',
  'lowres:label': '<b><span class="lowres-error-message">Attenzione! EuroFire non supporta la risoluzione dello schermo del tuo device.</span></b><br/><small>Per favore accedi all\'app da un device desktop o tablet con risoluzione dello schermo non inferiore a 600x600px</small>',
  'notSupported:label':
    'EuroFire non supporta la risoluzione dello schermo del tuo device.',
  'suitableDevice:label':
    "Per favore accedi all'app da un device desktop o tablet con risoluzione dello schermo non inferiore a 600px di larghezza",
  'onboarding-confirm-button:label': 'Conferma',
  'scenario-notes-confirm-button:label': 'Conferma',
  'onboarding-submit:label':
    '<b>Attenzione</b><br>Dopo la conferma il contenuto del questionario non sarà più modificabile. Per favore controllate l’esattezza dei dati inseriti.',
  'admin-confirm-button:label': 'Conferma',
  'admin-submit-popup:label': '<b>Attenzione.</b>',
  'admin-submitA-popup:label':
    'Confermando la seguente azione, un messaggio e-mail contenente il Report e il file Ms Excel sarà inviato a tutti i partecipanti del team',
  'admin-submitA-global-popup:label':
    'Confermando la seguente azione, un messaggio e-mail contenente il Report e il file Ms Excel sarà inviato a tutti i partecipanti.',
  'admin-submitB-popup:label': '.<br/>Vuoi procedere?',
  'members:label': 'Membri',
  'member-name:label': 'Nome',
  'member-lastname:label': 'Cognome',
  'member-email:label': 'E-mail',
  'chapter-notes:label': 'Aggiungete qui i vostri commenti.',
  'greetings:label':
    "<b class='greetings-label'>Complimenti!</b><br>Siete arrivati alla fine del vostro mandato.",
  'eurofire-knows:label':
    '<small>EuroFire S.p.A. è ora consapevole delle opzioni strategiche che la attendono e, grazie al vostro lavoro, potrà assumere le decisioni migliori.</small>',
  'report-location:label': 'Castelfranco Veneto',
  'report-doctor:label': 'Dott. E. Callegari',
  'report-eurofireAddress:label':
    'EuroFire S.p.A. \n Viale Piave, 12 \n Castelfranco Veneto 31033 - Treviso',
  'report-firstPartMessageA:label': 'Spett.le ',
  'report-firstPartMessageB:label': 'Gentile Team',
  'report-firstPartMessageC:label': 'Via Sarfatti, 10 - Milano',
  'report-secondPartMessage:label':
    "Un sentito ringraziamento per il prezioso lavoro svolto, che ci ha arricchito in termini di metodo e che ci consentirà di assumere con maggior consapevolezza le scelte che dobbiamo fronteggiare. \n \n \n Ai fini di una verifica conclusiva, diamo sintesi dei principali risultati dell'analisi, in relazione alle singole fasi di lavoro.",
  'reportSign:label':
    "Ringraziando nuovamente per il supporto metodologico fornito in questo progetto, nell'auspicio di avere ulteriori opportunità di collaborazione colgo l'occasione per porgere i miei più cordiali saluti.",
  'reportSign2:label': 'Castelfranco Veneto',
  'reportSign3:label': 'Dott. E. Callegari',
  'reportNotes:label':
    "Punti chiave e principali riflessioni a valle dell'analisi di questo scenario:",
  'contributionTeam:label': 'Contributo del team ',
  'contributionTeamOn:label': ' su ',
  'notYetInsertedMessage:label': 'Messaggio ancora non inserito!',
  'waitingAssetsActivation:label': 'Waiting for asset activation',
  'sectionKindNotFound:label': 'Section Kind not found! Call tech support!',
  'eurofireTooltipContext:label':
    "<p>EuroFire ha appena inviato maggiore documentazione sui propri <b style='color:#2764FF'>processi, prodotti e dati societari.</b></p><p>Cliccate qui sopra per prenderne visione!</p>",
  'eurofireTooltipChapter1:label':
    "<p>Ci è stato appena recapitato dallo Studio Cravero <b style='color:#2764FF'>il bilancio di ItalInox</b>, lo troverete sulla scrivania.</p><p>Cliccate qui per prenderne visione!</p>",
  'eurofireTooltipChapter2:label':'È giunto il momento di valutare cosa accadrà nei prossimi periodi: cliccate per accedere alle informazioni utili all’estensione del <b style=\'color:#2764FF\'>bilancio consolidato.</b>',
  'eurofireTooltipChapter3:label':
    "<p>È tempo di analizzare alcune <b style='color:#2764FF'>ipotesi di strategie commerciali.</b><br /><br />Cliccate per accedere alla fase corrispondente.</p>",
  'eurofireTooltipChapter4:label':
    "<p>È ora disponibile anche <b style='color:#2764FF'>un’analisi della dimensione finanziaria</b><br /><br />Cliccate per accedere alla fase corrispondente.</p>",
  'eurofireTooltipChapter5:label':
    "<p>EuroFire attende con interesse gli <b style='color:#2764FF'>esiti della vostra analisi.</b><br /><br />Cliccate per accedere ad una sezione in cui consegnare i documenti prodotti.</p>",
  'eurofireTooltipExcelButton:label':
    "<p>Al click su questo bottone potrete accedere al <b style='color:#39CB85'>foglio Excel condiviso</b>, dove condurre la vostra analisi.</p><p>È il documento che d’ora in poi verrà preso come riferimento del lavoro svolto e che dovrete consegnare al termine del mandato.</p>",
  'eurofireTooltipFirstTask:label':
    "<p><b>Ecco la vostra prima task:</b><br />Nella card sottostante vi è richiesto di inserire alcuni <b style='color:#6C39D8'>KPI</b>, da ricavare tramite la vostra analisi. All'inserimento non sarà necessario indicare separatori di migliaia o decimali.<br/>Riceverete un feedback grafico verde se il valore inserito è corretto, rosso altrimenti.</p>",
  'eurofireTooltipNotes:label':
    "<p>Per completare ogni scenario d’analisi vi è richiesto di aggiungere ai KPI un breve contributo contenente i <b style='color:#FF8744'>punti chiave</b>. Quando ritenete completo il testo, inviatelo tramite il tasto “INVIO” in basso a destra.</p>",
  'eurofireTooltipNotesComplex:label':
    "<p><b>Nello spazio sottostante vi è richiesto di inserire dei <b style='color:#FF8744'>punti chiave</b> per ogni scenario analizzato:</b><br /> la barra arancio vi indicherà qual è lo scenario a cui si riferisce in ogni momento lo spazio commenti. Quando avrete inviato un commento, comparirà un tick a conferma dell’avvenuto invio.</p>",
  'eurofireTooltipEpilogueExcel:label':
    "<p>Utilizzate gli spazi di upload qui sotto per caricare il <b style='color:#39CB85'>file excel</b> dove avete condotto l’analisi, dopo averlo scaricato tramite l’interfaccia di Excel (File > Salva con nome > Scarica una copia).</p>",
  'eurofireTooltipEpilogueBoth:label':
    "<p>Utilizzate gli spazi di upload qui sotto per caricare il <b style='color:#39CB85'>file excel</b> dove avete condotto l’analisi, dopo averlo scaricato tramite l’interfaccia di Excel (File > Salva con nome > Scarica una copia), e la <b style='color:#FF8744'>presentazione</b> preparata.</p>",
  'eurofireTooltipDocuments:label':
    "<p>Le card bianche come questa sono <b style='color:#CF0000'>documenti</b> del business case.<br /><br />Vi sarà sufficiente cliccare sopra ad esse per accedere al testo completo, per poterlo ingrandire o per scaricarlo.</p>",
  'sandyPresentation:label':
    '<p><b>Felice di conoscervi, il mio nome è Spark!</b><br />Vi assisterò per tutta la durata del vostro mandato. Sarà mio compito introdurvi agli strumenti di lavoro e notificarvi ogni nuova informazione disponibile.</p>',
  'sandyIntroduction:label':
    "<p>Cliccate sul tasto play della card alla mia sinistra per visualizzare il <b style='color:#CF0000'>video</b> introduttivo di EuroFire.<br /><br />Il Dottor Callegari è chiamato a prendere una decisione delicata per la propria azienda: sarà vostro compito aiutarlo nella scelta.</p>",
  'team-report-title:label': 'Team Report',
  'email-subject': 'EuroFire - Report del team ',
  'email-subject-b': ' ',
  'email-body':
    "<html><body><p>Gentile {name},</p><p>Grazie per aver partecipato alla nostra sessione di EuroFire.<br/>Arrivati a conclusione del mandato, puoi visualizzare e scaricare a <a href='{report}'>questo link</a> il report del tuo Team.</p><p>Desideriamo inoltre concederti l’accesso al <a href='{excel}'>file MS Excel</a> che ti ha permesso, insieme al Team, di raggiungere l'obiettivo desiderato.</p><p>Cogliamo l'occasione per augurarti il meglio per il tuo futuro professionale.</p><p>Valter Conca, Alberto Grando, Giorgio Lazzaro, Severino Meregalli,<br/>con il Team Learning Lab</p><div class='footer' style='margin-top:15px;'><a href='https://www.sdabocconi.it/en/faculty-research/learning-process/learning-lab' target='_blank'><img src='https://learninglab.sdabocconi.it/files/simulations/logo-llab-positive.png' width='220' /></a></div></body></html>",
  'email-phase4Offer-subject': 'Un messaggio da Sergio Callegari - EuroFire',
  'email-phase4Offer-popup-title': 'Un messaggio da Sergio Callegari',
  'phase4Offer-popup-other-message': '<p>Nel corso del processo di redazione del piano strategico legato all’integrazione di Italinox, una lettera sparigliava le carte: Il Gruppo China Kitchen Furniture, interessato ad entrare nel mercato delle cucine, proponeva un’offerta di 110 milioni di euro (entreprise value) per l’acquisto di EuroFire.</p>',
  'email-phase4Offer-body':
    "<html><body><p>Carissimi,</p><p>ricevo e condivido con voi una proposta appena ricevuta dal Financial Partners Advisors che, in nome e per conto del Gruppo China Kitchen Furniture, propone una offerta non negoziabile di 110 milioni di euro (enterprise value) per l’acquisto della mia azienda.</p><p>Ora più che mai sento il bisogno di un supporto professionale per assumere una decisione pienamente informata.</p><p>Vi prego di avviare le riflessioni in merito, in attesa di definire un incontro per una valutazione complessiva delle diverse opzioni che abbiamo sul tavolo.</p><p>Cordiali saluti,</p><p>Sergio Callegari<br/>CEO, EuroFire</p></body></html>",
  'email-phase4Offer-popup-signature': "<div class='footer' style='margin-top:15px;'><img src='https://learninglab.sdabocconi.it/files/simulations/logo-eurofire.png' width='200' /></div>",
  'team-phase4Offer:label': 'Offerta China K.F. Gr.',
  'phase4Offer-send:label': 'Invia',
  'phase4Offer-sended:label': 'Inviata',
  'admin-phase4Offer-submit-popup:label': '<b>Attenzione.</b>',
  'admin-phase4Offer-submitA-popup:label':'Confermando la seguente azione, un messaggio e-mail contenente l’offerta del Gruppo China Kitchen Furniture sarà inviato ai partecipanti del team.<br/>Vuoi procedere?',
  'admin-phase4Offer-submitB-popup:label': '.<br/>Vuoi procedere?',
  'admin-phase4Offer-confirm-button:label': 'Conferma',
  'old-correct-label': 'Valore corretto originale',
  'language-en': 'ENG',
  'language-it': 'ITA',
  'without-investment':'Senza',
  'with-investment':'Con',
  'admin-sessions-section-title': 'Gestione sessioni',
  'admin-sessions-selected': 'Sessione selezionata',
  'admin-sessions-archive': 'Archivio',
  'admin-sessions-see-params': 'Vedi parametri',
  'admin-sessions-edit-params': 'Modifica parametri',
  'admin-sessions-select-params': 'Seleziona parametri',
  'admin-sessions-create-params': 'Crea parametri',
  'admin-sessions-parameters': 'Parametri',
  'admin-sessions-media': 'Media',
  'admin-sessions-add-params': 'Aggiungi set',
  'admin-sessions-global': 'Globali',
  'admin-sessions-personal': 'Individuali',
  'admin-sessions-label-search': 'Ricerca per nome',
  'admin-sessions-from': 'da:',
  'admin-sessions-to': 'a:',
  'admin-sessions-active-list': 'Sessioni Attive',
  'admin-sessions-history-list': 'Storico',
  'admin-sessions-date': 'Data',
  'admin-sessions-date-creation': 'Data creazione',
  'admin-sessions-name': 'Nome',
  'admin-sessions-session': 'Sessione',
  'admin-sessions-session-id': 'ID Sessione',
  'admin-sessions-closed': 'chiusa',
  'admin-sessions-terminate': 'Termina',
  'admin-sessions-owner': 'Proprietario',
  'admin-sessions-teachers': 'Docenti',
  'admin-sessions-cancel': 'Annulla',
  'admin-sessions-visibility': 'Visibilità',
  'admin-sessions-default': 'Default',
  'admin-sessions-create': 'Crea',
  'admin-sessions-delete': 'Elimina',
  'admin-sessions-clone': 'Clona',
  'admin-sessions-phase': 'Fase {phase}',
  'admin-sessions-ask-param-selection': 'Selezione un set di parametri',
  'admin-sessions-confirm-selection': 'Conferma selezione',
  'admin-sessions-confirm-edit': 'Conferma modifiche',
  'admin-sessions-archive-generation': 'Generazione archivio',
  'admin-sessions-feedback-no-params': "Nessun set di parametri trovato. Crea un nuovo set o modifica i filtri scelti.",
  'admin-sessions-feedback-no-session-past': "Nessuna sessione passata trovata.",
  'admin-sessions-feedback-no-session-active': "Nessuna sessione attiva trovata.",
  'admin-sessions-feedback-no-param': "Nessun parametro trovato",
  'admin-sessions-feedback-no-media': "Nessun video o documento trovato",
  'admin-sessions-feedback-impossible-range': "Hai scelto una data di iniziale successiva a quella finale. Modifica i filtri scelti.",
  'admin-sessions-feedback-all-media-params': "Inserire tutti i parametri e media",
  'admin-sessions-feedback-all-params': "Inserire tutti i parametri",
  'admin-sessions-feedback-all-media': "Inserire tutti i media",
  'admin-sessions-feedback-all-file': "Inserire tutti i file",
  'admin-sessions-feedback-file-size': "Dimensioni del file troppo grandi",
  'admin-sessions-feedback-original-deleted': "Set originale eliminato",
  'admin-sessions-feedback-no-param-set': "Attenzione. La sessione selezionata non ha un set di parametri associato",
  'admin-sessions-info-session': 'In questo riquadro si trovano le informazioni relative la sessione attualmente selezionata. É possibile selezionare una sessione nei due riquadri sottostanti, <b>Sessioni attive</b> e <b>Storico</b>. Una volta selezionata una sessione, é possibile visualizzare e/o modificare il set di parametri assocciato oppure terminare la sessione. Le sessioni terminate si trovano nello storico.',
  'admin-sessions-info-global': 'Nella sezione <b>Globali</b> si trovano i set di parametri formalmente corretti definiti dai titolari della simulazione. Cliccando sulla singola entry nella lista sottostante é possibile vedere il dettaglio del set di paremetri e clonarlo. Cliccando sul bottone in alto a destra é possibile creare un nuovo set di parametri. É inoltre possibile filtrare i set di parametri per nome, creatore e data di creazione associata al set di parametri.',
  'admin-sessions-info-personal': 'Nella sezione <b>Individuali</b> si trovano i tuoi set di parametri nuovi e/o clonati. Cliccando sulla singola entry nella lista sottostante é possibile vedere il dettaglio del set di paremetri e clonarlo. Cliccando sul bottone in alto a destra é possibile creare un nuovo set di parametri. É inoltre possibile filtrare i set di parametri per nome, creatore e data di creazione associata al set di parametri.',
  'admin-sessions-info-params': `
    In questa sezione é possibile visualizzare il set di parametri selezionato.
    Un set di parametri é costituito dai KPI e dai media organizzati per fasi.
    I set di parametri sono immutabili e per effettuare una modifica é necessario clonarli.
    L'eliminazione di un set di parametri é possibile solo se il set non é utilizzato in qualche sessione di gioco. 

    É possibile modificare la visibilitá di un set di parametri, da globale a individuale.
    É possibile definire un set di paremetri di default; il set di default sará quello
    automaticamente utilizzato al momento della creazione di una nuova sessione;
    se esiste un set di parametri individuale di default questo avrá la precedenza su quello globale.
    <br />
    <br />
    Nella sezione <b>Parametri</b> si trova l'elenco delle coppie KPI-scenario organizzate per fase con i relativi valori.
    <br/>
    <br/>
    Nella sezione <b>Media</b> si trova l'elenco dei video e dei documenti organizzati per fase e per lingua. Ciascun documento é composto da due coppie di file word-pdf nelle rispettive lingue. In fase di clonazione o creazione é possibile modificare i singoli media cliccando sulla matita.
  `,
  'admin-sessions-create-params-subtitle': "Scegli il nome per questo set di parametri e imposta se sono se sono da impostare come globali e/o come default",
  'admin-sessions-params-name': 'Nome parametri',
  'admin-sessions-show-info': 'Clicca qui per ottenere maggiori informazioni',
  'admin-sessions-save-params': 'Salva parametri',
  'admin-sessions-save-subtitle': 'Scegli un nome da associare al set',
  'admin-sessions-yes': 'Sì',
  'admin-sessions-no': 'No',
  'admin-sessions-upload': 'Carica file',
  'admin-sessions-chapter-title-it': 'Titolo capitolo in italiano',
  'admin-sessions-chapter-title-en': 'Titolo capitolo in inglese',
  'admin-sessions-upload-it': 'Carica file {type} in italiano',
  'admin-sessions-upload-en': 'Carica file {type} in inglese',
  'admin-sessions-video-title': 'Titolo del video',
  'admin-sessions-video-link': 'Link del video',
  'admin-sessions-video-title-it': 'Titolo del video in italiano',
  'admin-sessions-video-title-en': 'Titolo del video in inglese',
  'admin-sessions-video-link-it': 'Link del video in italiano',
  'admin-sessions-video-link-en': 'Link del video in inglese',
  'admin-sessions-feedback-protocol': 'Il link deve contenere un protocollo valido (http o https).',
  'admin-sessions-set-video': "Carica video",
  'admin-sessions-feedback-all-video': "Video mancanti",
  'admin-sessions-phase:Prologo': "Prologo",
  'admin-sessions-phase:Contesto': "Contesto",
  'admin-sessions-phase:Fase 1': "Fase 1",
  'admin-sessions-phase:Fase 2': "Fase 2",
  'admin-sessions-phase:Fase 3': "Fase 3",
  'admin-sessions-phase:Fase 4': "Fase 4",
  'admin-sessions-phase:Epilogo': "Epilogo",
  'admin-sessions-confirm-terminate-title': "Conferma terminazione",
  'admin-sessions-confirm-terminate-content': "Sei sicuro di voler terminare la sessione?",
};
