import React, { ReactElement } from 'react';
import './style.scss';
import Image from '../Image';

interface Props {
  children: ReactElement;
  title?: string;
  subtitle?: string;
  fullSize?: boolean;
  actions?: ReactElement;
  footer?: ReactElement;
  onClose: () => void;
}

const index: React.FC<Props> = ({
  children,
  onClose,
  title,
  subtitle,
  fullSize,
  actions,
  footer,
}) => {
  return (
    <div className="AdminModal">
      <div className={`content ${fullSize ? 'fullSize' : 'smallSize'}`}>
        <div className="button-row">
          <div className="button" onClick={() => onClose()}>
            <Image name="close" className="close" />
          </div>
        </div>
        
        
        <div className="title-row">
          <p className="title">{title}</p>
          { actions ? <div className="actions">{actions}</div> : <></>}
        </div>
        <p className="subtitle">{subtitle}</p>
        
        <div className="children">{children}</div>
        { footer ? <div className="footer">{footer}</div> : <></> }
      </div>
    </div>
  );
};

export default index;
