/* eslint-disable */

import cloneDeep from 'lodash/cloneDeep';
import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { AppContext, AdminContext } from '../../context';
import { EChapterKind, IGame } from '../../interfaces';
import { generatePhase4OfferMail, generateReportMail } from '../../utilities/mail';
import AdminGlobal from '../AdminGlobal';

import { database } from '../../utilities/database';
import route from '../../route';

interface Props {
  intl: any;
}

const AdminGlobalProvider: React.FC<Props> = props => {
  const { session } = useContext(AppContext);
  const { games } = useContext(AdminContext);
  const [gameModel, setGameModel] = useState<IGame>();
  const { intl } = props;

  useEffect(() => {
    function generateGlobalGame() {
      const gameModel = games && cloneDeep(games[0]);

      if (games && gameModel) {
        const globalGame: IGame = gameModel;
        globalGame.id = 'global';
        for (
          let chapterIndex = 0;
          chapterIndex < gameModel.chapters.length;
          chapterIndex++
        ) {
          const chapterModel = gameModel.chapters[chapterIndex];
          if (chapterModel.assets) {
            chapterModel.assets.forEach((asset, assetIndex) => {
              const foundedInactive = games
                .map(game => game.chapters[chapterIndex].assets[assetIndex])
                .find(game => !game.active);

              gameModel.chapters[chapterIndex].assets[assetIndex].active =
                foundedInactive === undefined;
            });
          }
          if (
            chapterModel.scenarios &&
            chapterModel.kind === EChapterKind.SCENARIOS_MATRIX
          ) {
            chapterModel.scenarios.forEach((scenario, scenarioIndex) => {
              const foundedInactive = games
                .map(
                  game =>
                    game.chapters[chapterIndex].scenarios[scenarioIndex].active
                )
                .find(scenarioActive => !scenarioActive);
              const foundedInvisible = games
                .map(
                  game =>
                    game.chapters[chapterIndex].scenarios[scenarioIndex].show
                )
                .find(scenario => !scenario);

              gameModel.chapters[chapterIndex].scenarios[scenarioIndex].active =
                foundedInactive === undefined;
              gameModel.chapters[chapterIndex].scenarios[scenarioIndex].show =
                foundedInvisible === undefined;
            });
          }
          if (
            chapterModel.kpis &&
            chapterModel.kind === EChapterKind.KPIS_MATRIX
          ) {
            chapterModel.kpis.forEach((kpi, kpiIndex) => {
              const foundedInactive = games
                .map(game => game.chapters[chapterIndex].kpis[kpiIndex].active)
                .find(kpiActive => !kpiActive);
              const foundedInvisible = games
                .map(game => game.chapters[chapterIndex].kpis[kpiIndex].show)
                .find(scenario => !scenario);

              gameModel.chapters[chapterIndex].kpis[kpiIndex].active =
                foundedInactive === undefined;

              gameModel.chapters[chapterIndex].kpis[kpiIndex].show =
                foundedInvisible === undefined;
            });
          }

          if (chapterModel.pptUpload) {
            const foundedInactive = games
              .map(game => game.chapters[chapterIndex].pptUpload)
              .find(pptUpload => !pptUpload);

            gameModel.chapters[chapterIndex].pptUpload =
              foundedInactive === undefined;
          }
          if (chapterModel.excelUpload) {
            const foundedInactive = games
              .map(game => game.chapters[chapterIndex].excelUpload)
              .find(excelUpload => !excelUpload);

            gameModel.chapters[chapterIndex].excelUpload =
              foundedInactive === undefined;
          }
          if (gameModel.sended) {
            const foundedInactive = games
              .map(game => game.sended)
              .find(sended => !sended);

            gameModel.sended = foundedInactive === undefined;
          }
        }
        setGameModel(globalGame);
      } else {
      }
    }
    generateGlobalGame();
  }, [games]); // NON aggiungere generateGlobalGame come dipendenza perchè manda in loop la funzione

  function updateAllGames({
    active,
    chapterIndex,
    assetIndex,
    scenarioIndex,
    kpiIndex,
    isEpilogue,
    ppt,
    excel
  }: InputUpdateAllGames) {
    if (games) {
      for (const element of games) {
        let relativePath = '';
        let property = '';
        let oldObject = {};

        if (!isEpilogue) {
          property = 'active';
          if (typeof assetIndex !== 'undefined') {
            relativePath = `.assets#${assetIndex}`;
            oldObject = element.chapters[chapterIndex].assets[assetIndex];
            // Attivo il capitolo
            // il jsonstringify -> jsonparse serve per togliere proprietà undefined
            const chapter = JSON.parse(
              JSON.stringify(element.chapters[chapterIndex])
            );
            if (!chapter.activeAt) {
              database.set(session!.data.sessionDataId!,
                `games.${element.token}.chapters#${chapterIndex}`,
                {
                  ...chapter,
                  activeAt: new Date().toISOString()
                }
              );
            }
          } else if (typeof scenarioIndex !== 'undefined') {
            relativePath = `.scenarios#${scenarioIndex}`;
            oldObject = element.chapters[chapterIndex].scenarios[scenarioIndex];
          } else {
            relativePath = `.kpis#${kpiIndex}`;
            oldObject = element.chapters[chapterIndex].kpis[kpiIndex!];
          }
        } else {
          oldObject = element.chapters[chapterIndex];
          ppt ? (property = 'pptUpload') : (property = 'excelUpload');
        }
        const completePath = `games.${element.token}.chapters#${chapterIndex}${relativePath}`;
        database.set(session!.data.sessionDataId!,
          completePath,
          {...oldObject, [property]: active}
        );
      }
    }
  }  


  function sendMailClick() {
    if (games) {

      for (let x = 0; x < games.length; x++) {
        const { players, excelModelUrl, token, team }: IGame = games[x];
        if (!players) {
          break;
        }
        const reportLink = process.env.PUBLIC_URL + route('report', {
          gameSessionId: session!.id,
          token: token
        });
        for (let y = 0; y < players.length; y++) {
          const player = players[y];
          const data = generateReportMail(
            intl,
            player,
            reportLink,
            excelModelUrl,
            team
          );
          fetch('https://learninglab.sdabocconi.it/mail-sender/send', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
            .then(response => {
              console.log(response);
            })
            .catch(_ => console.error('Si è verificato un errore!'));
        }
        database.set(session!.data.sessionDataId!,
          `games.${token}`,
          { ...games[x], sended: true }
        );
      }
    }
  }

    function sendPhase4OfferMailClick() {
    if (games) {
      for (let x = 0; x < games.length; x++) {
        const { players, token }: IGame = games[x];

        if (!players) {
          break;
        }
        for (let y = 0; y < players.length; y++) {
          const player = players[y];
          const data = generatePhase4OfferMail(intl, player);

          fetch('https://learninglab.sdabocconi.it/mail-sender/send', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(response => {
            console.log(response);
          })
      .catch(error => console.log('Si è verificato un errore!'));
        }
        database.set(session!.data.sessionDataId!,
          `games.${token}`,
          {
            ...games[x],
            phase4ModalVisible: true,
            phase4OfferSended: true 
          }
        );
      }
    }
  }

  return (
    <>
      {gameModel ? (
        <AdminGlobal
          game={gameModel}
          updateData={updateAllGames}
          sendMails={sendMailClick}
          sendPhase4OfferMails={sendPhase4OfferMailClick}
        />
      ) : (
        <div />
      )}
    </>
  );
};

export default injectIntl(AdminGlobalProvider);

export interface InputUpdateAllGames {
  active: boolean;
  chapterIndex: number;
  assetIndex?: number;
  scenarioIndex?: number;
  kpiIndex?: number;
  isEpilogue?: boolean;
  ppt?: boolean;
  excel?: boolean;
}
