export default [
  {
    id : 'valterConca'
  },
  {
    id : 'albertoGrando'
  },
  {
    id : 'giorgioLazzaro'
  },
  {
    id : 'severinoMeregalli'
  }
]