import React, { Fragment } from 'react';
import AdminHeader from '../AdminHeader';
import AdminMain from '../AdminMain';
import Footer from '../Footer';
import Header from '../Header';
import './style.scss';

const Admin: React.FC = () => {
	return (
		<Fragment>
			<Header />
			<div className="Admin">
				<AdminHeader />
				<AdminMain />
				<Footer />
			</div>
		</Fragment>
	);
};

export default Admin;
