import React from 'react';
import Image from '../Image';
import './style.scss';

interface Props {
	color: string;
	image: string;
}

const SquareButton: React.FC<Props> = ({ color, image }) => {
	return (
		<div
			className="SquareButton"
			style={{
				boxShadow: `0px 2px 30px ${color}4d`
			}}>
			<Image name={image} />
		</div>
	);
};

export default SquareButton;
