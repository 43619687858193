import React, { Fragment, useContext } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { AppContext, PlayerContext } from '../../context';
import { IChapter } from '../../interfaces';
import route, { NavLink, Route } from '../../route';
import Image from '../Image';
import Tooltip from '../Tooltip';
import Uploader from '../Uploader';
import './style.scss';

import { database } from '../../utilities/database';

interface Props {
  intl: any;
}

const Epilogue: React.FC<Props> = ({ intl }) => {
  const { session } = useContext(AppContext);
  const { match, chapter, game } = useContext(PlayerContext);
  if (!match) {
    throw new Error('Not found match');
  }
  if (!chapter) {
    throw new Error('Not found chapter');
  }
  const { token } = match.params;

  const updateChapter = (newChapter: IChapter) => {
    database.set(session!.data.sessionDataId!,
      `games.${token}.chapters#${chapter.index}`,
      JSON.parse(JSON.stringify(newChapter))
    );
  };

  return (
    <Fragment>
      <div className="Epilogue">
        {chapter.excelUpload && (
          <Route
            path={route('chapter', { token, chapterID: 'e' })}
            render={() => (
              <Tooltip
                kind="bottom-right"
                style={{
                  left: '15rem',
                  top: '35rem',
                  minWidth: '30rem',
                  transform: 'none'
                }}>
                <div
                  className="ChaptersMenu-tooltip-avatar"
                  style={{ width: '30rem' }}>
                  <Image name="sparkAvatar" />
                  <div className="text">
                    <FormattedHTMLMessage
                      id={
                        chapter.excelUpload && chapter.pptUpload
                          ? 'eurofireTooltipEpilogueBoth:label'
                          : 'eurofireTooltipEpilogueExcel:label'
                      }
                    />
                  </div>
                </div>
              </Tooltip>
            )}
          />
        )}
        {chapter.excelUpload && (
          <Uploader
            nameID={token}
            teamName={game ? game.team : undefined}
            icon={'excelGreen'}
            title={intl.formatMessage({ id: 'uploadExcel:label' })}
            color={'#39CB85'}
            isXLS
            accept={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel'
            ]}
            onUploadSuccess={(fileKey: string) => {
              chapter.excelUrl = fileKey;
              updateChapter(chapter);
            }}
            fileKey={chapter.excelUrl}
            onReset={() => {
              console.log('reset');
              const newChapter = { ...chapter };
              newChapter.excelUrl = '';
              updateChapter(newChapter);
            }}
          />
        )}
        {chapter.pptUpload && (
          <Uploader
            nameID={token}
            teamName={game ? game.team : undefined}
            icon={'ppt'}
            title={intl.formatMessage({ id: 'uploadPresentation:label' })}
            color={'#FF8744'}
            accept={[
              '.ppt',
              '.ppts',
              '.pdf',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation'
            ]}
            onUploadSuccess={(fileKey: string) => {
              chapter.pptUrl = fileKey;
              updateChapter(chapter);
            }}
            fileKey={chapter.pptUrl}
            onReset={() => {
              console.log('reset');
              const newChapter = { ...chapter };
              newChapter.pptUrl = '';
              updateChapter(newChapter);
            }}
          />
        )}
      </div>
      <div className="link-to-thanks">
        {((chapter.excelUpload &&
          chapter.pptUpload &&
          !!chapter.excelUrl &&
          !!chapter.pptUrl) ||
          (chapter.excelUpload && !chapter.pptUpload && !!chapter.excelUrl) ||
          (!chapter.excelUpload && chapter.pptUpload && !!chapter.pptUrl)) && (
          <NavLink to={route('thankyou', { gameSessionId: session!.id, token })}>
            {intl.formatMessage({ id: 'proceed:label' })}
          </NavLink>
        )}
      </div>
    </Fragment>
  );
};

export default injectIntl(Epilogue);
