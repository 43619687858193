import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import './style.scss';


interface Props {
  intl : any
}

const AdminTimer: React.FC<Props> = ({ intl }) => {
  const [count, setCount] = useState(0);
  useEffect(
    () => {
      const timeout = setTimeout(() => {
        setCount(count + 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    },
    [count]
  );
  return (
    <div className="AdminTimer">
      <div className="admin-title">{intl.formatMessage({id : 'adminTimeline:label'})}</div>
      <div className="timer">{moment().format('HH:mm:ss')}</div>
    </div>
  );
};

export default injectIntl(AdminTimer);
