import find from 'lodash/find';
import React, { useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { AppContext, PlayerContext } from '../../context';
import { IChapter } from '../../interfaces';
import route, { NavLink, Route } from '../../route';
// import CustomButton from '../CustomButton';
import Image from '../Image';
import Tooltip from '../Tooltip';
import './style.scss';

import { database } from '../../utilities/database';

const ChaptersMenu: React.FC = () => {
  const { session } = useContext(AppContext);
  const { game, match, activeChapters } = useContext(PlayerContext);
  if (!match) {
    throw new Error('Not found match');
  }
  if (!game) {
    throw new Error('Not found game');
  }
  if (!activeChapters) {
    throw new Error('Not found activeChapters');
  }

  function onLinkClick(index: number | undefined) {
    if (typeof index != undefined) {
      database.set(session!.data.sessionDataId!,
        `games.${token}.chapters#${index}`,
        { ...game!.chapters[index!], viewed: true }
      );
    } else {
      console.error('Impossible update because index is undefined');
    }
  }
  const { token, chapterID } = match.params;

  const chapterContextActive = activeChapters.find(
    c => c.index === 1 && c.viewed === false
  );
  const chapter1Active = activeChapters.find(
    c => c.index === 2 && c.viewed === false
  );
  const chapter2Active = activeChapters.find(
    c => c.index === 3 && c.viewed === false
  );
  const chapter3Active = activeChapters.find(
    c => c.index === 4 && c.viewed === false
  );
  const chapter4Active = activeChapters.find(
    c => c.index === 5 && c.viewed === false
  );
  const chapter5Active = activeChapters.find(
    c => c.index === 6 && c.viewed === false
  );
  return (
    <nav className="ChaptersMenu">
      <div>
        {activeChapters.map((chapter: IChapter, index: number) => {
          const active = chapter.id === chapterID;
          const { viewed } = chapter;
          const founded = find(game.chapters, ['id', chapter.id]);
          return (
            <div
              key={chapter.id}
              onClick={ev => !viewed && founded && onLinkClick(founded.index)}>
              <NavLink to={route('chapter', { 
                  gameSessionId: session!.id,
                  chapterID: chapter.id,
                  token
                })}>
                <div
                  className={`menu-item ${!viewed ? 'first-time' : ''}`}
                  style={{
                    backgroundColor: active ? '#2764FF' : 'white',
                    color: active ? 'white' : 'grey'
                  }}>
                  <FormattedHTMLMessage
                    id={`chapter-${chapter.id}-menu:label`}
                  />
                </div>
              </NavLink>
              {chapterContextActive && index === 0 && (
                <Tooltip
                  kind="bottom-right"
                  style={{ left: '5px', top: '50px', minWidth: '25rem' }}>
                  <div className="ChaptersMenu-tooltip-avatar">
                    <Image name="sparkAvatar" />
                    <div className="text">
                      <FormattedHTMLMessage id="eurofireTooltipContext:label" />
                    </div>
                  </div>
                </Tooltip>
              )}
              {chapter1Active && index === 1 && (
                <Tooltip
                  kind="bottom-right"
                  style={{ left: '5px', top: '50px', minWidth: '25rem' }}>
                  <div className="ChaptersMenu-tooltip-avatar">
                    <Image name="sparkAvatar" />
                    <div className="text">
                      <FormattedHTMLMessage id="eurofireTooltipChapter1:label" />
                    </div>
                  </div>
                </Tooltip>
              )}
              {chapter2Active && index === 2 && (
                <Tooltip
                  kind="bottom-right"
                  style={{ left: '5px', top: '50px', minWidth: '25rem' }}>
                  <div className="ChaptersMenu-tooltip-avatar">
                    <Image name="sparkAvatar" />
                    <div className="text">
                      <FormattedHTMLMessage id="eurofireTooltipChapter2:label" />
                    </div>
                  </div>
                </Tooltip>
              )}
              {chapter3Active && index === 3 && (
                <Tooltip
                  kind="bottom-right"
                  style={{ left: '5px', top: '50px', minWidth: '25rem' }}>
                  <div className="ChaptersMenu-tooltip-avatar">
                    <Image name="sparkAvatar" />
                    <div className="text">
                      <FormattedHTMLMessage id="eurofireTooltipChapter3:label" />
                    </div>
                  </div>
                </Tooltip>
              )}
              {chapter4Active && index === 4 && (
                <Tooltip
                  kind="bottom-right"
                  style={{ left: '5px', top: '50px', minWidth: '25rem' }}>
                  <div className="ChaptersMenu-tooltip-avatar">
                    <Image name="sparkAvatar" />
                    <div className="text">
                      <FormattedHTMLMessage id="eurofireTooltipChapter4:label" />
                    </div>
                  </div>
                </Tooltip>
              )}
              {chapter5Active && index === 5 && (
                <Tooltip
                  kind="bottom-right"
                  style={{ left: '5px', top: '50px', minWidth: '25rem' }}>
                  <div className="ChaptersMenu-tooltip-avatar">
                    <Image name="sparkAvatar" />
                    <div className="text">
                      <FormattedHTMLMessage id="eurofireTooltipChapter5:label" />
                    </div>
                  </div>
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
      <div>
        {/* chapterID !== 'p' ? (
          <CustomButton
            url={game.excelModelUrl}
            backgroundColor="#39CB85"
            imageName="excel"
          />
        ) : null */}

        <Route
          path={route('chapter', { gameSessionId: session!.id, token, chapterID: 0 })}
          render={() => (
            <Tooltip
              kind="top-right"
              style={{ left: '0.3rem', top: '-5rem', minWidth: '25rem' }}>
              <div className="ChaptersMenu-tooltip-avatar">
                <Image name="sparkAvatar" />
                <div className="text">
                  <FormattedHTMLMessage id="eurofireTooltipExcelButton:label" />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </div>
    </nav>
  );
};

export default ChaptersMenu;
