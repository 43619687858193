import * as EmailValidator from 'email-validator';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import range from 'lodash/range';
import React, { useState } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { Header, Label } from 'semantic-ui-react';
import { IGame } from '../../interfaces';
import Card from '../Card';
import WhiteButton from '../WhiteButton';
import './style.scss';

interface Props {
  intl: any;
  game: IGame;
  updateData: Function;
  creationTeamCB: Function;
}

interface IPlayer {
  firstName: string;
  lastName: string;
  email: string;
}

const AdminOnboarding: React.FC<Props> = ({
  intl,
  game,
  updateData,
  creationTeamCB
}) => {
  const membersArray =
    game.players ||
    range(1).map(n => {
      return { firstName: '', lastName: '', email: '' };
    });
  const [members, setMembers] = useState<IPlayer[]>(membersArray);
  // const [modalVisible, setModalVisible] = useState(false);
  const [validationTime, setValidationTime] = useState(false);

  // function showModal(value: any) {
  //   setModalVisible(value);
  // }

  function updateMemberState(index: number, property: string, value: string) {
    const newMember: IPlayer = members[index];
    switch (property) {
      case 'firstName': {
        newMember.firstName = value;
        break;
      }
      case 'lastName': {
        newMember.lastName = value;
        break;
      }
      case 'email': {
        newMember.email = value;
        break;
      }
    }
    const newMembers = cloneDeep(members);
    newMembers[index] = newMember;
    setMembers(newMembers);
  }

  function updateSpecificMember(
    newMember: IPlayer,
    add?: boolean,
    index?: number
  ) {
    const newMembers = cloneDeep(members);
    if (add !== true && (index || index === 0)) {
      newMembers[index] = newMember;
    } else {
      newMembers.push(newMember);
    }
    setMembers(newMembers);
  }

  function addRowMember() {
    updateSpecificMember({
      firstName: '',
      lastName: '',
      email: ''
    });
  }

  function onConfirmClick() {
    creationTeamCB();
    //showModal(true);
  }

  function isInputAllValid() {
    return (
      // teamName !== '' &&
      filter(
        members,
        ({ firstName, lastName, email }) =>
          firstName === '' ||
          lastName === '' ||
          (email === '' || !EmailValidator.validate(email))
      ).length === 0
    );
  }

  function isInputAllFilled() {
    return (
      filter(
        members,
        ({ firstName, lastName, email }) =>
          firstName === '' || lastName === '' || email === ''
      ).length === 0
    );
  }

  function onBlurMember() {
    updateData(
      members.map(({ firstName, lastName, email }) => ({
        firstName,
        lastName,
        email
      })),
      'players'
    );
  }

  function onAddRowClick() {
    addRowMember();
    setValidationTime(false);
  }

  function onDeleteRowClick(indexMember: number) {
    setMembers(filter(members, (member, index) => index !== indexMember));
  }

  return (
    <div className="AdminOnboarding">
      <Card>
        <div className="grid-container">
          <div className="members-container">
            <div className="grid-item item-full">
              <Header as="h4">
                <FormattedHTMLMessage id="onboarding-team-number:label" />
              </Header>
            </div>
            {members.map(({ lastName, firstName, email }, index) => {
              return (
                <div className="grid-item item-full" key={index}>
                  <b>{index + 1}.</b>
                  <div className="third">
                    <Label size="large">
                      {intl.formatMessage({ id: 'member-name:label' })}
                    </Label>
                    <input
                      value={firstName}
                      onBlur={() => onBlurMember()}
                      onChange={el =>
                        updateMemberState(index, 'firstName', el.target.value)
                      }
                      className={`${
                        validationTime && firstName === '' ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className="third">
                    <Label size="large">
                      {intl.formatMessage({ id: 'member-lastname:label' })}
                    </Label>
                    <input
                      value={lastName}
                      onBlur={() => onBlurMember()}
                      onChange={el =>
                        updateMemberState(index, 'lastName', el.target.value)
                      }
                      className={`${
                        validationTime && lastName === '' ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className="third">
                    <Label size="large">
                      {intl.formatMessage({ id: 'member-email:label' })}
                    </Label>
                    <input
                      value={email}
                      type="email"
                      onBlur={() => onBlurMember()}
                      onChange={el =>
                        updateMemberState(index, 'email', el.target.value)
                      }
                      className={`${
                        validationTime &&
                        (email === '' || !EmailValidator.validate(email))
                          ? 'invalid'
                          : ''
                      }`}
                    />
                  </div>
                  {index !== 0 ? (
                    <div
                      className="remove-row-members"
                      onClick={() => onDeleteRowClick(index)}>
                      {' '}
                      -{' '}
                    </div>
                  ) : (
                    <div style={{ width: '3rem', height: '3rem' }} />
                  )}
                </div>
              );
            })}
          </div>
          <div className="grid-item item-full">
            <div className="add-row-members" onClick={() => onAddRowClick()}>
              {' '}
              +{' '}
            </div>
          </div>
          <div className="confirm-container">
            <div className="grid-item item-full">
              <WhiteButton
                className={`button-empty-white ${!isInputAllValid() &&
                  'disabled'}`}
                onClick={() =>
                  isInputAllValid() ? onConfirmClick() : setValidationTime(true)
                }>
                {intl.formatMessage({ id: 'onboarding-confirm-button:label' })}
              </WhiteButton>
            </div>
            <p className="validation-message">
              {validationTime &&
                !isInputAllValid() &&
                (!isInputAllFilled() ? (
                  <FormattedHTMLMessage id="onboarding-incomplete:label" />
                ) : (
                  <FormattedHTMLMessage id="onboarding-wrong-email:label" />
                ))}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default injectIntl(AdminOnboarding);
