import React from 'react';
import './style.scss';

interface Props {
    options: string[],
    onChange: (value: string, index: number) => void,
    selected: number,
}

const Selector: React.FC<Props> = ({options, selected, onChange}) => {
    return (
        <div className="selector">
            {options.map((option, index) => (
                <p
                key={option}
                className={`option
                    ${index === selected ? 'selected' : ''}
                    ${index === 0 ? 'first' : ''}
                    ${index === options.length - 1 ? 'last' : ''}`
                }
                onClick={() => {
                    if (index === selected) return;
                    onChange(option, index);
                }}
                >
                    {option}
                </p>
            ))}
        </div>
    );
}

export default Selector;
