import './style.scss';
import React, { useContext } from 'react';
import Image from '../../Image';
import { ISession } from '../../../interfaces';
import { AdminContext, AppContext } from '../../../context';
import route from '../../../route';
import { FormattedMessage } from 'react-intl';

interface Props {
  history: any
  sessionList: ISession[]
}

const Index: React.FC<Props> = (props) => {

  const { session, setSession } = useContext(AppContext);
  const { ssoUser } = useContext(AdminContext);
  
  const areParameterSelected = (session: ISession) => !!session?.data.paramId

  const activeSessions = props.sessionList.filter(s => !s.data.terminated);

  return (
    <section className='session-section'>
      <div className='content-container'>
        <div className='history-sessions'>

          { activeSessions.length > 0 ? (
            <div className='list'>
              <div className='header-list'>
                <p className='text date'>
                  <FormattedMessage id="admin-sessions-date" />
                </p>
                <p className='text teachers'>
                  <FormattedMessage id="admin-sessions-name" />
                </p>
                <p className='text parameter'>
                  <FormattedMessage id="admin-sessions-parameters" />
                </p>
                <p className='text parameter-warn'>&nbsp;</p>
              </div>
              <div className='list-content'>
                { activeSessions.map((s, idx) => (
                    <div 
                      key={s.id}
                      className={'element-box ' + (session?.id === s.id ? 'selected' : '')}
                      onClick={()=> {
                        setSession!(s);
                        props.history.push(route(
                          'adminSessionsWithSelection', { 
                            gameSessionId: s.id,
                            userId: ssoUser 
                          }
                        ));
                      }}
                    >    
                      <p className='text date'>{
                        new Date(s.date!).toLocaleString()
                      }</p>
                      <p className='text teachers'>{s.name}</p>
                      <p className='text parameter'>{s.data.paramLabel}</p>
                      <p className='text parameter-warn'>
                        { !areParameterSelected(s) ? 
                          <Image name="warning" className="param-warn-icon" /> :
                          <span></span>
                        }
                      </p>
                    </div>
                  ))
                }
              </div>
            </div>
          ) : (
            <div className='no-session'>
              <p className='info-title'>
                  <FormattedMessage id="admin-sessions-feedback-no-session-active" />
              </p>
            </div>
          )}
        </div>
      </div>
    </section>)
}

export default Index
