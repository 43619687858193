import { AssetType } from "../../api"

export type Lang = 'Ita' | 'Eng';
export type Section = 'Phases' | 'UploadDocuments' | 'UploadVideo';
export interface VideoData {
	id			     : string
	chapterIndex : number
	index			   : number
  name         : string
  nameEng      : string
	url			     : string
	urlEng		   : string
}
export interface DocumentData {
	id			     : string
	chapterIndex : number
	index			   : number

	name         : string
	nameEng      : string
	url			     : string
	urlWord		   : string
	urlEng		   : string
	urlEngWord   : string

	itaSizePdf	 : number
	itaSizeWord	 : number
	engSizeWord	 : number
	engSizePdf   : number

	previewImage ? : string
}

export interface FilesData{
  wordIta : File | undefined,
  wordEng : File | undefined,
  pdfIta  : File | undefined,
  pdfEng  : File | undefined
}

const emptyFilesTemplate : FilesData = {
  wordIta   :  undefined,
  wordEng   :  undefined,
  pdfEng    :  undefined,
  pdfIta    :  undefined
}

export type MediaInput =  { type: AssetType, data: (VideoData | DocumentData) }
interface MediaStaticChapter {
  id: string;
  index: number;
  name: string,
  assets:
    { type 	  :  'video' | 'document'
      key     :  string 
      label   :  string
      labelEng:  string
    }[]
}

export const chapters: MediaStaticChapter[] = [
  {
    id: '0',
    index: 0,
    name: 'Prologo',
    assets: [
      {
        key: 'video-prologue',
        type: 'video',
        label: 'Una fredda sera di Dicembre',
        labelEng:'A cold December night'
      },
      {
        key: 'doc-prologue',
        type: 'document',
        label: 'Una fredda sera di Dicembre',
        labelEng:'A cold December night'
      }
    ]
  },
  {
    id: '1',
    index: 1,
    name:'Contesto',
    assets: [
      {
        key: 'doc-contesto',
        type: 'document',
        label: "EuroFire: l'azienda e il contesto competitivo",
        labelEng:'EuroFire: the competitive environment'
      }
    ]
  },
  {
    id: '2',
    index: 2,
    name: 'Fase 1',
    assets: [
      {
        key: 'doc-bilanci-italinox',
        type: 'document',
        label: 'Il bilancio di italInox',
        labelEng:'ItalInox financial statement'
      }
    ]
  },
  {
    id: '3',
    index: 3,
    name: 'Fase 2',
    assets: [
      {
        key: 'doc-prospettiva',
        type: 'document',
        label: 'La prospettiva di medio termine',
        labelEng:'Medium-term outlook'
      },
      {
        key: 'video-contesto',
        type: 'video',
        label: 'EuroFire: Il sistema produttivo',
        labelEng:'EuroFire: the manufacturing process'
      },
      {
        key: 'doc-sistema-produttivo',
        type: 'document',
        label: 'EuroFire: Il sistema produttivo',
        labelEng:'EuroFire: the manufacturing process'
      },
      {
        key: 'doc-produzione',
        type: 'document',
        label: 'I costi di produzione',
        labelEng:'Production costs'       
      },
      {
        key: 'doc-capacita',
        type: 'document',
        label: 'Le capacità produttive',
        labelEng:'Production capacity'
      },
      {
        key: 'doc-effetto-kit',
        type: 'document',
        label: "Le sinergie commerciali: L'effetto kit",
        labelEng:'Business integration: the kit effect'
      }
    ]
  },
  {
    id: '4',
    index: 4,
    name: 'Fase 3',
    assets: [
      {
        key: 'doc-ristrutturare',
        type: 'document',
        label: 'La ristrutturazione produttiva',
        labelEng:'Restructuring production'
      },
      {
        key: 'doc-immagine',
        type: 'document',
        label: 'La politica di brand',
        labelEng:'Branding'
      },
      {
        key: 'doc-prezzi',
        type: 'document',
        label: 'La politica di prezzo',
        labelEng:'Pricing'
      }
    ]
  },
  {
    id: '5',
    index: 5,
    name: 'Fase 4',
    assets: [
      {
        key: 'doc-valutazione-finanziaria',
        type: 'document',
        label: 'La prospettiva finanziaria',
        labelEng:'Financial assessment'
      }
    ]
  }
];

export type DocumentsMap = Map<string, FilesData>

export const keysMedia = [
  'video-prologue',
  'doc-prologue',
  'doc-contesto',
  'doc-bilanci-italinox',
  'doc-prospettiva',
  'video-contesto',
  'doc-sistema-produttivo',
  'doc-produzione',
  'doc-capacita',
  'doc-effetto-kit',
  'doc-ristrutturare',
  'doc-immagine',
  'doc-prezzi',
  'doc-valutazione-finanziaria',
];



export const assetsMap = new Map<string, MediaInput | undefined>([
  ['video-prologue', undefined],
  ['doc-prologue', undefined],
  ['doc-contesto', undefined],
  ['doc-bilanci-italinox', undefined],
  ['doc-prospettiva', undefined],
  ['video-contesto', undefined],
  ['doc-sistema-produttivo', undefined],
  ['doc-produzione', undefined],
  ['doc-capacita', undefined],
  ['doc-effetto-kit', undefined],
  ['doc-ristrutturare', undefined],
  ['doc-immagine', undefined],
  ['doc-prezzi', undefined],
  ['doc-valutazione-finanziaria', undefined]
])

export const assetsFileMap = new Map<string, FilesData>([
  ['doc-prologue',  emptyFilesTemplate],
  ['doc-contesto', emptyFilesTemplate],
  ['doc-bilanci-italinox', emptyFilesTemplate],
  ['doc-prospettiva', emptyFilesTemplate],
  ['doc-sistema-produttivo', emptyFilesTemplate],
  ['doc-produzione',  emptyFilesTemplate],
  ['doc-capacita', emptyFilesTemplate],
  ['doc-effetto-kit', emptyFilesTemplate],
  ['doc-ristrutturare', emptyFilesTemplate],
  ['doc-immagine', emptyFilesTemplate],
  [ 'doc-prezzi', emptyFilesTemplate],
  ['doc-valutazione-finanziaria', emptyFilesTemplate]
])



