import React, { useEffect, useState } from 'react';
import './style.scss';
import { MediaInput,  assetsMap, chapters, FilesData, Section, Lang } from './data-templates';
import DocumentsTab from './document-tab';
import VideoTab from './video-tab';
import UploadDocuments from './upload-documents';
import UploadVideo from './upload-video';
import Image from '../Image';
import Selector from '../Selector';
import ResetButton from '../ResetButton';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';


interface Props {
 mediaList : MediaInput[]
 setMediaList : (assets: MediaInput[]) => void
 documentsMap: Map<string, FilesData>,
 setDocumentsMap : (filesMap: Map<string, FilesData>) => void
 setMissingData : (bool: boolean) => void
 isEditable: boolean
 setShowExtraInfo: (bool: boolean) => void
 intl: InjectedIntl
}

const MediaContainer: React.FC<Props> = (
  { mediaList, 
    setMediaList, 
    documentsMap, 
    setDocumentsMap, 
    setMissingData,
    setShowExtraInfo,
    isEditable,
    intl
  }) => {
  
  //Chapters
  const [chapter, setChapter] = useState(chapters[0]);
  const [chapterIndex, setChapterIndex] = useState<number>(0)

  //Maps
  const [assets, setAssets] = useState(new Map(assetsMap))

  //View triggers
  const [currentAsset, setCurrentAsset] = useState<MediaInput | undefined>(undefined);
  const [lang, setLang] = useState<Lang>('Ita');
  const [visibleSection, setVisibleSection] = useState<Section>('Phases');
  
  const [searchLabel, setSearchLabel] = useState<string>('');

  const handleLabelSearch = (event: React.ChangeEvent<HTMLInputElement> ) => {
    setSearchLabel(event.currentTarget.value);
  }

  useEffect(() => {
    const newMap = new Map(assetsMap) 
    mediaList.forEach((media) => {
      const key = media.data.id;
      if (newMap.has(key)) {
        newMap.set(key, media);
      }
    });
    setAssets(newMap);
  }, [mediaList])
  
  //Check every media is uploaded
  useEffect(() => {
    const assetValues = Array.from(assets.values());
    const allValuesDefined = assetValues.every(value => value !== undefined); 
    setMissingData(!allValuesDefined)
  }, [assets, setMissingData]);

  //Change view 
  const setSection = (section: Section) => {
    setShowExtraInfo(section === 'Phases');
    setVisibleSection(section)
  }

  const onViewAsset = (asset: MediaInput) => {
    setCurrentAsset(asset)
  }

  const close = (): void => {
    setSection('Phases');
  };

  //Map 
  const updateAssetsMap = (key: string, value: MediaInput) => {
    if (!isEditable) return;
    const updatedAssets = new Map(assets)
    updatedAssets.set(key, value)
    const newList = Array.from(updatedAssets.values());
    setMediaList(newList.filter(value => value !== undefined) as MediaInput[])
  }

  const updateFileMap = (key: string, value: FilesData) => {
    if (!isEditable) return;
    const updatedAssets = new Map(documentsMap)
    updatedAssets.set(key, value)
    setDocumentsMap(updatedAssets)
  }

  const filteredAssets = (): any[] => {
    return chapter.assets.filter(
      (asset) => asset.label.toLowerCase().includes(searchLabel.toLowerCase())
    )
  }

  return (
    <div className="media-container">
      {visibleSection === 'Phases' && (
        <div className="content-container">
          <div className="header-media">
            {/* Phases Selector */}
            <div className="phases-container">
              {chapters.map((cpt, index) => (
                <div
                  key={index}
                  onClick={() =>{setChapter(cpt); setChapterIndex(index)}}
                  className={`phase ${cpt.id === chapter.id ? 'isClicked' : ''}`}
                >
                  <FormattedMessage id={`admin-sessions-phase:${cpt.name}`} />
                </div>
              ))}
            </div>
            {/* Language Selector */}
            <Selector
                options={['Ita', 'Eng']}
                selected={lang === 'Ita' ? 0 : 1}
                onChange={(option) => { setLang(option as Lang ) }}
            />
          </div>

          <div className="filters">
            <div className="input-box">
              <input className="input" placeholder={intl.formatMessage({id: "admin-sessions-label-search"})} onChange={handleLabelSearch} value={searchLabel} />
              <Image name="search" className="search-icon" />
              <ResetButton onClick={() => setSearchLabel('')}>Reset</ResetButton>
            </div>
          </div>

          {filteredAssets().length !== 0 ? <div className="chapters-container">
            {filteredAssets()
            .map((asset, i) => (
              asset.type==='video'? 

              <VideoTab
                key={i}
                lang={lang}
                index = {i} 
                chapterIndex={chapterIndex}
                label= {asset.label}
                labelEng={asset.labelEng}
                assetKey ={asset.key}      
                assets = {assets}
                onViewAsset = {onViewAsset}
                setSection  = {setSection}
                isEditable = {isEditable}
              /> :

              <DocumentsTab
                key={i}
                lang={lang}
                index = {i} 
                chapterIndex={chapterIndex}
                label= {asset.label}
                labelEng={asset.labelEng}
                assetKey ={asset.key}      
                assets = {assets}
                localFilesMap={documentsMap}
                onViewAsset = {onViewAsset}
                setSection  = {setSection}
                isEditable = {isEditable}
              /> 

            ))}
          </div> : <p className="info-text">
            <FormattedMessage id="admin-sessions-feedback-no-media" />
          </p> }
        </div>
      )}
      {visibleSection === 'UploadDocuments' && <UploadDocuments close={close} asset={currentAsset} filesMap={documentsMap} updateDocument={updateAssetsMap} updateFile={updateFileMap} />}
      {visibleSection === 'UploadVideo' && <UploadVideo close={close} asset={currentAsset} updateVideo={updateAssetsMap} />}
      
    </div>
  );
};

export default injectIntl(MediaContainer);
