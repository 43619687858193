
import { AssetQueryOutput } from '../../../../api';
import gameModel from './gameModel';
import { teamName } from './teams';
import prologo from './prologo';
import contesto from './contesto';
import chapter1 from './chapter1';
import chapter2 from './chapter2';
import chapter3 from './chapter3';
import chapter4 from './chapter4';
import { createMatrix } from './utils';

const generateToken = () => {
  return Math.random().toString(10).substring(2, 8);
};

export function createSessionData(teamNumber: number) {

  const session: any = { 'games': {}};

  const generatedTokens: string[] = [];

  for (let index = 0; index < teamNumber; index++) {
    let token = generateToken();
    while (generatedTokens.includes(token)) {
      token = generateToken();
    }
    session['games'][token] = {
      token,
      ...gameModel(teamName[index])
    }
    generatedTokens.push(token);
  }
  return session;
}

export function updateParameters(sessionData: any, assets: AssetQueryOutput[]) {

  const parameter = JSON.parse(
      assets.find(asset => asset.type === 'parameter')!.data!
  );
  const matrixes = [
    [ 2, chapter1, parameter.filter((x: any) => x.chapterIndex === 0) ],
    [ 3, chapter2, parameter.filter((x: any) => x.chapterIndex === 1) ],
    [ 4, chapter3, parameter.filter((x: any) => x.chapterIndex === 2) ],
    [ 5, chapter4, parameter.filter((x: any) => x.chapterIndex === 3) ]
  ];
  matrixes.forEach(x => {
    const [chapterIdx, chapterData, parameters] = x;
    for (let token in sessionData.games) {
      const chapter = sessionData.games[token].chapters[chapterIdx];
      chapter.matrix = createMatrix(
        chapter.matrix, chapterData, parameters
      );
    }
  });

  console.log(matrixes)
}

export function updateMedia(sessionData: any, assets: AssetQueryOutput[]) {

  const media = assets
      .filter(asset => ['video', 'document'].includes(asset.type))
      .map(x => JSON.parse(x.data!));

  const mediaMap = new Map<string, any>(media.map((m: any) => [m.id, m]));

  [ [0, prologo] , [1, contesto], [2, chapter1], 
    [3, chapter2], [4, chapter3], [5, chapter4] ].forEach((x: any[]) => {
      const [chapterIdx, chapterData] = x;
      
      for (let token in sessionData.games) {
        const chapter = sessionData.games[token].chapters[chapterIdx];
        chapter.assets = chapterData.assets.map((x: any, idx: number) => {
          return { ...x,
            ...chapter.assets[idx],
            name    : mediaMap.get(x.id)?.name,
            nameEng : mediaMap.get(x.id)?.nameEng,
            url     : mediaMap.get(x.id)?.url,
            urlEng  : mediaMap.get(x.id)?.urlEng,

            previewImage: 'preview'     // TODO remove
          };
        });
      }
  });
}
