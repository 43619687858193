import { EChapterKind, IGame } from '../interfaces';
import { IChapter } from '../interfaces';
import map from 'lodash/map';
import find from 'lodash/find';
import every from 'lodash/every';

const activeByKind = (chapter: IChapter) => {
  switch (chapter.kind) {
    case EChapterKind.EPILOGUE:
      return chapter.excelUpload || chapter.pptUpload;
    default:
      return chapter.assets.filter(asset => asset.active).length > 0;
  }
};

const addIndex = (items: any) => {
  return items
    ? items.map((ele: any, index: number) => ({ ...ele, index }))
    : [];
};

const generateState = (game: IGame) => {
  const newGame = { ...game };
  newGame.chapters = newGame.chapters.map((chapter, index) => {
    let matrix: any[][];
    if (chapter.kpis && chapter.scenarios && chapter.matrix) {
      matrix = Array(chapter.kpis.length)
        .fill(null)
        .map(() => Array(chapter.scenarios.length).fill(null));
      for (let i = 0; i < chapter.kpis.length; i++) {
        for (let y = 0; y < chapter.scenarios.length; y++) {
          matrix[i][y] = chapter.matrix[i][y]
            ? {
                ...chapter.matrix[i][y],
                rowIndex: i,
                columnIndex: y,
                chapterIndex: index
              }
            : null;
        }
      }
    } else {
      matrix = [[]];
    }

    return {
      ...chapter,
      index,
      assets: addIndex(chapter.assets),
      /* .map((asset: any) =>
          ({
            ...asset,
            isLocked: activeScenarios.some((scenario: any) => {
              if (scenario && scenario.dependOn) {
                return scenario.dependOn.includes(asset.id)
              }
            })
          })) */ kpis:
        chapter.kpis &&
        chapter.kpis.map((kpi, index) => {
          const show = kpi.dependOn
            ? every(map(kpi.dependOn, id => find(chapter.assets, ['id', id])), [
                'active',
                true
              ])
            : true;
          return {
            ...kpi,
            label: kpi.id,
            index,
            show
          };
        }),
      scenarios:
        chapter.scenarios &&
        chapter.scenarios.map((scenario, index) => {
          /*  let show = false;
           let isLocked = false;
           if (scenario.dependOn) {
             const dependencyCondition = scenario.dependOn ?
               every(
                 map(scenario.dependOn, id =>
                   find(chapter.assets, ['id', id])
                 ), ['active', true]
               ) :
               true;
 
             show = dependencyCondition;
             if (chapter.id == '2') {
               const layerCondition = scenario.dependOn.length - 1 == activeScenarios.length || scenario.active!!
  */
          /* const dependOnSet = new Set<String>([]);
          let uniqueCondition = true;
          activeScenarios.forEach(scenario => {
            if (scenario.dependOn) {
              return scenario.dependOn.map(dependence => dependOnSet.add(dependence))
            }
          });
          uniqueCondition = scenario.dependOn.every(dependency => dependOnSet.has(dependency));
          console.log(dependOnSet); */
          //show = show && layerCondition; /* && uniqueCondition */
          /* isLocked = activeScenarios.length > scenario.dependOn.length;
        }
      } else {
        show = true;
      } */

          const show = scenario.dependOn
            ? every(
                map(scenario.dependOn, id => find(chapter.assets, ['id', id])),
                ['active', true]
              )
            : true;
          return {
            ...scenario,
            label: scenario.id,
            index,
            show
            /* isLocked */
          };
        }),
      matrix
    };
  });
  return newGame;
};

export { activeByKind, generateState };
