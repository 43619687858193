import React from 'react';
import PropTypes, { string } from 'prop-types';
import HtmlToReact, { Parser } from 'html-to-react';
import { Text } from '@react-pdf/renderer';

const parser = new Parser();
const isValidNode = function () {
  return true;
};
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const processingInstructions = [
  {
    // This is REQUIRED, it tells the parser
    // that we want to insert our React
    // component as a child
    //replaceChildren: true,
    shouldProcessNode: function (node: any) {
      return node.type === "tag" && node.name === "b"
    },
    processNode: function (node : any , children : any , index : number ) {
      return <Text style={{ fontWeight : 'bold' }} key={index}>{children}</Text>;
    }
  },
  {
    shouldProcessNode: function (node: any) {
      return node.type === "tag" && node.name === "i"
    },
    processNode: function (node: any, children: any, index: number) {
      return <Text style={{ fontStyle : 'italic' }} key={index}>{children}</Text>;
    }
  },
  {
    // Anything else
    shouldProcessNode: function (node : any) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];


const propTypes = {
  text : string.isRequired,
  styles: StyleSheet
}

type Props = PropTypes.InferProps<typeof propTypes>

const HtmlText : React.SFC<Props> = ({ text, styles }) => {
  const element = parser.parseWithInstructions( text, isValidNode, processingInstructions )
  return <Text style={styles}>{element}</Text>;
}


export default HtmlText;