/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onSessionUpdate = /* GraphQL */ `
  subscription OnSessionUpdate($session: ID!, $path: String!) {
    onSessionUpdate(session: $session, path: $path) {
      data
      path
      session
      __typename
    }
  }
`;
export const onAssetsUpdate = /* GraphQL */ `
  subscription OnAssetsUpdate($groupId: ID, $type: AssetType) {
    onAssetsUpdate(groupId: $groupId, type: $type) {
      op
      types
      groups
      data
      __typename
    }
  }
`;
